import React from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import { Card, CardContent, Typography, IconButton, Box, CardMedia, Chip, Tooltip, CardActionArea } from '@mui/material';
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { UserContext } from "../../context/usercontext";
import { LikeCount } from "../tests/stoybuttons";
import { GET_REDEEMED_BY_USER, REDEEM_BEANS, UPDATE_USER_PROF } from "../../context/gqllogics";

const REWARDS = gql`
    query Rewards {
        rewards {
            _id
            Title
            description
            beans
            imageurl
            available
        }
    }
`;

const GET_COMMENTS_BY_CONTENT_CREATOR = gql`
  query GetCommentsByContentCreator($contentCreator: String!) {
    comments(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      author
      contentCreator
      content
    }
  }
`;

const GET_LIKES_BY_CONTENT_CREATOR = gql`
  query GetLikesByContentCreator($contentCreator: String!) {
    likes(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      user
      contentCreator
      likecount
    }
  }
`;  // GraphQL Query

const Rewards = ({ triggerFetch }) => {
    const { user } = useContext(UserContext);
  const [rewards, setRewards] = useState([]);
  const [rewardsCount, setRewardsCount] = useState(0);
  const [totalLikeCount, setTotalLikeCount] = useState(0);
  const contentCreatorId = typeof user?.id === 'object' ? user.id.id : user.id;
  
  // Lazy query for likes
  const [getLikesByContentCreator, { loading: loadingLikes, data: likesData }] = useLazyQuery(GET_LIKES_BY_CONTENT_CREATOR, {
    variables: { contentCreator: contentCreatorId },
  });

  // Lazy query for redeemed rewards
  const [getRedeemedByUser, { data: dataUserprof }] = useLazyQuery(GET_REDEEMED_BY_USER, {
    variables: { userId: user.id },
  });

  // Effect to trigger lazy query when `triggerFetch` is true
  useEffect(() => {
    if (triggerFetch) {
      getLikesByContentCreator(); // Fetch likes
      getRedeemedByUser();        // Fetch redeemed rewards
    }
  }, [triggerFetch, getLikesByContentCreator, getRedeemedByUser]);

  // Update the total like count when likes data is available
  useEffect(() => {
    if (likesData) {
      const sumOfLikeCounts = likesData.likes.reduce((total, like) => total + (like.likecount || 0), 0);
      setTotalLikeCount(sumOfLikeCounts);
    }
  }, [likesData]);

  // Update the total rewards count when data is available
  useEffect(() => {
    if (dataUserprof) {
      const totalRewards = dataUserprof.redeems.reduce((acc, redeem) => acc + Number(redeem.reward), 0);
      setRewardsCount(totalRewards);
    }
  }, [dataUserprof]);

  const currentRewardsCount = Number(totalLikeCount) - Number(rewardsCount);

  return (
    <>
      <Typography variant="body1" fontWeight={700}>{currentRewardsCount}</Typography>
    </>
  );
};

export default Rewards;

  

  
