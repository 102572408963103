import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";  
import { UserContext } from '../../context/usercontext';
import { useContext, useState, useEffect } from 'react';
import Stack from "@mui/material/Stack";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link } from "react-router-dom";

import { useNavigate, useLocation } from "react-router-dom";
import Input from '@mui/material/Input';
import { makeStyles } from '@mui/styles';
import { Avatar } from "@mui/material";
import UsersList from "../tests/usertype";
import Work from "../tests/work";
import UserProfList from "../tests/again";
import { Log } from "ethers";
import { Logout } from "@mui/icons-material";
//import LogoutButton from "./logout";
import { Grid, Paper, 
 Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import FileUploadComponent from "./fileupload";
import { styled } from '@mui/material/styles';
import { color } from "@mui/system";
import Progress from "../mods/progress";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  
}));

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 400,
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
  },
  content: {
    padding: 24,
    transition: '0.3s',
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  }
}));




const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      Nickname
      imageurl
      usertype
      Koffie_story
      Title
      
    }
  }
`; 


const GET_USER = gql`
  query Userprof ($_id: ObjectId!)  {
  userprof (query: {_id: $_id}) {
   
    
    Nickname
    Title
    _id
    Koffie_story
    imageurl
  }
}
`; 

export default function Update() {
  const [updateOneUserprof, ] = useMutation(UPDATE_USERPROF);
  const [Nickname, setNickname] = useState("");
  const [Title, setTitle] = useState("");
 
  const [Koffie_story, setkoffieStory] = useState("");
  const [imageURL, setImageURL] = useState("");  // State to hold the uploaded image URL
  const { user } = useContext(UserContext);
  const { loading, error, data } = useQuery(GET_USER, {
      variables: { _id: user.id },
    });
  const [editing, setEditing] = useState(false);
const classes = useStyles();
      const location = useLocation();
      const passedUser = location.state?.user; // Access user data passed from Login component
    
     
      useEffect(() => {
        if (passedUser) {
          setNickname(passedUser.Nickname || "");
          setTitle(passedUser.Title || "");
        
          setkoffieStory(passedUser.Koffie_story || "");
          setImageURL(passedUser.imageurl || "");
        } else if (data?.userprof) {
          setNickname(data.userprof.Nickname);
          setTitle(data.userprof.Title);
         
          setkoffieStory(data.userprof.Koffie_story);
          setImageURL(data.userprof.imageurl);
        }
      }, [data, passedUser]);
 
      const handleEdit = () => {
        setEditing(true);
       
    }

    

    const handleSubmit = async () => {
      // Step 1: Upload the file and get the URL
      // This assumes that you have a function `uploadFile` in your `FileUploadComponent` that
      // uploads the file and returns the URL.
      // await uploadFile();
    
      // Step 2: Execute the GraphQL mutation
      const variables = {
        query: { _id: user.id },
        set: {
          Nickname: Nickname,  // Replace with your actual data
          imageurl: imageURL,   // This should now contain the URL from the upload
          Title: Title,
          
          Koffie_story: Koffie_story,

        }
      };
    
      try {
        await updateOneUserprof({ variables });
        console.log("Successfully updated the user profile.");
      } catch (error) {
        console.log("An error occurred while updating the user profile:", error);
      }
    };
    

    if (loading) return <div><Progress/></div>
      
    if (error) return <div>Unexpected Error: {error.message}</div>

    if(editing) {
      return (
          <div>
     
              <Card className={classes.root} sx={{ minWidth: 275 }}>
              <div>
            <label>Profile Image:</label>
            <FileUploadComponent setImageURL={setImageURL} />
          </div>
                <div>
                  <label>
                    Nickname
                    <Input
                      type="text"
                      value={Nickname}
                      onChange={(event) => setNickname(event.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Title:
                    <Input
                      type="text"
                      value={Title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </label>
                </div>
                {/* Similar divs for Title, cherryCount, and Koffie_story */}
                <div>
                <div>
                  <label>
                    Story
                    <Input
                      type="text"
                      value={Koffie_story}
                      onChange={(event) => setkoffieStory(event.target.value)}
                    />
                  </label>
                </div>
                  <Button onClick={handleSubmit} type="submit">Submit</Button>
                </div>
              </Card>
          </div>
      );
  } else {
    return (
      <>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <Item>
  <Box display="flex" alignItems="center">
    
    <Typography variant="h6" style={{ marginLeft: 10 ,color: '#000' }}> {Nickname}</Typography>
  </Box>
  </Item>
</Grid>
  <Grid item xs={12}>
    <Item>
    <FileUploadComponent setImageURL={setImageURL} userId={user.id} nickname={Nickname} />
    </Item>
  </Grid>
  
</Grid>
      <Card className={classes.root}>
    
      <CardContent className={classes.content}>
        <Typography variant="body2" color="text.secondary" component="p" className={classes.textInfoContent}>
          KOFFIE PROFILE
          
          <Typography>Nickname: {Nickname}</Typography>
          <Typography>Title: {Title}</Typography>
          <Typography>Image: {imageURL}</Typography>
          <Typography>Koffie Story: {Koffie_story}</Typography>
          <Button  onClick={handleEdit}>Edit</Button>
          </Typography>
      </CardContent>
      
    </Card>
    
    
          
        </>
      
    )
  }
}






