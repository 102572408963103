import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import spark from '../../images/spark1.png';

const vertexShader = `
  attribute float size;
  varying vec3 vColor;
  void main() {
    vColor = color;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_PointSize = size * ( 300.0 / -mvPosition.z );
    gl_Position = projectionMatrix * mvPosition;
  }
`;

const fragmentShader = `
  uniform sampler2D pointTexture;
  varying vec3 vColor;
  void main() {
    gl_FragColor = vec4( vColor, 1.0 );
    gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
  }
`;

function ThreeScene() {
  const containerRef = useRef();

  useEffect(() => {
    // Setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.z = 300;
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    
    // Custom shader material
    // You need to replace 'your_vertex_shader_code' and 'your_fragment_shader_code' with your actual shader codes
    const uniforms = {
        pointTexture: { value: new THREE.TextureLoader().load(spark) }
      };
      
    const shaderMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true,
        vertexColors: true
      });

    // Buffer Geometry
    const particles = 100000;
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];
    const sizes = [];
    const color = new THREE.Color();
    const radius = 200;
    for (let i = 0; i < particles; i++) {
      positions.push((Math.random() * 2 - 1) * radius);
      positions.push((Math.random() * 2 - 1) * radius);
      positions.push((Math.random() * 2 - 1) * radius);

      color.setHSL(i / particles, 1.0, 0.5);
      colors.push(color.r, color.g, color.b);

      sizes.push(20);
    }
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));

    // Particle System
    const particleSystem = new THREE.Points(geometry, shaderMaterial);
    scene.add(particleSystem);

    // Append renderer to the container
    containerRef.current.appendChild(renderer.domElement);

    // Animation loop
    const animate = () => {
      const time = Date.now() * 0.005;
      particleSystem.rotation.z = 0.01 * time;
      const sizes = geometry.attributes.size.array;
      for (let i = 0; i < particles; i++) {
        sizes[i] = 10 * (1 + Math.sin(0.1 * i + time));
      }
      geometry.attributes.size.needsUpdate = true;
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };
    animate();

    // Handle window resize
    const handleWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleWindowResize);

    // Clean up on unmount
    return () => {
        window.removeEventListener('resize', handleWindowResize);
        if (containerRef.current) { // add this check
          containerRef.current.removeChild(renderer.domElement);
        }
      };
  }, []);

  return <div ref={containerRef} />;
}

export default ThreeScene;
