import React, { useState } from "react";
import {
  Card,
  Table,
  Header,
  Statistic,
  Button,
  Typography,
} from "@mui/material";

const FarmerProfile = () => {
  const [data, setData] = useState([
    {
      country: "Brazil",
      production: 5714381000,
    },
    {
      country: "Vietnam",
      production: 3637627000,
    },
    {
      country: "Colombia",
      production: 1785744000,
    },
    {
      country: "Indonesia",
      production: 1455050000,
    },
    {
      country: "Ethiopia",
      production: 846575000,
    },
    {
      country: "Honduras",
      production: 767208000,
    },
    {
      country: "India",
      production: 767208000,
    },
    {
      country: "Uganda",
      production: 634931000,
    },
    {
      country: "Mexico",
      production: 515881000,
    },
  ]);

  return (
    <Card>
      <div>
        <Typography variant="h5">Top 10 Coffee Producing Countries</Typography>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Country</th>
            <th>Production (pounds)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((producer) => (
            <tr key={producer.country}>
              <td>{producer.country}</td>
              <td>{producer.production}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="contained" color="primary">
        Learn More
      </Button>
    </Card>
  );
};

export default FarmerProfile;
