import React from 'react';
import styled from "styled-components";
import { Avatar, Chip, Fab, Button as MuiButton, Typography, Card, CardActionArea, IconButton, Popover,
CardContent, CardActions, Box
} from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom'
//import { Store } from '@mui/icons-material';
import  Progress  from '../../mods/progress';
import { CardMedia } from '@mui/material';
import { User } from 'realm-web';
import { useState } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const GET_PRODUCTS = gql`
  query Products {
    products {
      Title
      _id
      description1
      description2
      description4
      imageurl
      name
      price
      category
      farmer
    }
  }
`;

export const GET_ROASTERIES = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
      Koffie_story
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
 
  border-radius: 10px;
  padding: 16px;
`;

const UsersList = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 8px;
  gap: 16px; // Adjust the gap as needed

  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox
`;


const UserCard = styled.div`
  min-width: 250px; // Adjust the width as needed
  border: 1px solid ;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // This adds a subtle shadow to the card
  text-decoration: none;
  display: flex;
  flex-direction: column;
 
  gap: 8px; // Adjust the gap as needed
 
`;

const FollowButton = styled(MuiButton)`
  && {
    margin-top: 8px;
  }
`;

function StoreEquip({ userType="prodman" }) {
  
    const { loading, error, data } = useQuery(GET_PRODUCTS);

    if (loading) return <div><Progress/></div>;
    if (error) return <div>{null}</div>;
  
    // Group products by category
    const productsByCategory = data.products.reduce((acc, product) => {
      if (!acc[product.category]) {
        acc[product.category] = [];
      }
      acc[product.category].push(product);
      return acc;
    }, {});
  
    const desiredCategories = ['Gear', ]; // Specify desired categories
   
  
  
  return (
    <>
    <Container>
        <Typography variant="h6" color='text.secondary' gutterBottom>
            You may also like
        </Typography>
      <UsersList>                 
      {Object.keys(productsByCategory).map(category => (
  desiredCategories.includes(category) && productsByCategory[category].map(product => (
    <UserCard
    
  >
   <Link to={`/product/${product._id}`} style={{textDecoration: 'none', color: 'inherit',  }}>
      {/* Content goes here */}
    
    <CardMedia
      component="img"
      height="200"
      image={product.imageurl}
      alt={product.name}
      sx={{borderRadius: '10px 10px 0 0'}}
    />
    <CardContent 
    
  sx={{ 
   
    padding: 1, 
  
    textAlign: 'center', // Center-align the text
   
  }}
>

  
  <Typography variant="body2" sx={{ fontWeight: 800, textAlign: 'left', marginBottom: '8px', marginLeft: 'auto' }}>
    {product.name}
  </Typography>



  </CardContent>
  <CardActions>
  <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '8px', marginRight: 'auto' }}>
    ${product.price}
  </Typography>
  </CardActions>
  </Link>
  </UserCard>
  
  ))
))}

      </UsersList>
    </Container>
    
 </>
  );
}

// Component to fetch and display </></> image
function UserImage({ userID }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);



  const { loading, error, data } = useQuery(GET_ROASTERIES, {
      variables: { _id: userID },
  });

  if (loading) return <img src="https://via.placeholder.com/30" alt="user" style={{ width: 30, height: 30, borderRadius: 50 }} />;
  if (error) return   <Chip
  label="Coming Soon"
  
  avatar={<Avatar src="https://storage.googleapis.com/app_darkendimg/assets/farmericon.jpg" alt="Bean" />}
  variant="outlined"
  style={{ marginTop: 8,}}
/>;

  const userimage = data.userprofs[0]?.imageurl || 'Unknown';
  const username = data.userprofs[0]?.Nickname || 'Unknown';
  const userstory = data.userprofs[0]?.Koffie_story || 'Unknown';

  return (
    <>
    
    <Chip
        label={username}
        onClick={handleChipClick}
        avatar={<Avatar src={userimage} alt={username} />}
      />

<Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 16 }}>
          <Typography variant="body1">{userstory}</Typography>
        </div>
      </Popover>
    </>
    
  );
          
}

export default StoreEquip;
