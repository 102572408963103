import React from 'react';
import { Typography, Box, Card, CardContent, Grid, useMediaQuery, useTheme, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText, } from '@mui/material';
import Divider from '@mui/material/Divider';

function GoalCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

const gotostore = () => {
    navigate('/store');
  } 
  return (
    <Box sx={{ padding: 1, border: 'solid 1px #d1d1d1', borderRadius: 4, boxShadow: 0 , mb: 1}}>
       
        
      <Card sx={{ boxShadow: 0, padding: isMobile ? 2 : 3 , }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 0 }}>
            <img src='https://storage.googleapis.com/app_darkendimg/assets/thebec.webp' alt="beanify" style={{ width: 100 }} />
        </Box>
        <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold" gutterBottom align="center">
            BEC
            </Typography>
        <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold" gutterBottom align="center">
            Bean Exchange Commission
            </Typography>
        <CardContent>
          <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold" gutterBottom align='center'>
            Help Us Reach Our Goal of 96 Million Digital Beans (23k lbs) in Koffieland!
          </Typography>
          <Typography variant="body1" color="primary" gutterBottom align='center' sx={{ fontWeight: 300 }}>
            Every bag you purchase adds 4000 beans. Unlock the world of koffie when we meet this goal!
          </Typography>
          <Card sx={{ padding: '16px', borderRadius: 5, boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}>
  <Typography variant="h5" fontWeight={900} gutterBottom color='#077336' sx={{textAlign: 'center'}}>
    Bean Drive Campaign 
  </Typography>
  
  <List>
    <ListItem>
      <ListItemText
        primary="Proof of Concept (POC)"
        secondary={
          <>
            Contract Deployed: XDC Testnet<br />
            Quantity of Coffee: 23,000 lbs
          </>
        }
      />
    </ListItem>
    <Divider component="li" />
    <ListItem>
      <ListItemText
        primary="Calculation Details"
        secondary={
          <>
            Cherries per Pound: 2,000<br />
            Beans per Cherry: 2<br />
            Total Beans per Pound: 4,000
          </>
        }
      />
    </ListItem>
    <Divider component="li" />
    <ListItem>
      <ListItemText
        primary="Total Beans"
        secondary={
          <>
            Total Coffee: 23,000 lbs<br />
            Total Beans: 92,000,000 beans
          </>
        }
      />
    </ListItem>
  </List>
</Card>
          <Typography variant="body1" gutterBottom>
            <b>What We'll Do When We Reach Our Goal:</b>
          </Typography>
          <Typography variant="body2" gutterBottom  >
            - <b>Visit Farms:</b> Onboard koffie farmers directly.<br/>
            - <b>Share Content:</b>Exclusive content on the journey and the farmers.<br/>
            - <b>Enhance the Experience:</b> Introduce new features and rewards.<br/>
            - <b>Iterate:</b> Intoduce Native App for Android and iOS.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
            <Fab onClick={gotostore} variant="extended" sx={{ backgroundColor: '#ffc107', color: 'black', ":hover": { backgroundColor: '#3ff107' }  }}>
              Join Us Now
            </Fab>
          </Box>
          <Typography variant="caption" display="block" align="center" sx={{ marginTop: 2, ":hover": { color: 'green' } }}>
            Koffieland – Where every bean tells a story.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default GoalCard;
