import { useLazyQuery, useMutation, useQuery, gql } from '@apollo/client';
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/usercontext';


export const useGoalManagement = () => {
    const { user } = useContext(UserContext);
  // Apollo Client Queries and Mutations
  const { loading, data, error, refetch } = useQuery(GET_USER_GOALS_QUERY, {
    variables: { userID: user.id },
    fetchPolicy: 'cache-and-network',  // Add this line
  });


  const [insertNewGoal] = useMutation(INSERT_NEW_GOAL_MUTATION);
  const [updateGoal] = useMutation(UPDATE_GOAL_MUTATION);
  const [updateStepStatus] = useMutation(UPDATE_STEP_STATUS_MUTATION);
  const [updateSubTaskStatus] = useMutation(UPDATE_SUBTASK_STATUS_MUTATION);
  
  // Local States
  const [beans, setBeans] = useState(0);
  const [goals, setGoals] = useState([]);

  // Load goals initially or whenever userID changes
  useEffect(() => {
    if (data && data.goals) {
      console.log("Apollo data:", data.goals);
      console.log("Setting goals to: ", data.goals);
console.log("Type of data: ", Array.isArray(data.goals));
      setGoals(data.goals);
    }
  }, [data]);
  

 

  const createGoal = async (primaryGoal, userID, steps = []) => {
    const response = await insertNewGoal({
      variables: { data: { primaryGoal, userID, steps } },
      update: (cache, { data: { insertOneGoal } }) => {
        // Get existing goals from cache
        const existingGoals = cache.readQuery({
          query: GET_USER_GOALS_QUERY,
          variables: { userID: user.id },
        });

        // Add the new goal to the cache
        cache.writeQuery({
          query: GET_USER_GOALS_QUERY,
          variables: { userID: user.id },
          data: { goals: [...existingGoals.goals, insertOneGoal] },
        });
      },
    });
    // Update local goals state by appending the new goal
  setGoals([...goals, response.data.insertOneGoal]);
    return response;
  };
  

  const completeStep = async (goalID, stepID) => {
    const response = await updateStepStatus({ variables: { query: { _id: goalID }, set: { 'steps.$[elem].status': 100 } } });
    // Update beans count
    setBeans(beans + 60);
    // Update local goals state if needed
    return response;
  };

  const completeSubTask = async (goalID, stepID, taskID) => {
    const response = await updateSubTaskStatus({ variables: { query: { _id: goalID }, set: { 'steps.$[elem].subTasks.$[subElem].status': 100 } } });
    // Update beans count
    setBeans(beans + 60);
    // Update local goals state if needed
    return response;
  };

  const deleteGoalFromState = (_id) => {
    setGoals(prevGoals => prevGoals.filter(goal => goal._id !== _id));
  };
  
  return {
    loading,
    error,
    goals: data?.goals || [],  // Updated this line
    beans,
    deleteGoalFromState,
    createGoal,
    completeStep,
    completeSubTask,
    refetch,  // Export refetch function
    
  };
};

// Define Apollo Client queries and mutations here
const GET_USER_GOALS_QUERY = gql`
  query GetUserGoals($userID: String!) {
  goals(query: {userID: $userID}) {
    Title
    _id
    userID
    beans
    primaryGoal
    steps {
      description
      status
      stepID
      subTasks {
        description
        status
        taskID
      }
    }
  }
}


`;

const INSERT_NEW_GOAL_MUTATION = gql`
mutation InsertNewGoal($data: GoalInsertInput!) {
  insertOneGoal(data: $data) {
    _id
    userID
    beans
    primaryGoal
    steps {
      description
      status
      stepID
      subTasks {
        description
        status
        taskID
      }
    }
  }
}




`;

const UPDATE_GOAL_MUTATION = gql`
  mutation UpdateGoal($query: GoalQueryInput, $set: GoalUpdateInput!) {
  updateOneGoal(query: $query, set: $set) {
    Title
    _id
    
  }

  }


`;

const UPDATE_STEP_STATUS_MUTATION = gql`
  mutation UpdateStepStatus($query: GoalQueryInput, $set: GoalUpdateInput!) {
  updateOneGoal(query: $query, set: $set) {
    _id
    steps {
      stepID
      status
    }
  }
}

`;

const UPDATE_SUBTASK_STATUS_MUTATION = gql`
mutation UpdateSubTaskStatus($query: GoalQueryInput, $set: GoalUpdateInput!) {
  updateOneGoal(query: $query, set: $set) {
    _id
    steps {
      stepID
      subTasks {
        taskID
        status
      }
    }
  }
}

`;
