import React from 'react';
import BerryCrush from './berrycrush';

const GameComponent = () => {

  return (
    <div id="treeContainer">
      <BerryCrush />
    </div>
  );
};

export default GameComponent;
