import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  AddressElement,
  confirmPayment,
} from "@stripe/react-stripe-js";
import { FormControl } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
  root: {
	padding: '10px',
   
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
      backgroundImage: `url(${''})`, // Replace with the URL of your image
    backgroundSize: 'cover', // Cover the entire card with the image
    backgroundRepeat: 'no-repeat', // Do not repeat the image
    backgroundPosition: 'center', // Center the image
     
  },
  content: {
    padding: 10,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
	margin: '0px' /* or a smaller value than what's currently set */
	
    
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    // Add styles for the button here
    backgroundColor: '#32a852',
    color: 'white',
    borderRadius: 20,
    padding: 10,
    border: 'none',
    width: '40%',
    height: 40,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#077336',
    },
  },
}));

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  
  
  
  
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          // Make a request to webhook endpoint after successful checkout
          fetch('/webhook', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              checkout_session_id: paymentIntent.id,
            }),
          })
          .then(response => response.json())
          .then(data => {
            // Handle the retrieved session details
            console.log(data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const getReturnUrl = () => {
    const origin = window.location.origin;
    if (origin.includes("koffieland.io")) {
      return `${origin}/payment-success/`;
    } else if (origin.includes("goodkoffie.com")) {
      return `${origin}/payment-success/`;
    }
    // Default return URL if neither condition matches
    return `${origin}/payment-success/`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }
  
    setIsLoading(true);
  
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Replace with your own URL
        return_url: getReturnUrl(),
      },
    });
  
    setIsLoading(false);
  
    if (error) {
      // Handle errors here
      setMessage(error.message);
    } else {
      // Payment successful
      try {
        // Send a POST request to the /payment-success endpoint
        await fetch('/payment-success', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        // Redirect the user to the payment success page
        navigate('/payment-success-page');
      } catch (error) {
        console.error('Error handling payment success:', error);
        setMessage('An error occurred while handling payment success');
      }
    }
  };
  const AddressElement = {
    allowedCountries: ['US', 'CA'],
    layout: "vertical",
    placeholder: "1234 Main St",
  };

  
  const paymentElementOptions = {
    layout: "tabs",
      
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
     <LinkAuthenticationElement id="link-authentication-element"  />
       
      <PaymentElement id="payment-element" options={paymentElementOptions}
      
      />
      <button className={classes.button} disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
