import React, { useRef, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent';
import { useQuery, gql } from '@apollo/client';
import { Box, Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Header from '../components/nav/mobilefarmer';
import PriceTicket from '../components/finance/prods/pricefeed';
import StackableRoad from '../components/mods/displays/stackroad';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Faq from './faq';
import MiniShow from '../components/mods/mini-mornignshow';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const GET_JOURNEY = gql`
  query Journey {
    quests {
      Title
      _id
      plot
      imageurl
      name
      cover
      date
      media
    }
  }
`;
function formatDate(isoDate) {
  const date = new Date(isoDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export default function Us() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderMedia = (media, title, poster) => {
    if (media && media.length > 0) {
      return (
        <Carousel showThumbs={false}>
          {media.map((item, index) => {
            const isVideo = item.match(/\.(mp4)$/i);
            return (
              <div key={index}>
                {isVideo ? (
                  <video controls width="100%" style={{ borderRadius: 10, padding: 0 }} poster={poster}>
                    <source src={item} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                ) : (
                  <CardMedia
                    component="img"
                    image={item}
                    alt={`${title} ${index + 1}`}
                    style={{ width: '100%' }}
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      );
    }

    return null;
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleFullscreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      } else if (videoRef.current.webkitEnterFullscreen) {
        videoRef.current.webkitEnterFullscreen();
      }
    }
  };

  const { loading, error, data } = useQuery(GET_JOURNEY);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;
  if (!data || !data.quests) return <p>No data available</p>;

  const sortedQuests = data.quests
    .map(quest => ({ ...quest, date: new Date(quest.date) }))
    .filter(quest => !isNaN(quest.date))
    .sort((a, b) => a.date - b.date);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ gap: 12, display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'left', padding: 0, borderRadius: 0, boxShadow: 0 , mt: 0}}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 800, textShadow: '1px 1px 2px #ebecf0', padding: 1 }}>
          The Journey
        </Typography>
        <Box sx={{ display: 'flex', alignSelf: 'flex-end'  }}>
          <MiniShow  />
        </Box>
      </Box>
      <Box sx={{ width: '100%', paddingTop: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Card sx={{
            display: 'flex',
            width: '100%',
            backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/assets/beanback.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            objectFit: 'scale-down',
            borderRadius: 0,
            boxShadow: 0,
            paddingTop: 0,
            margin: 0,
            overflow: 'hidden',
          }}>
            <Box style={{
              flex: 'grow',
              borderRadius: '0px',
              margin: '0px 0',
              padding: '0px',
              backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/vids/koffiewood.png'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              objectFit: 'contain',
            }}>
              <Card sx={{ borderRadius: '0px', backgroundColor: 'transparent', overflow: 'hidden', position: 'relative', width: "100%", padding: '0px' }}>
                <video
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                  ref={videoRef}
                  poster='https://storage.googleapis.com/app_darkendimg/assets/musevcover.png'
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  controls
                >
                  <source src='https://storage.googleapis.com/app_darkendimg/vids/The%20Koffie%20Question.mp4' type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <Box sx={{ position: 'absolute', bottom: -30, left: 50, transform: 'translate(-50%, -50%)', zIndex: 1, backgroundColor: 'transparent' }}>
                  <IconButton onClick={handlePlayPause} sx={{ backgroundColor: 'transparent' }}>
                    {isPlaying ? (
                      <PauseIcon sx={{ color: '#fff', fontSize: '3rem', backgroundColor: 'transparent', width: 40, height: 40 }} />
                    ) : (
                      <PlayArrowIcon sx={{ color: '#077336', fontSize: '3rem', backgroundColor: 'transparent' }} />
                    )}
                  </IconButton>
                  <IconButton onClick={handleFullscreen} sx={{ backgroundColor: 'transparent' }}>
                    <FullscreenIcon style={{ color: '#077336', fontSize: '3rem', backgroundColor: 'transparent', width: 40, height: 40 }} />
                  </IconButton>
                </Box>
              </Card>
            </Box>
          </Card>
        </Box>
        <Box sx={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, borderRadius: 0, boxShadow: 0 }}>
          <PriceTicket />
        </Box>
        <Header />
        <div style={{ borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '.5rem', paddingTop: '.5rem' }}>
          <Card sx={{
            display: 'flex',
            width: '100%',
            backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/vids/koffiewood.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            objectFit: 'contain',
            borderRadius: 0,
            boxShadow: 3,
            paddingTop: 0,
            height: '600px',
            margin: 0,
            overflow: 'hidden',
          }}>
          </Card>
        </div>
      </Box>
      <Timeline
        sx={{
          [`& .MuiTimelineItem-root:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {sortedQuests.map((quest) => (
          <TimelineItem key={quest._id}>
            <TimelineContent>
              <div>
                <h2>{quest.Title}</h2>
                <p>{formatDate(quest.date)}</p>
                <p>{quest.plot}</p>
                {renderMedia(quest.media, quest.Title, quest.cover)}
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem' }}>
        <Card sx={{
          display: 'flex',
          width: '100%',
          backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/vids/koffiewood.png'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          objectFit: 'contain',
          borderRadius: 0,
          boxShadow: 0,
          paddingTop: 0,
          height: 400,
          margin: 0,
          overflow: 'hidden',
        }}>
          <CardContent>
            <Typography variant="h4" component="div" sx={{ fontWeight: 700, color: '#fff', textShadow: '1px 1px 2px #000' }}>
              Stay Tuned for a ton of old and new content
            </Typography>
            <Typography variant="body1" component="div" color='white'>
              Join the Journey
            </Typography>
          </CardContent>
        </Card>
      </Box>
      {!isMobile && 
        <Box sx={{ position: 'fixed', bottom: '50px', left: 10, zIndex: 2000}}>
          <StackableRoad />
        </Box>
      }
      <Typography
        variant="h5"
        fontWeight="bold"
        gutterBottom
        sx={{ textAlign: 'center', textShadow: '1px 1px 1px #d1d1d1', marginTop: 3 }}
      >
        Frequently Asked Questions
      </Typography>
      <Faq />
    </Box>
  );
}
