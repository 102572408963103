import React from 'react';
import './AboutSection.css'; // Make sure to create this CSS file

const AboutSection = () => {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <h2>About Koffieland</h2>
            <p>
              Koffieland is not just an app—it's a revolution in the coffee industry. We've digitized CoffeeBeans to create a fairer, more transparent coffee trade. By purchasing coffee through Koffieland, you earn DigiBeans, our digital currency, which you can use to interact within our vibrant community. Our mission is to disrupt BigCoffee and ensure that every farmer gets their fair share. Join us in raising 96 million DigiBeans to transform the coffee industry for good.
            </p>
          </div>
          <div className="col-lg-6 col-md-12">
            <img src="images/koffieland-banner.jpg" alt="Koffieland Banner" className="img-fluid" />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6 col-md-12">
            <img src="images/koffieman.jpg" alt="Koffieman" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12">
            <h2>Meet the Koffieman</h2>
            <p>
              My journey began in the coffee fields, witnessing firsthand the struggles of farmers. I learned to roast coffee, created my own brand, and even opened a coffee shop with prize money from a pitch contest. Despite the challenges, my passion for coffee never waned. This passion led to the creation of Koffieland, an app that connects the entire coffee value chain—from farmers to drinkers. With over a decade in the coffee industry, I am committed to creating a fairer, more transparent coffee trade. Together, we can make a difference, one cup at a time.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
