import React from 'react';
import styled from 'styled-components';
import SupplyForm from '../blockchain/farmersupply';

const GridContainer = styled.div`
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-areas:
    'header header header'
    'nav main main'
    'nav footer footer';
  
  gap: 1rem;
`;

const Header = styled.header`
  grid-area: header;
  background: #333;
  color: #fff;
  padding: 1rem;
`;

const Nav = styled.nav`
  grid-area: nav;
  background: #ddd;
  padding: 1rem;
`;

const Main = styled.main`
  grid-area: main;
  background: #f9f9f9;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
`;

const Inventory = styled.section`
  grid-column: 1 / 2;
  background: #fff;
  padding: 1rem;
`;

const EarningsCalculator = styled.section`
  grid-column: 2 / 3;
  background: #fff;
  padding: 1rem;
`;

const MarketInsights = styled.section`
  grid-column: 3 / 4;
  background: #fff;
  padding: 1rem;
`;

const Footer = styled.footer`
  grid-area: footer;
  background: #333;
  color: #fff;
  padding: 1rem;
`;

const FarmDash = () => {
  return (
    
  
            
      <div className="farmer-dashboard">
    
    
    <div className="contracts-section">
        <h2>Your Current Contracts</h2>
        <p>You don't currently have any contracts. <a href="/new-contract">Create a new contract</a></p>
    </div>

    <div className="farm-info-section">
        <h2>Your Farm Information</h2>
        <SupplyForm/>
    </div>

    <div className="news-updates-section">
        <h2>News and Updates</h2>
        UpdateCard Component Goes Here 
    </div>
</div>

      
  
  );
};

export default FarmDash;
