import React, { useState, useContext } from 'react';
import {
  List,
  ListItem,
  Typography,
  Divider,
  Box,
  TextField,
  Button,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  Modal,
  IconButton,
} from '@mui/material';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { UserContext } from '../context/usercontext';
import Progress from '../components/mods/progress';
import CloseIcon from '@mui/icons-material/Close';

const SEND_MESSAGE = gql`
  mutation SendMessage($data: MessageInsertInput!) {
    insertOneMessage(data: $data) {
      _id
      content
      createdAt
      isRead
      senderId
      receiverId
      parentId
      sendername
      receivername
      senderimage
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($receiverId: ObjectId!) {
    messages(query: { receiverId: $receiverId }) {
      _id
      content
      createdAt
      isRead
      senderId
      receiverId
      parentId
      sendername
      receivername
        senderimage
    }
  }
`;

export default function Deskmessage1() {
  const { user } = useContext(UserContext);
  const { Nickname,  imageurl } = user.customData;
  const [replyContent, setReplyContent] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [dialogType, setDialogType] = useState('');
  const [sendMessage, { loading: sendLoading }] = useMutation(SEND_MESSAGE);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [fetchMessages, { loading, data, refetch }] = useLazyQuery(GET_MESSAGES, {
    variables: { receiverId: user.id },
  });

  React.useEffect(() => {
    fetchMessages();
  }, []);

  const handleReply = async (parentId, receiverId) => {
    if (!replyContent) {
      alert('Reply content is required.');
      return;
    }

    try {
      await sendMessage({
        variables: {
          data: {
            content: replyContent,
            receiverId,
            senderId: user.id,
            parentId, // Link to the parent message
            isRead: false,
            sendername: user.customData.Nickname,
            senderimage: user.customData.imageurl,
            createdAt: new Date().toISOString(),
          },
        },
      });
      setReplyContent('');
      alert('Reply sent successfully!');
      refetch();
      setSelectedMessage(null); // Close dialog after sending
    } catch (err) {
      console.error('Error sending reply:', err);
      alert('Failed to send reply.');
    }
  };

  const handleCloseDialog = () => {
    setSelectedMessage(null);
    setReplyContent('');
    setDialogType('');
  };

  if (loading) return <Progress />;
  if (!data?.messages || data.messages.length === 0)
    return <Typography>No messages found.</Typography>;

  return (
    <Box>
    <List>
  {data.messages.map((message) => {
    const isLongMessage = message.content.split('\n').length > 3 || message.content.length > 100;
    return (
      <React.Fragment key={message._id}>
        <ListItem alignItems="flex-start">
          {/* Header: Avatar, Sender Name, and Created At */}
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <img
                src={message.senderimage}
                alt="Sender"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {message.sendername}
              </Typography>
            </Box>
            <Typography variant="caption" sx={{ color: 'text.secondary', ml: 1 }}>
              {new Date(message.createdAt).toLocaleString()}
            </Typography>
          </Box>

          {/* Content */}
          <Box sx={{ mt: 1, width: '100%' }}>
            <Typography
              variant="body2"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                mb: 1,
              }}
            >
              {message.content}
            </Typography>
          </Box>

          {/* Action: Read More or Reply */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Button
              size="small"
              onClick={() => {
                setSelectedMessage(message);
                setDialogType(isLongMessage ? 'read' : 'reply');
              }}
            >
              {isLongMessage ? 'Read More' : 'Reply'}
            </Button>
          </Box>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  })}
</List>




      {/* Full-Screen Dialog for Mobile */}
      {isMobile && (
        <Dialog
          fullScreen
          open={!!selectedMessage}
          onClose={handleCloseDialog}
          sx={{ zIndex: 2000 }}
        >
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
            {dialogType === 'read' ? (
              <>
                <Typography variant="h6">{selectedMessage?.content}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {new Date(selectedMessage?.createdAt).toLocaleString()}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h6">
                  Reply to Message from {selectedMessage?.sendername || selectedMessage?.senderId}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            {dialogType === 'reply' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleReply(selectedMessage?._id, selectedMessage?.receiverId)
                }
                disabled={sendLoading}
              >
                {sendLoading ? 'Sending Reply...' : 'Send Reply'}
              </Button>
            )}
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Modal for Desktop */}
      {!isMobile && (
        <Modal open={!!selectedMessage} onClose={handleCloseDialog}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            {dialogType === 'read' ? (
              <>
                <Typography variant="h6">{selectedMessage?.content}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {new Date(selectedMessage?.createdAt).toLocaleString()}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h6">
                  Reply to Message from {selectedMessage?.sendername || selectedMessage?.senderId}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </>
            )}
            {dialogType === 'reply' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleReply(selectedMessage?._id, selectedMessage?.receiverId)
                }
                disabled={sendLoading}
                sx={{ mt: 2 }}
              >
                {sendLoading ? 'Sending Reply...' : 'Send Reply'}
              </Button>
            )}
            <Button onClick={handleCloseDialog} sx={{ mt: 2 }}>
              Close
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
}
