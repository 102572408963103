import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/usercontext';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Card, TextField, Typography, CardContent, Box, CardActionArea, CardMedia, Radio,  Grid, Button, Fab  } from '@mui/material';
import { Button as Botton } from '@mui/material';
import Progress from '../mods/progress';
import { Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import barista from '../../images/cololady.webp'; 
import  farmer from '../../images/rita.jpg';
import  drink from '../../images/redpill.png';
import  roaster from '../../images/cololady.webp';
import  shop from '../../images/baggy.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TermsAndAgreement from './terms';
import useFileUpload from './cloud';  // Import your useFileUpload hook



const GET_DarkRoads = gql`
  query GetDarkRoads {
    darkroads {
      _id
      Who
      What
      When
    }
  }
`;

const INSERT_USERPROF = gql`
  mutation insertOneUserprof($data: UserprofInsertInput!) {
    insertOneUserprof(data: $data) {
      _id
      Nickname
      usertype
      Koffie_story
      profileBuilt
      imageurl
    }
  }
`;

const UPSERT_USERPROF = gql`
  mutation upsertOneUserprof($data: UserprofUpsertInput!) {
    upsertOneUserprof(query: { _id: $id }, set: $data) {
      _id
      Nickname
      usertype
      Koffie_story
      profileBuilt
      imageurl
    }
  }
`;





const UserTypeOption = ({ userType, description, imageSrc, selectedValue, onChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 2 }}>
      <Box sx={{ position: 'relative' }}>
        <Avatar
          onClick={() => onChange(userType)}
          alt={userType}
          src={imageSrc}
          sx={{ width: 80, height: 80, cursor: 'pointer' }}
        />
        {selectedValue === userType && (
          <CheckCircleIcon  sx={{ position: 'absolute', top: -10, right: -10 }} />
        )}
      </Box>
      <Typography gutterBottom variant="h6">
        {userType}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
};


const ProfileBuilder = (newUser) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [Nickname, setNickname] = useState('');
  const [usertype, setUserType] = useState('');
  const [Koffie_story, setKoffieStory] = useState('');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { data: darkroads, loading } = useQuery(GET_DarkRoads);
  const [selectedUserType, setSelectedUserType] = useState('');
  const { file, previewURL, handleFileChange, uploadFile } = useFileUpload(); // Use your custom hook
  const fileInputRef = React.createRef();
  const [insertUserprof, { data }] = useMutation(INSERT_USERPROF);
  const [upsertUserprof] = useMutation(UPSERT_USERPROF);


  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    try {
      const uploadedImageURL = await uploadFile(file, user.id);

  // Check if the upload was successful
  if (!uploadedImageURL) {
    console.error('File upload failed: no image URL returned');
    
    return;
  }
      await upsertUserprof({
        variables: {
          _id: user.id,
          data: {
            Nickname,
            usertype: selectedUserType,
            Koffie_story,
            profileBuilt: true,
            imageurl: uploadedImageURL,
          },
        },
      });
    } catch (error) {
      console.error("Error updating user profile data:", error);
    }
  };



  const userTypes = [
    { type: 'kupster', description: 'Dinker', imageSrc: drink },
    { type: 'Farmer', description: 'Grower', imageSrc: farmer },
    { type: 'roaster', description: 'Roaster', imageSrc: roaster },
     { type: 'coffeeShop', description: 'Shop Owner', imageSrc: shop },
     { type: 'barista', description: 'Barista', imageSrc: barista },
    // ... other user types
  ];

  const handleUserTypeChange = (type) => {
    setSelectedUserType(type);
  };

  // Form validation
  const isFormValid = Nickname && Koffie_story && selectedUserType;

  const handleNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Insert the user profile data into the database
    try {
      if (!newUser) {
        console.log("User from Context:", newUser); // Debug line
  console.error("User is not defined");
  return;
}

const uploadedImageURL = await uploadFile(file, user.id);

  // Check if the upload was successful
  if (!uploadedImageURL) {
    console.error('File upload failed: no image URL returned');
    
    return;
  }
     
      await insertUserprof({
        variables: {
          data: {
            Nickname,
            usertype: selectedUserType,
            Koffie_story,
            _id: user.id,
            imageurl : uploadedImageURL,
            profileBuilt: true,
          },
        },
      });

       // Redirect to the corresponding dashboard based on user type
       if(usertype === 'kupster') {
        navigate('/kupster-on');
      } else if (usertype === 'Farmer') {
        navigate('/farmer-on');
      } else if (usertype === 'roaster') {
        navigate('/roaster-on');
      } else if (usertype === 'coffeeShop') {
        navigate('/shop-on');
      } else if (usertype === 'barista') {
        navigate('/barista-on');
      }
      else {
        // Default case if the user type is unknown or not set
        navigate('/land');
      }
    
    } catch (error) {
      console.error("Error inserting user profile data:", error);
    }
  }

  if (darkroads) {
    console.log("Dark Roads:", darkroads);
  }
  if (loading) return <p><Progress/></p>;

  


  return (
    <>
     <Box sx={{ flexGrow: 1, padding: 2, height: '100%', overflowY: 'auto' }}>
      {currentStep === 1 && (
        <>
          <Typography variant="h4">Koffie Builder</Typography>
          <Typography variant="h6">Select your Link to the Koffie Chain</Typography>
          <Grid container spacing={1}>
            {userTypes.map((userType) => (
              <Grid item xs={4} sm={4} md={4} lg={3} key={userType.type}>
                <UserTypeOption
                  userType={userType.type}
                  
                  imageSrc={userType.imageSrc}
                  selectedValue={selectedUserType}
                  onChange={setSelectedUserType}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Fab 
              onClick={handleNextStep} 
              disabled={!selectedUserType}
              variant="contained"
              endIcon={<ArrowForwardIcon />}
            >
              Next
            </Fab>
          </Box>
        </>
      )}

      {currentStep === 2 && (
        <>
          <Box sx={{ marginTop: 2, marginBottom: 2, padding: 2 }}>
            <TextField 
              type="text" 
              value={Nickname} 
              onChange={(e) => setNickname(e.target.value)} 
              placeholder="Nickname" 
              required
              fullWidth
              helperText="Your Nickname"

            />
           
    <Box sx={{ minWidth: '250px', paddingBottom: 2 }}>
    <Card sx={{ display: 'flex', flexDirection: 'row' }}>
      {previewURL && (
        <CardMedia
          component="img"
          sx={{ width: 90, objectFit: 'cover' }}
          image={previewURL}
          alt="Uploaded Image Preview"
        />
      )}
      {/* Data on the right */}
      <input 
                type="file" 
                hidden 
                ref={fileInputRef} 
                onChange={handleFileChange}
              />
      <CardContent sx={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="body2" color="text.secondary">
              Add an image because you are beautiful!
            </Typography>
          </Grid>
          <Grid item>
            <Button 
              variant="contained" 
              size='small' 
              component="label" 
              sx={{background: '#71c584', color: '#fff', border: 'none'}}
              onClick={() => fileInputRef.current.click()}
            >
              Pick Photo
             
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Box>
  
            <TextField 
              value={Koffie_story} 
              onChange={(e) => setKoffieStory(e.target.value)} 
              placeholder="Your Koffie Story" 
              required
              fullWidth
              multiline
              rows={4}
              helperText="Tell us about your Koffie Journey"
            />
          </Box>
          <TermsAndAgreement />
         <Box sx={{ display: 'flex', padding: 2 }}>
          <Button sx={{ width: '100%', background: '#71c584', color: '#fff', border: 'none'}}  onClick={handleSubmit} disabled={!isFormValid}>Create my account</Button>
        </Box>
        
        </>
      )}
      
    
    
    </Box>
  </>
  );
}

export default ProfileBuilder;
