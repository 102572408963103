
import { useState, useEffect } from 'react';

const useInfiniteScroll = (callback) => {
    const [isFetching, setIsFetching] = useState(false);
    const [scrollEvent, setScrollEvent] = useState('scroll');
  
    useEffect(() => {
      if ('ontouchstart' in window) {
        setScrollEvent('touchmove');
      }
      window.addEventListener(scrollEvent, handleScroll);
      return () => window.removeEventListener(scrollEvent, handleScroll);
    }, []);
  
    useEffect(() => {
      if (!isFetching) return;
      callback();
    }, [isFetching]);
  
    function handleScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop <
          document.documentElement.offsetHeight - 400 ||
        isFetching
      )
        return;
      setIsFetching(true);
    }
  
    return [isFetching, setIsFetching];
  };
  
  export default useInfiniteScroll;