import React, { useState } from 'react';
import { Card, IconButton, Typography } from '@mui/material';

function LocationComponent() {
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);

  const geocodioAPIKey = "1a9a90a91628315a6399a1895a1094385399016";
  

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });

        // Reverse geocoding using Geocodio
        const url = `https://api.geocod.io/v1.7/reverse?q=${latitude},${longitude}&api_key=${geocodioAPIKey}`;
        
        try {
          const response = await fetch(url);
          const data = await response.json();
          if (data && data.results && data.results.length > 0) {
            setAddress(data.results[0].formatted_address);
          }
        } catch (error) {
          console.error("Geocoding error: ", error);
        }
      });
    }
  };

  return (
    <div sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
     
      <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Typography variant="caption" sx={{fontWeight: 0}}>Location</Typography>
        <IconButton aria-label="location" sx={{backgroundColor: '#fff'}} onClick={getLocation}>
          <img src="https://img.icons8.com/ios-filled/50/000000/worldwide-location.png" alt="location" width={20}/>
        </IconButton>
     
      <Typography variant="caption" sx={{fontWeight: 500}}>
      {address && <div>Your address: {address}</div>}
      </Typography>
      </Card>
    </div>
  );
}

export default LocationComponent;
