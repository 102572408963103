import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  Box,
  MenuItem,
  ListItemText,
  List,
  ListItem,
  Drawer,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  useTheme,
  Chip,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import PortalFooter from '../components/portal/pfooter';
import ProductLine from '../components/portal/ProductLine';
import habish from '../images/rlady.jpg';  // Update with correct path
import Featured from '../components/finance/adds/featured';  // Update with correct path
import Us from './us';  // Update with correct path
import GoogleOneTapLogin from '../components/nav/onetap';  // Update with correct path
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: 'all 0.3s ease',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '90%',
  },
  appBarScrolled: {
    backgroundColor: '#fff',
    
    border: '0px solid green',
    borderRadius: '8px',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: '0 1rem',
    transition: 'all 0.3s ease',
    color: '#352f36',

    
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    
  },
  desktopMenu: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    color: '#352f36',
    backgroundColor: 'transparent',
    transition: 'all 0.6s ease',
    

  },
  logo: {
    width: 60,
  },

bec: {
  width: 150,
},

}));

const features = [
  { title: 'Personalized AI Salesbot', description: 'Leverage AI to personalize and automate sales for your products.' },
  { title: 'Trustless Smart Contracts', description: 'Implement transparent and trustless smart contracts, revolutionizing fair-trade.' },
  { title: 'AI Koffie Connoisseur', description: 'Utilize AI to enhance your coffee experience and recommendations.' },
  { title: 'Direct Chat with Producers', description: 'Communicate directly with coffee producers for better transparency and relationships.' },
  { title: 'Posts from Your Favorite Shop', description: 'Stay updated with posts and offers from your favorite coffee shops.' },
  { title: 'History of Koffie', description: 'Explore the rich history and culture of coffee through our platform.' },
  { title: 'Koffie EDU', description: 'Educational content about coffee, from growing to brewing.' },
  { title: 'Bean-Based Social Interactions', description: 'Engage with a community of coffee lovers through bean-based interactions.' }
];


const EntryPoint = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = (
    <>
    <MenuItem onClick={handleMenuClose} component={Link} to="/beanwatch">
      KAAF
    </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/login">
        Login
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/signup">
        Sign Up
      </MenuItem>
    </>
  );

  const drawerItems = (
    <List>
      <ListItem button component={Link} to="/beanwatch" onClick={toggleDrawer(false)}>
        <ListItemText primary="KAAF" />
      </ListItem>
      <ListItem button component={Link} to="/login" onClick={toggleDrawer(false)}>
        <ListItemText primary="Login" />
      </ListItem>
      <ListItem button component={Link} to="/signup" onClick={toggleDrawer(false)}>
        <ListItemText primary="Sign Up" />
      </ListItem>
      <ListItem button component={Link} to="/about" onClick={toggleDrawer(false)}>
        <ListItemText primary="About" />
      </ListItem>
      <ListItem button component={Link} to="/contact" onClick={toggleDrawer(false)}>
        <ListItemText primary="Contact" />
      </ListItem>


    </List>
  );

  return (
    <React.Fragment>
      {/* Transparent AppBar */}
      <AppBar
      sx={{backgroundColor: 'transparent', boxShadow: 'none', borderBottom: '0px solid #f0f0f0', padding: '0.5rem 0' }}
      className={clsx(classes.appBar, {
        [classes.appBarScrolled]: scrolled,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src="https://storage.googleapis.com/app_darkendimg/assets/kland.png" alt="logo" className={classes.logo} />
        </Typography>
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon fontSize="large" />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawerItems}
            </Drawer>
          </>
        ) : (
          <div className={classes.desktopMenu}>
           <Link to="/investors" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant='h6' >Koffieland</Typography>
              </Link>
              <Link to="/company" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant='h6' >Company</Typography>
              </Link>
              <Link to="/koffieland" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='h6' >Koffieland</Typography>
              </Link>
              <Link to="/koffieland" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='h6' >Investors</Typography>
              </Link>
              <Link to="/koffieland" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='h6' >Contact</Typography>
              </Link>
              <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button sx={{ width: '200px' ,color: '#fff', backgroundColor: '#077336',borderRadius: 10, border: 'none' ,'&:hover': { backgroundColor: '#33f107', color: '#000' } }}>Login</Button>
              </Link>
          </div>
        )}
      </Toolbar>
    </AppBar>


      {/* Hero Section */}
      <section style={{
        backgroundColor: '#f0f0f0',
        padding: '2rem',
        backgroundImage: 'url(https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#fff',
        textAlign: 'center',
        position: 'relative'
      }}>
        <Container>
          <Typography color="inherit" align="center" variant="h3" fontWeight={400} marked="center" paddingTop={5}>
            UNLOCK THE POWER OF KOFFIE
          </Typography>
          <Typography color="inherit" align="center" variant="h4" fontWeight={900} marked="center" paddingTop={2}>
            KOFFIELAND™
          </Typography>
          <Typography color="inherit" align="center" variant="h5" sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}>
            Revolutionizing the way we think about koffie, one cup at a time.
          </Typography>
          <Button
            variant="contained"
            size="large"
            component="a"
            sx={{ minWidth: 200, color: '#fff', backgroundColor: '#077336', '&:hover': { backgroundColor: '#33f107', color: '#000' } }}
          >
            Learn More
          </Button>
          <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
            Discover the experience
          </Typography>
        </Container>
      </section>
      {/* Product Line Section */}
      <section style={{
        backgroundColor: '#fff',
        padding: '2rem',
        backgroundImage: 'url()',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#fff',
        textAlign: 'center',
        position: 'relative'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb:10 }}>
        <Typography variant="h2" color='#000' fontWeight={900} marked="center" paddingBottom={5}>
            Digital Wave Koffie
          </Typography>
          </Box>
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6}>
          <Box 
            className="flex-1 flex flex-col justify-center items-center" 
            sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <Box component="header" sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography 
                variant="h4" 
                sx={{ fontWeight: 800, color: '#fff', textShadow: '2px 2px 4px rgba(0,0,0,1)', textAlign: 'center' }}
              >
                Join the Koffie Revolution
              </Typography>
            </Box>
            <Box sx={{ mb: 4, textAlign: 'center', padding: 2, borderRadius: 5 }}>
              <Typography 
                variant="h6" 
                sx={{ fontWeight: 900, mb: 1, textAlign: 'center', color: '#ffd700', textShadow: '2px 2px 4px rgba(0,0,0,1)' }}
              >
                The People's, people people, drink people
              </Typography>
            </Box>
            <Typography 
              variant="body1" 
              fontWeight={800} 
              gutterBottom 
              sx={{ mt: 1, textAlign: 'center', color: '#fff', textShadow: '2px 2px 4px rgba(0,0,0,1)' }}
            >
              Kaffeinate
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box 
            className="flex-1 flex flex-col justify-center items-center" 
            sx={{ backgroundColor: '#bce3c0', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10, padding: 2}}
          >
            <Box component="header" sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography 
                variant="h4" 
                sx={{ fontWeight: 800, color: '#000', textShadow: '2px 2px 4px rgba(0,0,0,0)', textAlign: 'center' }}
              >
                Join the Koffie Revolution
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                <img src="https://storage.googleapis.com/app_darkendimg/assets/thebec.webp" alt="logo" className={classes.bec} />
                </Box>
                <Typography 
                variant="h6" 
                sx={{ fontWeight: 900, mb: 1, textAlign: 'center', color: '#000', textShadow: '2px 2px 4px rgba(0,0,0,0)' }}
              >
                Introdcing the BEC
              </Typography>
            </Box>
            
            <Typography 
              variant="body1" 
              fontWeight={800} 
              gutterBottom 
              sx={{ mt: 1, textAlign: 'center', color: '#fff', textShadow: '2px 2px 4px rgba(0,0,0,1)' }}
            >
              Kaffeinate
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
      </section>
        {/* Featured Products Section */}
        <section style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>
        <Container sx={{ backgroundColor: 'transparent',
        padding: '2rem',
        backgroundImage: 'url(https://storage.googleapis.com/app_darkendimg/vids/landif2.gif)',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#fff',
        textAlign: 'center',
        position: 'relative' }}>
         
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 50 }}>
            <Button variant="contained" size="large" component="a" sx={{ minWidth: 200, color: '#fff', backgroundColor: '#077336', '&:hover': { backgroundColor: '#ffc107', color: '#000' } }}>
              Get your Digi Beans
            </Button>
           
            </Box>
          
        </Container>
      </section>
     
      
      {/* Featured Products Section */}
      <section style={{
        backgroundColor: '#fff',
        padding: '2rem',
        backgroundImage: 'url()',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#fff',
        textAlign: 'center',
        position: 'relative'
      }}>
        
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 10 }}>
      <Typography variant="h2" color='#000' fontWeight={900} marked="center" paddingBottom={5}>
        Koffieland™ Features
      </Typography>
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent>
                  <Typography variant="h5" component="div" fontWeight={700}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
        </Container>

        </section>

      <section style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight={900} marked="center" paddingTop={2}>
            Publicly Accessible Pipeline
            </Typography>
            <Typography color="inherit" align="center" variant="h5" sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}>
              This is <b>NOT</b> Crypto
            </Typography>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="140"
                image={habish}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  This is Blockchain
                </Typography>
                <Typography variant="body2" color="text.secondary">
                From the seedling to your grinder, connect with your favorite farmer's and roaster's coffee production pipeline on the blockchain. </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Share</Button>
                <Button size="small">Learn More</Button>
              </CardActions>
            </Card>
          </Box>
        </Container>
      </section>

      {/* Footer Section */}
      <section style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PortalFooter />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default EntryPoint;
