import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography, Box, Card, Dialog, DialogTitle, Chip, CardContent, DialogContent, IconButton, Divider, CardActionArea , useMediaQuery} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DailyGrindMediaItem from '../components/posts/DailygrindMedia';
import StoreAdd from '../components/finance/adds/storeadd';
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DailyGrindDesk from './DailyGrindDesk';


const GET_VIDEOS = gql`
  query  {
    videos(sortBy: CREATEDAT_ASC) {
      _id
      Title
      description
      videourl
      summary
      cover
    }
  }
`;

const ScrollableContainer = styled(Box)({
  display: 'flex',
  overflowX: 'scroll',
  padding: '4px',
  gap: '16px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbar-width': 'none',  /* Firefox */
});

const StoryCard = styled(Card)({
  width: '200px',
  height: 'auto',
  flex: '0 0 auto',
  overflow: 'hidden',
  cursor: 'pointer',
  
  
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ImageContainer = styled('div')({
  width: '100%',
  height: 'auto',
  aspectRatio: '16/9',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
});

const TextContainer = styled('div')({
  padding: '8px',
  fontSize: '14px',
  fontWeight: 800,
  textAlign: 'left',
  alignSelf: 'flex-start', // Aligns the text to the left within the card
});


function DailyGrind() {
  const { loading, error, data } = useQuery(GET_VIDEOS);
  const [selectedVideo, setSelectedVideo] = useState(null);  // Selected video for the scroll container
  const [dialogVideo, setDialogVideo] = useState(null);  // Separate state for video inside the dialog
  const [expanded, setExpanded] = useState(false);  // To manage summary expansion
  const isDarkMode = useTheme().palette.mode === 'dark';
  const isMobile = useMediaQuery('(small)');
  // Toggle expand/collapse of the description
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, gap: 2 }}>
  <Skeleton variant="rectangular" width="1100%" height={160} sx={{ borderRadius: 2 }} />
  
  </Box>
  if (error) return <div>Error fetching data</div>;

  const handleCardClick = (video) => {
    setSelectedVideo(video);
    setDialogVideo(video);  // Set the clicked video in both state variables
  };

  const handleDialogVideoClick = (video) => {
    setDialogVideo(null);  // Clear the previous video before setting the new one
    setTimeout(() => {
      setDialogVideo(video);  // Only update the dialog video when clicked inside the dialog
    }, 0); // Use a small delay to force re-render
  };

  const handleClose = () => {
    setSelectedVideo(null);
    setDialogVideo(null);  // Reset both states when dialog is closed
  };

  const summary = dialogVideo?.summary || '';
  const summaryPreview = summary.length > 150 ? `${summary.slice(0, 150)}...` : summary;

  return (
    <div>
      
      {/* Scrollable video container */}
      <ScrollableContainer>
  {data.videos.map((video) => (
    <StoryCard 
      key={video._id} 
      onClick={() => handleCardClick(video)}
    >
      <ImageContainer
        style={{
          backgroundImage: `url(${video.cover})`,
          position: 'relative', // Make this container a positioned element
        }}
      >
        {/* Text Overlay */}
        <div
  style={{
    position: 'absolute',
    bottom: 10,
    width: '90%',
    textShadow: isDarkMode
      ? 'rgba(255, 255, 255, 0.1)' // Light semi-transparent background for dark mode
      : 'rgba(0, 0, 0, 0.1)', // Dark semi-transparent background for light mode
    fontSize: '1.5rem',
    padding: '5px',
    textAlign: 'center',
    fontWeight: 800,
    color: isDarkMode ? '#f0e130' : '#f0e130', // Text color inverted for mode
  }}
>
  <strong>{video.description}</strong>
</div>
      </ImageContainer>
      <TextContainer>
        {video.Title} {/* Adjust to use title or description */}
      </TextContainer>
    </StoryCard>
  ))}
</ScrollableContainer>


      {/* Video Dialog */}
      <Dialog open={!!selectedVideo} onClose={handleClose} fullScreen>
        {dialogVideo && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton edge="end" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <DialogTitle variant='caption' sx={{ display: 'flex', alignItems: 'center' }}>
                {dialogVideo.Title}
              </DialogTitle>
            </Box>
            <DialogContent sx={{ padding: 2 }}>
              {dialogVideo.videourl && (
                <DailyGrindMediaItem key={dialogVideo.videourl} item={dialogVideo.videourl} title={dialogVideo.Title} />
              )}

              {/* Video description and summary */}
              <Box sx={{ marginTop: 1, zIndex: 1400 }}>
                <Typography variant="body1" sx={{ fontWeight: 800, textAlign: 'left' }}>
                  {dialogVideo?.Title || 'Untitled'}
                </Typography>
                <Card sx={{ marginTop: 0, padding: 0 }}>
                  <Divider />
                  <CardContent sx={{ padding: 1 }}>
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                      {expanded ? summary : `${summaryPreview}...`}
                    </Typography>
                    <Chip label={expanded ? 'Less' : 'More'} onClick={toggleExpand} />
                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
                      {dialogVideo?.description || 'No description available'}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              {/* Scrollable video container inside the dialog */}
              <Box sx={{ marginTop: 1 }}>
                <Divider />
                <ScrollableContainer>
                  {data.videos.map((video) => (
                    <StoryCard
                      key={video._id}
                      onClick={() => handleDialogVideoClick(video)}
                      sx={{
                        objectFit: 'contain',
                        width: '80%',
                        borderRadius: 2,
                        backgroundImage: `url(${video.cover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        aspectRatio: '16/9',
                      }}
                    />
                  ))}
                </ScrollableContainer>
              </Box>

              <Box sx={{ marginTop: 1 }}>
                <StoreAdd />
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>

      <Box sx={{ marginTop: 1 }}>
        <Divider />
      </Box>
         
    </div>
  );
}

export default DailyGrind;
