import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  frappuccino: {
    width: '200px',
    height: '300px',
    position: 'relative',
  },
  cup: {
    width: '150px',
    height: '250px',
    backgroundColor: '#8B5F35', // brown color for the cup
    borderRadius: '50%',
    position: 'absolute',
    bottom: 0,
    left: '25px',
  },
  blueIcing: {
    width: '150px',
    height: '100px',
    backgroundColor: '#add8e6', // blue color for the icing
    borderRadius: '50%',
    position: 'absolute',
    bottom: '100px',
    left: '25px',
    zIndex: 1,
  },
  whiteIcing: {
    width: '100px',
    height: '50px',
    backgroundColor: '#ffffff', // white color for the icing
    borderRadius: '50%',
    position: 'absolute',
    bottom: '150px',
    left: '50px',
    zIndex: 2,
  },
  straw: {
    width: '10px',
    height: '150px',
    backgroundColor: '#f2f2f2', // gray color for the straw
    position: 'absolute',
    bottom: '100px',
    left: '75px',
  },
}));

const Frappuccino = () => {
  const classes = useStyles();

  return (
    <div className={classes.frappuccino}>
      <div className={classes.cup}>
        <div className={classes.blueIcing}></div>
        <div className={classes.whiteIcing}></div>
        <div className={classes.straw}></div>
      </div>
    </div>
  );
};

export default Frappuccino;
