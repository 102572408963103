import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useMutation, useQuery } from '@apollo/client';
import { UserContext } from '../../../context/usercontext';
import { useContext, useEffect } from 'react';
import { UPDATE_USER_PROF, GET_USER, INSERT_USER_PROF } from '../../../context/gqllogics';
import useFileUpload from '../cloud';
import farmer from '../../../images/rita.jpg';
import drink from '../../../images/redpill.png';
import roaster from '../../../images/cololady.webp';
import shop from '../../../images/baggy.png';
import barista from '../../../images/cololady.webp';
import { Dialog, Drawer } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import kcoin from '../../../images/kcoin.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import confetti from 'canvas-confetti';

const steps = [
  {
    label: 'Enter Nickname',
    description: 'Please enter a nickname for your profile.',
  },
  {
    label: 'Select User Type',
    description: 'Select the type of user you are.',
  },

  
];

const renderCard = (userProfile) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
      <Card
sx={{ display: 'flex', flexDirection: 'column', padding: 0, width: '100%', height: '100%', borderRadius: 5, }}
>
      <CardContent
       sx={{ display: 'flex', flexDirection: 'column',  padding: 2, width: '100%', height: '100%', borderRadius: 5, backgroundColor: '#c0c0c0'}}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
         <CardMedia
            component="img"
            image={userProfile?.imageurl || 'https://storage.googleapis.com/app_darkendimg/assets/thebec.webp'}
            alt="Profile Image"
            sx={{ width: 50, height: 50, borderRadius: '50%', marginRight: 2 }}
          />
          <Typography
            variant="caption"
            sx={{ fontWeight: 400, marginRight: '8px' }}
          >
            {userProfile?.Nickname} Bean Card
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontWeight: 400, marginRight: '8px' }}
          >
            
          </Typography>
         
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="caption"
            sx={{ fontWeight: 800, marginRight: '8px' }}
          >
            ID# {userProfile?._id.substring(0, 11)}
          </Typography>
          <Typography variant="caption" sx={{  }}>
            {userProfile?.beanCount || 0}
          </Typography>
          <img src={kcoin} alt="Bean" width={50} />
        </Box>
      </CardContent>
    </Card>
    </Box>
        
    );
}
export default function Sigma({userID, userProfile, }) {
  const { user } = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);
  const [beanCount, setBeanCount] = useState(0);
 
  const [formErrors, setFormErrors] = useState({});
  const fileInputRef = React.createRef();
  const { file, previewURL, handleFileChange, uploadFile, handleRemoveImage } = useFileUpload();
  const navigate = useNavigate();
  const [createConfetti, setCreateConfetti] = useState(false);

  const [formData, setFormData] = useState({
    
    nickname: '',
    selectedUserType: '',
  });

  const { data, loading, error, refetch } = useQuery(GET_USER, {
    variables: { _id: user.id },
    notifyOnNetworkStatusChange: true,
  });
  const [updateUserprof] = useMutation(UPDATE_USER_PROF);

  const [insertUserprof] = useMutation(INSERT_USER_PROF);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      nickname: '',
      selectedUserType: '',
    });
  };

  useEffect(() => {
    if (activeStep === 2) {
      refetch();
    }
  }, [activeStep, refetch]);
//navigate to  /kupster/${user.id}
 const goToProfile = () => {
  window.location.href = `/logic/`;

    };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formIsValid = () => {
    const { nickname, selectedUserType } = formData;
    let errors = {};

    if (!nickname.trim()) {
      errors.nickname = "Nickname is required";
    } else if (nickname.length < 3) {
      errors.nickname = "Nickname must be at least 3 characters long";
    }

    if (activeStep === 1 && !selectedUserType) {
      errors.selectedUserType = "Please select your user type";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (formIsValid()) {
      let imageURL = "https://storage.googleapis.com/app_darkendimg/assets/purp.png";

     

      try {
        await insertUserprof({
          variables: {
            data: {
              Nickname: formData.nickname,
              usertype: formData.selectedUserType,
              imageurl: imageURL,
              profileBuilt: true,
              isOnboard: false,
              _id: user.id,
            },
          },
        });
        console.log("Profile inserted successfully.");
        refetch();
        goToProfile();
       
      } catch (error) {
        if (error.message.includes('E11000 duplicate key error collection')) {
          setFormErrors({ ...formErrors, duplicateUser: "A user with this ID already exists. Please use a different ID." });
        } else {
          console.error("Error inserting profile:", error);
        }
      }
    } else {
      console.log("Form is not valid. Cannot submit.");
    }
  };
  function createCoffeeBeanConfetti(options = {}) {
    const defaults = {
      scalar: 1.5,
      spread: 80,
      particleCount: 150,
      origin: { y: -0.1 },
      startVelocity: -40,
      zIndex: 1500,
    };
  
    // Merge provided options with defaults
    const mergedOptions = { ...defaults, ...options };
  
    // Define the coffee bean shape as an array of coordinate pairs
    const coffeeBeanShape = [
      [0.1, 0.1],
    [0.2, 0.2],
      
    ];
  
    confetti({
      ...mergedOptions,
      shape: coffeeBeanShape, // Use the custom coffee bean shape
      colors: ['#704214', '#704214', '704214']
    });
  }
  
  const handleGetBeans = async () => {
    // Logic to claim kupa koffie (100 beans)
    const variables = {
        query: { _id: user.id },
        set: {
         
          beanCount: beanCount + "100",
          

        }
      };
    
      try {
        await updateUserprof({ variables });
      
        console.log("Successfully updated the user profile.");
      } catch (error) {
        console.log("An error occurred while updating the user profile:", error);
      }

    // Decrement the beanCount by 100
   // Show the bean rain dialog
   setCreateConfetti(true);

   // Trigger the confetti animation with the custom coffee bean shape
   createCoffeeBeanConfetti();

   // Hide the bean rain dialog after 1 second
   setTimeout(() => {
    setCreateConfetti(false);
    
   }, 3000);
   
   
  };

  const userTypes = [
    { type: 'kupster', description: 'Koffie Lover', imageSrc: 'https://storage.googleapis.com/app_darkendimg/lovekoffie.webp' },
    { type: 'Farmer', description: 'Koffie Grower', imageSrc: farmer },
    { type: 'barista', description: 'Barista', imageSrc: barista },
    { type: 'roaster', description: 'Koffie Roaster', imageSrc: roaster },
    { type: 'coffeeShop', description: 'Koffie Shop Owner', imageSrc: farmer },
    
  ];

  return (
    <Dialog
   fullScreen
    open={true}
    variant="persistent"
    sx={{
      
      width: '100%',
      height: '100%',
     
    }}
  >

<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
    <Typography variant="h3" sx={{ mt: 6, fontWeight: 700, color: '#077336'}}>Passport</Typography>
    <Typography variant="body1" sx={{ mb: 0, fontWeight: 300 }}>Bean Card Application form</Typography>
    <img src='https://storage.googleapis.com/app_darkendimg/assets/BLS.webp' alt='yeah' width={50}/>
    <Typography variant="caption" sx={{ mb: 0, fontWeight: 500, color: '#077336'}}>Boarder & Customs</Typography>
    <Typography variant="caption" sx={{ mb: 0, fontWeight: 300 }}>Your first step to Citizenship</Typography>
  </Box>
  
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel>
            {step.label}
          </StepLabel>
          <StepContent>
            <Typography>{step.description}</Typography>
            <Box component="form" sx={{ mb: 2 }}>
              {index === 0 && (
                <TextField
                  label="Nickname"
                  name="nickname"
                  value={formData.nickname}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!!formErrors.nickname}
                  helperText={formErrors.nickname}
                />
              )}
              {index === 1 && (
                <FormControl fullWidth margin="normal" error={!!formErrors.selectedUserType}>
                  <InputLabel>User Type</InputLabel>
                  <Select
                    label="User Type"
                    name="selectedUserType"
                    value={formData.selectedUserType}
                    onChange={handleInputChange}
                  >
                    {userTypes.map((type) => (
                      <MenuItem key={type.type} value={type.type}>
                        <img src={type.imageSrc} alt={type.type} style={{ width: 20, height: 20, marginRight: 5 }} />
                        {type.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.selectedUserType && <Typography variant="caption" color="error">{formErrors.selectedUserType}</Typography>}
                </FormControl>
              )}
              {index === 2 && renderCard(data?.user?.profile)}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1, color: 'white', backgroundColor: '#077336', borderRadius: 5, padding: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={index === steps.length - 1 ? handleSubmit : handleNext}
                  sx={{ mt: 1, mr: 1, color: 'white', backgroundColor: '#077336', borderRadius: 5, padding: 1 }}
                >
                  {index === steps.length - 1 ? 'Finish' : 'Continue'}
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
    {activeStep === steps.length && (
      <Paper square elevation={0} sx={{ p: 3 }}>
        <Typography>All steps completed - you&apos;re finished</Typography>
        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
          Reset
        </Button>
      </Paper>
    )}
  </Box>
    </Dialog>

    
  );

  
}
