import React from 'react';
import cx from 'clsx';
import { Card, CardMedia, Avatar, Typography, Chip } from '@mui/material';
import congo from '../../../images/congobag.png';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import ethio from '../../../images/ethioflag.png';
import '../../../context/countryticker.css';
import { UserContext } from '../../../context/usercontext';
import { useContext } from 'react';



const GET_PRODUCTS = gql`
    query Products {
  products {
    Title
    _id
    description1
    description2  
    imageurl
    name
    price
  }
}
`;

const GET_KOFFIE_COUNTRY = gql`
    query allKoffieCountry {
        koffies {
            countryName
            _id
            flag
        }
    }
`;

export const CountryTicket = React.memo(function PlaneTicketCard() {
  
  const { user } = useContext(UserContext);

  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_KOFFIE_COUNTRY);
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  console.log(data);

  

  return (
    <div className="ticker-container1">
    <div className="ticker-wrapper1">
    {data2 && data2.koffies.map((koffie, index) => (
        <span key={index} className="ticker-item">

<Chip sx={{  width: '100%', borderRadius: '20px'}}
        avatar={<Avatar alt="Natacha" src={koffie.flag} />}
        label={koffie.countryName}
        
        
        variant="outlined"
        component={Link}
        to={`/koffie/${koffie._id}`}
      />
      
     
    </span>
    ))}
    </div>
    </div>
    
  );
});

export default CountryTicket;