import React from 'react'
import Toe from '../components/nav/bottom'
import KupsterDash from './kupsterdash'
import FarmDash from './farmdash'


function Mobilefarm() {
  return (
    <div>
      
      <FarmDash/>
      
    </div>
  )
}

export default Mobilefarm