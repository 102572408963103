// BagContext.js
import React, { createContext, useContext, useState } from 'react';

export const BeanBagContext = createContext();

export const BeanBagProvider = ({ children }) => {
  const [itemsInBag, setItemsInBag] = useState([]);
  
   console.log("BagProvider itemsInBag:", itemsInBag); // Add this line
  return (
    <BeanBagContext.Provider value={{ itemsInBag, setItemsInBag, itemCount1: itemsInBag.length }}>
      {children}
    </BeanBagContext.Provider>
  );
};

export const useBeanBag = () => useContext(BeanBagContext);