import React from 'react';
import './Heart.css';

const Heart = ({ style, beanUrl }) => {
  return (
    <div className="heart" style={style}>
      <img src={beanUrl} alt="+3" width={30} />
    </div>
  );
};

export default Heart;
