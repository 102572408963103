import React, { useState, useContext } from 'react';
import { Card, Grid, Paper, Typography, Box, Avatar, CardMedia, Chip, CardContent, CardActions, Modal,
Button, CardActionArea, TextField, IconButton, useMediaQuery, 
Drawer} from '@mui/material';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import congo from '../../images/congobag.png';
import Progress from '../mods/progress';
import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddShoppingCart, Close } from '@mui/icons-material';
import StackableFoot from './displays/stackfoot';
import { BagContext,useBag } from '../../context/BagContext';
import { UserContext } from '../../context/usercontext';
import IvyAdd from '../finance/adds/ivyadd';
import KoffieCalc from '../finance/koffiecalc';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSpring, animated, config } from 'react-spring';
import { useTheme } from '@mui/material/styles';
import StackableRoad from './displays/stackroad';
import StoreEquip from '../finance/adds/k-equip';
import { motion } from 'framer-motion';




const useStyles = makeStyles((theme) => ({
    transparentChip: {
        backgroundColor: 'transparent',
        border: 1,
        borderColor: '#32a852',
        color: '#32a852',
    },
   
}));

const GET_PRODUCTS = gql`
  query Products($isavail: Boolean!) {
    products(query: { isavail: $isavail }) {
      Title
      _id
      description1
      description2
      description4
      imageurl
      countryName
      name
      price
      farmer
      isavail
      category
    }
  }
`;

export const GET_ROASTERIES = gql`
  query GetImage ($_id: ObjectId!) {
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
    }
  }
`;

// Insert one cart insertOneCart(data: CartInsertInput!): Cart

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    category
    beanies
    name
    quantity
    options {
      size
      color
    }
  }
}
`;

const Health = () => {
    const { user } = useContext(UserContext);
    const { itemsInBag , setItemsInBag} = useBag();
    const classes = useStyles();
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isClicked, setIsClicked] = useState(false);
    
    
    const { loading, error, data } = useQuery(GET_PRODUCTS, {
        variables: { isavail: true },
      });
    const navigate = useNavigate();
   
    const [searchTerm, setSearchTerm] = useState('');

    const [insertCart, ] = useMutation(INSERT_CART_MUTATION);

    const [open, setOpen] = useState(false);
    const [OpenModal, setOpenModal] = useState(false);

    // Handle Open Modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //Navigate to Koffie Calculator
    const goToCalc = () => {
        navigate('/calculator');
    };

    // uspring animation
    const animationStyles = useSpring({
        from: { transform: 'translateY(-100%)', opacity: 0 },
        to: async (next) => {
          // Falls and bounces into place more slowly
          await next({ transform: 'translateY(0%)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
          // Zooms out slowly
          await next({ transform: 'scale(1.2)', opacity: 1, config: { duration: 1000 } });
          // Zooms back in to its original state more slowly
          await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
          // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after zooming back in
          await next({ opacity: 1, config: { duration: 500 } });
        },
        delay: 600,
      });

    
    if (loading) return <div><Progress /></div>;
    if (error) return <div>{null}</div>;

    // Group products by category
    const productsByCategory = data.products.reduce((acc, product) => {
        if (!acc[product.category]) {
            acc[product.category] = [];
        }
        acc[product.category].push(product);
        return acc;
    }, {});

    if (loading) return <Progress />;
    if (error) return <Typography>Error fetching data</Typography>;

    const handleClick = () => {
        setIsClicked(true);
        // Reset the animation state after a short delay
        setTimeout(() => setIsClicked(false), 300);
      };

    const handleAddToCart3 = async (product) => { 
        handleClick();
      const cartData = {
        data: {
          userID: user.id,
          productId: product._id,
          price: product.price,
          beanies: '4000',
          isPaid: false,
          name: product.name,
          imageurl: product.imageurl,
          category: product.category,
          quantity: 1,
          options: {
            size: "M",
            color: "Red"
          }
        }
      };
    
      try {
        await insertCart({
          variables: cartData
        });
        setItemsInBag((prevItems) => [...prevItems, product]);  // UI feedback action
      } catch (error) {
        console.error("Error inserting cart:", error);
      }
    };

    const desiredCategories = ['Health']; // Specify desired categories

     // Filter products based on search term
     const filteredProductsByCategory = Object.keys(productsByCategory).reduce((acc, category) => {
      acc[category] = productsByCategory[category].filter(product =>
          product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return acc;
  }, {});

    return (

        
      
        
        <Box sx={{ flexGrow: 1, height: '100hv' }}>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
              alignItems: 'center',
               gap: 1,
                boxShadow: 1,
                 borderRadius: 2,
                  padding: 2,
                   m: 1,
                   backgroundColor: '#E84393',
             }}>
              <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }} // Start state (invisible and below)
      animate={{ opacity: 1, y: 0 }} // End state (fully visible and in place)
      transition={{ duration: 0.8, ease: 'easeOut' }} // Animation timing
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', // Vertically align the image and text
        width: '100%',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 300, mb: 1 , fontStyle: 'italic',  }}>
        Build the Bean economy when you purchase from Koffielandiers
      </Typography>
      <img
        src="https://storage.googleapis.com/app_darkendimg/lilbeanie.png"
        alt="Beancoin"
        style={{ width: 80, height: 80, borderRadius: '50%' }}
      />
    </Box>
       </Box>
<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, padding: 1 }}>
<TextField
      label="Search by Country"
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      sx={{ flexGrow: 1, marginRight: 2 , borderRadius: 8}}
    />
    <Link to="/bag" style={{ textDecoration: 'none' }}>
      <Chip
        label={`Bag (${itemsInBag.length})`}
        sx={{  fontWeight: 600,  borderRadius: '50px' }}
        avatar={<Avatar src='https://storage.googleapis.com/app_darkendimg/assets/royal.png' alt="Congo" />}
      />
    </Link>
  </Box>        
           {Object.keys(filteredProductsByCategory).map(category => (
                desiredCategories.includes(category) && filteredProductsByCategory[category].length > 0 && ( // Render only specified categories
                    <div key={category} style={{ }}>
                       
                        <Grid container spacing={2} justifyContent="center" sx={{ padding: 2, mb: 8 }}>
                            {filteredProductsByCategory[category].map(product => (
                                <Grid item xs={6} key={product._id}>
                                    <Card sx={{ boxShadow: 3, borderRadius: '4px 4px 4px 4px', border: 'solid 0px #333', }}>
                                        <CardActionArea>
                                        <CardMedia
    component="img"
    alt="Product"
    height="auto"
    image={product.imageurl}
    sx={{ backgroundImage: 'contain', borderRadius: '4px 4px 0 0' }}
    onClick={() => navigate(`/product/${product._id}`)}
  />
  
  {/* Overlay Text */}
  <Typography
   onClick={() => navigate(`/product/${product._id}`)}
    variant="body2"
    sx={{
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
     fontWeight: 400,
      padding: '4px 8px', // Optional padding
      borderRadius: '4px', // Optional border radius
      textAlign: 'center',
      textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    }}
  >
    {product.countryName}
  </Typography>
                                            <CardContent sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between',  }}>
                                            <Typography
                                              gutterBottom
                                              variant="body2"
                                              component="div"
                                              sx={{
                                              overflow: 'hidden',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              }}
                                              >
                                              {product.name}
                                              </Typography>

                                                <Box 
                                                     sx={{mb: 0}}  
                                                        
                                                        >
                                                    <AddShoppingCart onClick={() => handleAddToCart3(product)} edge="end" size="small"  sx={{ 
                                                            display: 'inline-flex', 
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            transition: 'transform 0.3s, color 0.3s',
                                                            transform: isClicked ? 'scale(1.2)' : 'scale(1)',
                                                            color: isClicked ? '#0a0' : '#077336',
                                                            cursor: 'pointer',
                                                        }}/>
                                                    </Box>
                                            </CardContent>
                                            <CardActions sx={{ justifyContent: 'flex-end', paddingLeft: 0, paddingRight: 0, }}>
                                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                <Box onClick={handleOpenModal} sx={{ display: 'flex', alignItems: 'left', gap: 0 }}>
                                                <img src='https://storage.googleapis.com/app_darkendimg/assets/jetset.png' alt="Congo" style={{ width: 30, height: 30, borderRadius: 50 }} />
                                                <Typography variant="caption" fontWeight={400}>
                                                  4K tokens
                                                </Typography>
                                                </Box>
  <Typography variant="body2" fontWeight={600}sx={{paddingRight: 1}}>
    ${product.price.toFixed(2)}
  </Typography>
  </Box>
</CardActions>

                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Modal
  open={OpenModal}
  onClose={handleCloseModal}
  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  padding: 1 }}
>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column', // Added to align text and image vertically
      backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',  // Dark mode: dark background, Light mode: white background
      padding: 2,
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      boxShadow: 3,
      border: 'solid 1px ',
    }}
  >
    <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 600 }}>
      Earn 4k DigiBeans with this purchase
    </Typography>
    <img
      src="https://storage.googleapis.com/app_darkendimg/assets/jetset.png"
      alt='bean head'
      style={{
        width: '100px',
        height: '100px',
        marginBottom: 10,
        border: 'none', // Remove any border on the image
      }}
    />
    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 500 }}>
      Use to Post, Like, Share, Sell products and exchange and redeem for discounts
    </Typography>
  </Box>
</Modal>

                       
                        {!isMobile && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      <div style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 1000, mb: 1 }}>
      <StackableRoad />
      </div>
    </Box>
      }
                    </div>
                )
            ))}
           
        </Box>
        
        
    );
};

// Component to fetch and display image
function UserImage({ userID }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChipClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const { loading, error, data } = useQuery(GET_ROASTERIES, {
        variables: { _id: userID },
    });

    if (loading) return <img src="https://via.placeholder.com/30" alt="user" style={{ width: 30, height: 30, borderRadius: 50 }} />;
    if (error) return <Chip
        label="Coming Soon"
        avatar={<Avatar src="https://storage.googleapis.com/app_darkendimg/assets/farmericon.jpg" alt="Bean" />}
        variant="outlined"
        style={{ marginTop: 8, }}
    />;

    const userimage = data.userprofs[0]?.imageurl || 'Unknown';
    const username = data.userprofs[0]?.Nickname || 'Unknown';
    const userstory = data.userprofs[0]?.Koffie_story || 'Unknown';

    return (
        <>
            <Chip
                label={username}
                onClick={handleChipClick}
                avatar={<Avatar src={userimage} alt={username} />}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: 16 }}>
                    <Typography variant="body1">{userstory}</Typography>
                </div>
            </Popover>
        </>
    );
}

export default Health;
