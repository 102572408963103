// constants.js
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import * as Realm from "realm-web";

const APP_ID = process.env.REACT_APP_APP_ID; // Your Realm app ID

// Initialize a Realm App instance
const app = new Realm.App(APP_ID);

// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  if (!app.currentUser) {
    console.error('User is not logged in');
    throw new Error('User is not logged in');
  } 
  try {
    await app.currentUser.refreshCustomData();
  } catch (e) {
    console.error('Could not refresh custom data:', e);
    throw e;
  }
  return app.currentUser.accessToken;
}

// Create an HTTP link
const httpLink = new HttpLink({
  uri: `https://realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
});

// Create a middleware link that sets the Authorization header with the token
const authLink = setContext(async (_, { headers }) => {
  const accessToken = localStorage.getItem('') || await getValidAccessToken();
  return {
    headers: {
      ...headers,
      'Authorization': accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

// Combine the middleware link with the HTTP link
const link = authLink.concat(httpLink);

// Configure the ApolloClient with the combined link and cache
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export { client, app };
