//create a search feild for google react-google-maps/api to enable users to search and rate koffie shops the maps search is to validate the business and review will be within our app not on google site, the review should capture the location so we can extract basic data and build a profile of koffie shop in our db

import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, LoadScript, Marker , StandaloneSearchBox} from '@react-google-maps/api';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Card, CardContent, CardMedia,  } from '@mui/material';


const useStyles = makeStyles(({  theme }) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    card: {
      borderRadius: 12,
      minWidth: 256,
      textAlign: 'center',
      transition: '0.3s',
    },
    avatar: {
      width: 50,
      height: 50,
      margin: 'auto',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      
      marginBottom: '0.875em',
    },
    media: {
      minHeight: 250,
      width: 345,
      
      borderRadius: 10,
      
    },
    statLabel: {
      fontSize: 12,
      color: "none",
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 4,
      letterSpacing: '1px',
      fadeIn: 'ease-in 5s',
      visibility: 'visible',
      animation: 'fadeIn ease-in 5s',
      width: '100%',
      
      
    },
    searchbox : {
        width: '100%',
        height: '40px',
        padding: '12px 20px',
        margin: '8px 0',
        boxSizing: 'border-box',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '10px',
        border: '1px solid #ccc'
        
    }

  }));
const libraries = ["places"];

// const onLoad = ref => {
//   console.log('autocomplete', ref);
// }

const onLoad = (ref) => {
  console.log('autocomplete', ref);
}

const onPlacesChanged = (ref) => {
  console.log('places', ref.getPlaces());
}
  
// const onLoad = (ref) => {
//   console.log('autocomplete', ref);
// }
;
// const onPlacesChanged = (ref) => {
//   console.log('places', ref.getPlaces());
// }
const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

const position = {
  lat: -3.745,
  lng: -38.523
};

const Map = () => {
    const searchBoxRef = useRef(null);
        const classes = useStyles();
    const handleSearch = useCallback(() => {
      // Ensure searchBoxRef.current and places are defined
      if (!searchBoxRef.current || !searchBoxRef.current.getPlaces()) {
        console.error("No places found");
        return;
      }
    
      const places = searchBoxRef.current.getPlaces();
    
      // Ensure places is an array and has elements
      if (!Array.isArray(places) || places.length === 0) {
        console.error("No places found");
        return;
      }

      // Get the first place
      const place = places[0];
    
      // Ensure place has a geometry
      if (!place.geometry) {
        console.error("Place does not have a geometry");
        return;
      }
      console.log(place.geometry.location);

      
      }, []);
    
  return (
    <>
    <Box sx={{ flexGrow: 1, maxWidth: 600, mx: 'auto', my: 4 }}>
       <Typography variant="h6" gutterBottom>
        Tell us about your koffie experience
      </Typography>  
    
    <LoadScript
      googleMapsApiKey="AIzaSyAGEvhVRFmxBBiSsZ8e7LI11slf89LHfMI"
      libraries={libraries}
    >
         <StandaloneSearchBox
        onLoad={ref => searchBoxRef.current = ref}
        onPlacesChanged={handleSearch}
      >
        <input
          type="text"
          placeholder="Search for coffee shops..."
          className= {classes.searchbox}
        />
      </StandaloneSearchBox>
    
    </LoadScript>
    </Box>
    </>
  )
}   

export default Map;

// import React from 'react';
