import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Card, Input, List, Typography, Button , Chip, CardMedia, Avatar, Dialog, DialogTitle} from '@mui/material';
import  beanify  from '../../images/beaniefy.png';

import CloseButton from '../../constants/close';


const UPDATE_GOAL = gql`
  mutation UpdateGoal($query: GoalQueryInput, $set: GoalUpdateInput!) {
  updateOneGoal(query: $query, set: $set) {
    Title
    _id
    
  }

  }
`;


const GET_SINGLE_GOAL = gql`
  query GetSingleGoal($_id: ObjectId!) {
    goal(query: { _id: $_id }) {
      Title
      _id
      primaryGoal
      steps {
        description
        status
        stepID
        subTasks {
          description
          status
          taskID
        }
      }
    }
  }
`;


const DoIT = ({ _id: propId }) => {
  const { _id: urlId } = useParams(); // Get the id from the URL
  const [updateGoal] = useMutation(UPDATE_GOAL);
  const [isEditing, setIsEditing] = useState(false);
  const [ primaryGoal, setPrimaryGoal] = useState('');
  const {  refetch } = useQuery(GET_SINGLE_GOAL, {
    variables: { _id: propId || urlId }
  });

  const idToUse = propId || urlId; // Use the prop if it exists, otherwise use the URL
  const [timeLeft, setTimeLeft] = useState(3600); // Example: 1 hour = 3600 seconds
const [isTimerRunning, setIsTimerRunning] = useState(false);
const [outputNumber, setOutputNumber] = useState(null); // State to hold the number to output
const [dialogOpen, setDialogOpen] = useState(false);

useEffect(() => {
  let intervalId;

  if (isTimerRunning && timeLeft > 0) {
    intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      // Increment outputNumber by 1 every 33.3333 seconds
      // Calculate if the current second is a multiple of 33.3333 to increment outputNumber
      if (Math.floor(3600 - timeLeft) % Math.floor(33.0) === 0) {
        setOutputNumber((prevNumber) => prevNumber + 1);
      }
    }, 1000);
  } else if (timeLeft === 0) {
    setIsTimerRunning(false);
    setDialogOpen(true); // Show dialog when time runs out
  }

  return () => clearInterval(intervalId);
}, [isTimerRunning, timeLeft]);

const toggleTimer = () => {
  setIsTimerRunning(!isTimerRunning);
  if (timeLeft === 0) {
    setTimeLeft(3600); // Reset timer if it's finished
    setOutputNumber(0); // Reset output number
  }
};

   const handleEditClick = () => {
    setIsEditing(true);
    setPrimaryGoal(goal.primaryGoal); // Initialize with existing primaryGoal
  };

  const handleSaveClick = async () => {
    try {
      await updateGoal({
        variables: {
          input: {
            _id: goal._id,
            primaryGoal: primaryGoal,
            steps: goal.steps,
            subTasks: goal.subTasks,
          },
        },
      });
      await updateGoal({
        variables: {
          query: { _id: goal._id },
          set: { primaryGoal: setPrimaryGoal },
        },
      });
      await refetch();
      setIsEditing(false); // Turn off edit mode after successful update
    } catch (error) {
      console.error('Failed to update:', error);
    }
  };
  

  const { loading, error, data } = useQuery(GET_SINGLE_GOAL, {
    variables: { _id: idToUse }
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const goal = data.goal;

 

  return (
    <div style={{ padding: '20px', backgroundColor: '#f4f4f4', borderRadius: '8px' }}>
    {isEditing ? (
      <input
        type="text"
        value={primaryGoal}
        onChange={(e) => setPrimaryGoal(e.target.value)}
        style={{ padding: '10px', margin: '10px 0', width: 'calc(100% - 20px)', borderRadius: '5px', border: '1px solid #ccc' }}
      />
    ) : (
      <h1 style={{ fontWeight: 'bold', marginBottom: '15px' }}>
        {goal.primaryGoal}
      </h1>
    )}
  
    <h2 style={{ fontWeight: '600', marginBottom: '15px' }}>
      {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}
    </h2>
    <div>
      {/* Your existing UI elements */}

      {/* Timer and controls */}
      <Typography variant="h6" gutterBottom>
        Time Left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Output Number: {outputNumber}
      </Typography>
      <Button onClick={toggleTimer} variant="contained" color="primary">
        {isTimerRunning ? 'Stop Timer' : 'Start Timer'}
      </Button>

      {/* Dialog to show when timer completes */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Complete</DialogTitle>
      </Dialog>
    </div>
  
    <p style={{ marginBottom: '20px', fontSize: '18px', fontWeight: 'bold' }}>
      Stay focused on your goal for an hour and win 110 beans!
    </p>
    <p style={{ marginBottom: '20px', fontSize: '14px' }}>
      Keep this page open and stay off your phone to let the timer run out. Navigating away or closing the page will reset your progress.
    </p>
  
    <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '15px' }}>
      {goal.steps.map((step, stepIndex) => (
        <div key={step.stepID} style={{ marginBottom: '20px' }}>
          <h3 style={{ marginBottom: '10px' }}>
            {step.description} - Status: {step.status}
          </h3>
          {step.subTasks.map((subTask, taskIndex) => (
            <p key={subTask.taskID} style={{ marginLeft: '20px' }}>
              {subTask.description} - Status: {subTask.status}
            </p>
          ))}
        </div>
      ))}
    </div>
  
    <button
      onClick={handleEditClick}
      style={{ backgroundColor: '#42b345', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '20px', width: '100%' }}
    >
      Edit
    </button>
  </div>
  

  );
};
export default DoIT;
