import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, CardMedia, Box, Icon, Drawer, Chip, CardActions, Divider, Dialog, Paper, DialogActions, Avatar } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import showme from "../../../images/rita.jpg";
import { ArrowForward, ArrowRight, OutboundOutlined } from "@mui/icons-material";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {Link} from 'react-router-dom';
import Dhome from "../../../pages/homedesk";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import beanify  from '../../../images/beaniefy.png';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from "react-router-dom";
import Progress from "../../mods/progress";


export const MY_ADDS = gql`
  query MyAdds($isAdd: Boolean!) {
    aiadds(query: { isAdd: $isAdd }) {
      Title
      When
      _id
      description
      price
      primaryImage
      productID
      userID
      isAdd
      imageurl
    }
  }
`;
 
export const GET_IMAGE = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
    }
  }
`;

const AiGen2 = () => {
  const [fact, setFact] = useState("");
  
  const { loading: loadingImage, error: errorImage, data: dataImage } = useQuery(GET_IMAGE, {
    variables: { _id: fact.userID,  },
  });

  const getimage = dataImage?.userprofs[0];
  
  const userimage = getimage?.imageurl || showme;
  const userid = getimage?._id;

  const { loading, error, data } = useQuery(MY_ADDS, {
    variables: { isAdd: true },
  });
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const [selectedItem, setSelectedItem] = useState(null);
const [isDialogOpen, setIsDialogOpen] = useState(false);
  

const handleDialogOpen = (item) => {
  setSelectedItem(item);
  setIsDialogOpen(true);
};

  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 300); // Reset to original scale after 2 seconds
  };

  useEffect(() => {
    if (data && data.aiadds.length > 0) {
      generateFact();  // Generate an initial fact
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      generateFact();
    }, 3000); // 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [data]); // Make sure to include data in the dependency array
 
  const generateFact = () => {
    if (data && data.aiadds.length > 0) {
      const addsWithYes = data.aiadds.filter(item => item.isAdd); // Filter for isAdd === true
      if (addsWithYes.length > 0) {
        const randomIndex = Math.floor(Math.random() * addsWithYes.length);
        setFact(addsWithYes[randomIndex]); // Use a fact from the filtered list
      }
    }
  };

 

  if (loading) return <p><Progress/></p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', padding: 1 }}>
  
  <Typography variant="body2" sx={{ fontWeight: 600, textAlign: 'left',   }}>
   Smart Market
 </Typography>
   </Box>
   <Card sx={{   boxShadow: 0, paddingBottom: 0 }}>
  
   
  
            
 
  {fact && (
  <>
   
    <Box 
      position="relative" 
      sx={{
        display: 'flex',
        borderRadius: 0,
        overflow: 'hidden', // Ensures the child components adhere to the border radius
      }}
    >
 
      <CardMedia
        component="img"
        height='200px'
        width='100px'
        image={fact.primaryImage}
        alt="Story image"
       
        sx={{ 
          width: '100%', // Ensures the image covers the card width
          
          objectFit: 'cover', // Ensures the image covers the area without distortion
        }}
      />
      <Box 
        position="absolute" 
        top={2} 
        left={0} 
        right={0} // Added to ensure the box takes the full width
         
        p={1}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'space-between', // Space between top and bottom elements
          
        }}
      >
        
        
        
      
      </Box>
      <CardContent sx={{padding:1, paddingBottom: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', padding: 0 }}>
      <Typography 
          variant="heading" 
          component="div" 
          sx={{
            
            fontWeight: 700,
            mb: 'auto', // Pushes the rest of the content to the 
            padding: 0,
          }}
        >
          {fact.Title}
         
        </Typography>
        <Link to={`/kupster/${userid}`} key={userimage._id}>
            <Avatar src={userimage} alt="beanify" style={{width: 40, height: 40}} />
            </Link>
        </Box>
      <Typography 
           variant="heading"
           sx={{
             mt: 0,
             fontWeight: 400,
              padding: 0,
             alignSelf: 'flex-end',
             display: '-webkit-box',
             WebkitLineClamp: 3,
             WebkitBoxOrient: 'vertical',
             overflow: 'hidden',
             textOverflow: 'ellipsis',
           }}
        >
          {fact.description}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', paddingTop: 2 }}>
          <Typography variant="body1" sx={{ color: 'success', fontWeight: 500}}> ${fact.price} </Typography>
          
          <Chip label='Learn More' onClick={() => setIsDrawerOpen(true)} color='success' />
          </Box>
          
        </CardContent>
    </Box>
  </>
)}
 
 
  
</Card>
<Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} sx={{ width: '100%', padding: 0 }}>
  <Box sx={{ dispplay: 'flex', justifyContent: 'flex-start', padding: 0 }}>
    
    <Card sx={{ 
    display: 'flex', 
    width: 'calc(100% + 10px)', // Adjust the calculation based on actual body padding
          
    backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/path/to/destination/koffiecandy.jpeg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 200,
    borderRadius: 0,
    boxShadow: 0,
    paddingTop: 0,
    margin: 0,
    overflow: 'hidden', // Ensure no scrollbars appear due to negative margins
  }}>
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }}>
    
      <ArrowBackIcon onClick={handleDrawerClose} sx={{ color: " white" }} />
   
    </Box>
    <CardContent>
    <Typography variant="h4" sx={{ fontWeight: 700,  textAlign: 'left', paddingTop: 0, textShadow: '2px 2px 4px #000000' , color: 'white' }} >
      Create your Online Store 
    </Typography>
    <Typography variant="h6" sx={{ fontWeight: 600,  textAlign: 'left', paddingRight: 2, paddingTop: 1, textShadow: '2px 2px 4px #000000' , color: 'white' }} >
    Let our AI salesperson sell 
    
    <Link to="/chat" style={{ textDecoration: 'none' }}>
    <Chip  color='success' label="Go to Store"  />  
    </Link>
    </Typography>
    </CardContent>
  </Card>
  </Box>
  <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)', paddingTop: 2, }}>
 {data && data.aiadds.map((item) => (
 
    <Card sx={{ display: 'flex', padding: 1, margin: 1, borderRadius: 0, }}>
     
      <CardContent sx={{padding:1}}>
        <Box 
          position="relative" 
          sx={{
            display: 'flex',
            borderRadius: 0,
            overflow: 'hidden', // Ensures the child components adhere to the border radius
          }}
        >
          <CardMedia
            component="img"
            height='120px'
            width='120px'
            image={item.primaryImage}
            alt="Story image"
            sx={{ 
              width: '100%', // Ensures the image covers the card width
              objectFit: 'cover', // Ensures the image covers the area without distortion
            }}
          />
          <Box 
            position="absolute" 
            top={2} 
            left={0} 
            right={0} // Added to ensure the box takes the full width
            p={1}
            sx={{
              display: 'flex',
              flexDirection: 'column', // Stack children vertically
              justifyContent: 'space-between', // Space between top and bottom elements
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', paddingTop: 2 }}>
              <Chip label={item.price} />
            </Box>
          </Box>
          <CardContent sx={{padding:0}}>
            <Typography 
              variant="heading" 
              component="div" 
              sx={{
                fontWeight: 700,
                mb: 'auto', // Pushes the rest of the content to the 
                padding: 0,
              }}
            >
              {item.Title}  <Chip 
          key={item._id} 
          label="Details" 
          
          onClick={() => handleDialogOpen(item)} 
          sx={{ m: 1 }}
        />
      
             
            </Typography>
            
             
            
            <Typography 
              variant="heading"
              sx={{
                mt: 0,
                fontWeight: 400,
                padding: 0,
                alignSelf: 'flex-end',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item.description}
            </Typography>
            <Avatar src={''} alt="beanify" style={{width: '30px', height: '30px'}} />
          </CardContent>
          
        </Box>

      </CardContent>
      <Paper>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} sx={{boxShadow: 0}}>
        <Box sx={{ padding: 2 }}>
          <CardMedia
            component="img"
            height='150px'
            width='150px'
            image={selectedItem?.primaryImage}
            alt="Story image"
            sx={{ 
              width: '100%', // Ensures the image covers the card width
              objectFit: 'cover', // Ensures the image covers the area without distortion
            }}
          />
         <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'right', paddingTop: 2 }}>
         <Typography variant="h5" sx={{ fontWeight: 600, textAlign: 'left', paddingTop: 2 }}>
            {selectedItem?.Title || "AI Adds"}
          </Typography>
          
          </div>
          <Typography variant="body2" sx={{ fontWeight: 300, textAlign: 'left', paddingRight: 2, paddingTop: 1 }}>
            {selectedItem?.description || "This is a dialog box"}
          </Typography>
          <DialogActions>
            <Typography variant="h4" sx={{ fontWeight: 600, textAlign: 'left', paddingTop: 2 }}>
          ${selectedItem?.price}
          </Typography>
            <Chip label="Get It" color='success' onClick={() => navigate("/product/" + selectedItem?.productID)} />
            
          </DialogActions>
        </Box>
      </Dialog>
      </Paper>
      </Card>
      
  ))}
</Box>
   
  </Drawer>

</>
  );
};

export default AiGen2;
