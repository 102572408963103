import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, CardMedia, Box, Icon, Drawer, Chip, CardActions, Avatar } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import showme from "../../../images/rita.jpg";
import { ArrowForward, ArrowRight, OutboundOutlined } from "@mui/icons-material";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {Link} from 'react-router-dom';
import Dhome from "../../../pages/homedesk";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import beanify  from '../../../images/beaniefy.png';
import { useSpring, animated } from 'react-spring';

export const MY_ADDS = gql`
  query MyAdds($isAdd: Boolean!) {
    aiadds(query: { isAdd: $isAdd }) {
      Title
      When
      _id
      description
      price
      primaryImage
      productID
      userID
      isAdd
      imageurl
    }
  }
`;
 
export const GET_IMAGE = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
    }
  }
`;

const AiGen = () => {
  const [fact, setFact] = useState("");

  const { loading: loadingImage, error: errorImage, data: dataImage } = useQuery(GET_IMAGE, {
    variables: { _id: fact.userID,  },
  });

  const getimage = dataImage?.userprofs[0];
  
  const userimage = getimage?.imageurl || showme;
  const Nickname = getimage?.Nickname || 'Beanify';

  const { loading, error, data } = useQuery(MY_ADDS, {
    variables: { isAdd: true },
  });
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 300); // Reset to original scale after 2 seconds
  };

  useEffect(() => {
    if (data && data.aiadds.length > 0) {
      generateFact();  // Generate an initial fact
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      generateFact();
    }, 3000); // 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [data]); // Make sure to include data in the dependency array
 
  const generateFact = () => {
    if (data && data.aiadds.length > 0) {
      const addsWithYes = data.aiadds.filter(item => item.isAdd); // Filter for isAdd === true
      if (addsWithYes.length > 0) {
        const randomIndex = Math.floor(Math.random() * addsWithYes.length);
        setFact(addsWithYes[randomIndex]); // Use a fact from the filtered list
      }
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
   <Card sx={{ padding: ".1rem", borderRadius: 5 ,  }}>
  <CardContent sx={{padding:1, }}>
    

  {fact && (
  <>
   <Typography variant="h4" sx={{ fontWeight: 700,  backgroundColor: '', padding: 1, borderRadius: '5px 5px 0px 0px'}}>
        Smart Market
      </Typography>
    <Box 
      position="relative" 
      sx={{
        boxShadow: '14px 14px 80px rgba(34, 35, 58, 0.2)', 
        borderRadius: 0,
        overflow: 'hidden', // Ensures the child components adhere to the border radius
       // Background color for the card
      }}
    >
     
      <CardMedia
        component="img"
        height={350}
        image={fact.primaryImage}
        alt="Story image"
        sx={{ 
          width: '100%', // Ensures the image covers the card width
          borderRadius: 0, // Makes the image square
          objectFit: 'cover', // Ensures the image covers the area without distortion
        }}
      />
      <Box 
        position="absolute" 
        top={2} 
        left={0} 
        right={0} // Added to ensure the box takes the full width
        color="white" 
        p={1}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'space-between', // Space between top and bottom elements
          height: '100%', // Full height of the parent
        }}
      >
        
       
      </Box>
    </Box>
  </>
)}

  </CardContent>
  <CardActions disableSpacing sx={{ justifyContent: 'space-between', }}>
  <Link to={`/userdeets/${userimage._id}`} key={userimage._id}>
            <Avatar src={userimage} alt="beanify" style={{width: 40, height: 40}} />
            </Link>
    <div>
    
            <Typography variant="body2"  sx={{ fontWeight: 500,  borderRadius: '5px 5px 0px 0px', padding: 0, color: '#44ad3b'}}>
      {Nickname}
    </Typography> 
    <Typography 
          variant="body1" 
          
          sx={{
           
            fontWeight: 500,
            mb: 'auto', // Pushes the rest of the content to the 
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          }}
        >
          {fact.Title}
        </Typography>
    </div>   
    <IconButton aria-label="next-bite" onClick={generateFact}>
      <ArrowForward/>
    </IconButton>
  </CardActions>
  
</Card>
<Drawer
  anchor="bottom"
  open={isDrawerOpen}
  onClose={() => setIsDrawerOpen(false)}
  sx={{
    '& .MuiDrawer-paper': {
      height: '60%',
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px'
    }
  }} // Style to set height and border radius of drawer
>
         <Box p={2} sx={{padding: 0}}  >
    <IconButton onClick={() => setIsDrawerOpen(false)}>
      <ArrowBackIcon />
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
      All Bean-Bites
    </Typography>
    </IconButton>
    
  
  </Box>
  
  <Box p={2} sx={{overflowY: 'auto', height: '100%'}}>
 
    {/* Add the content you want to show in the drawer here */}
    <Dhome/>
  </Box>
</Drawer>
</>
  );
};

export default AiGen;
