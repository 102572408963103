import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';



export default function HilltopDialog() {
  const [open, setOpen] = useState(false);

  // Content for the Hilltop Dialog
  //const content = 'Welcome to Hilltop! Enjoy your stay.';

  useEffect(() => {
    if (!sessionStorage.getItem('hilltopDone')) {
      setOpen(true);
      sessionStorage.setItem('hilltopDone', 'true');
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    PaperProps={{
      sx: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    <Box
      sx={{
        
        fontWeight: 800,
        textAlign: 'center',
        padding: 2,
        
        height: 'auto',
        borderRadius: 5,
        backgroundColor: '#008C59',
        
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'auto',
          width: '100%',
          padding: 2,
        }}
      >
        <DialogTitle variant="h6" fontWeight={600} sx={{ mt: 1 ,  color: '#fff',  padding: 0 }}>
          Koffie makes people think
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          <img src="https://storage.googleapis.com/app_darkendimg/youngbean.png" alt="Hilltop" style={{ width: '200px', height: 'auto', borderRadius: 5 }} />
          <DialogContentText sx={{ color: '#fff',  padding: 0,  }}>
            Thinking is the most dangerous thing to tyrants, and the most precious thing to the oppressed.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mt: 15, position: 'absolute', bottom: 5, right: 0  }}>
        <Button
          onClick={handleClose}
          sx={{  color: '#fff', fontWeight: 800, border: '1px solid #fff', backgroundColor: 'transparent', }}
        >
          Close
        </Button>
      </DialogActions>
      </Box>
     
    </Box>
  </Dialog>
  
  );
}
