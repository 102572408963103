import React, { useState, useEffect } from 'react';
import jsonData from './cost';  // Import your JSON data here

const CoffeeValueChain = () => {
  // Initialize state variables
  const [selectedCoffeeType, setSelectedCoffeeType] = useState(null);
  const [cutMiddleman, setCutMiddleman] = useState(false);
  const [costBreakdown, setCostBreakdown] = useState({});

  // Function to calculate the cost breakdown
  const calculateCostBreakdown = () => {
    if (!selectedCoffeeType) return;

    const coffeeYield = jsonData.coffee_yield[selectedCoffeeType];
    const valueChain = { ...jsonData.value_chain_costs };
    const cupsPerLb = coffeeYield.Coffee_Mugs;  // You can also allow users to select cup size

    // If "Cut the Middleman" is enabled, remove certain roles
    if (cutMiddleman) {
      delete valueChain.processor;
      delete valueChain.exporter;
      delete valueChain.roaster;
    }

    // Calculate the farmer's earnings based on the selected coffee type and whether middlemen are cut
    let farmerEarnings = (valueChain.farmer / jsonData.c_price_per_lb) * cupsPerLb;

    // Set the cost breakdown state
    setCostBreakdown({
      ...valueChain,
      farmerEarnings
    });
  };

  // Update the cost breakdown whenever the selected coffee type or "Cut the Middleman" option changes
  useEffect(() => {
    calculateCostBreakdown();
  }, [selectedCoffeeType, cutMiddleman]);

  return (
    <div>
      {/* Dropdown or Buttons for selecting Coffee Type */}
      <button onClick={() => setSelectedCoffeeType('Espresso_Double_Shot')}>Espresso</button>
      {/* ... other coffee types */}
      
      {/* Output Display */}
      <div>
        {/* Display the cost breakdown */}
        {Object.entries(costBreakdown).map(([role, cost]) => (
          <div key={role}>
            {role}: ${cost.toFixed(2)}
          </div>
        ))}
      </div>
      
      {/* Cut the Middleman Toggle */}
      <button onClick={() => setCutMiddleman(!cutMiddleman)}>
        {cutMiddleman ? 'Include Middleman' : 'Cut the Middleman'}
      </button>
    </div>
  );
};

export default CoffeeValueChain;
