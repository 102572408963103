import React, { useState, useEffect,  useContext } from 'react';
import Plot from 'react-plotly.js';
import Typeit from '../mods/typeit';
//import CoffeePage from './des';
import { 
Card, Chip, List, Paper, Typography, Avatar, Button,
Box, Divider, CardContent, Drawer, Grid, CardMedia,
CardActions, CardHeader,  TextField,  Stack, 
} from '@mui/material';
import { App } from 'realm-web';
import '../../App.css';
import bean from '../../images/coffeebeans.png';
import Flavloader from '../mods/flavloader';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import kup from '../../images/steamkup.jpg';
import koffieman from '../../images/koffieman1.png';
import ThreeScene from '../mods/particles';
import '../../context/EarthMode.css';
import { Link } from 'react-router-dom';
import { ListAlt } from '@mui/icons-material';
import {useQuery, gql, useMutation } from '@apollo/client';
//import Aifood from './aifood';
import Header from '../nav/mobilefarmer';
import { UserContext } from '../../context/usercontext';
import Ticker from '../finance/prods/Ticker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import DFiveAI from './Dfiveai';
import Progress from '../mods/progress';
import { useParams } from 'react-router-dom';



//Get FIVE
const GET_PRODUCTS = gql`
  query Products ($userID: String!){
    products (query: {userID: $userID}
      ) {
    
    _id
    description1
    userID
    imageurl
    name
    price
    
   
  }
  }
`;

//Get Single Product
const GET_SINGLE_PRODUCT = gql`
  query SingleProduct($_id: ObjectId!) {
    product(query: {_id: $_id}) {
      _id
    description1
    userID
    imageurl
    name
    price
    }
  }
`;

//Mutate Koffie
const UPSERT_USERPROF = gql`
  mutation upsertOneUserprof($query: UserprofQueryInput, $data: UserprofInsertInput!) {
    upsertOneUserprof(query: $query, data: $data) {
      _id
      name
      description
      country   
      tasteProfile
    }
  }
`;

//this
const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      name
      description
      country   
      tasteProfile
      purchaseLink
    }
  }
`; 



const GET_USER = gql`
  query Userprof ($_id: ObjectId!)  {
  userprof (query: {_id: $_id}) {
    author
    cherryCount
    Nickname
    Title
    _id
    Koffie_story
  }
}
`; 



const kupakoffie = () => {
    return {
      "name": "kupakoffie",
      "description": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name of the coffee"
          },
          "country": {
            "type": "string",
            "description": "The country of origin of the coffee"
          },
          "description": {
            "type": "string",
            "description": "Short Whitty Description of the coffee"
          },
          "tasteProfile": {
            "type": "string",
            "description": "How i like my coffee"
          },
          "purchaseLink": {
            "type": "string",
            "description": "/koffie/country "
          },
          
        },
        "required": ["name", "country", "description", "tasteProfile",  "purchaseLink"]
      }
    };
  };


  const useStyles = makeStyles(({ palette }) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    card: {
      borderRadius: 12,
      minWidth: 256,
      textAlign: 'center',
      transition: '0.3s',
    },
    avatar: {
      width: 150,
      height: 150,
      margin: 'auto',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      color: 'inherit',
      marginBottom: '0.875em',
    },
    statLabel: {
      fontSize: 12,
      color: "none",
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 4,
      letterSpacing: '1px',
      fadeIn: 'ease-in 5s',
      visibility: 'visible',
    },
  }));



const DongFeng5 = () => {
  const { _id } = useParams();
  const { user, setUser } = useContext(UserContext);
  const { userID } = user;
  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const styles = useStyles();
  const [name, setName] = useState("");
const [country, setCountry] = useState("");
const [flavorProfile, setFlavorProfile] = useState("");
const [description, setDescription] = useState("");
const [purchaseLink, setPurchaseLink] = useState("");
const [showCard, setShowCard] = useState(true);
const [drawerOpen, setDrawerOpen] = useState(false);
const [selectedProductId, setSelectedProductId] = useState(null);

  const { loading: singleProductLoading, error: singleProductError, data: singleProductData } = useQuery(GET_SINGLE_PRODUCT, {
    skip: !drawerOpen,  // Skip the query if the drawer is not open
    variables: { _id: selectedProductId },
  });

  const openDrawer = (_id) => {
    setSelectedProductId(_id);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const Item = styled(Paper)(({ theme }) => ({
	
    padding: theme.spacing(1),
    textAlign: 'center',
  
    boxShadow: 'none'
    }));
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { _id: user.id },
  });
  const [updateUserProf] = useMutation(UPDATE_USERPROF);

  const { loading: productsLoading, error: productsError, data: productsData } = useQuery(GET_PRODUCTS
    , {variables: { userID: user.user ? user.user.id : null },});
  let productsByCategory = {};

// Check if productsData is defined and has a 'dfives' field
if (productsData && productsData.products) {
  productsByCategory = productsData.products.reduce((acc, product) => {
    const owner = product.userID;
    if (!acc[owner]) {
      acc[owner] = [];
    }
    acc[owner].push(product);
    return acc;
  }, {});
}
  const [isButtonClicked, setButtonClicked] = useState(false);

   


  useEffect(() => {
    if (data?.userprof) {
      setName(data.userprof.name || "");
      setCountry(data.userprof.country || "");
      setFlavorProfile(data.userprof.flavorprofile || "");
      setDescription(data.userprof.description || "");
      setPurchaseLink(data.userprof.purchaseLink || "");
      
      // Hide the card and button when userprof data is available
      setShowCard(false);
    }
    // Revert to showing the card if data becomes null or undefined
    else {
      setShowCard(true);
    }
  }, [data]);

  
  
  
  
  const ChartData = [
    {
        maxdepth: 6,
        insidetextorientation: 'radial',
      type: "sunburst",
      labels: ["Coffee", "Aroma", "Brown Sugary",  "Dry Distillation",  "Taste", "Sour",  "Soury", "Enzymatic", "Sweet", "Salt", "Bitter", "Carbony", "Spicy", "Resinous" , "Ashey", "Smokey", "Burnt", "Charred", "Tarry", "Pipe Tobacco", "Warming", "Pungent", "Thyme", "Clove", "Cedar", "Pepper", "Tupeny", "Medicinal", "Ceniolic", "Camphoric", "Piney", "Blackcurrant-like", "Chocolatey", "Caramelly", "Nutty", "Vanilla-like", "Chocolate-like", "Syrup-like", "Candy-like","Malt-like", "Nut-like", "Butter", "Swiss", "Dark Chocolate", "Bakers", "Maple Syrup", "Honey", "Roasted Almond", "Roasted Hazlenut", "Herby", "Fruity", "Flowery", "Leguminous", "Alliaceous", "Berry-like", "Citrus", "Fragrant", "Floral", "Onion", "Garlic", "Cucumber", "Garden Peas", "Blackberry", "Apricot", "Lemon", "Apple", "Coriander Seeds", "Cardamon Caraway", "Tea Roase", "Coffee Blossom", "Acidity", "Mellow", "Winey", "Harsh", "Pungenty", "Rough", "Sharp", "Bland", "Astringent", "Soft", "Neutral", "Creosol", "Phenolic" , "Caustic", "Alkeline", "Tangy", "Tart", "Acid", "Hard", "Mild", "Delicate", "Piquant", "Nippy"],
      parents: ["", "Coffee", "Aroma",  "Aroma",  "Coffee", "Taste", "Sour",  "Aroma", "Taste", "Taste", "Taste", "Dry Distillation", "Dry Distillation", "Dry Distillation", "Carbony", "Carbony", "Ashey", "Ashey", "Smokey", "Smokey" , "Spicy", "Spicy", "Pungent", "Pungent", "Warming", "Warming", "Resinous", "Resinous", "Medicinal", "Medicinal", "Tupeny", "Tupeny", "Brown Sugary", "Brown Sugary", "Brown Sugary" , "Chocolatey", "Chocolatey", "Caramelly", "Caramelly", "Nutty", "Nutty", "Vanilla-like", "Vanilla-like", "Chocolate-like", "Chocolate-like", "Syrup-like", "Syrup-like", "Candy-like", "Candy-like", "Enzymatic","Enzymatic", "Enzymatic", "Herby", "Herby", "Fruity", "Fruity", "Flowery", "Flowery", "Alliaceous", "Alliaceous", "Leguminous", "Leguminous", "Berry-like", "Berry-like", "Citrus", "Citrus", "Fragrant", "Fragrant", "Floral", "Floral", "Sweet", "Sweet", "Sour", "Bitter", "Bitter", "Sharp", "Salt", "Salt", "Sharp", "Bland", "Bland", "Pungenty", "Pungenty", "Harsh", "Harsh", "Winey", "Winey", "Soury", "Soury", "Mellow", "Mellow", "Acidity", "Acidity"  ], 
        marker: {line: {width: 2},
        colors: [ "#876545", "#dbd225", "#ab9628", "#e6845a", "#88c292", "#73e067", "#73e067", "#e6845a", "#800000", "#008000", "#000080", "#808000", "#f5a327", "#000080", "#808000", "#f5a327", "#f5a329", "#808000", "#f5a327", "#33bbff", "#fdff33", "#808000", "#f5a327", "#f5a329", "#808000", "##ab9628", '#f127f5', "#fdff33", "#f127f5", "#f5a327","#a37814", "#806a37", "#cfbe06 ", "#d4570f"],
      
    }
    }
  ];

  
  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  
  
  

  const layout = {
    width: windowSize.width * .97, 
    margin: {l: 0, r: 0, b: 0, t: 0}, 
    showlegend: false,
    ModeBarButtons: '#fff', 
    paper_bgcolor: "transparent",
    plot_bgcolor: "#d2d2d2", 
    font: {color: "#000000"}
  };
  

  const handleClick = (data) => {
    if (data.points && data.points.length > 0) {
        const label = data.points[0].label;
        
        setClickedItems([...clickedItems, { label, }]);
        setButtonClicked(true);
    }
};

const clearClickedItems = () => {
  setClickedItems([]);  // Reset clickedItems to an empty array
};

const handleUpdate = (outputData) => {
  updateUserProf({
    variables: {
      query: { _id: outputData._id },
      set: outputData.data
    }
  });
}



const coffeeFunction = kupakoffie();  // Get the coffee function

  const handleSubmit = () => {
    const profileData = clickedItems.map(item => item.label).join(', ');
    setIsLoading(true);  // Start loading before the fetch request

  
    fetch('https://api.openai.com/v1/chat/completions', { // replace with your server URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${"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"}`,

      },
      body: JSON.stringify({
        
        "messages": [
           
            {"role": "system", "content": "You are embodiment of the brand DONGFENGFIVE. Your clothing brand is themed to Sattirical towards ALL BIG recognized global brands. You have an online store for your items and you should interact with the user in the theme of your brand to sell your items. You are very Whitty "},
            {"role": "user", "content": "I like this one, please explain the concept behind this desingn and how it embodies the brand: " + profileData + "."},
            ],
            
            "temperature": 0.9,
            'model': 'gpt-4',
            'functions': [coffeeFunction],
       
        
        
      }),
    })
    .then(response => response.json())
    .then(data => {
      let contentData;
      if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
        contentData = {
          name: data.choices[0].message.content.name,
          country: data.choices[0].message.content.country,
          flavorprofile: data.choices[0].message.content.tasteProfile,
          description: data.choices[0].message.content.description,
          purchaseLink: data.choices[0].message.content.purchaseLink,
        };
      } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
        contentData = JSON.parse(data.choices[0].message.function_call.arguments);
      }
      
  
      setOutput(contentData); // Render the content on the frontend
      console.log('Success:', contentData);
      console.log('Total tokens used:', data.usage.total_tokens);
  
      // Construct the variables for the GraphQL mutation
      const output = {
        data: contentData,
        _id: user.id
      };
  
      // Perform the GraphQL mutation using the 'output' object
      handleUpdate(output);
     
      setIsLoading(false); // End loading after receiving the response
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false); // End loading if there's an error
    });
      
  };
  
  const trademark = "™";

  return (
    <Box sx={{ flex: 'grow', padding: 1, margin: 0, overflowX: 'hidden' }}>
      
      <Card sx={{ borderRadius: '10px'}}>
      <div>

   
  {Object.keys(productsByCategory).map(products => (
    <div key={products}>
      <Typography variant="h4" sx={{fontWeight: 900}}> {products}</Typography>
      <Box display="flex" flexWrap="wrap">
        {productsByCategory[products].map(product => (
          <Box key={product._id} m={1}>

<div onClick={() => openDrawer(product._id)}>
  <Avatar
    sx={{ border: '5px', borderColor: '#000', width: 100, height: 100 }}
    alt={product.name}
    src={product.imageurl}
    label={product.price}
  />
</div>

            <Typography variant="caption">{product.name}</Typography>
            <Typography variant="body2">${product.price}</Typography>
          </Box>
        ))}
      </Box>
    </div>
  ))}
 <Drawer anchor="bottom" open={drawerOpen} onClose={closeDrawer}>
  <div style={{ width: 300, padding: 20 }}>
 <IconButton onClick={closeDrawer}>
    <ArrowBackIcon />
  </IconButton>
  </div>
        {singleProductLoading && <div>Loading...</div>}
        {singleProductError && <div>Error: {singleProductError.message}</div>}
        {singleProductData && (
          <div>
            

            {/* Render your single product details here */}
            <Box display="flex" alignItems="center">
  <Avatar alt="fiveguy" src={singleProductData.dfive.imageurl} sizes='10' />
  <Typography variant="h2" sx={{fontWeight: 700, marginLeft: 2}}>
    {singleProductData.dfive.name}
  </Typography>
</Box>

            {/* ... other details */}
            <Stack spacing={2}  >
      
        <Card  sx={{maxWidth: '400px'}}>
	<CardMedia sx={{ width:'200x', height:'400px', borderRadius: 5}}  image={singleProductData.dfive.imageurl} title="dfive product" />


<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
<Grid item xs={6}>
          <Item >
			
      <Typography variant='h6' sx={{ textAlign: 'left'}} >
			{singleProductData.dfive.description1}
      </Typography>
		  </Item>
        </Grid>
        <Grid item xs={6}>
          <Item sx={{fontWeight: 'bold', fontSize: '16px',}}>Rock with us</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
			<Typography variant='h6' sx={{ textAlign: 'left'}} >
			You Get?
			</Typography>
			<Typography variant="body2"sx={{ textAlign: 'left'}} >{singleProductData.dfive.description2}</Typography>
		  </Item>
        </Grid>
        <Grid item xs={6}>
          <Item sx={{fontWeight: 'bold', fontSize: '34px'}}>${singleProductData.dfive.price}</Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{fontWeight: 'normal', fontSize: '18px', textAlign: 'left'}}>
           <List sx={{ }}>
            <Typography variant='h6' sx={{ textAlign: 'left'}} >
           Benefits  
           </Typography>
           </List>
           <List>
           <Typography variant="body2"sx={{ textAlign: 'left'}} >{singleProductData.dfive.description3}</Typography>
           </List>
           <List>
           <Typography variant='caption' sx={{ textAlign: 'left', fontWeight: 500}} >
            Authentic DongFengFive&trade;, I pledge Alegience to the  DongFeng5Trade&trade; Movement.
           </Typography>
           </List>
            </Item>
          </Grid>
		</Grid>

	
    <Grid container justifyContent="center" alignItems="center" paddingBottom={10} >
	<Button style={{width:'200px', }} type="button" >Add to Cart</Button>

  </Grid>
</Card>

</Stack>
          </div>
          
        )}
      </Drawer>
</div>


      
      <Fab onClick={clearClickedItems} style={{  }}>
          Clear
        </Fab>
           <Typography variant='h5' textAlign={"center"}sx={{}}>
    
      </Typography>
      {clickedItems.map((item, index) => (
 <Chip 
 key={index}
 label={item.label}
 avatar={<Avatar alt="fiveguy" src="https://storage.googleapis.com/app_darkendimg/dfive/getbusy.png" sizes='10' />}
 sx={{ m:1}}
/>

))}
 
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , padding: 5}}>
      <Fab  variant="extended" aria-label="add" sx={{ color: 'inherit', marginBottom: 4}} onClick={handleSubmit}>
  <div sx={{ mr: 1 }}>
    <Avatar src="https://storage.googleapis.com/app_darkendimg/dfive/getbusy.png" width={30}   alt="Bean" />
  </div>
  Resist
</Fab>

</div>
      
     </Card>
 
<div>      
{isLoading ? (
      <Flavloader />
    ) : (
      <>
               
          <div className="App">
          
            <div className='grid-item'>
            <Typography variant="h6" >
                    
                  </Typography>
                  </div>
           
                  {content && (
  <Card className={styles.card}>
    <CardContent>
      <Avatar className={styles.avatar} src={koffieman} />
      <Typography className={styles.heading} align="center">{content.name}</Typography>
      <Typography className={styles.subheader}>{content.tasteProfile}</Typography>
      <Typography variant="h6">{content.country}</Typography>
      
      <Typography className={styles.statValue}>{content.description}</Typography>
      <Link to={`/koffie/${content.country}`} >
  <Button>
    Purchase
  </Button>
</Link>

      {/* ... other properties ... */}
    </CardContent>
    
   <Header/>
  </Card>
            )}	
          </div>   
      </>
    )}            
        </div>     
      
<Box sx={{ flex: 'grow', marginTop: 4 }}>
hello
</Box>

    </Box>  
  );
};

export default DongFeng5;
