import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import useFileUpload3 from './productupload';
import { useItem } from '..//finance/mangeshop/useitems';
import { UserContext } from '../../context/usercontext';
import ClaimBeans from '../mods/dialogue/claimbeans';
import { Input } from '@mui/material';
import { Box, Button, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';


const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const MultiFileUpload = ({ userId }) => {
  const { user } = useContext(UserContext);
  const { files, previewURLs, handleFileChange, uploadFiles, handleRemoveFile, errors } = useFileUpload3();
  const { products, handleInsertProduct, handleUpdateProduct, handleDeleteProduct } = useItem();
  const [isUploading, setIsUploading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const { data: beanData, loading: beanLoading } = useQuery(GET_BEAN_COUNT, {
    variables: { _id: user.id },
  });

  const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);

  const formik = useFormik({
    initialValues: {
      productName: '',
      productDescription: '',
      productPrice: '',
      beanamount: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (beanData?.userprof?.beanCount < 1) {
        setShowDialog(true); // Show the dialog if beans are insufficient
        return;
      }

      const priceInt = parseInt(values.productPrice, 10);
      if (isNaN(priceInt)) {
        alert('Please enter a valid price.');
        return;
      }

      setIsUploading(true);
      const uploadedImageURLs = await uploadFiles(user.id);

      if (!uploadedImageURLs || uploadedImageURLs.length !== 5) {
        alert('Please upload all 5 images.');
        setIsUploading(false); // Reset the upload state on error
        return;
      }

      await decrementBeanCount({
        variables: { _id: user.id, beanCount_inc: -50 },
      });

      const newProduct = {
        name: values.productName,
        description1: values.productDescription,
        price: values.productPrice,
        imageurl: uploadedImageURLs[0],
        imageurl1: uploadedImageURLs[1],
        imageurl2: uploadedImageURLs[2],
        imageurl3: uploadedImageURLs[3],
        imageurl4: uploadedImageURLs[4],
        userID: user.id,
      };

      handleInsertProduct(newProduct);
      resetForm(); // Reset the form fields
      setIsUploading(false);
      setShowDialog(false); // Ensure dialog closes after upload
    },
  });

  return (
    <Box sx={{ padding: '16px', width: '100%', width: '100%', margin: '0 auto', padding: 0  }}>
      {showDialog && <ClaimBeans onClose={() => setShowDialog(false)} />}
      
      <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
        {/* Product Name Input */}
        <TextField
          variant="outlined"
          label="Product Name"
          name="productName"
          value={formik.values.productName}
          onChange={formik.handleChange}
          fullWidth
        />
        
        {/* Product Description Input */}
        <TextField
          variant="outlined"
          label="Product Description"
          name="productDescription"
          value={formik.values.productDescription}
          onChange={formik.handleChange}
          fullWidth
          multiline
          rows={2}
        />
        
        {/* Product Price Input */}
        <TextField
          variant="outlined"
          label="Product Price"
          name="productPrice"
          type="number"
          value={formik.values.productPrice}
          onChange={formik.handleChange}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        
        {/* Image Upload Section */}
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center', mt: 2, }}>
          {[0, 1, 2, 3, 4].map((index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center', padding: 0, width: 'auto'  }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id={`upload-button-${index}`}
                type="file"
                onChange={(e) => handleFileChange(e, index)}
              />
              <label htmlFor={`upload-button-${index}`}>
                <IconButton
                  color="primary"
                  component="span"
                  aria-label="upload picture"
                >
                  <AddPhotoAlternateIcon fontSize="medium" />
                </IconButton>
              </label>
              
              {previewURLs[index] && (
                <img
                  src={previewURLs[index]}
                  alt={`Preview ${index + 1}`}
                  style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '8px', marginTop: '8px' }}
                />
              )}
              
              {errors[index] && (
                <Typography variant="caption" color="error">
                  {errors[index]}
                </Typography>
              )}
            </Box>
          ))}
        </Box>

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" disabled={isUploading} fullWidth>
          {isUploading ? 'Uploading...' : 'Submit'}
        </Button>
      </form>
    </Box>
  );
};

export default MultiFileUpload;
