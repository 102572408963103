import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, CardMedia, Box, Icon, Drawer, Chip, CardActions, Divider } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import showme from "../../images/rita.jpg";
import { ArrowForward, ArrowRight, OutboundOutlined } from "@mui/icons-material";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {Link} from 'react-router-dom';
import Dhome from "../../pages/homedesk";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import beanify  from '../../images/beaniefy.png';
import { useSpring, animated } from 'react-spring';

//Bean icon
const BeanIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/newton.png' alt="Beanify" style={{ width: 30, height: 30 }} />
  );
};




const GET_STORIES = gql`
  query {
    darkroads(limit: 100) {
      ImageUrl
      When
      How
      What
      Title
      _id
    }
  }
`;

const FactGen = () => {
  const [fact, setFact] = useState("");
  const { loading, error, data } = useQuery(GET_STORIES);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 300); // Reset to original scale after 2 seconds
  };

  useEffect(() => {
    if (data && data.darkroads.length > 0) {
      generateFact();  // Generate an initial fact
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      generateFact();
    }, 7000); // 7 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [data]); // Make sure to include data in the dependency array
 
  const generateFact = () => {
    if (data && data.darkroads.length > 0) {
      const randomIndex = Math.floor(Math.random() * data.darkroads.length);
      setFact(data.darkroads[randomIndex]); // Assuming the story is in the 'What' field
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
   <Card sx={{ padding: "0rem", border: 'solid 1px #ddd' ,  }}>
  <CardContent sx={{ padding: '0px', }}>
    

  {fact && (
  <>
    <Box 
      position="relative" 
      sx={{
        backgroundImage: `url(${fact.ImageUrl})`, // Set the background image
        backgroundSize: 'cover', // Ensure the image covers the area
        backgroundPosition: 'center', // Center the image
        height: 200, // Set a fixed height
        width: '400px', // Set a fixed width
        borderRadius: ' 5px 5px 0px 0px',
        
        overflow: 'hidden', // Ensures the child components adhere to the border radius
      }}
    >
     
      <Box 
        position="absolute" 
        top={2} 
        left={0} 
        right={0} // Added to ensure the box takes the full width
        color="white" 
        p={1}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'space-between', // Space between top and bottom elements
          height: '100%', // Full height of the parent
        }}
      >
        <Typography 
          variant="h4" 
          component="div" 
          sx={{
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', 
            fontWeight: 600,
            mb: 'auto', // Pushes the rest of the content to the bottom
          }}
        >
          {fact.Title}
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            mt: 2, 
            fontWeight: 400, 
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Shadow for bottom text
            alignSelf: 'flex-end', // Aligns the text to the bottom
          }}
        >
          
        </Typography>
      </Box>
    </Box>
  </>
)}

  </CardContent>
  <CardActions disableSpacing sx={{ justifyContent: 'space-between', boxShadow: 5}}>
    
  <Chip component={Link} to={`/darkroad/${fact._id}`} icon={<BeanIcon/>} label="Read More" variant="outlined" color="primary" />
       
   
    <IconButton aria-label="next-bite" onClick={generateFact}>
      <ArrowForward/>
    </IconButton>
  </CardActions>

</Card>

</>
  );
};

export default FactGen;
