import React, { useContext, useEffect, useState } from 'react'

import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, Drawer, TextField, Box, Typography, CardMedia, Card, Grid, Chip, Fab, Divider, Snackbar, CardActions, CardContent, Icon, CardActionArea, Dialog } from '@mui/material';
import { UserContext } from '../../context/usercontext';
import Progress from '../../components/mods/progress';
import { useItem } from '../finance/mangeshop/useitems';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import { Avatar, ListItem, ListItemAvatar, ListItemText, List } from '@mui/material';
import styled from "styled-components";

//import ClaimBeans from '../components/mods/dialogue/claimbeans'
import { ArrowBack, FormatItalic, Redeem } from '@mui/icons-material'


import { useParams, Link } from 'react-router-dom'


import coin from '../../images/brkoin.png';
import { set } from 'lodash';
import { finished } from 'form-data';


const ScrollContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  background: transparent;
  
  
  top: 0;
  overflow: hidden;
`;

const Scroll = styled.div`
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto;
  white-space: no-wrap;
  scroll-behavior: smooth;
  scrollbar-width: none;
  background: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Other = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  
  
  background: transparent;
  
  border: black solid 5px;
  
  
`;

const Buttoned = styled.button`

background: transparent;
padding: 5px;
border:  none 5px;


`;
const GET_COFFEESHOPS = gql`
  query GetAllUsers($usertype: String!, $searchTerm: String) {
    userprofs(where: { usertype: $usertype, Nickname_contains: $searchTerm }) {
      _id
      Nickname
      Title
      beanCount
      Koffie_story
      usertype
      imageurl
    }
  }
`;



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const REWARDS = gql`
    query Rewards {
        rewards {
            _id
            Title
            description
            beans
            imageurl
            available
        }
    }
`;

const KUPSTER_BY_USERID = gql`
  query KupsterByUserID($userID: ObjectId!) {
    kupster(query: { userID: $userID }) {
      Title
      _id
      favorite_koffie
      userID
      
    }
  }
`;
const CREATE_KUPSTER = gql`
  mutation CreateKupster($data: KupsterInsertInput!) {
    insertOneKupster(data: $data) {
      _id
      favorite_koffie
      Title
      userID
    }
  }
`;

const UPDATE_ONE_KUPSTER = gql`
  mutation UpdateUserprof($query: KupsterQueryInput!, $set: KupsterUpdateInput!) {
    updateOneKupster(query: $query, set: $set) {
      _id
      favorite_koffie
      Title
      userID
      
      
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
      redeemed
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const MY_ADDS = gql`
  query MyAdds ($userID: String!) {
    aiadds (query: {userID: $userID}) {
    Title
		When
		_id
		description
		price
		primaryImage
		productID
		userID
  }
}
  
`; 

const UPDATE_ISADD = gql`
  mutation UpdateOneAiadd($query: AiaddQueryInput!, $set: AiaddUpdateInput!) {
    updateOneAiadd(query: $query, set: $set) {
      _id
      isAdd
      # Include other fields you might want to return
    }
  }
`;

const GET_LIKES_BY_CONTENT_CREATOR = gql`
  query GetLikesByContentCreator($contentCreator: String!) {
    likes(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      user
      contentCreator
    }
  }
`;

const GET_COMMENTS_BY_CONTENT_CREATOR = gql`
  query GetCommentsByContentCreator($contentCreator: String!) {
    comments(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      author
      contentCreator
      content
    }
  }
`;

//image to be added to the rewards
const image = coin;

function RedeemCoffee() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {user} = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  
  const { __id } = useParams();
  const [expanded, setExpanded] = React.useState(false);
 const { Nickname } = user.customData;
  console.log("User from context:", Nickname);

  console.log("Querying with userID:", user.id);
  console.log("Querying with userID:", user.id);
 
  const { loading, error, data, refetch } = useQuery(KUPSTER_BY_USERID, {
    variables: { userID: user ? user.id : null },
  });
   // Ensure that the user ID is available
  useEffect(() => {
    console.log("User from context:", user);
  }, [user]);

  const contentCreatorId = typeof user?.id === 'object' ? user.id.id : user.id;
  const { loading: loadingLikes, error: errorLikes, data: likesData } = useQuery(GET_LIKES_BY_CONTENT_CREATOR, {
    variables: { contentCreator: contentCreatorId }, // Assuming user.id is a string
  });
 // likes count
 
// Bean count

const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
  variables: { _id: user.id },
});

  // comments count
  const { loading: loadingComments, error: errorComments, data: commentsData } = useQuery(GET_COMMENTS_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });
  const [rewards, setRewards] = useState(0);

  useEffect(() => {
    if (!loadingLikes && !loadingComments && !errorLikes && !errorComments) {
      const likesCount = likesData?.likes.length || 0;
      const commentsCount = commentsData?.comments.length || 0;
      const redeemedCount = beanData?.userprof.redeemed || 0;
  
      // Now we log the counts to verify they are correct
      console.log(`Likes: ${likesCount}, Comments: ${commentsCount * 3 - redeemedCount}, Redeemed: ${redeemedCount  }`);
  
      // Calculate the total rewards
      const totalRewards = commentsCount * 3 + likesCount - redeemedCount;
      setRewards(totalRewards);
    }


  }, [loadingLikes, loadingComments, likesData, commentsData, errorLikes, errorComments]);
 const { data: rewardsData, loading: rewardsLoading, error: rewardsError } = useQuery(REWARDS);
    const [showDialog, setShowDialog] = useState(false);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const fileInputRef = React.createRef();
   
    
    const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
    const [updateKupster] = useMutation(UPDATE_ONE_KUPSTER);
    const { data: aiAdds, loading: aiLoading, error: aiError } = useQuery(MY_ADDS,
      {variables: { userID: user ? user.id : null } });
      const [updateIsAdd] = useMutation(UPDATE_ISADD);
      const { loading: loadingcoffeeShops, error: errorcoffeeShops, data: coffeeShopsData } = useQuery(GET_COFFEESHOPS, {
        variables: { usertype: 'coffeeShop',  },
      });
      
      const [searchTerm, setSearchTerm] = useState('');
      const [filteredUsers, setFilteredUsers] = useState([]);

      const handleSearch = (e) => {
        setSearchTerm(e.target.value);
      };
      
      useEffect(() => {
        if (coffeeShopsData && coffeeShopsData.userprofs) {
          setFilteredUsers(coffeeShopsData.userprofs);
        }
      }, [coffeeShopsData]);
      
      useEffect(() => {
        if (searchTerm) {
          // Assuming your GraphQL server supports live search through queries
          // You can use something like below to refetch with new variables
          refetch({
            usertype: 'coffeeShop',
            searchTerm: searchTerm
          });
        }
      }, [searchTerm, refetch]);

  // Set the Add to ture
  const handleUpdateIsAdd = (_id) => {
    updateIsAdd({
      variables: {
        query: { _id: _id }, // Specify which document to update
        set: { isAdd: true } // Set isAdd to true
      }
    })
    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
      openDialog();
      return;
    }
    decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -200 }
    })

    .then(response => {
      console.log('Update successful', response);
      // Perform any actions after successful update, e.g., refresh data or UI
    })
    .catch(error => {
      console.error('Error updating isAdd', error);
    });
  };
    

    useEffect(() => {
        if (beanData && beanData.userprof) {
            setBeanCount(beanData.userprof.beanCount);
        }
    }, [beanData]);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

  const [editProduct, setEditProduct] = useState(null);  // For storing product being edited
  const [openEditDialog, setOpenEditDialog] = useState(false);  // For controlling edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);  // For controlling delete dialog
  const { products, handleInsertProduct, handleUpdateProduct, handleDeleteProduct } = useItem();
  const [createKupster] = useMutation(CREATE_KUPSTER,
    {
      refetchQueries: [{ query: KUPSTER_BY_USERID, variables: { userID: user ? user.id : null } }],
      variables: { _id: user.id },
    });

  const handleDrawerOpen1 = () => {
    setIsDrawerOpen(true);
  };
  
  const handleDrawerClose1 = () => {
    setIsDrawerOpen(false);
  };
  const query = { userID: user ? user.id : null }; // assuming you identify users by an _id

  const dataExists = data && data.kupster && data.kupster.userID; // Example condition
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
  
    // Prepare the input data
    const input = {
      flavor_profile: formData.get('Title'),
      favorite_koffie: formData.get('favorite_koffie')
     
    };
  
    // Check if the data exists (you need a way to determine this)
    if (dataExists) { // Implement your logic to determine if data exists
      // Update the profile
      updateKupster({ variables: { data } })
        .then(response => {
          console.log('Profile Updated:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not update profile:', error);
        });
    } else {
      createKupster({ variables: { data } })
        .then(response => {
          console.log('New Profile Created:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not create profile:', error);
        });
    }
  };
  

  // Drawer with Form
  const renderDrawer = () => (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose1}>
      <Box
        sx={{ width: '100%', padding: 3, backgroundColor: '#fff'}}
        role="presentation"
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton onClick={handleDrawerClose1}>
          <ArrowBack />
        </IconButton>
        <Card sx={{ padding: 3, marginBottom: 2 }}>
        <Typography variant="h4" component="h2"fontWeight={500} color='#e39024'>
          Coming Soon!
          </Typography>
          </Card>
        <TextField label="Title" name="Title" fullWidth />
        <TextField label="Favorite Koffie" name="favorite_koffie" fullWidth />

        <Button type="submit">Save Settings</Button>
      </Box>
    </Drawer>
  );

  

  const startEditing = (product) => {
    setEditProduct(product);
    setOpenEditDialog(true);
  };

  const startDeleting = (product) => {
    setEditProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    // Call your update function here, e.g.
    handleUpdateProduct(editProduct);
    setOpenEditDialog(false);
  };

  const handleDelete = () => {
    // Call your delete function here, e.g.
    handleDeleteProduct(editProduct._id);
    setOpenDeleteDialog(false);
  };


 
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  

 // Function to determine cols and rows based on some condition, you can adjust this logic
 const getColsAndRows = (product) => {
  // You could replace this with your actual logic to determine featured products
  return product.featured ? { cols: 2, rows: 2 } : { cols: 1, rows: 1 };
};

const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setOpenSnackbar(true);
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};



 const formik = useFormik({
  initialValues: {
    productName: '',
    productDescription: '',
    productPrice: '',
    // productImage: '', // This can be removed if you're not using it
  },
  onSubmit: async (values, { resetForm }) => {
    // Directly call uploadFile function to upload the image
   

    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
     
      return;
    }

  
    await decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -50 }
    });
    
   
    // Check if the upload was successful
  
    
   
  
    // Decrement beanCount on the client side for immediate feedback
    setBeanCount(beanCount - 1);
    
    // Create the new product item with the uploaded image URL
    const newProduct = {
      name: values.productName,
      description1: values.productDescription,
      price: values.productPrice,
     
      userID: user.id, // Make sure this is the correct way to access the user's ID
    };
    
    // Insert the product item
    handleInsertProduct(newProduct);

    // Reset the form and close any modals/drawers
    resetForm(); // You can use the formik bag's resetForm function
    handleCloseDialog();
  },
  // ... You can add validationSchema or validate function if needed
});

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  useEffect(() => {
    if (user && user.user && user.user.id) {
      // Trigger refetch or some other action
    }
  }, [user]);
  
  
    
  console.log("Data from query:", data);
  const handleFabClick = () => {
    fileInputRef.current.click();
};

// Trigger a query re-fetch or filter your local state based on the search term
useEffect(() => {
  if (searchTerm) {
    // Assuming your GraphQL server supports live search through queries
    // You can use something like below to refetch with new variables
    refetch({
      usertype: 'coffeeShop',
      searchTerm: searchTerm
    });
  }
}, [searchTerm, refetch]);

const BeanIcon = () => {
  return (
    
    <img src={coin} alt="koffie" width={20} />
  
  );
};

  // Add this line to handle errors
  if (error) return <div>Error: {error.message}</div>;

  if (loading) return <div><Progress/></div>;
  
  return (
    <div>

  <Card sx={{ flex: 'grow', textAlign: 'center', borderRadius: 0, boxShadow: 0, mt: 0, mb: 0,  }}>
    <CardContent sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box sx={{
        position: 'relative', // Set the positioning context
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start', // Align the text to the right
        mb: 0,
        p: 1, // Padding inside the box to ensure the text doesn't touch the edges
        backgroundPosition: 'center',
        borderRadius: 5,
      }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 900, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)', }}>
          Rewards: {rewards} DigiBeans
        </Typography>
      </Box>
      <CardActionArea
      
        onClick={handleDrawerOpen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 50,
          borderRadius: 10,
          backgroundColor: '#e39024',
          color: 'white',
          textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)',
          padding: 0,
          gap: 4,
          
        }}
      >

        <Typography variant="bosy2" gutterBottom sx={{ fontWeight: 400 }}>
           <img src={coin} alt="koffie" width={15} />  1 Bean 
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: 400 }}>
            3 Comment
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: 400 }}>
             Search Shops
        </Typography>
      </CardActionArea>

      <Typography variant="body2" color='#000' gutterBottom sx={{ fontWeight: 500 }}>
        Redeem your DigiBeans for rewards at a participating Koffie shop
      </Typography>

      <ScrollContainer >   
        <Scroll>  
          <Other  >  
     
      {rewardsData && rewardsData.rewards.map(reward => (
         <Buttoned>
        <Card sx={{  boxShadow: 0, mt: 2, mb: 2, minWidth: 200, border: 1, borderColor: '#ddd', padding: 0 , borderRadius: '10px 10px 0 0'}}>
  <CardActionArea>
  <CardMedia
  component="img"
  height={100}
  
  image={reward.imageurl}
  alt="green iguana"
  sx={{
    borderRadius: '10px 10px 0 0'  // Correct order: top-left, top-right, bottom-right, bottom-left
  }}
/>

    <CardContent sx={{backgroundColor: '#ebecf0',  borderRadius: '0 0 10px 10px'}}>
      <Typography gutterBottom variant="caption" component="div" sx={{textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)', fontWeight: 600}}>
        {reward.Title}
      </Typography>
      <Typography variant="caption" color="text.secondary" fontWeight={700}>
        {reward.description}
      </Typography>
    </CardContent>
    <CardActions sx={{ justifyContent: 'flex-end',backgroundColor: '#ebecf0' }}> {/* This line pushes the Chip to the right */}
      <Chip onClick={handleOpenDialog} label={reward.beans} icon={<BeanIcon />} />
    </CardActions>
  </CardActionArea>
</Card>

        </Buttoned>
      ))}
    
      </Other>
      </Scroll>
      </ScrollContainer>
    </CardContent>
  </Card>
  

  <Dialog open={openDialog} onClose={handleCloseDialog}>
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Claim Successful!
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Your reward has been claimed successfully.
      </Typography>
      <Button onClick={handleCloseDialog} variant="contained" sx={{ mt: 2 }}>
        Close
      </Button>
    </Box>
  </Dialog>
  
  <Snackbar
    open={openSnackbar}
    autoHideDuration={6000}
    onClose={handleCloseSnackbar}
    message="Claim Successful!"
  />
</div>

  )
}

export default RedeemCoffee

