import React, { useEffect } from 'react';
import * as Realm from 'realm-web';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderRadius: 5,
    boxShadow: 10,
    position: 'relative',
  },
  logo: {
    width: 100,
    borderRadius: 0,
  },
  ribbon: {
    position: 'absolute',
    bottom: 20,
    left: 90,
    backgroundColor: '#d1f0d2',
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
  },
  ribbonText: {
    color: 'black',
    fontWeight: 900,
    fontSize: 10,
  },
  tooltipContent: {
    padding: theme.spacing(2),
  },
}));

const GoogleOneTapLogin = () => {
  const classes = useStyles();
  const { signInWithGoogle } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    // Initialize Realm App
    const app = new Realm.App({ id: 'application-0-jyiid' });

    // Callback function to handle Google's response and log the user into App Services
    const handleCredentialsResponse = (response) => {
      const token = { id_token: response.credential };
      const redirectUrl = location.state?.from?.pathname || null;
      signInWithGoogle(token, redirectUrl);
    };

    // Load Google Identity Services script
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: '213836331022-r0v1mth6ha7603bggo3b4lff361tpr3t.apps.googleusercontent.com',
          callback: handleCredentialsResponse,
        });

        window.google.accounts.id.renderButton(
          document.getElementById("signin-button"),
          {
            theme: "outline",
            size: "large",
            text: "continue_with",
            shape: "pill",
            width: "300px",
            locale: "en-US",
            prompt_parent_id: "signin-button",
            borderRadius: "20px",
          }
        );

        window.google.accounts.id.prompt();
      };
      document.body.appendChild(script);
    };

    loadGoogleScript();
  }, [signInWithGoogle, location.state]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100vh', padding: '0 16px' }}>
      <Box className={classes.boxContainer}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/kland.png' alt='klogo' className={classes.logo} />
        <Box className={classes.ribbon}>
          <div className={classes.ribbonText}>
            BETA
          </div>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '16px' }}>
        <Typography color="inherit" align="center" variant="h3" fontWeight={400} marked="center" paddingTop={2}>
          Welcome to KOFFIELAND™
        </Typography>
        <Typography color="inherit" align="center" variant="h4" fontWeight={900} marked="center" paddingTop={2}>
          The Future of Coffee
        </Typography>
        <Typography color="inherit" align="center" variant="h5" fontStyle='oblique' sx={{ mb: 4, mt: { xs: 1, sm: 2 } }}>
          Get your Bean Card with 100 complimentary DigiBeans and start the transformation!
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
          Sign in with Google
        </Typography>
        <div style={{background: "#077336"}} id="g_id_onload" data-client_id="213836331022-r0v1mth6ha7603bggo3b4lff361tpr3t.apps.googleusercontent.com"></div>
        <div id="signin-button" />
      </Box>
     
    </Box>
  );
};

export default GoogleOneTapLogin;
