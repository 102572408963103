import React from 'react'
import {  Typography,  Paper, CardMedia, Card, Grid,CardContent,  CardActionArea ,CardActions,
 Button, List, Chip, Box, CardHeader, Icon, IconButton, Fab,  } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Link, useNavigate } from 'react-router-dom';

function Featured() {
  const navigate = useNavigate();

  const goSubscribe = () => {
    navigate= ('/subscribe');
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 0,
      borderRadius: 10,
      boxShadow: 0,
      marginTop: 0,
      position: 'relative',
      width: '100%',
      height: '100vh',
      
      zIndex: 1,
    }}>


<Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
     
      borderRadius: 0,
      boxShadow: 0,
      marginTop: 0,
      backgroundImage: 'url(https://storage.googleapis.com/app_darkendimg/assets/subscribelady.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    objectFit: 'scale-down',
    position: 'absolute',
    padding: 0,
    width: '100%',
    height: '100%',
   
    zIndex: -1,
    
      
     
    }}>
         
            
           
          
           <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 2, mt: 60}}>
        <Typography gutterBottom variant="h4" component="div" fontWeight={800} sx={{color: '#fff', textShadow: '1px 1px 1px #000'}}>
           Koffieland Subscriptions
        </Typography>
        <Typography variant="h6" color="white" sx={{color: '#fff', textShadow: '1px 1px 1px #000', fontWeight: 900 }}>
        The Revolution will be subscribed
        </Typography>
        <Typography variant="h5" fontWeight={700} color="text.secondary" sx={{color: '#fff', textShadow: '1px 1px 1px #000'}}>
          $49.99
        </Typography>
      </CardContent>
      <Link to='/subscribe' style={{ textDecoration: 'none', color: 'inherit', textShadow: '1px 1px 1px #000' }}>
    <Button variant="contained"  sx={{backgroundColor: '#ffc107', border: 'none', color: 'black',
  borderRadius: 15, boxShadow: 0, mt: 2, mb: 2, ":hover": { backgroundColor: '#3ff107' } }} >
 
       
        Subscribtions
      </Button>
    </Link>

       
        </Box>

    </Box>
  )
}

export default Featured