import { useState } from 'react';
import axios from 'axios';

const useFileUpload3 = () => {
  const [files, setFiles] = useState([]);
  const [previewURLs, setPreviewURLs] = useState(Array(5).fill(null));
  const [uploadURLs, setUploadURLs] = useState(Array(5).fill(null)); // Store the actual upload URLs
  const [errors, setErrors] = useState(Array(5).fill(''));

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const validVideoTypes = ['video/mp4', 'video/quicktime'];
      
      // Check if file type is valid
      if (validImageTypes.includes(file.type) || validVideoTypes.includes(file.type)) {
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);

        // Preview URL (optional: keep it for user preview)
        const newPreviewURLs = [...previewURLs];
        newPreviewURLs[index] = URL.createObjectURL(file);
        setPreviewURLs(newPreviewURLs);

        setErrors(prevErrors => {
          const newErrors = [...prevErrors];
          newErrors[index] = '';
          return newErrors;
        });
      } else {
        setErrors(prevErrors => {
          const newErrors = [...prevErrors];
          newErrors[index] = 'Only images and videos (MP4, MOV) are allowed.';
          return newErrors;
        });
      }
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);

    const newPreviewURLs = [...previewURLs];
    newPreviewURLs[index] = null;
    setPreviewURLs(newPreviewURLs);

    const newUploadURLs = [...uploadURLs];
    newUploadURLs[index] = null;
    setUploadURLs(newUploadURLs);

    const newErrors = [...errors];
    newErrors[index] = '';
    setErrors(newErrors);
  };

  const uploadFiles = async (userId) => {
    if (files.includes(null) || files.length < 5) {
      setErrors(prevErrors => {
        const newErrors = [...prevErrors];
        files.forEach((file, index) => {
          if (!file) {
            newErrors[index] = 'Please upload all 5 files';
          }
        });
        return newErrors;
      });
      return null;
    }

    setErrors(Array(5).fill('')); // Clear errors after validation
    const newUploadURLs = [];

    try {
      // Loop through all files and upload them one by one
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('file', files[i]);
        formData.append('userId', userId);

        // Upload each file
        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data.url) {
          newUploadURLs[i] = response.data.url; // Store the URL returned from the server
        } else {
          setErrors(prevErrors => {
            const newErrors = [...prevErrors];
            newErrors[i] = 'Failed to get URL from server';
            return newErrors;
          });
        }
      }

      setUploadURLs(newUploadURLs); // Set the state for uploaded URLs
      return newUploadURLs; // Return the array of uploaded URLs
    } catch (error) {
      console.error('File upload failed:', error);
      setErrors(['Error uploading files. Please try again.']);
      return null;
    }
  };

  return {
    files,
    previewURLs,
    uploadURLs, // Return the uploaded URLs
    errors,
    handleFileChange,
    handleRemoveFile,
    uploadFiles,
  };
};

export default useFileUpload3;
