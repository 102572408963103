import React from 'react'
import { Avatar, Box, Card , CardContent, CardMedia, Tab, Tabs} from '@mui/material'
import kpot from '../../images/drip.png'
import espresso from '../../images/redpresso.png'
import jebena from '../../images/jebey.png'
import mocha from '../../images/mocha.png'
import chemex from '../../images/chemex.png'
import press from '../../images/pressy.png'
import { Typography } from '@mui/material'
import { List, ListItem } from '@mui/material'
import { useEffect } from 'react'
import ShopAd from '../finance/adds/shopadd'
import BeansAd from '../finance/adds/beansadd'
import { Store } from '@mui/icons-material'
import StoreAdd from '../finance/adds/storeadd'


// Define the brew methods and their details
const brewMethods = {
  "Drip ": {
    "image": kpot,
    
    'displayimage': kpot,
    "instructions": [
      "Place a coffee filter in the basket.",
      "Add one to two tablespoons of coffee grounds per six ounces of water.",
      "Fill the water chamber with fresh, filtered water.",
      "Turn on the machine and let it brew.",
      "Stir the coffee in the carafe before serving."
    ]
  },
  "Espresso": {
    "image": espresso,
    'displayimage': espresso, 
    "instructions": [
      "Ensure the machine is preheated.",
      "Grind coffee beans to a fine consistency.",
      "Tamp down the coffee grounds in the portafilter.",
      "Lock the portafilter into the machine.",
      "Start the shot extraction.",
      "Serve immediately."
    ]
  },
  "Press": {
    "image": press,
    'displayimage': press,
    "instructions": [
      "Add coffee grounds to the French Press.",
      "Pour hot water into the chamber.",
      "Stir the coffee and water mixture.",
      "Attach the filter and cap.",
      "Press down the plunger.",
      "Steep for four minutes.",
      "Serve and enjoy."
    ]
  },
  "Chemex": {
    "image": chemex,
    'displayimage': 'https://storage.googleapis.com/app_darkendimg/path/to/destination/cherry.jpg',
    "instructions": [
      "Place a Chemex filter in the top of the brewer.",
      "Add coffee grounds to the filter.",
      "Pour a small amount of hot water to bloom the coffee.",
      "Pour remaining water in a circular motion.",
      "Wait for the coffee to drip through.",
      "Serve immediately."
    ]
  },
  "Mocha": {
    "image": mocha,
    'displayimage': mocha,
    "instructions": [
      "Fill the bottom chamber with water.",
      "Add coffee grounds to the filter basket.",
      "Assemble the Mocha pot.",
      "Place on medium heat.",
      "Wait for the coffee to fill the upper chamber.",
      "Stir and serve."
    ]
  },
  "Jebena": {
    "image": jebena,
    'displayimage': jebena,
    "instructions": [
      "Boil water in the Jebena.",
      "Add coffee grounds.",
      "Allow the coffee to steep.",
      "Pour into another container to separate grounds.",
      "Pour back into Jebena.",
      "Serve in small cups."
    ]
  }
};

const DisplayBrewInstructions = ({ method }) => {
  const instructions = brewMethods[method]?.instructions || [];

  return (
    <>
      <Typography variant="h6">Brewing Instructions for {method}</Typography>
      <List>
        {instructions.map((instruction, index) => (
          <ListItem key={index}>
            <Typography variant="body1">{`${index + 1}. ${instruction}`}</Typography>
          </ListItem>
        ))}
      </List>
    </>
  );
};
function Brew() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // Set the initial selectedMethod to the first method
    const initialMethod = Object.keys(brewMethods)[0];
    setSelectedMethod(initialMethod);
  }, []); // Empty dependency array ensures this effect runs once on mount

        const [selectedMethod, setSelectedMethod] = React.useState(null);

return (
  <Box sx={{ maxWidth: { xs: 400, sm: 480 }, bgcolor: 'background.paper' }}>


<div style={{
  display: 'flex',
  overflowY: 'auto',
  width: '100%',
  scrollbarWidth: 'none', /* For Firefox */
  msOverflowStyle: 'none', /* For Internet Explorer and Edge */
  '&::-webkit-scrollbar': {
    display: 'none' /* For Webkit browsers like Chrome and Safari */
  }
}}>
  

        {Object.keys(brewMethods).map((method) => (
        // Inside your Brew component
<div onClick={(e) => { e.stopPropagation(); setSelectedMethod(method); }} className="brew-method">
  
<Card
            key={method}
            onClick={() => setSelectedMethod(method)}
            sx={{ cursor: 'pointer', textAlign: 'center', padding: 2, borderRadius: 2, spacing: 8, margin: 2 }}
          >
            <Avatar alt={method} src={brewMethods[method].image} sx={{ width: 60, height: 60, margin: 'auto' }} />
            <Typography variant="body2" sx={{ marginTop: 1 }}>{method}</Typography>
          </Card>
</div>

      
        ))}
      </div>

      {/* Display selected brew method details */}
      {selectedMethod && (
        <Card sx={{ width: '100%'}}>
         <CardMedia component="img" height="200"  image={brewMethods[selectedMethod].displayimage} alt={selectedMethod} />
          
          <DisplayBrewInstructions method={selectedMethod} />

        </Card>
      )}
    <BeansAd />
    <StoreAdd/>
  </Box>
    
  )
}

export default Brew