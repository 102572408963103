import React, { useState, useEffect,  useContext } from 'react';
import Plot from 'react-plotly.js';
import Typeit from '../mods/typeit';
//import CoffeePage from './des';
import { 
Card, Chip, List, Paper, Typography, Avatar, Button,
Box, Divider, CardContent, Skeleton, CardHeader, CardActions, Grid, CardMedia, Container, IconButton, Dialog, DialogContentText, DialogContent, 
} from '@mui/material';
import { App } from 'realm-web';
import '../../App.css';
import bean from '../../images/coffeebeans.png';
import Flavloader from '../mods/flavloader';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import kup from '../../images/steamkup.jpg';
import koffieman from '../../images/koffieman1.png';
import ThreeScene from '../mods/particles';
import '../../context/EarthMode.css';
import { Link } from 'react-router-dom';
import { Close, ConstructionOutlined, ListAlt } from '@mui/icons-material';
import {useQuery, gql, useMutation } from '@apollo/client';
//import Aifood from './aifood';
import Header from '../nav/mobilefarmer';
import { UserContext } from '../../context/usercontext';
import Ticker from '../finance/prods/Ticker';
import Store from '../mods/frontgrid';
import StoreAdd from '../finance/adds/storeadd';
import beaniefy from '../../images/beaniefy.png';
import Koffieticket from '../finance/prods/coffee';
import kbeans from '../../images/kbeans.webp';
import { motion, AnimatePresence } from 'framer-motion';


//Mutate Koffie
const UPSERT_USERPROF = gql`
  mutation upsertOneUserprof($query: UserprofQueryInput, $data: UserprofInsertInput!) {
    upsertOneUserprof(query: $query, data: $data) {
      _id
      name
      description
      country   
      tasteProfile
    }
  }
`;

//this
const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      name
      description
      country   
      tasteProfile
      purchaseLink
    }
  }
`; 

const GET_USER = gql`
  query Userprof ($_id: ObjectId!)  {
  userprof (query: {_id: $_id}) {
    author
    cherryCount
    Nickname
    Title
    _id
    Koffie_story
  }
}
`; 



const kupakoffie = () => {
    return {
      "name": "kupakoffie",
      "description": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name of the coffee"
          },
          "country": {
            "type": "string",
            "description": "The country of origin of the coffee"
          },
          "description": {
            "type": "string",
            "description": "Short Whitty Description of the coffee"
          },
          "tasteProfile": {
            "type": "string",
            "description": "How i like my coffee"
          },
          "purchaseLink": {
            "type": "string",
            "description": "/koffie/country "
          },
          
        },
        "required": ["name", "country", "description", "tasteProfile",  "purchaseLink"]
      }
    };
  };


  const useStyles = makeStyles(({ palette }) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    card: {
      borderRadius: 0,
      paddingTop: 10,
      
      textAlign: 'center',
      transition: '0.3s',
    },
    avatar: {
      width: 200,
      height: 200,
      margin: 'auto',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      color: 'inherit',
      marginBottom: '0.875em',
    },
    statLabel: {
      fontSize: 12,
      color: "none",
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 4,
      letterSpacing: '1px',
      fadeIn: 'ease-in 5s',
      visibility: 'visible',
    },
  }));

 

const OllieAI = () => {
  const { user, setUser } = useContext(UserContext);
  const { Nickname, imageurl } = user.customData;
  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const styles = useStyles();
  const [name, setName] = useState("");
const [country, setCountry] = useState("");
const [flavorProfile, setFlavorProfile] = useState("");
const [description, setDescription] = useState("");
const [purchaseLink, setPurchaseLink] = useState("");
const [showCard, setShowCard] = useState(true);


  const { loading, error, data } = useQuery(GET_USER, {
    variables: { _id: user.id },
  });
  const [updateUserProf] = useMutation(UPDATE_USERPROF);
 


  const [isButtonClicked, setButtonClicked] = useState(false);

  
  useEffect(() => {
    if (data?.userprof) {
      setName(data.userprof.name || "");
      setCountry(data.userprof.country || "");
      setFlavorProfile(data.userprof.flavorprofile || "");
      setDescription(data.userprof.description || "");
      setPurchaseLink(data.userprof.purchaseLink || "");
      
      // Hide the card and button when userprof data is available
      setShowCard(false);
    }
    // Revert to showing the card if data becomes null or undefined
    else {
      setShowCard(true);
    }
  }, [data]);

  
  
  
  
  const ChartData = [
    {
        maxdepth: 6,
        insidetextorientation: 'radial',
      type: "sunburst",
      labels: ["Coffee", "Aroma", "Brown Sugary",  "Dry Distillation",  "Taste", "Sour",  "Soury", "Enzymatic", "Sweet", "Salt", "Bitter", "Carbony", "Spicy", "Resinous" , "Ashey", "Smokey", "Burnt", "Charred", "Tarry", "Pipe Tobacco", "Warming", "Pungent", "Thyme", "Clove", "Cedar", "Pepper", "Tupeny", "Medicinal", "Ceniolic", "Camphoric", "Piney", "Blackcurrant-like", "Chocolatey", "Caramelly", "Nutty", "Vanilla-like", "Chocolate-like", "Syrup-like", "Candy-like","Malt-like", "Nut-like", "Butter", "Swiss", "Dark Chocolate", "Bakers", "Maple Syrup", "Honey", "Roasted Almond", "Roasted Hazlenut", "Herby", "Fruity", "Flowery", "Leguminous", "Alliaceous", "Berry-like", "Citrus", "Fragrant", "Floral", "Onion", "Garlic", "Cucumber", "Garden Peas", "Blackberry", "Apricot", "Lemon", "Apple", "Coriander Seeds", "Cardamon Caraway", "Tea Roase", "Coffee Blossom", "Acidity", "Mellow", "Winey", "Harsh", "Pungenty", "Rough", "Sharp", "Bland", "Astringent", "Soft", "Neutral", "Creosol", "Phenolic" , "Caustic", "Alkeline", "Tangy", "Tart", "Acid", "Hard", "Mild", "Delicate", "Piquant", "Nippy"],
      parents: ["", "Coffee", "Aroma",  "Aroma",  "Coffee", "Taste", "Sour",  "Aroma", "Taste", "Taste", "Taste", "Dry Distillation", "Dry Distillation", "Dry Distillation", "Carbony", "Carbony", "Ashey", "Ashey", "Smokey", "Smokey" , "Spicy", "Spicy", "Pungent", "Pungent", "Warming", "Warming", "Resinous", "Resinous", "Medicinal", "Medicinal", "Tupeny", "Tupeny", "Brown Sugary", "Brown Sugary", "Brown Sugary" , "Chocolatey", "Chocolatey", "Caramelly", "Caramelly", "Nutty", "Nutty", "Vanilla-like", "Vanilla-like", "Chocolate-like", "Chocolate-like", "Syrup-like", "Syrup-like", "Candy-like", "Candy-like", "Enzymatic","Enzymatic", "Enzymatic", "Herby", "Herby", "Fruity", "Fruity", "Flowery", "Flowery", "Alliaceous", "Alliaceous", "Leguminous", "Leguminous", "Berry-like", "Berry-like", "Citrus", "Citrus", "Fragrant", "Fragrant", "Floral", "Floral", "Sweet", "Sweet", "Sour", "Bitter", "Bitter", "Sharp", "Salt", "Salt", "Sharp", "Bland", "Bland", "Pungenty", "Pungenty", "Harsh", "Harsh", "Winey", "Winey", "Soury", "Soury", "Mellow", "Mellow", "Acidity", "Acidity"  ], 
        marker: {line: {width: 2},
        colors: [ "#876545", "#dbd225", "#ab9628", "#e6845a", "#88c292", "#73e067", "#73e067", "#e6845a", "#800000", "#008000", "#000080", "#808000", "#f5a327", "#000080", "#808000", "#f5a327", "#f5a329", "#808000", "#f5a327", "#33bbff", "#fdff33", "#808000", "#f5a327", "#f5a329", "#808000", "##ab9628", '#f127f5', "#fdff33", "#f127f5", "#f5a327","#a37814", "#806a37", "#cfbe06 ", "#d4570f"],
      
    }
    }
  ];

  const menuData = {
    labels: [
      {
        label: 'Fruity',
        name: 'Coffee Name',
        description: 'Description of Coffee',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/fruity.png'
      },
      {
        label: 'Nutty',
        name: 'Nutty Name',
        description: 'Description of Tea',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/nuts.png'
      },
     
      // ... add other items similarly
      {
        label: 'Floral',
        name: 'Floral Name',
        description: 'Description of Cigars',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/floral.png'
      },
      {
        label: 'Earthy',
        name: 'Earthy Name',
        description: 'Description of Tea',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/earthy.png'
      },
      {
        label: 'Sweet',
        name: 'Sweet Name',
        description: 'Description of Chocolate',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/sweets.png'
      },
      
      {
        label: 'Citrus',
        name: 'Citrus Name',
        description: 'Description of Chocolate',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/orangy.png'
      },
      {
        label: 'Acidic',
        name: 'Acidic Name',
        description: 'Description of Chocolate',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/acidic.png'
      },
      {
        label: 'Chocolatey',
        name: 'Chocolatey Name',
        description: 'Description of Chocolate',
        imageUrl: 'https://storage.googleapis.com/app_darkendimg/assets/chocoprof.png'
      },
      // ... add other items similarly
     
     
    ]
  };
  
  
  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  
  
  

  const layout = {
    width: windowSize.width * .97, 
    margin: {l: 0, r: 0, b: 0, t: 0}, 
    showlegend: false,
    ModeBarButtons: '#fff', 
    paper_bgcolor: "transparent",
    plot_bgcolor: "#d2d2d2", 
    font: {color: "#000000"}
  };
  

  const handleClick = (item) => {
    setClickedItems([...clickedItems, { 
      label: item.label, 
      name: item.name, 
      description: item.description, 
      imageUrl: item.imageUrl 
    }]);
  };
  

  const clearClickedItems = () => {
    setClickedItems([]);
  };



const handleUpdate = (outputData) => {
  updateUserProf({
    variables: {
      query: { _id: outputData._id },
      set: outputData.data
    }
  });
}



const coffeeFunction = kupakoffie();  // Get the coffee function

  const handleSubmit = () => {

  
    const profileData = clickedItems.map(item => item.label).join(', ');
    setIsLoading(true);  // Start loading before the fetch request

  
    fetch('https://api.openai.com/v1/chat/completions', { // replace with your server URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${"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"}`,

      },
      body: JSON.stringify({
        
        "messages": [
           
            {"role": "system", "content": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink!."},
            {"role": "user", "content": "This is my flavor Profile: " + profileData + "."},
            ],
            
            "temperature": 0.2,
            'model': 'gpt-4',
            "function_call": coffeeFunction,
            'functions': [coffeeFunction],
            
       
        
        
      }),
    })
    .then(response => response.json())
    .then(data => {
      let contentData;
      if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
        contentData = {
          name: data.choices[0].message.content.name,
          country: data.choices[0].message.content.country,
          flavorprofile: data.choices[0].message.content.tasteProfile,
          description: data.choices[0].message.content.description,
          purchaseLink: data.choices[0].message.content.purchaseLink,
        };
      } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
        contentData = JSON.parse(data.choices[0].message.function_call.arguments);
      }
      
  
      setOutput(contentData); // Render the content on the frontend
      console.log('Success:', contentData);
      console.log('Total tokens used:', data.usage.total_tokens);
  
      // Construct the variables for the GraphQL mutation
      const output = {
        data: contentData,
        _id: user.id
      };
  
      // Perform the GraphQL mutation using the 'output' object
      handleUpdate(output);
     
      setIsLoading(false); // End loading after receiving the response
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false); // End loading if there's an error
    });
      
  };
  
  

  return (
    <Box sx={{ }}>
       <Card sx={{ borderRadius: '10px' }}>
  <CardMedia component="img" height={50} width={600} image={kbeans} alt="koffieman" />
  <CardContent>
    <Typography variant='h6' textAlign="center">
      This agent is in development Mode <ConstructionOutlined />
    </Typography>
    {/* Use a div to apply text alignment for the Link component */}
    <div style={{ textAlign: 'center' }}>
      <Link to="/wheel" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography variant='caption' sx={{ color: 'green' }}>
          Head over to the geeks agent for a better experience
        </Typography>
      </Link>
    </div>
  </CardContent>
</Card>
       
            <Box sx={{ flex: 'grow', padding: 1, margin: 0, overflowX: 'hidden' }}>
            
           
            <div>
  <Grid container spacing={1}>
    {menuData.labels.map((item, index) => (
      <Grid item xs={4} sm={2} md={2} lg={2} key={index}>
        <Card sx={{   }} onClick={() => handleClick(item)}>
         <IconButton>
          <img src={item.imageUrl} alt={item.label} width={50} height={50} style={{borderRadius: 5, boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.9)'}} />
          </IconButton>
          <CardContent>
            <Typography gutterBottom variant="body2" component="div">
              {item.label}
            </Typography>
           
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>

  <Fab onClick={clearClickedItems} style={{ margin: '10px', backgroundColor: '#077336' }}>
    Clear
  </Fab>

  
  {clickedItems.map((item, index) => (
    <Chip
      key={index}
      label={item.label}
      avatar={<Avatar alt="Kup" src={item.imageUrl} />}
      sx={{ m: 1 }}
    />
  ))}
</div>


        
            
            </Box>
        
      <Card sx={{ borderRadius: '10px'}}>
      
    
 
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Fab  variant="extended" aria-label="add" sx={{ color: 'white', marginBottom: 4,  ':hover': {backgroundColor: '#33f107', color: '#000'}}} onClick={handleSubmit}>
  <div sx={{ mr: 1 }}>
    
  </div>
  Submit
</Fab>

</div>
      
     </Card>
 
<div>      
{isLoading ? (
     <Dialog open={isLoading}>
     <DialogContent>
       <DialogContentText>
         Brewing up your profile...
       </DialogContentText>
     </DialogContent>
     
   </Dialog>
    ) : (
      <>
               
          <div className="App">
          
            <div className='grid-item'>
            <Typography variant="h6" >
                    
                  </Typography>
                  </div>
           
                  {content && (
                    <Dialog  fullScreen open={true} onClose={() => setOutput('')} >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                     
              <AnimatePresence>
              <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <IconButton onClick={() => setOutput('')} style={{ position: 'absolute', top: 10, left: 10 }}>
    <Close />
  </IconButton>
                    <Typography variant="h6" fontWeight={700}>
                     {Nickname}  Flavor Profile
                    </Typography>
  </Box>
  <Card className={styles.card}>
    <CardContent>
      <Avatar className={styles.avatar} src={imageurl} />
      <Typography className={styles.heading} align="center">{content.name}</Typography>
      <Typography className={styles.subheader}>{content.tasteProfile}</Typography>
      <Typography variant="h6">{content.country}</Typography>
      
      <Typography className={styles.statValue}>{content.description}</Typography>
      <Link to={`/main/`} >
  <Button   sx={{ marginBottom: 2, width: '50%', alignContent: 'center', backgroundColor: '#077336', color: '#fff',
borderRadius: 10, border: 'none'
}}>
    Koffie Kit
  </Button>
</Link>

   
    </CardContent>
    
   
  </Card>
 
  </motion.div>
  </AnimatePresence>
  </Box>
  </Dialog>
            )}	
          </div>   
      </>
    )}            
        </div>     
      


    </Box>  
  );
};

export default OllieAI;
