import React from 'react';
import { Card, Grid, Paper, Typography, Box, Avatar } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';


const GET_PRODUCTS = gql`
  query Products {
    dfives {
    Title
    _id
    description1
    description2
    description3
    imageurl
    name
    price
    profile
    category
  }
  }
`;

const DFive = () => {
  const { loading, error, data } = useQuery(GET_PRODUCTS);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{null}</div>;

  // Group products by category
  const productsByCategory = data.dfives.reduce((acc, dfive) => {
    if (!acc[dfive.category]) {
      acc[dfive.category] = [];
    }
    acc[dfive.category].push(dfive);
    return acc;
  }, {});

  return (
    <div>
      <h4>Store</h4>
      {Object.keys(productsByCategory).map(category => (
        <div key={category}>
          <Typography variant="h4" sx={{fontWeight: 900}}> {category}</Typography>
          <Box display="flex" flexWrap="wrap">
            {productsByCategory[category].map(dfive => (
              <Box key={dfive._id} m={1}>
                <Link to={`/dfivestore/${dfive._id}`}>
                <Avatar
                    sx={{ border: '5px', borderColor: '#000', width: 80, height: 80 }}
                    alt={dfive.name}
                    src={dfive.imageurl}
                    label={dfive.price}

                  />
                </Link>
                <Typography variant="caption">{dfive.name}</Typography>
                <Typography variant="body2">${dfive.price}</Typography>
              </Box>
            ))}
          </Box>
        </div>
      ))}
    </div>
  );
};

export default DFive;
