import React, { useState, useEffect, useContext } from 'react';
import { Drawer, IconButton, Card, Typography, Grid, Button, Divider, CardContent, CardMedia,   ListItem, ListItemAvatar, ListItemText, Chip, List, Icon } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import KComments from '../mods/kstorycomments';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { usePostData } from '../tests/comquery';
import { makeStyles } from '@mui/styles';
import { LikeCount, CommentCount, LikeButton } from '../tests/stoybuttons';
import { GET_COMMENTS, GET_LIKES, CREATE_LIKE_MUTATION } from '../mods/kstorycomments'; 
import useInfiniteScroll from '../../queries/listarray';
import Product from '../../components/finance/Product';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Box } from '@mui/material';
import Update from '../profile/update';
import Userpost from '../profile/userpost';
import bean from '../../images/coffeebeans.png';
import mug from '../../images/kup.png';
import PostAndUploadComponent from '../posts/post';
import CallToActionAd from '../finance/getkoffie';
import { useSpring, animated } from 'react-spring';
import Progress from '../mods/progress';
import FarmerAd from '../finance/adds/farmeradd';
import BaristaAd from '../finance/adds/baristaadd';
import RoasterAd from '../finance/adds/roasteradd';
import BeansAd from '../finance/adds/beansadd';
import ShopAd from '../finance/adds/shopadd';
import FarmerCard from '../nav/farmslider';
import StoreAdd from '../finance/adds/storeadd';
import  UserContext  from '../../context/usercontext';
import PComments from '../mods/postcomments';
import { ArrowForward } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { User } from 'realm-web';
import Post from './singlepost';


const useStyles = makeStyles((theme) => ({
  root: {
     // You can set this to match the width in your UI
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    
  },
  media: {
    minHeight: 250,
    width: 345,
   
    borderRadius: 10,
    
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 10,
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.16)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 30px 0 rgba(0,0,0,0.16)',
    },
  },

  stickyArticle: {
    position: 'sticky',
    top: theme.spacing(2),
   
    
  
  },
}));




export const GET_ALL_POSTS = gql`
  query GetAllPosts {
    posts(sortBy: CREATEDAT_DESC limit: 5 ) {
      _id
      content
      createdAt
      author
      imageurl
      contentType
      userID
      
    }
  }
`;



const GET_ALL = gql `
 query GetAllComments {
  comments {
    _id
    content
    contentId
    contentType
    author
  }
}
`;

const GET_ALL_LIKES = gql `
  query GetAllLikes {
  likes {
    _id
    contentId
    contentType
    user
  }
}
`;





const LatestPost = ({ userprof }) => {
  
  const [items, setItems] = useState([]);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
    const { user, imageurl } = usePostData();
    
  
  const [currentUserprofId, setCurrentUserprofId] = useState(null);
  const { loading, error, data } = useQuery(GET_ALL_POSTS);
  const [postSubmitted, setPostSubmitted] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activePostId, setActivePostId] = useState(null);
  
    const [theData, setTheData] = useState([]);
    const classes = useStyles();
    const { data: allCommentsData } = useQuery(GET_ALL);
  const { data: allLikesData } = useQuery(GET_ALL_LIKES);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [expandedCards, setExpandedCards] = React.useState({});
  
    
  const handleOpen1 = (postId) => {
    setActivePostId(postId);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer1 = () => {
    setIsDrawerOpen(false);
  };

  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
  };



  const ads = [<FarmerAd/>, <StoreAdd/>,<FarmerCard/>, <BaristaAd/>, <RoasterAd/>, <BeansAd/>, <ShopAd/> /*... and so on*/];
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 400); // Reset to original scale after 2 seconds
  };

  const [open, setOpen] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState('');

    const handleOpen = (imageUrl) => {
        setCurrentImageUrl(imageUrl); // Set the current image URL
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (postSubmitted) {
      setDrawerOpen(false);
      setPostSubmitted(false); // Reset for next time
    }
  }, [postSubmitted]);

  useEffect(() => {
    if (data && data.posts.length) {
      setItems(data.posts.slice(0, 8));
    }
  }, [data]);

  function fetchMoreListItems() {
    if (!data || items.length >= data.posts.length) return;
    setIsFetching(true);
    setTimeout(() => {
      const moreItems = data.posts.slice(items.length, items.length + 50);

      setItems((prevState) => ([...prevState, ...moreItems]));
      setIsFetching(false);
    }, 1500);
  }

  const handleCommentButtonClick = (userprofId) => {
    setCurrentUserprofId(userprofId);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentUserprofId(null);
  };
  // Queries for comments and likes
  const { data: commentsData } = useQuery(GET_COMMENTS, {
    variables: { contentId: userprof?._id },
    skip: !userprof
  });

  const { data: likesData } = useQuery(GET_LIKES, {
    variables: { contentId: userprof?._id },
    skip: !userprof
  });

  // Mutation for likes
  const [createLike] = useMutation(CREATE_LIKE_MUTATION);

  

  // Calculate counts
  const commentCount = commentsData?.comments.length;
  const likeCount = likesData?.likes.filter(like => like.contentId === userprof?._id).length;

  // Handle like button click
  const handleLike = () => {
    if (userprof) {
      createLike({
        variables: {
          data: {
            user: user.id,
            contentId: userprof._id,
            contentType: 'koffie_post'
          }
        }
      });
    }
  };

 // A helper function to format the date
 const formatDate = (dateString) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }).format(new Date(dateString));
};
  if (loading) return <p><Progress/></p>;
    if (error) return <p>Error :</p>;
    
    
    

// Retrieve the user profile by ID
//const currentUserprof = userprofs ? userprofs.find((userprof) => userprof._id === currentUserprofId) : null;

// ...



  return (
    <Box sx={{ flexGrow: 1 ,  borderRadius: 5, padding: 1}}>
    
    
    <Grid  spacing={1} justifyContent="center">
      <>
      <Typography variant="h6" fontWeight={800} gutterBottom>
        Latest Posts
      </Typography>
      </>
       {items.map((post, index) =>  {
        const commentsCounts = allCommentsData?.comments.filter(comment => comment.contentId === post._id);
        const likesCounts  = allLikesData?.likes.filter(like => like.contentId === post._id);

        return (
          <Box mb={0}>
      
      <ListItem key={post._id} alignItems="flex-start" sx={{  }}>
          <ListItemAvatar>
          <Link to={`/posts/${post._id}`} style={{ color: 'inherit' }}>
            <CardMedia 
            sx={{ borderRadius: 2, width: 50, height: 50, }}
            component="img"
            image={post.imageurl}
            alt="green iguana"
            />
             
            </Link>
          </ListItemAvatar>
          <ListItemText
            primary={post.author}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  <Link to={`/posts/${post._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  {post.content}
                  </Link>
                </Typography>
              </React.Fragment>
            }
            secondaryTypographyProps={{ noWrap: true, display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
          />
                <Chip edge="end" label="Read"  component={Link} to={`/posts/${post._id}`} variant="outlined" color="primary" 
                 sx={{
                  borderColor: '#32a852', // Green border color
                  borderWidth: '1px', // Border width
                  borderStyle: 'solid', // Border style
                  backgroundColor: 'transparent', // Green background color
                
                }}
                />
             
            </ListItem>
            <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer1}>
            <Icon onClick={handleCloseDrawer1}>
              <ArrowForward />
            </Icon>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 2 }}>
            <Avatar src={post.imageurl} alt="Avatar" />
                            <Typography variant="body2">
                                <Link to={`/kupster/${post.userID}`}>{post.author}</Link>
                             
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {formatDate(post.createdAt)} {/* Format as needed */}
                            </Typography>
                        </Box>  
                
                {/* Optional title next to the back arrow */}
                <Typography variant="body1" sx={{ fontWeight: 700, mt: 2, mb: 2 }}>
                    {post.content}
                </Typography>
               

            </Drawer>
    </Box>
      );
            
      })}
     
      
      <>
      
        </>
    </Grid>
    </Box>
  );
};

export default LatestPost;
