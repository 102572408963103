import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h3" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Effective date: June 20, 2024
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Koffieland. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regards to your personal information, please contact us at support@goodkoffie.com.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect personal information that you voluntarily provide to us when you register on the services, express an interest in obtaining information about us or our products and services, when you participate in activities on the services or otherwise when you contact us.
        </Typography>

        <Typography variant="body1" paragraph>
          The personal information that we collect depends on the context of your interactions with us and the services, the choices you make and the products and features you use. The personal information we collect may include the following:
        </Typography>
        <Typography variant="body1" component="ul" paragraph>
          <li>Personal Information Provided by You. We collect names; phone numbers; email addresses; job titles; contact preferences; and other similar information.</li>
          <li>Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by our payment processor and you should review its privacy policies and contact the payment processor directly to respond to your questions.</li>
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use personal information collected via our services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
        </Typography>
        <Typography variant="body1" component="ul" paragraph>
          <li>To facilitate account creation and logon process.</li>
          <li>To post testimonials.</li>
          <li>Request feedback.</li>
          <li>To enable user-to-user communications.</li>
          <li>To manage user accounts.</li>
          <li>To send administrative information to you.</li>
          <li>To protect our services.</li>
          <li>To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</li>
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may process or share your data that we hold based on the following legal basis:
        </Typography>
        <Typography variant="body1" component="ul" paragraph>
          <li>Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
          <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
          <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
          <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have questions or comments about this notice, you may contact us at support@goodkoffie.com or by mail to: Good Koffie, 123 Coffee St, Atlanta, GA, 30301.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Changes to This Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this privacy notice from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the new privacy policy on our site. You are advised to review this privacy policy periodically for any changes.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
