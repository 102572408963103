// CallToActionAd.js
import { Button, Card, Typography } from '@mui/material';
import React from 'react';

const BeansAd = () => (
  <Card sx={{ padding: '20px', textAlign: 'center', borderRadius: 0, height: '50vh',
  backgroundImage: `url("https://storage.googleapis.com/app_darkendimg/path/to/destination/koffiecandy.jpeg")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  overflow: "hidden"

  }}>
    <Typography variant='h3' sx={{ fontWeight: 700, paddingTop: 5, paddingBottom: 5,color: '#fff',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
    }} >Tokenize your Beans </Typography>
    <Typography variant='h3' sx={{ color: '#fff',fontWeight: 500,boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)' }} >Koffie Contracts</Typography>
    <Typography variant='h4' sx={{color: '#fff', fontWeight: 200, boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)' }} >Trustless Platform</Typography>
    <Button variant='contained' sx={{ marginTop: 5, borderRadius: 5, width: '80%', boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)' }} >Learn More... </Button>
    {/* Replace '#' with your actual shopping cart link */}
    
  </Card>
);

export default BeansAd;
