import React, { useState, useEffect } from 'react';
//import Product from './Product';
import { useBeanBag } from '../../context/beanBagContext';
import { useBag } from '../../context/BagContext';
import Progress from '../mods/progress';
import ShopAd from './adds/shopadd';
import { Store } from '@mui/icons-material';
import StoreAdd from './adds/storeadd';
import { Box } from '@mui/material';
import FromBeans from './prods/frombeans';
//import Prod1 from './prods/prod';

const BeansComponent = (props) => {
    const { itemsInBag, setItemsInBag } = useBeanBag();
    
  const handleAddToBag = (product) => {
    const newItemsInBag = [...itemsInBag, product];
    setItemsInBag(newItemsInBag);
   
  };
  
  

 

  return (
    <Box sx={{paddingBottom: 10}}> 
      
              
              <FromBeans onAddToBag={handleAddToBag} />

              
              
             <StoreAdd/>
           
    </Box>
  );
};

export default BeansComponent;
