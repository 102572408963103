import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  IconButton,
  Snackbar,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { UserContext } from '../context/usercontext';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const SEND_MESSAGE = gql`
  mutation SendMessage($data: MessageInsertInput!) {
    insertOneMessage(data: $data) {
      _id
      content
      createdAt
      isRead
      senderId
      receiverId
    }
  }
`;

export default function SendMessageButton({ receiverId, receiverName }) {
  const { user } = useContext(UserContext);
  const { Nickname, imageurl } = user.customData;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [sendMessage, { loading }] = useMutation(SEND_MESSAGE);
  const [error, setError] = useState(null); // New: Error state for feedback

  const handleOpenDialog = () => setDialogOpen(true);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setMessageContent(''); // Reset content
  };

  const handleSendMessage = async () => {
    if (!messageContent) {
      setError('Message content cannot be empty.');
      return;
    }

    if (messageContent.length > 500) { // Example validation for length
      setError('Message is too long. Please limit to 500 characters.');
      return;
    }

    try {
      await sendMessage({
        variables: {
          data: {
            content: messageContent,
            senderId: user.id,
            receiverId: receiverId,
            isRead: false,
            sendername: Nickname,
            senderimage: imageurl,
            createdAt: new Date().toISOString(),
          },
        },
      });
      handleCloseDialog();
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message. Please try again.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, padding: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          right: 0,
          paddingRight: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <IconButton
          onClick={handleOpenDialog}
          sx={{ color: 'white' }}
          aria-label="Send Message"
        >
          <MailOutlineIcon />
        </IconButton>
        <Typography variant="caption" sx={{ color: 'white', marginTop: '4px' }}>
          Message
        </Typography>
      </Box>

      {/* Dialog for Mobile */}
      {isMobile && (
        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={handleCloseDialog}
          sx={{ zIndex: 2000 }}
        >
          <DialogContent>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Send a Message to {receiverName}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              placeholder="Type your message here..."
              variant="outlined"
              error={!!error}
              helperText={error}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleSendMessage}
              disabled={loading}
              sx={{
                backgroundColor: '#077336',
                color: 'white',
                ':hover': {
                  backgroundColor: '#06562c',
                },
              }}
            >
              {loading ? 'Sending...' : 'Send'}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Modal for Desktop */}
      {!isMobile && (
        <Modal open={dialogOpen} onClose={handleCloseDialog}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Send a Message to {receiverName}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              placeholder="Type your message here..."
              variant="outlined"
              error={!!error}
              helperText={error}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button
                variant="contained"
                onClick={handleSendMessage}
                disabled={loading}
                sx={{
                  backgroundColor: '#077336',
                  color: 'white',
                  ':hover': {
                    backgroundColor: '#06562c',
                  },
                }}
              >
                {loading ? 'Sending...' : 'Send'}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {/* Snackbar for Errors */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />
      )}
    </Box>
  );
}
