import React, { useContext, useEffect, useState } from 'react';
import {  useQuery, useMutation } from '@apollo/client';
import { UserContext } from '../../context/usercontext';
import KupsterDash from '../kupsterdash';
import gql from 'graphql-tag';
import { useLazyQuery } from "@apollo/client";
import { useLocation } from 'react-router-dom';
import beans from '../../images/coffeebeans.png';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { 
  Paper,
  Avatar,
  Grid,
  Card,
  Typography,
  Drawer,
  Box,
  Button,
 CardActionArea,
 Accordion,
 AccordionSummary,
  AccordionDetails,

 
  CardActions,
 
  IconButton,
  
  Chip,
  Divider
  } from '@mui/material';
 
import { makeStyles } from '@mui/styles';

import Update from '../../components/profile/update';

import Baristadash from '../../components/profile/dashboards/baristadash';
import RoasterDash from '../../components/profile/dashboards/roasterdash';
import ShopDash from '../../components/profile/dashboards/shopdash';
import FarmerDash from '../prodman';

import { CollaboratorsCount } from '../../components/profile/settings/CollaborateComponents';

import KoffieCalc from '../../components/finance/koffiecalc';

import Fade from '@mui/material/Fade';
import SwipeDownOutlinedIcon from '@mui/icons-material/SwipeDownOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import MainSt from '../mainstreet';
import { PersonOffOutlined, SensorOccupiedOutlined } from '@mui/icons-material';
import LocalCafeOutlined from '@mui/icons-material/LocalCafeOutlined';



const userTypes = [
  { type: 'kupster', label: "Kupster's Contracts" },
  { type: 'prodman', label: "Prodman's Contracts" },
  { type: 'barista', label: "Barista's Contracts" },
  { type: 'coffeeShop', label: "Coffee Shop's Contracts" },
  { type: 'roaster', label: "Roaster's Contracts" },
];



function generate(element) {
  return [0,  ].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}



const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,
    
    borderRadius: '20px',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    fontSize:100,
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  body: {
    fontSize: 16,
    color: '#727573',
    padding: 15
  }
}));





function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const theme = useTheme();

    return (
      <div
  role="tabpanel"
  hidden={value !== index}
  id={`full-width-tabpanel-${index}`}
  aria-labelledby={`full-width-tab-${index}`}
  sx={{
    width: '100%',
    bgcolor: 'transparent',
    padding: '5px',
    boxShadow: 'none',
    textTransform: 'lowercase',
    textColor: theme.palette.type === 'dark' ? 'primary' : 'secondary'
  }}
  {...other}
>
  {value === index && (
    <Box sx={{ p: 3, padding: '10px' }}>
      <Typography>{children}</Typography>
    </Box>
  )}
</div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
      
    };
  }



const GET_USER = gql`   
    query Userprof ($_id: ObjectId!)  {
        userprof (query: {_id: $_id}) {
            flavorprofile
            Nickname
            tasteProfile
            description
            name
            purchaseLink
            Koffie_story
            usertype
            _id
            country
            beanCount 
            imageurl
        }
    }
`;




const GET_KOFFY_DETAILS = gql`
    query GetKoffyDetails($countryName: String!) {
        koffy(query: {countryName: $countryName}) {
            countryName
            _id
            Pounds
          
           
        }
    }
`;

const UPDATE_USER_PROF = gql`
  mutation UpdateUserprof($query: UserprofQueryInput!, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      tasteProfile
      description
      name
      country
      
    }
  }
`;
const GET_LIKES_BY_CONTENT_CREATOR = gql`
  query GetLikesByContentCreator($contentCreator: String!) {
    likes(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      user
      contentCreator
    }
  }
`;

const GET_COMMENTS_BY_CONTENT_CREATOR = gql`
  query GetCommentsByContentCreator($contentCreator: String!) {
    comments(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      author
      contentCreator
      content
    }
  }
`;

const GET_COLLABORATING = gql`
 query GetCollaborating($userId: String!) {
  collaborates(query: { collaboratorID: $userId }) {
    _id
    collaboratingID
  }
}

`;
const GET_COLLABORATORS = gql`
query GetCollaborators($userId: String!) {
  collaborates(query: { collaboratingID: $userId }) {
    _id
    collaboratorID
  }
}
`;

const BeanValueDisplay = ({ beanCount }) => {
  // Assuming beanCount is the number of beans and each bean costs .01 cents
  const costPerBean = 0.01; // Cost per bean in dollars
  const totalValue = beanCount * costPerBean;
  const galleryValue = 30; // Assuming the gallery value is 0 for now
  const totalearnings = beanCount * costPerBean + galleryValue;

  return (
   <Card sx={{ display: 'flex', flexDirection: 'column', padding: 2, boxShadow: 3, borderRadius: 5 }}>
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <span>Bean Value</span>
        <span>{totalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
      </li>
      <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <span>Gallery Value</span>
        <span>{galleryValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
      </li>
      <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <span>Earnings</span>
        <span><b>{totalearnings.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b></span>
      </li>
    </ul>
  </Card>
  
  );
};


function Logics() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
    const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [expanded, setExpanded] = React.useState();

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeIndex = (index) => {
    
    setValue(index);
  };
    const { user } = useContext(UserContext);
    const location = useLocation();
    const passedUser = location.state?.user; // Access user data passed from Login component
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const classes = useStyles();
    const [updateUserprof] = useMutation(UPDATE_USER_PROF);
    const { data, error, refetch } = useQuery(GET_USER, {
        variables: { _id: user?.id },
    });
  //GET COLLABS
  const { loading: loadingCollaborating, error: errorCollaborating, data: collaboratingData } = useQuery(GET_COLLABORATING, {
    variables: { userId: user?.id }, // Assuming user.id is a string
  });
  const { loading: loadingCollaborators, error: errorCollaborators, data: collaboratorsData } = useQuery(GET_COLLABORATORS, {
    variables: { userId: user?.id }, // Assuming user.id is a string
  });
    const [getKoffyDetails, { loading, data: koffyData }] = useLazyQuery(GET_KOFFY_DETAILS);

    console.log('koffie', koffyData);
    
 const { loading: loadingLikes, error: errorLikes, data: likesData } = useQuery(GET_LIKES_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });
 // likes count
  const likesCount = likesData?.likes.length || 0;

  // comments count
  const { loading: loadingComments, error: errorComments, data: commentsData } = useQuery(GET_COMMENTS_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });

  if (errorComments) {
    console.error("Error fetching comments:", errorComments.message);
  }
  const commentsCount = commentsData?.comments.length || 0;


  // To clear the tasteProfile and description
  const handleClearProfile = () => {
    const query = { _id: user.id };
    const set = { tasteProfile: "", description: "", name: "", country: "" };
  
    updateUserprof({ variables: { query, set } })
      .then(response => {
        console.log('Profile cleared:', response.data);
      })
      .catch(error => {
        console.error('Could not clear profile:', error);
      });
  };
    
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        if (passedUser) {
          // Do something with the passedUser
        }
    }, [passedUser]);

    useEffect(() => {
        if (user?.id) {
          refetch();
        }
    }, [user?.id, refetch]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if(loading) return <p>Loading...</p>
    if(error) return <p>Error: {error.message}</p>

    let userTypeComponent;

    if(data && data.userprof) {
        switch(data.userprof.usertype) {
            case 'prodman':
                userTypeComponent = windowWidth <= 768 ? <FarmerDash userID={data.userprof._id} /> : <FarmerDash userID={data.userprof._id} />;
                break;
            case 'kupster':
                userTypeComponent = windowWidth <= 768 ? <KupsterDash userID={data.userprof._id} /> : <Baristadash userID={data.userprof._id} />;
                break;
                case 'barista':
                userTypeComponent = windowWidth <= 768 ? <Baristadash userID={data.userprof._id} /> : <Baristadash userID={data.userprof._id} />;
                break;
                case 'roaster':
                userTypeComponent = windowWidth <= 768 ? <RoasterDash userID={data.userprof._id} /> : <RoasterDash userID={data.userprof._id} />;
                break;
                case 'coffeeShop':
                userTypeComponent = windowWidth <= 768 ? <ShopDash userID={data.userprof._id} /> : <ShopDash userID={data.userprof._id} />;
                break;
            default:
                userTypeComponent = null;
        }
    }

    const rewardCategories = [
      { title: 'Coffee Points', icon: '☕', description: 'Earn points for every purchase',   },
      { title: 'Special Offers', icon: '🎁', description: 'Exclusive discounts and promotions' },
      { title: 'Premium Membership', icon: '🌟', description: 'Unlock premium benefits' },
    ];
   
    // Filler user details
    const userDetails = {
      name: 'John Doe',
      points: 500,
    };

    
    return (   
        <div>   
              
              
              
            <Box sx={{  width: '100%', paddingBottom: 2  }}>
  
            <div 
  position="static"
  key={theme.palette.type}  // Force re-render when theme changes
  sx={{ 
    bgcolor: 'transparent', 
    width: '100%', 
    padding: '5px', 
    boxShadow: 'none', 
    textTransform: 'lowercase',
    textColor: theme.palette.type === 'dark' ? 'primary' : 'secondary'
  }}
>

<Tabs 
  key={theme.palette.type}  // Force re-render when theme changes
  value={value}
  onChange={handleChange}
  indicatorColor={theme.palette.type === 'dark' ? 'primary' : 'primary'}
  textColor={theme.palette.type === 'dark' ? 'primary' : 'primary'}
  variant="fullWidth"
  aria-label="full width tabs example"
  sx={{ bgcolor: 'transparent', width: '100%'  }}
>
          <Tab  icon={<LocalCafeOutlined />} iconPosition="start"  label="Dash" {...a11yProps(0)} />
          <Tab  icon={<SensorOccupiedOutlined />} iconPosition="start"  label="Settings" {...a11yProps(1)} />
        
        </Tabs>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <Box sx={{ flex: 1,  paddingBottom: 5 }}> 
        <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={12}>
        <Card  sx={{  borderRadius: 2 , boxShadow: 0,}}>
          {userTypes.map((userTypeObj) => {
            if (data?.userprof?.usertype === userTypeObj.type) {
              return (
                <Box sx={{ flexGrow: 1, padding: 1 }}>
 
</Box>
              );
            }
            return null;
          })}
        </Card>
      </Grid>
      <Grid container item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
</Grid>

    </Grid>
    <MainSt/>
            </Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        Account Settings
        
        <Update/>
        <Typography>
          </Typography>
        </TabPanel>
      </SwipeableViews>
    </Box>

        </div>
    )
}

export default Logics;
