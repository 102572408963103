import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Card, Avatar, Typography, Grid, LinearProgress, Button, Fab, Dialog, DialogActions, Chip, DialogTitle, DialogContent, CardContent, DialogContentText, Skeleton } from '@mui/material';
import { gql } from '@apollo/client';
import coffeebeans from '../../images/brkoin.png';
import minted from '../../images/kbean.png';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale , } from 'chart.js/auto'; 
// Register the moment adapter
import moment from 'moment';
import { registerAdapterDateFns } from 'chartjs-adapter-moment'; 
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Progress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/usercontext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PriceTicket from '../finance/prods/pricefeed';
import kcoin from '../../images/kcoin.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GaugeChart from 'react-gauge-chart'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import ClaimBeans from './dialogue/claimbeans';
import { useMutation } from '@apollo/client';

import { useSpring, animated } from 'react-spring';
import confetti from 'canvas-confetti';
import CoinFlip from './kcoin/coinflip';


const useStyles = makeStyles((theme) => ({
  progressAndChart: {
    display: 'flex', 
    
  },
  progressContainer: {
    width: 300, 
    height: 30,
    border: '1px solid #ddd', 
    borderRadius: 5,
  },
  progressFiller: {
    backgroundColor: 'lightgreen', 
  },
  chartContainer: {
     width: '100%',
  },
}));




// Register the moment adapter

ChartJS.register(CategoryScale);


function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}


const GET_USER = gql`   
    query Userprof ($_id: ObjectId!)  {
        userprof (query: {_id: $_id}) {
            flavorprofile
            Nickname
            tasteProfile
            description
            name
            purchaseLink
            Koffie_story
            usertype
            _id
            country
            beanCount 
            imageurl
            profileBuilt
        }
    }
`;

const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
        beanCount
      
    }
  }
`; 

const BeanCard = () => {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl, _id } = user.customData
  const classes = useStyles();
  
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [createConfetti, setCreateConfetti] = useState(false);
  //get user by _id
  const { data, error, refetch, loading } = useQuery(GET_USER, {
    variables: { _id: user?.id },
});
const [updateOneUserprof, ] = useMutation(UPDATE_USERPROF);

useEffect(() => {
  if (user?.id) {
    refetch();
  }
}, [user?.id, refetch]);
  if (loading) return <p>
    <Skeleton variant="rectangular" width={400} height={150}  sx={{width: '100%', borderRadius: 3}}/>
  </p>;
  if (error) return <p>Error :</p>;

  const topUsers = data.userprofs;
  
  const refill = () => {
    setOpenDialog2(true);
  };

  
  
const open = () => {
  setOpenDialog1(true);
};
  
const upgrade = () => {
  setOpenUpgrade(true);
};

  



const isSmartCitizen = data.userprof?.beanCount > 500;


 // Chart Data Preparation (assuming you want to visualize beanCounts of top users)
 
 
 const handleGetBeans = async () => {
  // Logic to claim kupa koffie (100 beans)
  const variables = {
      query: { _id: user.id },
      set: {
       
        beanCount:  data.userprof.beanCount + 100,
        

      }
    };
  
    try {
      await updateOneUserprof({ variables });
      console.log("Successfully updated the user profile.");
    } catch (error) {
      console.log("An error occurred while updating the user profile:", error);
    }

  // Decrement the beanCount by 100
 // Show the bean rain dialog
  // Decrement the beanCount by 100
   // Show the bean rain dialog
   setCreateConfetti(true);

   // Trigger the confetti animation with the custom coffee bean shape
   createCoffeeBeanConfetti();
 // Hide the bean rain dialog after 1 second
 setTimeout(() => {
  setCreateConfetti(false);
  
 }, 3000);

  // Close the dialog
  setOpenDialog2(false);
};

function createCoffeeBeanConfetti(options = {}) {
  const defaults = {
    scalar: 1.5,
    spread: 80,
    particleCount: 150,
    origin: { y: -0.1 },
    startVelocity: -40,
    zIndex: 1500,
  };

  // Merge provided options with defaults
  const mergedOptions = { ...defaults, ...options };

  // Define the coffee bean shape as an array of coordinate pairs
  const coffeeBeanShape = [
    [0.1, 0.1],
  [0.2, 0.2],
    
  ];

  confetti({
    ...mergedOptions,
    shape: coffeeBeanShape, // Use the custom coffee bean shape
    colors: ['#704214', '#704214', '704214']
  });
}


  
  return (
    <>
   

    
    




<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, borderRadius: 0, boxShadow: 0,  marginTop: 0 }}>
  

   
   
    {isSmartCitizen ? (
       <Card
       sx={{
         flex: 1,
         borderRadius: 2,
         overflow: 'hidden',
         width: '100%',
        
         border: '1px solid ',
       }}
     >
       <CardContent
         sx={{
           display: 'grid',
           gridTemplateColumns: '1fr 1fr',
           gridTemplateRows: 'auto auto auto',
           gap: '8px',
           
           padding: 2,
         }}
       >
         <Box sx={{ gridColumn: '1 / 2', gridRow: '1' }}>
           <Typography variant="h6" sx={{ fontWeight: 700 }}>
             Citizen
           </Typography>
         </Box>

         <Box sx={{ gridColumn: '1 / 2', gridRow: '2', display: 'flex', alignItems: 'center' }}>
           <Avatar src={imageurl} alt="bean" sx={{ marginRight: '8px' }} />
           <Typography variant="body2" sx={{ fontWeight: 700 }}>
             {data.userprof?.Nickname}
           </Typography>
         </Box>

         <Box sx={{ gridColumn: '1 / 2', gridRow: '3' }}>
           <Typography variant="body2" sx={{ fontWeight: 400 }}>
             ID# {_id.substring(0, 8)}
           </Typography>
         </Box>

         <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 4', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
           <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
             <Typography variant="body2" sx={{ fontWeight: 700, marginRight: '8px' }}>
               {data.userprof?.beanCount}
             </Typography>
             <img src={kcoin} alt="Bean" width={30} />
           </Box>
          <CoinFlip/>
         </Box>
       </CardContent>
     </Card>
    ) : (
      <Card
      sx={{
        flex: 1,
        borderRadius: 2,
        
        width: '100%',
        
      }}
    >
      <CardContent
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto auto auto',
          gap: '8px',
          backgroundColor: '#addeb8',
          padding: 2,
        }}
      >
        <Box sx={{ gridColumn: '1 / 2', gridRow: '1' }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Bean Card
          </Typography>
        </Box>

        <Box sx={{ gridColumn: '1 / 2', gridRow: '2', display: 'flex', alignItems: 'center' }}>
          <Avatar src={imageurl} alt="bean" sx={{ marginRight: '8px' }} />
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {data.userprof?.Nickname}
          </Typography>
        </Box>

        <Box sx={{ gridColumn: '1 / 2', gridRow: '3' }}>
          <Typography variant="body2" sx={{ fontWeight: 400 }}>
            ID# {_id.substring(0, 8)}
          </Typography>
        </Box>

        <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 4', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Typography variant="body2" sx={{ fontWeight: 700, marginRight: '8px' }}>
                    {data.userprof?.beanCount}
                  </Typography>
                  <img src={kcoin} alt="Bean" width={30} />
                  
                </Box>
                {data.userprof?.beanCount < 60 ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="caption" sx={{ fontWeight: 700, color: '#077336', alignSelf: 'flex-end', textShadow: 'rgba(0, 0, 0, 0.0) 1px 1px 1px' }}>
                    Refill?
                  </Typography>
                    <LocalDrinkIcon  onClick={refill}  sx={{ color: 'red', marginLeft: '8px' }} /> 
                    </Box>
                  ) : (
                    <Chip label="Upgrade" onClick={upgrade} sx={{ backgroundColor: '#32a852', color: '#fff', alignSelf: 'flex-end' }} />
                  )}
              </Box>
      </CardContent>
    </Card>
    )}
     <Dialog open={openUpgrade} onClose={() => setOpenUpgrade(false)}>
    <IconButton
      onClick={() => setOpenUpgrade(false)}
      sx={{
        marginLeft: 0,  // Ensures there's no additional space on the left
        color: 'primary.main',  // Optional: change the icon color if needed
        backgroundColor: 'transparent' , // Ensures the button background is transparent
        position: 'absolute', top: 8, right: 8, fontSize: 2, 
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogTitle variant='h5' sx={{fontWeight: 600}}>Upgrade to Smart Citizen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Become a lifetime Smart Citizen and enjoy all the benefits of being a citizen of KoffieLand with your first Purchase of 1lb, 4000 KoffieBeans.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Chip label="Upgrade" onClick={() => navigate('/store')} sx={{ backgroundColor: '#32a852', color: '#fff' }} />
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog2} onClose={() => setOpenDialog2(false)}>
    <IconButton
      onClick={() => setOpenDialog2(false)}
      sx={{
        marginLeft: 0,  // Ensures there's no additional space on the left
        color: 'primary.main',  // Optional: change the icon color if needed
        backgroundColor: 'transparent' , // Ensures the button background is transparent
        position: 'absolute', top: 8, right: 8, fontSize: 2, 
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogTitle variant='h5' sx={{fontWeight: 600}}>Refill Beans</DialogTitle>
        <DialogContent>
          <DialogContentText>
            In Koffieland you always get a refill of 100 beans when you run low. Enjoy your refill.
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Chip label="Refill" onClick={handleGetBeans} sx={{ backgroundColor: '#32a852', color: '#fff' }} />
        </DialogActions>
      </Dialog>
      
  </Box>
  

      
    
    </>
  );
};

export default BeanCard;
