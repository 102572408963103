import React, { useState } from 'react';
import { Paper, Button } from '@mui/material';
import Barista from './barista';

// Our menu data, organized by category
const menuData = {
  "Food": [
    { "id": 1, "name": "Sandwich" },
    { "id": 2, "name": "Salad" }
  ],
  "Drinks": [
    { "id": 3, "name": "Coffee" },
    { "id": 4, "name": "Tea" }
  ],
  "Coffee Beans": [
    { "id": 5, "name": "Arabica" },
    { "id": 6, "name": "Robusta" }
  ]
};

function CoffeeShopMenu() {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemClick = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  const handleDeleteClick = (itemToDelete) => {
    setSelectedItems(selectedItems.filter(item => item.id !== itemToDelete.id));
  };

  const handleSubmit = () => {
    // Replace this with a function to send the data to a server
    console.log('Submitted:', selectedItems);
  };

  return (
    <div>
      <h1>Barista AI</h1>
      <Paper>
        <Barista/>
      </Paper>
      {Object.entries(menuData).map(([category, items]) => (
        <Paper key={category}>
          <h2>{category}</h2>
          {items.map(item => (
            <Button key={item.id} onClick={() => handleItemClick(item)}>
              {item.name}
            </Button>
          ))}
        </Paper>
      ))}
      <h2>Selected Items</h2>
      {selectedItems.map(item => (
        <Paper key={item.id}>
          {item.name}
          <Button color="secondary" onClick={() => handleDeleteClick(item)}>
            Delete
          </Button>
        </Paper>
      ))}
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
}

export default CoffeeShopMenu;
