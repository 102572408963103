import React from 'react'
import styled from "styled-components";
import {Avatar, Card, Typography, Grid} from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../context/usercontext';
//import '../../styles/styles.css'
import { GetFarmers } from '../../queries/someq'
import habish from '../../images/habish.jpg'
import { Link } from 'react-router-dom'



const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  background: transparent;
  padding: 0px ;
  top: 0;
  overflow: hidden;
  text-decoration: none;
  
`;

const Scroll = styled.div`
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto;
  white-space: no-wrap;
  scroll-behavior: smooth;
  scrollbar-width: none;
  background: transparent;
  &::-webkit-scrollbar {
    display: none;
  

  }
`;

const Other = styled.div`
  display: flex;
  
  text-decoration: none;
  background: transparent;
  color: green;
  border: transparent solid 0px;
  
  
`;

const Button = styled.button`

background: transparent;
color: green;
border: white solid 0px;
text-decoration: none;
`;

function Darkswipe() {
   
    const {data, loading, error } = GetFarmers( );
    if (loading) return <div>Loading...</div>
    if (error) return <div> {null}</div>
    return (
      <>
    
          <ScrollContainer >   
          <Grid sx={{minWidth: '100' ,minHeight: '100', borderRadius: 1}}>
     <Typography sx={{ height: '100%',width: '100%', fontSize: '1.5rem', fontWeight: '900',  textAlign: 'left' , padding: '0.35rem', backgroundColor: '#000' }}>
          Koffie News 
        </Typography>
     </Grid>
        <Scroll>  
          <Other  >                 
          {data && data.farmprofs &&
    data.farmprofs.map((farmprofs, _id, ) => (
    
    <Button >
        <Link style={{ textDecoration: 'none' }} key={farmprofs._id} to={`/prodman/${farmprofs._id}`} >
        <Card 
  sx={{
    width: 300,
    height: 80,
    borderRadius: 2,
  }}
>
  <Grid container>
    {/* Grid item for the image */}
    <Grid item xs={4}>
      <div
        sx={{
          width: '100%', // Take up full width of the grid item
          height: '100%', // Take up full height of the card
        
          backgroundSize: 'cover', // Cover the entire grid item with the image
          backgroundRepeat: 'no-repeat', // Do not repeat the image
          backgroundPosition: 'center', // Center the image
        }}
      >
        <img src={habish} alt="Description" style={{ width: '100%', height: '100%' }} />
      </div>
    </Grid>
    {/* Grid item for the text */}
    <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center">
      <Typography sx={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'black' }}>
        Smart Contracts
      </Typography>
      <Typography sx={{ fontSize: '0.8rem', fontWeight: 'normal', color: 'grey' }}>
        Contract ID: {farmprofs._id}
      </Typography>
    </Grid>
  </Grid>
</Card>
           </Link>
    </Button>
         ))}
         </Other>
        </Scroll>
        
      </ScrollContainer>
    
       </>
      
    )
}

export default Darkswipe





