import React, { useState, useContext } from 'react';
import { Web3ModalContext } from '../constants/Web3ModalProvider';
import useKupToken from './useKupToken';
import { Button, TextField, Box, Stack, Card, Typography, Avatar, Chip, CardActions } from '@mui/material';
import { Container, } from '@mui/system';
import { Drawer, Grid } from '@mui/material';
import kcoin from '../images/kcoin.png';



function FarmerSupplySubmit() {
    const { account, connect } = useContext(Web3ModalContext);
    const { farmerSupplySubmit, totalCherries, budding, farmerProcess, processStep, fertilizing, drying } = useKupToken(account);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [supplyInLbs, setSupplyInLbs] = useState('');
    const [strain, setStrain] = useState('');
    const [elevation, setElevation] = useState('');
    const [profile, setProfile] = useState('');

    const update = farmerProcess;

    const processSteps = [
      { label: 'Fertilizing', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
      { label: 'Flowering', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info',  image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Flowering is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
      { label: 'Drying', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
      { label: 'Pruning', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
      { label: 'Growing', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
      { label: 'Budding', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
      { label: 'Harvesting', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
      { label: 'Pulping', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
      { label: 'Fermenting', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
      { label: 'Ripening', value: 'hi', imgSrc: 'oclhost', btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
  
    ]; 

    // Function to handle opening the drawer
const handleDrawerOpen = (item) => {
    setSelectedItem(item);
    setDrawerOpen(true);
  };
  
  // Function to handle closing the drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!account) {
            await connect();
        }

        console.log(`Strain: ${strain}, Elevation: ${elevation}, Profile: ${profile}, Supply: ${supplyInLbs}`);

        try {
            await farmerSupplySubmit(strain, Number(elevation), profile, Number(supplyInLbs), );
            alert('Supply submitted successfully');
            setSupplyInLbs('');
            setStrain('');
            setElevation('');
            setProfile('');
        } catch (error) {
            console.error('An error occurred:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (

        <Box sx={{ flexGrow: 1, overflow: 'hidden',  padding: '10px' }}>
            
        <Box  sx={{ flexGrow: 1, overflow: 'hidden',   }}>
        <Card sx={{ flexGrow: 1, overflow: 'hidden',    }}>
    <Typography variant="h6" component="div">
        Submit Koffie Supply
    </Typography>

    <Stack spacing={2} direction="column">
        <TextField sx={{ width: '100%' }} type="text" value={strain} onChange={e => setStrain(e.target.value)} placeholder="Strain" />
        <TextField sx={{ width: '100%' }} type="number" value={elevation} onChange={e => setElevation(e.target.value)} placeholder="Elevation" />
        <TextField sx={{ width: '100%' }} type="text" value={profile} onChange={e => setProfile(e.target.value)} placeholder="Profile" />
        <TextField sx={{ width: '100%' }} type="number" value={supplyInLbs} onChange={e => setSupplyInLbs(e.target.value)} placeholder="Supply in lbs" />
      
    </Stack>
    <CardActions sx={{ justifyContent: 'flex-end' }}>
    <Chip onClick={handleSubmit} label="Submit" />
    </CardActions>
</Card>

        </Box>
        <Box sx={{ p: 3 }}>
      <Card sx={{ borderRadius: '20px', p: 3, display: 'flex', alignItems: 'center' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar sx={{ width: 70, height: 70, backgroundColor: 'green', marginRight: 2 }}>
                <img src={kcoin} alt="Cherries"  width={70}/>
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Minted Beans
            </Typography>
            <Typography variant="body1" sx={{fontWeight: 700}}>
              {totalCherries} 
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
        
        </Box>
        
    );
}

export default FarmerSupplySubmit;
