import React from 'react';
import { Bar } from 'react-chartjs-2';

const VChart = () => {
  const data = {
    labels: ['Smallholder', 'Estate', 'Curing Planting'],
    datasets: [
      {
        label: 'Value',
        data: [12, 19, 3],
        backgroundColor: 'rgba(75, 192, 192, 0.6)'
      }
    ]
  };

  const options = {
    title: {
      display: true,
      text: 'Coffee Value Chain',
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return <Bar data={data} options={options} />;
};

export default VChart;
