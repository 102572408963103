import React from 'react'
import { Box, Typography, Card, Dialog} from '@mui/material'

import { makeStyles } from '@mui/styles'
import Store from './frontgrid'



const Rewards = () => {
    return (
        <Box>
            <h1>Rewards</h1>
        </Box>
    )
}

const Referrals = () => {
    return (
        <Box>
            <h1>Referrals</h1>
        </Box>
    )
}

const History = () => {
    return (
        <Box>
            <h1>History</h1>
        </Box>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function Mission7() {

    const [value, setValue] = React.useState(0);

function handleChange(event, newValue) {
    setValue(newValue);
}
  return (
    
    <Box sx={{  justifyContent: 'center', alignItems: 'center',  marginLeft: '-5px', marginRight: '-5px'  , mt: 1 }}>

       <Dialog fullScreen open={true} onClose={true}>
         <Box sx={{ borderBottom: 1, borderColor: 'divider',
         width: 'calc(100% + 0px)',
            }}>
            <Store />
            </Box>
                </Dialog>
    </Box>
    );
}

export default Mission7