import React, { useState, useEffect } from 'react';

const Typeit = ({ text = '' }) => {  // Initialize text to an empty string if it's not provided
    const [visibleText, setVisibleText] = useState('');
    const typingDelay = 50; // Time delay between each character in milliseconds
  
    useEffect(() => {
      let index = 0;
      const timerId = setInterval(() => {
        if (index < text.length) {
          setVisibleText((prev) => prev + text[index]);
          index++;
        }
        if (index >= text.length) {
          clearInterval(timerId);
        }
      }, typingDelay);
      return () => clearInterval(timerId); // Clear interval on component unmount
    }, [text]);
  
    return <p>{visibleText}</p>;
  };
  
  export default Typeit;
  