import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Typography, Box, Button, Grid, Container, Drawer, IconButton, Avatar, Badge, useMediaQuery, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Toe from '../components/nav/bottom';
import LeftBar from '../components/nav/leftbar';
import AudioPlayerComponent from '../components/mods/morningshow';
import AiGen2 from '../components/finance/adds/aifeeds';
import LatestPost from '../components/posts/latest';
import Featured from '../components/finance/adds/featured';
import { UserContext } from '../context/usercontext';
import { useBag } from '../context/BagContext';
import { useBeanBag } from '../context/beanBagContext';
import { useQuery, gql , useLazyQuery, useApolloClient} from '@apollo/client';
//import { GET_CART, GET_USER } from '../queries';
import LogoutButton from '../components/profile/logout';
import Display from '../components/profile/settings/displaymode';
import MenuIcon from '@mui/icons-material/Menu';
import koffielogo from '../images/kcoin.png';
import { animated } from 'react-spring';
import coffeecoin from '../images/kcoin.png';
import { makeStyles } from '@mui/styles';
import { useSpring } from 'react-spring';
import { MenuList, MenuItem, ListItemIcon, ListItemText, Divider, Paper } from '@mui/material';
import { ArrowForward, Check, Close, CommentOutlined, ConfirmationNumberOutlined, EmailOutlined, FeedbackOutlined, JoinRightOutlined, ListAltOutlined, LiveHelpOutlined, LocalCafeOutlined, PublicOutlined, ShoppingBagOutlined } from '@mui/icons-material';
import { List, ListItem } from '@mui/material';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ReceiptOutlined from '@mui/icons-material/ReceiptOutlined';
import GradeOutlined from '@mui/icons-material/GradeOutlined';
 import {Icon} from '@mui/material';
 import Rewards from '../components/mods/beanrewards';
 import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
 import DeskMenu from '../components/nav/deskmenu';
import TopBean from '../components/mods/topbean';
import { GET_MESSAGES } from './messagesdesk';
import BeanTracker from '../components/mods/beantracker';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';



const Beanhead = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/beanheadicon.png' alt="koffie" width={20} />
  );
};

const useStyles = makeStyles((theme) => ({
  boxContainer: {
   display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
     padding: theme.spacing(1),
     borderRadius: 5,
     boxShadow: 10,
     position: 'relative', // Add position relative for absolute positioning of ribbon
   },
   logo: {
     borderRadius: 0,
     margin: '0 auto',
     display: 'block',
     width: 40,
     height: 40,
     
   },
   logoWrapper: {
     display: 'flex',
     justifyContent: 'space-between',
     alignItems: 'center',
     width: '100%', // Ensure the wrapper takes the full width
     paddingLeft: theme.spacing(2),
     paddingRight: theme.spacing(2),
     marginBottom:   theme.spacing(2),
   },
   ribbon: {
     position: 'absolute',
     bottom: 0, // Adjust the position of the ribbon vertically
     left: 20, // Adjust the position of the ribbon horizontally
     backgroundColor: '#96ff00', // Adjust the background color of the ribbon
     padding: theme.spacing(0.05),
     borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
     borderWidth: 1, // Adjust border width
     borderStyle: 'solid', // Adjust border style
     borderColor: '#fff', // Adjust border color
   },
   ribbonText: {
     color: 'black', // Adjust the text color of the ribbon
     fontWeight: 900, // Adjust the font weight of the ribbon text
     fontSize: 10, // Adjust the font size of the ribbon text
   },
   hideScrollbar: {
     overflowY: 'auto',
     scrollbarWidth: 'none', // For Firefox
     '-ms-overflow-style': 'none', // For Internet Explorer and Edge
     '&::-webkit-scrollbar': {
       display: 'none', // For Chrome, Safari, and Opera
     },
   },
   menuBox: {
   
     padding: 2,
     display: 'flex',
     width: '100%',
     margin: 'auto',
     justifyContent: 'space-around',
     marginTop: 20,
     
     
    
     '&:hover': {
       backgroundColor: theme.palette.action.hover,
     },
     '& a': {
       textDecoration: 'none',
       color: theme.palette.text.primary,
       '&:hover': {
         textDecoration: 'none',
       },
     },
   },
   menuItem: {
     fontSize: 16,
     fontWeight: 500,
     '&:hover': {
       color: theme.palette.primary.main,
     },
   },
   logoContainer: {
     paddingLeft: theme.spacing(0),
     paddingTop: theme.spacing(2),
   },
   walletContainer: {
     paddingRight: theme.spacing(5),
   },
   menuTransition: {
     transition: 'fade 0.5s',
   },
   buttonContainer: {
     display: 'flex',
     justifyContent: 'center', // Centers the button horizontally
     alignItems: 'center', // Centers the button vertically if needed
     width: '50%',
     height: '40px',
     borderRadius: 20,
     backgroundColor: '#077336', // Green color, you can change it as per your theme
     color: 'white',
     border: 'none',
     marginTop: 30,
     fontSize: 20,
     fontWeight: 700,
   },
 
   
 }));

export const GET_USER = gql`   
  query Userprof ($_id: ObjectId!)  {
    userprof (query: {_id: $_id}) {
      flavorprofile
      Nickname
      tasteProfile
      description
      name
      purchaseLink
      Koffie_story
      usertype
      _id
      country
      beanCount 
      imageurl
      profileBuilt
      redeemed
      waitlist
      cashapp
      cashappid
      zelle
      zelleid
      payout
    }
  }
`;

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    carts(query: { userID: $userID, isPaid: false }) {
      userID
      beanies
      isPaid
      productId
      category
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;

const AppLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const { itemCount } = useBag();
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const lastScrollY = useRef(0);
  const childrenRef = useRef(null);
 const classes = useStyles();
  
  const [drawerOpen1, setDrawerOpen1] = useState(false);
  const [drawerOpen2, setDrawerOpen2] = useState(false);
  const [drawerOpen3, setDrawerOpen3] = useState(false);
  const [drawerOpen4, setDrawerOpen4] = useState(false);
  const [drawerOpen5, setDrawerOpen5] = useState(false);
  const [drawerOpen6, setDrawerOpen6] = useState(false);
  const [drawerOpen7, setDrawerOpen7] = useState(false);
  const [drawerOpen8, setDrawerOpen8] = useState(false);
  const { itemCount1 } = useBeanBag();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { itemsInBag , setItemsInBag, } = useBag();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [fetchMessages, { loading, data, error, refetch }] = useLazyQuery(GET_MESSAGES, {
    fetchPolicy: 'network-only', // Ensures the query fetches fresh data
  });
  //Spring style

  const styles = useSpring({
    from: { scale: 1 },
    to: async (next) => {
      while (true) {
        await next({ scale: 1.2 });
        await next({ scale: 1 });
      }
    },
    config: { duration: 2000, easing: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t },
  });


  const { data: userData } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: { _id: user.id },
    skip: !user.id,
  });
  
  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id , },
    skip: !user.id,
  });
  

  useEffect(() => {
    if (cartData && cartData.carts) {
      const newItemsInBag = cartData.carts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
          isPaid: item.isPaid,
          options: item.options,
          category: item.category,
        };
      });
      setItemsInBag(newItemsInBag);
    }
  }, [cartData, ]);

  //fileter cart items that are Koffie and output the number of items
  const koffieItems = itemsInBag.filter((item) => item.category === 'Koffie');

  //list number of items in cart that are Koffie
 


  const [value, setValue] = useState('recents');
 
  
  
  const handlelogoClick = () => {
    navigate('');
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openFeedback, setOpenFeedback] = useState(false);

  const handleFeedback = () => {
    setOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };

  const handleLogoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const thelogo = (
    <img
      src={koffielogo}
      alt="logo"
      width="60"
      height="60"
      onClick={handleLogoClick}
    />
  );

  const toggleDrawer1 = () => {
    setDrawerOpen1(!drawerOpen1);
    setTriggerFetch(true); // Trigger fetch when menu is opened
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
   
  };

  const toggleDrawer2 = () => setDrawerOpen2(!drawerOpen2);
  const toggleDrawer3 = () => setDrawerOpen3(!drawerOpen3);
  const toggleDrawer4 = () => setDrawerOpen4(!drawerOpen4);
  const toggleDrawer5 = () => setDrawerOpen5(!drawerOpen5);
  const toggleDrawer6 = () => setDrawerOpen6(!drawerOpen6);
  const toggleDrawer7 = () => setDrawerOpen7(!drawerOpen7);

  //Navigate to the koffie kit page
  const handleKitClick = () => {
    navigate('/main');
  };

  const handleBeanClick = () => {
    navigate('/dashboard');
  };
const handleDoClick = () => {
  navigate('/goals');
}

const handleReportClick = () => {
  navigate('/bean-report');
}

const handleWorldClick = () => {
  navigate('/allkoffie');
}

const handleMessageClick = () => {
  navigate('/messages');
}

const handleHistoryClick = () => {
  navigate('/chron');
}

const handleContractClick = () => {
  navigate('/contractdetails');
}

const handleBeanEconClick = () => {
  navigate('/beanlist');
}

const handleFAQClick = () => {
  navigate('/faq');
}

const handleFeedbackClick = () => {
  navigate('/feedback');
}

const handleNftClick = () => {
  navigate('/web3market');
}

const handleProfileClick = () => {
  navigate(`/userprof/${user.id}`); // Corrected the use of backticks
};

const handleRewardClick = () => {
  navigate('/reviews');
};


  const handleItemClick = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleReportClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  };

  const handleItemClick1 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleBeanClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick3 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
     handleKitClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick4 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleDoClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick5 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleWorldClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick6 = () => {
    // Close the first drawer and open the second after a delay
   handleRewardClick();// Trigger fetch when menu is opened
    setDrawerOpen1(false);
    
    
  }

  const handleItemClick7 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleHistoryClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick8 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleContractClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick9 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleBeanEconClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick10 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleFeedbackClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick11 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleFAQClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }
  
  const handleItemClick12 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleNftClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }
 const handleItemClick13 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleProfileClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick14 = () => {
    setDrawerOpen(false); // Close the drawer
    setTimeout(() => {
      fetchMessages({ variables: { receiverId: user.id } }) // Trigger the lazy query
        .then(() => {
          refetch(); // Refetch to ensure fresh data
        });
      handleMessageClick(); // Navigate to the messages page
    }, 300);
  };
  

  const handleScroll = useCallback(() => {
    const currentScrollY = childrenRef.current ? childrenRef.current.scrollTop : window.pageYOffset;
    const threshold = 50;
    if (Math.abs(lastScrollY.current - currentScrollY) >= threshold) {
      setShowHeader(currentScrollY < lastScrollY.current);
      setShowFooter(currentScrollY < lastScrollY.current);
      lastScrollY.current = currentScrollY;
    }
  }, []);
  
  useEffect(() => {
    const childrenElement = childrenRef.current;
    if (childrenElement) {
      childrenElement.addEventListener('scroll', handleScroll);
      return () => childrenElement.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);
  
  // Drawer handlers
  const [drawerOpen, setDrawerOpen] = useState(false);
 
  const formatDigiBeans = (count) => {
    if (count >= 1000) {
      return `${count / 1000}k`;
    }
    return count;
  };

  const kcoin = coffeecoin;

  return (
    <Container maxWidth="lg" sx={{ padding: 0, height: '100vh' }}>
      <Grid container sx={{ height: '100vh', position: 'relative' }}>
        {!isMobile && (
          <Grid item xs={3} sx={{ position: 'sticky', top: 0, height: '100%', overflowY: 'auto' }}>
            <LeftBar />
          </Grid>
        )}
        <Grid
  item
  xs={12}
  md={6}
  sx={{
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'relative', // Ensure the Grid is positioned to center items within it
  }}
>
  {isMobile && showHeader && (
    <div className={classes.menuTransition}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '0px solid lightgrey',
          paddingTop: '0px',
          paddingLeft: '0px',
          paddingRight: '16px',
        }}
      >
        {/* Left Menu (Profile or Menu Icon) */}
        <div className={classes.boxContainer}>
          <IconButton onClick={toggleDrawer}>
            <MenuIcon fontSize="large" />
          </IconButton>
        </div>

        {/* Centered Kland Logo */}
        <div
          style={{
            position: 'absolute', // Ensure it's absolutely positioned within the header
            top: '4%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Fine-tune to the exact center
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {itemCount1 > 0 ? (
            <Chip
              component={Link}
              to="/bean-bag"
              label={`${itemCount1} Beanout`}
              icon={<img src={kcoin} alt="Kcoin" style={{ width: 15 }} />}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 1,
                borderRadius: 5,
              }}
            />
          ) : (
            <img
              src={
                theme.palette.mode === 'dark'
                  ? 'https://storage.googleapis.com/app_darkendimg/assets/klandwhite.png'
                  : 'https://storage.googleapis.com/app_darkendimg/assets/kland.png'
              }
              alt="Koffie"
              style={{ width: 50, height: 50 }}
            />
          )}
        </div>

        {/* Right Menu */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '16px' }}>
            {itemCount > 0 && (
              <>
                <IconButton component={Link} to="/bag" sx={{ position: 'relative' }}>
                  <Badge
                    badgeContent={itemCount}
                    overlap="circular"
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: '#ffc107',
                        color: '#000',
                        top: 0,
                        right: 0,
                        fontSize: '0.65rem',
                      },
                    }}
                  >
                    <ShoppingCartIcon sx={{ color: '#077336' }} />
                  </Badge>
                </IconButton>
                <Box component={Link} to="/bag" sx={{ position: 'relative', display: 'flex', alignItems: 'center', ml: 1 }}>
  <animated.div style={styles}>
    <img
      src="https://storage.googleapis.com/app_darkendimg/newton.png"
      alt="Kcoin"
      style={{ width: 40, marginRight: 1, marginBottom: 10 }}
    />
  </animated.div>
  
  {/* Overlay count on top of the image in the upper left */}
  <Typography
    variant="caption"
    fontWeight={500}
    sx={{
      position: 'absolute', 
      top: 0,
      right: -10,
      color: '#ffc107',
      padding: '2px 4px',
      borderRadius: '4px'
    }}
  >
    {formatDigiBeans(koffieItems.length * 4000)}
  </Typography>
</Box>


              </>
            )}
          </Box>

          {/* Profile Icon */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {userData && userData.userprof && userData.userprof.imageurl ? (
                <Avatar
                  alt="Nickname"
                  src={userData.userprof.imageurl}
                  sx={{ width: 40, height: 40 }}
                  onClick={toggleDrawer1}
                />
              ) : (
                <Avatar
                  alt="Nickname"
                  src="{profileimage}"
                  sx={{ width: 40, height: 40 }}
                  component={Link}
                  onClick={toggleDrawer1}
                />
              )}
            </div>
          </Box>
        </div>
      </div>
    </div>
  )}

  {/* Children Section */}
  {isMobile && <BeanTracker />
  }
  {!isMobile && <DeskMenu />
  }
  <Box ref={childrenRef} sx={{ flex: 1, overflow: 'auto', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
  {children}
</Box>


  {isMobile && showFooter && <Toe />}
</Grid>


        <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer}
              className={classes.drawer}
              
            >
            <Paper sx={{ width: 300, maxWidth: '100%', boxShadow: 0, height: '100vh' }}>
  <MenuList>
    <MenuItem sx={{ py: 2 }}>
      <ListItemIcon>
        <Box className={classes.boxContainer}>
          <img
            className={classes.logo}
            src={
              theme.palette.mode === 'dark'
                ? 'https://storage.googleapis.com/app_darkendimg/assets/klandwhite.png'
                : 'https://storage.googleapis.com/app_darkendimg/assets/kland.png'
            }
            alt="Koffie"
          />
          <Box className={classes.ribbon}>
            <div className={classes.ribbonText}>BETA</div>
          </Box>
          <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 700 }}>
            KOFFIELAND™
          </Typography>
        </Box>
      </ListItemIcon>
    </MenuItem>

    {/* Menu Items with Larger Font and Spacing */}
   

    <MenuItem onClick={handleItemClick5} sx={{ py: 2 }}>
      <ListItemIcon>
        <PublicOutlined fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>World Koffie</Typography>
      </ListItemText>
    </MenuItem>

    <MenuItem onClick={handleItemClick7} sx={{ py: 2 }}>
      <ListItemIcon>
        <MenuBookOutlinedIcon fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Koffie History</Typography>
      </ListItemText>
    </MenuItem>

    <MenuItem onClick={handleItemClick9} sx={{ py: 2 }}>
      <ListItemIcon>
        <ConfirmationNumberOutlined fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Bean Exchange</Typography>
      </ListItemText>
    </MenuItem>

    <MenuItem onClick={handleItemClick12} sx={{ py: 2 }}>
      <ListItemIcon>
        <JoinRightOutlined fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Beanheadz</Typography>
      </ListItemText>
    </MenuItem>

    <MenuItem onClick={handleItemClick11} sx={{ py: 2 }}>
      <ListItemIcon>
        <LiveHelpOutlined fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>How it Works</Typography>
      </ListItemText>
    </MenuItem>

    <MenuItem onClick={handleItemClick8} sx={{ py: 2 }}>
      <ListItemIcon>
        <ReceiptOutlined fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Blockchain Coffee</Typography>
      </ListItemText>
    </MenuItem>
    
    <MenuItem onClick={handleItemClick14} sx={{ py: 2 }}>
      <ListItemIcon>
        <EmailOutlinedIcon fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Messages</Typography>
      </ListItemText>
    </MenuItem>
    <MenuItem onClick={handleItemClick10} sx={{ py: 2 }}>
      <ListItemIcon>
        <CommentOutlined fontSize="medium" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Feedback</Typography>
      </ListItemText>
    </MenuItem>

    <Divider sx={{ my: 2 }} />

    <MenuItem sx={{ py: 2 }}>
      <ListItemIcon>
        <Display />
      </ListItemIcon>
    </MenuItem>

    <MenuItem sx={{ py: 2 }}>
      <ListItemText>
        <Typography variant="h6" color="text.secondary">
          <LogoutButton />
        </Typography>
      </ListItemText>
    </MenuItem>
  </MenuList>
</Paper>

            </Drawer>

            <Drawer 
              anchor="right"
              open={drawerOpen1}
              onClose={toggleDrawer1}
              className={classes.drawer}
            >
               <Box sx={{ width: 350, padding: 0 , mt: 8}}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0 }}>
                    <Avatar
                      alt={userData?.userprof?.Nickname}
                      src={userData?.userprof?.imageurl}
                      sx={{ width: 90, height: 90 }}
                      onClick={handleItemClick13}

                    />
                   
                  </Box>  
                  <Box sx={{ display: 'flex', justifyContent: 'center', ml: 0 }}>
                    <Typography variant="caption" sx={{ textAlign: 'center', fontWeight: 400 }}>
                    go to profile
                  </Typography>
                  </Box>
                  {/*go to user profile */}
                  
                  
                  
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Typography variant="h5" sx={{fontWeight: 700}}>{userData?.userprof?.Nickname}</Typography>
                  </Box>
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={4}>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={handleItemClick1}>
  <ShoppingBagOutlined  fontSize='medium'/>
        <Typography variant="body1" fontWeight={700} >{userData?.userprof?.beanCount}</Typography>
        <Typography variant="body2" fontWeight={600} color='text.secondary'>Bean Bag</Typography>
  </Box>
</Grid>

<Grid item xs={4}>
 
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'  }} onClick={handleItemClick6}>
    <GradeOutlined fontSize="medium" />
    <Typography variant="body1"><Rewards triggerFetch={triggerFetch} /></Typography>
    <Typography variant="body2" fontWeight={600} color='text.secondary'>Rewards</Typography>
  </Box>
  
</Grid>

<Grid item xs={4}>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
   <Beanhead fontSize="medium" />
    <Typography variant="body2" fontWeight={700} >{userData?.userprof?.beanCount}</Typography>
    <Typography variant="body1" fontWeight={600} color='text.secondary'>Digi Beans</Typography>
  </Box>
</Grid>

                  </Grid>
                 
                  <List sx={{ fontWeight: 700 }}>
      

      <ListItem button onClick={handleItemClick3}>
        <ListItemIcon>
          <Icon><LocalCafeOutlined /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="Koffie Kit" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>

      <ListItem button onClick={handleItemClick4}>
        <ListItemIcon>
          <Icon><ChecklistRtlOutlinedIcon /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="To Do List" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>

      <ListItem button onClick={handleItemClick}>
        <ListItemIcon>
          <Icon><ListAltOutlined /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="Reports" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>
      <ListItem button onClick={handleItemClick1}>
        <ListItemIcon>
          <Icon><ShoppingBagOutlined /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="Bookmarks" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>
      <ListItem button onClick={handleItemClick1}>
        <ListItemIcon>
          <Icon><ShoppingBagOutlined /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="WishList" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>
    </List>
                </Box>
                
            </Drawer>
        
            {!isMobile && (
  <Grid 
    item 
    xs={3} 
    sx={{ 
      position: 'sticky', 
      top: 0, 
      height: '100%', 
      overflowY: 'auto', 
      p: 2, 
      scrollbarWidth: 'none', // Hides scrollbar in Firefox
      '&::-webkit-scrollbar': { display: 'none' } // Hides scrollbar in Chrome, Safari, etc.
    }}
  >
    <Box display="flex" flexDirection="column">
      <Link to="/feedback" style={{ textDecoration: 'none' }}>
        <Chip label='Feedback' variant="contained" color="success" sx={{ mb: 2, justifyContent: 'flex-end' }}/>
      </Link>
      <AudioPlayerComponent />
      <TopBean />
      <AiGen2 />
      <LatestPost />
      <Featured />
    </Box>
  </Grid>
)}

        
        
      </Grid>
    </Container>
  );
};

export default AppLayout;
