import React from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { CardMedia, TextField, Box, Icon, IconButton, TextareaAutosize, Typography, Card, Chip } from '@mui/material';
import useFileUpload from '../profile/cloud';  // Import your useFileUpload hook
import { UserContext } from '../../context/usercontext';
import { useContext, useState, useEffect } from 'react';
import { AddAPhotoRounded, CameraAltOutlined, SendOutlined } from '@mui/icons-material';
import  { GET_ALL_POSTS } from './dailypost';
import ClaimBeans from '../mods/dialogue/renewbeans';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Progress from '../mods/progress';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import BeanList from '../finance/beanlist';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { useNavigate } from 'react-router-dom';








const CustomTextareaAutosize = styled(TextareaAutosize)({
  width: '100%',
 
  borderRadius: '10px',
  fullWidth: 'true',
  padding: '1rem',
  backgroundColor: '#f5f5f5',
  border: 'none',
  fontSize: '16px',
  fontWeight: '400',
  '&::placeholder': {
    fontWeight: '100',
    border: 'none',
    fontSize: '1.5rem',
  },
});



const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      sx={{
        width: 80, // Example dimension, adjust as needed
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.75rem',
        borderRadius: '4px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        // Increase specificity here if needed
        '&.MuiAlert-root': { // This targets the root class of MuiAlert
          // Place specific overrides here
        }
      }}
    />
  );
});


const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const INSERT_ONE_POST = gql`
  mutation InsertOnePost($data: PostInsertInput!) {
    insertOnePost(data: $data) {
      author
      _id
      content
      contentType
      createdAt
      imageurl
    }
  }
`;




const PostAndUploadComponent = ({ setPostSubmitted }) => {
  const { user } = useContext(UserContext);
 const navigate = useNavigate();
  const [content, setContent] = React.useState(''); // For the post content
  const { file, previewURL, handleFileChange, uploadFile, handleRemoveImage, handleRemoveFile  } = useFileUpload(); // Use your custom hook
  const { Nickname, imageurl } = user.customData;
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showDialog1, setShowDialog1] = useState(false);
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
  const fileInputRef = React.createRef();
  // Fetch the current beanCount
  const isVideo = previewURL && (previewURL.endsWith('.mp4') || previewURL.endsWith('.mov'));

  
  const userImage = imageurl;
  
  const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
    variables: { _id: user.id },
  });
  const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
  

  useEffect(() => {
    if (beanData && beanData.userprof) {
      setBeanCount(beanData.userprof.beanCount);
    }
  }, [beanData]);

  
  

  const [insertOnePost] = useMutation(INSERT_ONE_POST, {
    update: (cache, { data: { insertOnePost } }) => {
      try {
        const existingPosts = cache.readQuery({ query: GET_ALL_POSTS });
        
       
        
        if (existingPosts && existingPosts.posts) {
          // Insert the new post at the beginning of the array
          const updatedPosts = [insertOnePost, ...existingPosts.posts];
          
          cache.writeQuery({
            query: GET_ALL_POSTS,
            data: { posts: updatedPosts },
          });
        }
      } catch (error) {
        console.error('Error updating cache:', error);
      }
    },
  });
  
 
  //navigate to the post page
  const handlePostClick = () => {
    navigate('/post');
  };


  const handleFabClick = () => {
    fileInputRef.current.click();
  };

  const handlePostAndUpload = async () => {
    // Check if user has enough beans
    console.log("Current beanCount:", beanCount); // Debugging line
  
    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
      return;
    }
  
    setIsUploading(true); // Start uploading
  
    let uploadedImageURL = null;

    if (file) {
      uploadedImageURL = await uploadFile(file, user.id);
      
      if (!uploadedImageURL) {
        console.error('File upload failed: no image URL returned');
        setIsUploading(false);
        setIsButtonClicked(false);
        return;
      }
    }
  
    // Create a new post with the uploaded image URL
    setPostSubmitted(true);
    const currentTime = new Date().toISOString();
  
    await insertOnePost({
      variables: { 
        data: {
          content,
          author: Nickname,
          imageurl: uploadedImageURL, // Use the returned URL from uploadFile
          contentType: 'koffie_post',
          createdAt: currentTime,
          userID: user.id,
          userimage: userImage,
        }
      }
    });
  
    // Decrement beanCount on the server
    await decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -5 }
    });
  
    // Decrement beanCount on the client side for immediate feedback
    setBeanCount(beanCount - 1);
    setSnackbarOpen(true);
    setIsUploading(false); // End uploading
    handlePostClick();
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
        Bean count updated! New count: {beanCount}
      </Alert>
    </Snackbar>
    
    {/* Only show image preview if previewURL exists */}
    {previewURL && (
      <Box position="relative" display="inline-block">
        {isVideo ? (
          <video
            controls
            src={previewURL}
            style={{ width: 'auto', height: 'auto', maxWidth: '100%', borderRadius: 2 }}
          />
        ) : (
          <CardMedia
            component="img"
            alt="Preview"
            image={previewURL}
            sx={{ width: 'auto', height: 'auto', maxWidth: '100%', borderRadius: 2 }} 
          />
        )}
        <IconButton
          onClick={handleRemoveFile}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'grey.900',
            backgroundColor: 'common.white',
            '&:hover': {
              backgroundColor: 'grey.100',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    )}
    
    <input
      type="file"
      ref={fileInputRef}
      style={{ display: 'none' }}
      onChange={handleFileChange}
    />

    {showDialog && <ClaimBeans onClose={() => setShowDialog(false)} />}
    
    <CustomTextareaAutosize
      fullWidth
      aria-label="medium-height"
      minRows={1}
      placeholder="Spill the beans here..."
      value={content}
      onChange={(e) => setContent(e.target.value)}
      size="medium"
    />

<Box className="fade-in" sx={{ display: 'flex', alignItems: 'center' }}>
  <Box className="fade-in" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', mt: 2 }}>
    {/* Add margin to space the icon and chip */}
    <AddPhotoAlternateOutlinedIcon 
      fontSize='large' 
      sx={{ color: 'grey.600', marginRight: 1 }}  // Adds space between icon and chip
      onClick={handleFabClick} 
    />
    
    {isUploading ? (
      <CircularProgress size={24} />
    ) : (
      <Chip
        label="Post"
        onClick={handlePostAndUpload}
        sx={{ backgroundColor: '#077336', cursor: 'pointer' }}
      />
    )}
  </Box>
</Box>

  </div>
  );
};

export default PostAndUploadComponent;
