
import { useParams } from 'react-router'

import { Card, Typography, Button, Box, CardMedia, CardContent, Divider, CardActionArea } from "@mui/material";


import { useQuery, gql } from '@apollo/client';

const GET_KOFFIE = gql `
query aKoffie($countryName: String!){
koffy (query: {countryName: $countryName}){
  countryName
    
    
    climate
    pounds
    
    Title
    _id

  }

}
`
;
export default function  WKoffie  () {
  
 
 const { countyName  } = useParams()
  const { data, error, loading } = useQuery(GET_KOFFIE, {
    variables: { countyName: countyName }
  });

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
      
    <div >
      <Box>
      
      </Box>
         
      {data.koffy.map(koffie => ( 
        <Card>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={koffie.countryName}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {koffie.Title}
            </Typography>
           
            <Typography variant="body2" color="text.secondary">
              {koffie.pounds}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button size="small" color="primary">
            Learn More
          </Button>
        </Box>
      </Card>
      ))}
         
      <Card>

    <CardActionArea>
     
      </CardActionArea> 

    
      
	
  </Card>
  
    </div>
  );
};

