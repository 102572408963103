import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { lowerCase } from 'lodash';



// Create a context
const ThemeContext = createContext();


// Export a hook to use the context
export const useTheme = () => useContext(ThemeContext);

const lightTheme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
  },
    palette: {
      type: 'light',
      primary: {
        main: '#333333',  // example color
        
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
      text: {
        primary: '#333333',
        secondary: '#727573',
      },
      padding: {
        default: '0.5rem',
        sm: '0.25rem',
        md: '0.5rem',
        lg: '1rem',
        xl: '2rem',
      },
      
      
    },
    components: {
      
      MuiCssBaseline: { // Target the CssBaseline for body styles
        styleOverrides: {
          body: {
            padding: '0px !important'
          },
          
        }
        
      }, 
      zIndex: {
        modal: 1300, // Increase if the default is not sufficient
        drawer: 1400, // Ensure this is higher than modal if the drawer needs to overlap modals
      }, 
      MuiSpeedDial: {
        styleOverrides: {
          fab: { // Target the fab component specifically
            backgroundColor: '#077336', // Replace '#yourColor' with your chosen hex color
            '&:hover': {
              backgroundColor: '#96FF00', // Darker version for hover state
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            // Define styles here
           
            // Other styles as above
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // You can add your custom styling here
            border: '1px solid #000', // White border for example
            borderRadius: 5, // Rounded corners
            textTransform: 'none !important', // use !important to ensure the styles are applied
            backgroundColor: '#3a3939', // White background for example
            color: '#000', // Black text for example
          
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            textTransform: 'none !important', // use !important to ensure the styles are applied
            backgroundColor: '#fff', // White background for example
            color: '#000', // Black text for example
           
          
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            // Custom styling for MuiDivider
            backgroundColor: '#ddd',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
           
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            // Custom styling for MuiSnackbar
            backgroundColor: '#4bab43',
            borderRadius: 5,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            // Custom styling for MuiAutocomplete
            backgroundColor: 'transparent',
            borderRadius: 15,
          },
        },
      },
      
      MuiLink: {
        styleOverrides: {
          root: {
            // Custom styling for MuiLink
            textDecoration: 'none',
            color: '#077336',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            // Custom styling for MuiFab
            backgroundColor: '#000',
            color: '#fff',
          },
        },
      },
  
      MuiTabs: {
        styleOverrides: {
          root: {
            // Custom styling for MuiTabs
            backgroundColor: "transparent",
            textTransform:  'lowercase',  // Disable text transformation
            textDecoration: 'none',
            
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&&': { // Increase specificity
              textTransform: 'none',
              fontSize: '0.7rem',
            },
          },
        },
      },
      

      MuiItem: {
        styleOverrides: {
          root: {
            // Custom styling for MuiPaper
           
          },
        },
      },
     
      MuiTextField: {
        styleOverrides: {
          root: {
            // Custom styling for MuiTextField
            color: '#000',
            label: {
              color: 'primary',
              focused: {
                color: 'primary',
              },
            },
  
          },
        },
      },
      MuiMaterialIcon: {
        styleOverrides: {
          root: {
            // Custom styling for MuiMaterialIcon
            color: '#fff',
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: { 
          root: {
            // Custom styling for MuiBottomNavigation
            backgroundColor: 'transparent',
            color: '#fff',
            padding: '0px !important',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            // Custom styling for MuiSvgIcon
            
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            elevation: 5,
            
          },
        },
      },
      MuiStack: { 
        styleOverrides: {
          root: {
            // Custom styling for MuiStack
            
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            // Custom styling for MuiLinearProgress
            backgroundColor: '#fcba03',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
           
            
          },
        },
      },
      
    MuiCard: {
        styleOverrides: {
          root: {
            // Custom styling for MuiPaper
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            borderRadius: 8,
            boxShadow: 'none',  // This is an example of a light top shadow style
           
            
          },
        },
      },
    
      MuiCardHeader: {
        styleOverrides: {
          root: {
            // Custom styling for MuiPaper
            
            borderRadius: 5,
             boxShadow: 'none', // This is an example of a light top shadow style
            transition: '0.3s',
          
          },
        },
      },
      transitions: {
        easing: {
          easeInOut: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)',
        },
        duration: {
          enteringScreen: 500, // Time in ms
          leavingScreen: 300,
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: {
            // Custom styling for MuiPaper
            
            borderRadius: 8,
             // This is an example of a light top shadow style
            transition: '0.3s',
            
          },
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            'a': {
              textDecoration: 'none',
            },
          },
        },
      },
    
    },
  });
  
  
  
  const darkTheme = createTheme({
    typography: {
      fontFamily: [
        'Open Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 14,
    },
    palette: {
      type: 'dark',
      primary: {
        main: '#fff',
      },
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      text: {
        primary: '#ffffff',
        secondary: '#bbbbbb',
      },
    },
     
    components: {
      MuiCssBaseline: { // Target the CssBaseline for body styles
        styleOverrides: {
          body: {
            padding: '10px !important'
          },
          
        }
        
      }, 
      zIndex: {
        modal: 1300, // Increase if the default is not sufficient
        drawer: 1400, // Ensure this is higher than modal if the drawer needs to overlap modals
      },
      MuiSpeedDial: {
        styleOverrides: {
          fab: { // Target the fab component specifically
            backgroundColor: '#077336', // Replace '#yourColor' with your chosen hex color
            '&:hover': {
              backgroundColor: '#077336', // Darker version for hover state
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            // Define styles here
           
            // Other styles as above
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // Custom styling for MuiButton
            border: '1px solid #ffffff', // White border
            borderRadius: 5, // Rounded corners
            textTransform: 'none !important', // use !important to ensure the styles are applied
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            textTransform: 'none !important', // use !important to ensure the styles are applied
            backgroundColor: '#fff', // White background
            
          },
        },
      },

      MuiFab: {
        styleOverrides: {
          root: {
            // Custom styling for MuiFab
            backgroundColor: '#000',
            
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#077336',  // Make it inherit the color
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            // Custom styling for MuiLink
            textDecoration: 'none',
            color: '#077336',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            // Custom styling for MuiDivider
            backgroundColor: '#fff',
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            // Custom styling for MuiLinearProgress
            backgroundColor: '#fcba03',
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: { 
          root: {
            // Custom styling for MuiBottomNavigation
            backgroundColor: 'transparent',
            color: '#fff',
            backgroundColor: '#1e1e1e',
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            // Custom styling for MuiSnackbar
            backgroundColor: '#4bab43',
            borderRadius: 5,
            padding: '0.5rem',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            // Custom styling for MuiTabs
            backgroundColor: '#121212',
            textTransform: 'none',  // Disable text transformation
          },
        },
      },
      MuiTab: {  // Add this block to override the MuiTab styling
        styleOverrides: {
          root: {
            textTransform: 'none',  // Disable text transformation
            fontSize: '0.7rem',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            // Custom styling for MuiAutocomplete
            backgroundColor: 'transparent',
            borderRadius: 15,
          },
        },
      },
    
    
      MuiItem: {
        styleOverrides: {
          root: {
            // Custom styling for MuiPaper
            backgroundColor: '#1e1e1e',
          },
        },
      },
     
      MuiMaterialIcon: {
        styleOverrides: {
          root: {
            // Custom styling for MuiMaterialIcon
            
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            // Custom styling for MuiSvgIcon
            
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            // Custom styling for MuiPaper
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            borderRadius: 5,
            boxShadow: 'none', // This is an example of a light top shadow style
            
           
          },
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: {
            // Custom styling for MuiPaper
            borderRadius: 8,
            boxShadow: 'none',  // This is an example of a light top shadow style
            transition: '0.3s',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            elevation: 5,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
           
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            // Custom styling for MuiStack
            backgroundColor: '#1e1e1e',
          },
        },
      },
      MuiCheckCircleIcon: {
        styleOverrides: {
          root: {
            // Custom styling for MuiCheckCircleIcon
            color: '#98fa67',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            // Custom styling for MuiTextField
            color: '#fff',
            label: {
              color: 'primary',
              focused: {
                color: 'primary',
              },
            },
  
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
  
            
          },
        },
      },
      transitions: {
        easing: {
          easeInOut: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)',
        },
        duration: {
          enteringScreen: 500, // Time in ms
          leavingScreen: 300,
        },
      },
      
    },
   
    overrides: {
      MuiCssBaseline: {
        '@global': {
          'a': {
            textDecoration: 'none',
          },
        },
      },
    },
  });
  
  
// Function to create a theme based on the mode
const getTheme = (isDarkMode) => createTheme({
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    primary: {
      main: isDarkMode ? '#90caf9' : '#1976d2',  // Customize primary color
    },
    secondary: {
      main: isDarkMode ? '#f48fb1' : '#dc004e',  // Customize secondary color
    },
    typography: {
      fontFamily: [
        'Open Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  },

  components: {
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          backgroundColor: isDarkMode ? '#fff' : '#000',  // SpeedDial background white for dark mode
          color: isDarkMode ? '#000' : '#fff',  // "+" icon color black for dark mode
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: isDarkMode ? '#000' : '#fff',  // Paper background color
          color: isDarkMode ? '#fff' : '#333',  // Paper text color
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#000' : '#fff',  // Drawer background color
            color: isDarkMode ? '#fff' : '#333',  // Drawer text color
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#000' : '#fff',  // Box background color
            color: isDarkMode ? '#fff' : '#333',  // Box text color
          },
        },
      },
    },
  },
});



// Custom theme provider component
export const CustomThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  // Get the theme object based on the current mode
  const theme = getTheme(isDarkMode);

  // Function to toggle the theme mode
  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
