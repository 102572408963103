import { useState, useEffect, useContext } from 'react';
import Web3 from 'web3';
import { CoffeeTokenAddress } from './constants';
import Artifacts from './contracts/KupToken.json'
import { Web3ModalContext } from '../constants/Web3ModalProvider'; // import your Web3ModalContext
import { set } from 'lodash';

const useKupToken = (userAddress) => {
  const [initiateRequest, setInitiateRequest] = useState(() => () => {});
  const [setFarmer, setSetFarmer] = useState(() => () => {});
  const [setRoaster, setSetRoaster] = useState(() => () => {});
  const [setShipper, setSetShipper] = useState(() => () => {});
  const [farmerSupplySubmit, setFarmerSupplySubmit ] = useState(() => () => {});
  const { web3 } = useContext(Web3ModalContext); // Get the web3 instance from your Web3ModalContext
  const [totalCherries, setTotalCherries] = useState(0);
  const [budding, setBudding] = useState(() => () => {});
  const [processStep, setProcessStep] = useState(1);
  const [farmerProcess, setFarmerProcess] = useState(() => () => {});
  const [fertilizing, setFertilizing] = useState(() => () => {});
  const [flowering, setFlowering] = useState(() => () => {});
  const [drying, setDrying ] = useState(() => () => {});
  const [acceptContract, setAcceptContract] = useState(() => () => {}); // Add this line
  const [startRoasting, setStartRoasting] = useState(() => () => {}); // Add this line
  const [addToBasket, setAddToBasket] = useState(() => () => {}); // Add this line
  const [balance, setBalance] = useState(0);
  const [farmerSupply, setFarmerSupply] = useState(null);


  

  useEffect(() => {
    const fetchTokenData = async () => {
      // The address of the contract you want to interact with
      const contractAddress = CoffeeTokenAddress.Contract[51];

      // The ABI of the contract
      const contractABI = Artifacts.abi;

      // Create a new contract instance
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      // Convert the user address to the checksummed format
      const checksummedAddress = web3.utils.toChecksumAddress(userAddress);

      try {
        // Get the initiateRequest function of the contract
        const initiateRequestFunction = async () => {
          const gas = await contract.methods.initiateRequest().estimateGas({ from: checksummedAddress });
          await contract.methods.initiateRequest().send({ from: checksummedAddress, gas });
        };
        setInitiateRequest(() => initiateRequestFunction);



        // Get the acceptContract function of the contract
      const acceptContractFunction = async () => {
        const gas = await contract.methods.acceptContract().estimateGas({ from: checksummedAddress });
        await contract.methods.acceptContract().send({ from: checksummedAddress, gas });
      };
      setAcceptContract(() => acceptContractFunction);

      //Start Roasting Process
      const startRoastingProcess = async () => {
        const gas = await contract.methods.startRoasting(processStep).estimateGas({ from: checksummedAddress });
        await contract.methods.startRoasting(processStep).send({ from: checksummedAddress, gas });
      };
      setStartRoasting(() => startRoastingProcess);
        

        // Get the addToBasket function of the contract
const addToBasketFunction = async (cherriesAmount) => {
  const gas = await contract.methods.addToBasket(cherriesAmount).estimateGas({ from: checksummedAddress });
  await contract.methods.addToBasket(cherriesAmount).send({ from: checksummedAddress, gas });
};
setAddToBasket(() => addToBasketFunction);

        //Get Budding
        const getBuddingStatus = async () => {
          const result = await contract.methods.budding().call();
          setBudding(result);
        };
        getBuddingStatus();

        //Get Drying Process Step
       //Get Budding
       const getDryingStatus = async () => {
        const result = await contract.methods.drying().call();
        setDrying(result);
      };
      getDryingStatus();

      //Get farmer Supply as an array 
      const getFarmerSupply = async () => {
        const result = await contract.methods.farmerSupply().call();
        setFarmerSupply(result);
      };
      getFarmerSupply();
      


        // Get the setRoaster function of the contract
        const setRoasterFunction = async (roaster) => {
          const gas = await contract.methods.setRoaster(roaster).estimateGas({ from: checksummedAddress });
          await contract.methods.setRoaster(roaster).send({ from: checksummedAddress, gas });
        };
        setSetRoaster(() => setRoasterFunction);

    //Get balanceOf
    

// Get balanceOf
const getBalanceOf = async () => {
  try {
    const result = await contract.methods.balanceOf(checksummedAddress, 0).call();
    console.log('Balance result:', result);
    setBalance(result);
  } catch (error) {
    console.error('Error getting balance:', error);
  }
};

getBalanceOf();
        
   //Get Fertilizing
   const getFertilizationStatus = async () => {
    const result = await contract.methods.fertilizing().call();
    setFertilizing(result);
  };
  getFertilizationStatus();

  //Get Flowering
  const getFloweringStatus = async () => {
    const result = await contract.methods.flowering().call();
    setFlowering(result);
  };
  getFloweringStatus();


// Get total cherries from the contract
const getTotalCherries = async () => {
  const result = await contract.methods.totalCherries().call();
  setTotalCherries(result);
};
getTotalCherries();


        const farmerSupplySubmit = async (strain, elevation, profile, supplyInLbs) => {
          // Ensure input types are correct
          strain = String(strain);
          profile = String(profile);
          elevation = Number(elevation);
          supplyInLbs = Number(supplyInLbs);
        
          // Log the parameters for debugging
          console.log({ strain, elevation, profile, supplyInLbs });
        
          // Execute the contract function
          try {
            const gas = await contract.methods.farmerSubmitSupply(strain, elevation, profile, supplyInLbs).estimateGas({ from: checksummedAddress });
            const receipt = await contract.methods.farmerSubmitSupply(strain, elevation, profile, supplyInLbs).send({ from: checksummedAddress, gas });
            console.log(receipt);
          } catch (error) {
            console.error("Error in sending transaction", error);
          }
        };
        
        setFarmerSupplySubmit(() => farmerSupplySubmit);

        // Add to Basket
        const addToBasket = async (cherriesammount) => { 
          try {
            const gas = await contract.methods.addToBasket(cherriesammount).estimateGas({ from: checksummedAddress });
            const receipt = await contract.methods.addToBasket(cherriesammount).send({ from: checksummedAddress, gas });
            console.log(receipt);
          } catch (error) {
            console.error("Error in sending transaction", error);
          }
        };

        setAddToBasket(() => addToBasket);    

        // Get the setShipper function of the contract
        const setShipperFunction = async (shipper) => {
          const gas = await contract.methods.setShipper(shipper).estimateGas({ from: checksummedAddress });
          await contract.methods.setShipper(shipper).send({ from: checksummedAddress, gas });
        };
        setSetShipper(() => setShipperFunction);

      } catch (error) {
        console.error('An error occurred:', error);
      }

      const farmerProcessFunction = async () => {
        console.log('farmerProcess called');
        if(contract){
          const gas = await contract.methods.farmerProcess(processStep).estimateGas({ from: checksummedAddress });
          const receipt = await contract.methods.farmerProcess(processStep).send({ from: checksummedAddress, gas });
          if(receipt.status){
            console.log("Transaction Successful: ", receipt);
            setProcessStep((prevStep) => prevStep + 1);
          } else {
            console.error("Transaction Failed");
          }
        }
      };

      // Update farmerProcess state variable with the new function
      setFarmerProcess(() => farmerProcessFunction);
    
      
      

    };

    // Only fetch token data if web3 and userAddress are defined
    if (web3 && userAddress) fetchTokenData();
  }, [userAddress, web3]); // Add web3 to the dependency array

  return { initiateRequest, setFarmer, setRoaster,
     setShipper, fertilizing,
      farmerSupplySubmit, totalCherries,
       budding, processStep, setProcessStep, balance, farmerSupply, 
       farmerProcess, flowering, drying,
        acceptContract, startRoasting, addToBasket, setBalance
    };
};


export default useKupToken;
