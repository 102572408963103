import React from 'react';
import ReactPlayer from 'react-player';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, Box, IconButton } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import koin from '../../images/kcoin.png';

function MiniShow() {
  const [playing, setPlaying] = React.useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  return (
    <div>
       <Box sx={{display: 'flex', padding: 0, alignSelf: 'flex-end' }}>
      <Card sx = {{ minWidth: 100, borderRadius: 5, boxShadow: 0,alignContent: 'center', alignItems: 'center',padding: 0 }}>
                 
                        
        
            
            <ReactPlayer
              url="https://storage.googleapis.com/app_darkendimg/daily-grind/Play.ht%20-%20_Welcome%2C%20fellow%20Bean%20Landiers%2C%20to%20the....wav"
              playing={playing}
              controls={true}
              width="100%"
              height="50px"
              background="#b9f0ad"
              label='Good Morning'
                
               // light={<img src={koin} width={200} alt="Good Morning" />}
            />
          
         
        
      </Card>
    </Box>
    </div>
  );
}

export default MiniShow;
