import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import beans from '../../../images/coffeebeans.png';
import { useMutation, gql } from '@apollo/client';
import { UserContext } from '../../../context/usercontext';
//import './rain.css'
import { Divider, Typography, Chip, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
//import CoffeeIcon from '@mui/icons-material/LocalCafe';
import IconButton from '@mui/material/IconButton';
import beaniefy from '../../../images/beaniefy.png';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd'; // Icon for posting
import ProfileIcon from '@mui/icons-material/AccountCircle'; // Icon for AI flavor profile
import CommentIcon from '@mui/icons-material/Comment'; // Icon for replying to comments
import StoreIcon from '@mui/icons-material/Store'; // Icon for AI salesman store
import brownbean from '../../../images/brkoin.png';
import confetti from 'canvas-confetti';

const CoffeeIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="Coffee Bean" width={35} style={{paddingLeft: 10 }} />
  );
};
const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    margin: 'auto',
    marginTop: 20,
    textAlign: 'center',
    
  },
  iconButton: {
    backgroundColor: '#3ff107', // Green color, you can change it as per your theme
    color: 'black', // Text color
    
    '&:hover': {
      backgroundColor: '#a1db91',
    },
    margin: '20px 0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    
  },
  beanImage: {
    marginLeft: 'auto', // Pushes the image to the far right
    marginRight: '2rem', // Pushes the image to the far left
    
  },
});


const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
        beanCount
      
    }
  }
`; 
function createCoffeeBeanConfetti(options = {}) {
  const defaults = {
    scalar: 2,
    spread: 180,
    particleCount: 90,
    origin: { y: -0.1 },
    startVelocity: -35,
    zIndex: 1500,
  };

  // Merge provided options with defaults
  const mergedOptions = { ...defaults, ...options };

  // Define the coffee bean shape as an array of coordinate pairs
  const coffeeBeanShape = [
    [0, 0],
    [0.1, 0.1],
    [0.2, 0.2],
    [0.3, 0.3],
    [0.4, 0.4],
    [0.5, 0.5],
    [0.6, 0.6],
    [0.7, 0.7],
    [0.8, 0.8],
    [0.9, 0.9],
    [0.9, 0.8],
    [0.8, 0.7],
    [0.7, 0.6],
    [0.6, 0.5],
    [0.5, 0.4],
    [0.4, 0.3],
    [0.3, 0.2],
    [0.2, 0.1],
    [0.1, 0],
  ];

  confetti({
    ...mergedOptions,
    shape: coffeeBeanShape, // Use the custom coffee bean shape
    colors: ['#704214', '#704214', '#966c42']
  });
}

const RenewBeans = () => {
    const classes = useStyles();
    const { user } = useContext(UserContext);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const [createConfetti, setCreateConfetti] = useState(false);  // New state variable to control the visibility of the bean rain animation
  const [open, setOpen] = useState(true); // State to control the dialog
  const [updateOneUserprof, ] = useMutation(UPDATE_USERPROF);
  // Function to show the dialog
  const showDialog = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle "Get Beans" action
  const handleGetBeans = async () => {
    // Logic to claim kupa koffie (100 beans)
    const variables = {
        query: { _id: user.id },
        set: {
         
          beanCount: beanCount + "100",
          

        }
      };
    
      try {
        await updateOneUserprof({ variables });
        console.log("Successfully updated the user profile.");
      } catch (error) {
        console.log("An error occurred while updating the user profile:", error);
      }

    // Decrement the beanCount by 100
   // Show the bean rain dialog
   setCreateConfetti(true);

   // Trigger the confetti animation with the custom coffee bean shape
   createCoffeeBeanConfetti();

   // Hide the bean rain dialog after 1 second
   setTimeout(() => {
    setCreateConfetti(false);
     handleClose();
   }, 3000);
 
    // Close the dialog
    handleClose();
  };

  return (
    <div>
      {/* Your existing components and logic here */}

      {/* Button to trigger the dialog for demonstration */}
      
      
      {/* The Dialog component */}
      <Dialog open={open} onClose={handleClose} className={classes.root}>
        
        <DialogTitle>  
        <Typography variant="h5" gutterBottom sx={{fontWeight: 900, }}>
           You've Got Beans!
          </Typography>
          <div className={classes.beanImage}>
          
          <img src={beaniefy} alt="Coffee Bean" width= {80}/>
        </div></DialogTitle>
        
        <DialogContent>
          <DialogContentText>
          <List>
      <ListItem>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary="5 beans per post" />
      </ListItem>
        <Divider />
      <ListItem>
        <ListItemIcon>
          <ProfileIcon />
        </ListItemIcon>
        <ListItemText primary="1 bean is a like" />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText primary="3 beans to per comment" />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary="50 beans for every item added to your AI salesman store" />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary="200 beans for every item added to Smart smarket" />
      </ListItem>
      <Divider />
    
    </List>  
    
     </DialogContentText>
        </DialogContent>
        
        <DialogActions className={classes.dialogActions}>
        <div className={classes.buttonContainer}>
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <Chip
          sx={{ mt: 2, backgroundColor: '#ffc107', color: '#000', boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)', borderRadius: 5 }}
          label="Claim 100 Beans"
          icon={<CoffeeIcon />}
          variant="outlined"
          onClick={handleGetBeans}
          size='lg'
        />
      </Box> 
      
      
      </div>
     
    </DialogActions>
      </Dialog>
       {/* The Dialog component for raining beans */}
       <Dialog open={createConfetti} fullWidth={true} maxWidth={"md"}>
      <div >
        <div className="centered-message">
          <Typography variant="h3" gutterBottom sx={{fontWeight: 500, color: '#fff'}}>
           "Congrats, your beans are in your bag, grind on"
          </Typography>
        </div>
      </div>
    </Dialog>
    </div>
  );
};

export default RenewBeans;
