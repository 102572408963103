import React, { createContext, useContext, useState, useEffect } from 'react';

const WelcomeContext = createContext();

export const WelcomeProvider = ({ children }) => {
    const [hasBeenWelcomed, setHasBeenWelcomed] = useState(() => {
        // Initialize from session storage to maintain state across refreshes
        return sessionStorage.getItem("hasBeenWelcomed") === "true";
    });

    useEffect(() => {
        if (!hasBeenWelcomed) {
            sessionStorage.setItem("hasBeenWelcomed", "true");
            setHasBeenWelcomed(true);
        }
    }, [hasBeenWelcomed]);

    return (
        <WelcomeContext.Provider value={{ hasBeenWelcomed, setHasBeenWelcomed }}>
            {children}
        </WelcomeContext.Provider>
    );
};

export const useWelcome = () => useContext(WelcomeContext);
