import { gql, useQuery}from '@apollo/client'




const GET_KOFFIES = gql `

query{

koffies {

  

  
Rank

MetricTons
  KilogramBags
    Country
    Name
    Pounds
    Rank
    Title
    _id


}

}
`
;

const GET_KOFFIE = gql `

query aKoffie($_id: ObjectId!){

koffy (query: {_id: $_id}){

  

  Country
    KilogramBags
    MetricTons
    Name
    Pounds
    Rank
    Title
    _id

  }

}
`
;

export const useKoffie = (_id) => {
  const { data, error, loading } =useQuery(GET_KOFFIE, {
    variables: {
      _id
      
    }
  });

    return {
      data,
      error,
      loading,
    };
};

export const GetKoffie = () => {
  const { data, error, loading } =useQuery(GET_KOFFIES, {
    
  });

    return {
      data,
      error,
      loading,
    };
};


