import React, { useContext, useCallback } from "react";
import { Web3ModalContext } from "./Web3ModalProvider";
import Chip from "@mui/material/Chip";

const Wallet = () => {
  function ellipseAddress(address, width = 4) {
    return `${address?.slice(0, width + 2)}...${address?.slice(-width)}`;
  }

  const { account, connect, disconnect } = useContext(Web3ModalContext);

  const handleConnectWallet = useCallback(() => {
    connect().catch((error) => {
      if (error.message === "Modal closed by user") {
        console.log("User closed the modal.");
      } else {
        console.error(error);
      }
    });
  }, [connect]);

  const handleDisconnectWallet = useCallback(() => {
    disconnect(); // Ensures disconnect without page reload
  }, [disconnect]);

  const accountLabel = !account ? "Connect Wallet" : ellipseAddress(account);

  return (
    <nav>
      <div>
        <Chip
          label={accountLabel}
          variant="outlined"
          sx={{
            backgroundColor: "rgba(108, 92, 231, 0.8)", // Blends with #6C5CE7
            color: "#ffffff", // White text for contrast
          
            borderRadius: "16px", // Rounded corners for the chip
            
            fontWeight: 600, // Bold text for better visibility
            cursor: "pointer", // Pointer cursor for interactivity
            "&:hover": {
              backgroundColor: "rgba(108, 92, 231, 1)", // Darker on hover
            },
          }}
          onClick={!account ? handleConnectWallet : handleDisconnectWallet}
        />
      </div>
    </nav>
  );
};

export default Wallet;
