import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useQuery,  } from '@apollo/client';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { Icon, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import StoryCard from '../components/mods/comments';
import {  Drawer } from '@mui/material'; 
import { UserContext } from '../context/usercontext';
import { useContext, useState } from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { makeStyles } from '@mui/styles';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Timeline , TimelineSeparator, TimelineContent,  } from '@mui/lab';
import  AiGen2  from '../components/finance/adds/aifeed2';
import StoreAdd from '../components/finance/adds/storeadd';
import RoasterAd from '../components/finance/adds/roasteradd';
import FarmerAd from '../components/finance/adds/farmeradd';
import Featured from '../components/finance/adds/featured';
import FactGen from '../components/mods/factgen';
import Darkswipe from '../components/mods/darkswipe';

import { Divider, useMediaQuery } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Progress from '../components/mods/progress';
import DailyGrind from './DailyGrind';
import ChronButtons from '../components/mods/chronbuttons';
import DailyGrindDesk from './DailyGrindDesk';



const useStyles = makeStyles(() => ({
  root: {
    
    borderRadius: 0,
    boxShadow: 10,  // This is an example of a light top shadow style
      transition: '0.3s',
      backgroundColor: 'transparent !important',
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    width: '100%',
    minHeight: 500,
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  commentsContainer: {
    maxHeight: '400px',  // Set a max height that works for you
    overflowY: 'auto',  // Enable vertical scrolling
  },
  stickyArticle: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: 'white',  // Set a background color to cover any content scrolling behind it
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.0rem',
  
  },
}));


const GET_STORYS = gql`
    query{
        krons (sortBy: YEAR_ASC, ){
            year 
            What
            ImageUrl
            countryName
            How
            _id
        }
    }
`;


const GET_KOFFIE = gql`
query($countryName: String!){
  product(query: {countryName: $countryName}){
    Title
    _id
    description1
    description2
    imageurl
    price
    countryName
  }
}
`;


export default function Chron(kron) {
  const { user } = useContext(UserContext);
  const { data, error, loading } = useQuery(GET_STORYS);
  const [yearFilter, setYearFilter] = React.useState("");
  const [sortedData, setSortedData] = React.useState([]);
  const [expandedCards, setExpandedCards] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const isMobile = useMediaQuery('(sm)');

  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  React.useEffect(() => {
    if (data) {
      let filteredData = data.krons.filter((kron) => kron.year.includes(yearFilter));
      setSortedData(filteredData.sort((a, b) => b.year - a.year));
    }
  }, [data, yearFilter]);

  const handleOpen = (id) => {
    setOpen(prev => ({ ...prev, [id]: true }));
  };

  // Function to close a specific drawer
  const handleClose = (id) => {
    setOpen(prev => ({ ...prev, [id]: false }));
  };


  if (loading) return <p><Progress/></p>;
  if (error) return <p>Error :</p>;

  const ads = [<AiGen2/> , <Featured/>,  /*... and so on*/];
  const randomAd = ads[Math.floor(Math.random() * ads.length)];

  return (
    
    <Box sx={{ flexGrow: 1, mt:2 }}>
     
      <Box sx={{  justifyContent: 'center', marginTop: 2 }}>
      {!isMobile ? ( <DailyGrindDesk /> ) : ( <DailyGrind /> )}
      </Box>
      
      <Box sx={{ display: 'flex',  marginTop: 0, padding: 1 }}>
      <TextField
      variant='outlined'
      fullWidth
      size='small'
        type="text"
        value={yearFilter}
        onChange={(e) => setYearFilter(e.target.value)}
        placeholder="Filter by Year"
        sx={{
          mb: 0,
          mt: 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
          },
        }}
      />
      </Box>
      <Timeline 
  sx={{
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
    },
  }}
>
  {sortedData.map((kron, index) => (
    <TimelineItem key={kron._id}>
      <TimelineSeparator>
        <Avatar alt="Remy Sharp" src={kron.ImageUrl} onClick={() => handleOpen(kron._id)} />
      </TimelineSeparator>
      <TimelineContent>
        <Card className={classes.root}>
          <div style={{ position: 'relative' }}>
            {kron.ImageUrl ? (
              <CardMedia
                component="img"
                image={kron.ImageUrl}
                alt="Chronicle Image"
                onClick={() => handleOpen(kron._id)}
              />
            ) : (
              <CardContent sx={{ height: 300, borderRadius: 10, boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)', backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/vids/questioncover.png'})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }} >
                <Typography variant="hr" color="white" fontWeight={600} onClick={() => handleOpen(kron._id)}>
                  Add Image to StoryCard
                </Typography>
              </CardContent>
            )}
            {/* Year text with text shadow */}
            <Typography
              variant="h4"
              style={{
                fontWeight: 800,
                position: 'absolute',
                top: 10, // Adjust the positioning as needed
                left: 10, // Adjust the positioning as needed
                color: 'white',
                textShadow: '2px 2px 4px rgba(0,0,0,0.9)', // This adds the shadow to the text
              }}
            >
              {kron.countryName}
            </Typography>
          </div>
          <CardHeader
            onClick={() => handleOpen(kron._id)}
            title={
              <Typography style={{ fontSize: '0.975rem', fontWeight: 700, color: '#077336' }}> {/* Customize font size here */}
                {kron.year}
              </Typography>
            }
            subheader={
              <Typography style={{ fontSize: '0.875rem' }}> {/* Customize subheader font size here */}
                {kron.What}
              </Typography>
            }
          />
          <Drawer
            anchor="right"
            open={open[kron._id] || false}
            onClose={() => handleClose(kron._id)}
            
          >
            <Box sx={{ display: 'flex', width: '100%',  padding: 1,  }}>
              <IconButton onClick={() => handleClose(kron._id)}>
                <CloseOutlined />
              </IconButton>
              <Typography variant="h6" style={{ fontWeight: 700, marginLeft: 10 }}>
                {kron.countryName}
              </Typography>
            </Box>
            <Box kron={kron} sx={{  }}>
              <div style={{ padding: 10, spacing: 2 , mb:10}}>
                {kron.ImageUrl ? (
                  <Card
                    elevation={24}
                    sx={{
                      borderRadius: 0,
                      backgroundImage: `url(${kron.ImageUrl})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      maxWidth: 400,
                      height: 300,
                      marginBottom: 2,
                    }}
                  />
                ) : (
                  <CardContent>
                    <Typography variant="body1" color="textSecondary">
                      No image available. <a href="#" onClick={() => handleOpen(kron._id)}>Add an image to this story.</a>
                    </Typography>
                  </CardContent>
                )}
                <Box sx={{ marginBottom: 3 }}>
                  <Typography variant="h5" component="div" sx={{ marginBottom: 2, fontWeight: 'bold', fontSize: "2.0rem" }}>
                    {kron.countryName}
                  </Typography>
                  <Typography variant="h5" color="textSecondary">
                    {kron.year}
                  </Typography>
                </Box>
               
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="h6">How:</Typography>
                  <Typography>{kron.What}</Typography>
                </Box>
                <Divider sx={{ marginBottom: 3 }} />
                <Typography variant="h4" color="textSecondary">Related Stories</Typography>
                <Darkswipe />
                <Typography variant="h4" color="textSecondary">Koffie</Typography>
              </div>
              <StoryCard kron={kron} />
            </Box>
          </Drawer>
          <CardActions disableSpacing>
            <ChronButtons kron={kron} />
            
          </CardActions>
          
        </Card>
        <Card sx={{ boxShadow: 0, borderRadius: 0, backgroundColor: 'transparent' }}>
          {(index + 1) % 8 === 0 && (
            <>
              {/* Display the ad based on current ad index */}
              {ads[(Math.floor((index + 1) / 8) - 1) % ads.length]}
            </>
          )}
        </Card>
      </TimelineContent>
    </TimelineItem>
  ))}
</Timeline>

      
    </Box>
  
    

  );
}
