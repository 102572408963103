import { useQuery, gql } from '@apollo/client';

const GET_STORIES = gql`
  query {
    userprofs {
      Koffie_story
      Nickname
      _id
      usertype
      flavorprofile
    }
  }
`;

const GET_POSTS = gql`
  query {
    posts {
      _id
      content
      contentId
      contentType
      author
      createdAt
    }
  }
`;

export function usePostData() {
  const { loading, error, data } = useQuery(GET_POSTS);

  // Transform the data into the desired format if necessary
  const posts = data ? data.posts : [];

  return { loading, error, posts };
}

export function useStoryData() {
  const { loading, error, data } = useQuery(GET_STORIES);

  // Transform the data into the desired format if necessary
  const stories = data ? data.userprofs : [];

  return { loading, error, stories };
}
