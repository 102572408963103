import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Typography, Button, Drawer, IconButton, Container, Box, Grid, useMediaQuery, CardActionArea, CardMedia,
  Dialog, DialogTitle, DialogContent
 } from '@mui/material';
import { Link , useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';
import PortalFooter from '../components/portal/pfooter';
import { List, ListItem, ListItemText } from '@mui/material';
import { isMobile } from 'web3modal';
import { Avatar } from '@mui/material';
import imageurl from '../images/brkoin.png';
import kcoin from '../images/kcoin.png';
import VideoPlayer from '../components/portal/portvid';
import Divider from '@mui/material/Divider';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GoogleOneTapLogin from '../components/nav/onetap';
import CoinFlip from '../components/mods/kcoin/coinflip';
import Login from '../components/nav/mongologin';
import { Close } from '@mui/icons-material';
import Display from '../components/profile/settings/displaymode';






const useStyles = makeStyles((theme) => ({
    appBar: {
      transition: 'all 0.3s ease',
      backgroundColor: 'transparent',
      boxShadow: 'none',

     
    },
    appBarScrolled: {
      
      
      border: '0px solid green',
      
      color: '#000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      padding: '0 1rem',
      transition: 'all 0.3s ease',
     
  
      
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      
    },
    desktopMenu: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      backgroundColor: 'transparent',
      transition: 'all 0.6s ease',
      
  
    },
    logo: {
      width: 60,
      
    },
  
  bec: {
    width: 300,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    },
    kaaf: {
        maxWidth: '100%',
        },
        boxContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(1),
            borderRadius: 5,
            boxShadow: 10,
            position: 'relative', // Add position relative for absolute positioning of ribbon
          },
          logo: {
            width: 50,
            borderRadius: 0,
          },
          ribbon: {
            position: 'absolute',
            bottom: 5, // Adjust the position of the ribbon vertically
            left: 50, // Adjust the position of the ribbon horizontally
           
            padding: theme.spacing(.05),
            borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
            borderWidth: 1, // Adjust border width
            borderStyle: 'solid', // Adjust border style
            borderColor: '#fff', // Adjust border color
            
           
          },
          ribbonText: {
          
            fontWeight: 900, // Adjust the font weight of the ribbon text
            fontSize: 10, // Adjust the font size of the ribbon text
          },
          tooltipContent: {
            padding: theme.spacing(2),
          },
  }));

  const coffeeBeans = [
    {
      title: 'Ethiopian Yirgacheffe',
      description: 'Transform your coffee purchases into digital beans, fostering transparency and fairness in the coffee trade.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/addisbean-tran.webp',
      description: 'Discover the birthplace of coffee with Ethiopia s rich, floral brews, known for their complex flavors and bright acidity. This uniquely aromatic coffee carries notes of jasmine, berries, and citrus, delivering a captivating cup every time.',
      digibeans: 'Claim 4k Digital Coffee beans and use them as your coffee land currency when you purchase this coffee.',
      price: '23',
      coffeebag : 'https://storage.googleapis.com/app_darkendimg/assets/whitebrazil.png'
    },
    {
      title: 'Colombian Supremo',
      description: 'Implement transparent and trustless smart contracts to ensure Good Trade™ and secure transactions.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/bogotabean-tran.webp',
      description: 'Experience the smooth, mild flavor of Colombian Supremo, a classic coffee known for its rich aroma and balanced acidity. This medium-bodied brew offers a sweet, nutty taste with hints of chocolate, making it a favorite among coffee lovers.',
      digibeans: 'Claim 4k Digital Coffee beans and use them as your coffee land currency when you purchase this coffee.',
      price: '22',
      coffeebag : 'https://storage.googleapis.com/app_darkendimg/assets/whitebrazil.png'
    },
    {
      title: 'Costa Rican Tarrazu',
      description: 'Create personalized shopping carts, automate sales, and receive payments through Zelle and Cash App.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/costacoffee-tran.webp',
      description: 'Indulge in the bright, clean taste of Costa Rican Tarrazu, a high-quality coffee grown in the lush mountains of Costa Rica. Known for its bright acidity and full body, this coffee offers a sweet, fruity flavor with hints of chocolate and caramel.',
      digibeans: 'Claim 4k Digital Coffee beans and use them as your coffee land currency when you purchase this coffee.',
      price: '22',
      coffeebag : 'https://storage.googleapis.com/app_darkendimg/assets/whitebrazil.png'
    },
    
  ];

  const features = [
    { 
      title: 'Digitized Koffie Beans', 
      description: 'Transform your coffee purchases into digital beans, fostering transparency and fairness in the coffee trade.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/kcoin.png' 
    },
    { 
      title: 'Smart Contracts', 
      description: 'Implement transparent and trustless smart contracts to ensure Good Trade™ and secure transactions.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/contricon.png' 
    },
    { 
      title: 'AI Salesbot', 
      description: 'Create personalized shopping carts, automate sales, and receive payments through Zelle and Cash App.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/boticon.png' 
    },
    { 
      title: 'AI Koffie Connoisseur', 
      description: 'Experience our groundbreaking AI Connoisseur for personalized coffee recommendations and enhanced experiences.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/aicon.png' 
    },
    { 
      title: 'Direct Chat with Producers', 
      description: 'Communicate directly with coffee producers to build better relationships and transparency.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/chaticon.png' 
    },
    { 
      title: 'Digi Beans for Social Interactions', 
      description: 'Use Digi Beans to post, like, and advertise your products, increasing visibility within the community.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' 
    },
    { 
      title: 'Posts from Your Favorite Shop', 
      description: 'Stay updated with the latest posts and offers from your favorite coffee shops.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' 
    },
    { 
      title: 'History of Koffie', 
      description: 'Dive into the rich history and culture of coffee through our comprehensive platform.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/historyicon.png' 
    },
    { 
      title: 'Koffie EDU', 
      description: 'Access educational content about coffee, from cultivation to brewing techniques.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/products/smkontract.png' 
    },
   
  ];
  
  const categories = [
    {
      category: "Koffieland Ecosystem Benefits",
      features: [
        {
          title: "Empowering Producers",
          description: "As a Koffielandeer farmer, enhance the value of your product and engage in the value-added side of the industry with revolutionary technology. Communicate with consumers in real-time, provide updates on the process, and offer Koffielandiërs a seed-to-cup experience. Increased farmgate value",
          footer: "Unite Your Cup with Koffieland",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        {
            title: "Engaging for Drinkers",
            description: "An immersive and interactive coffee experience for drinkers. Enjoy personalized recommendations, interactive features, and a vibrant community of coffee enthusiasts. Whether you enjoy a plain ole cup or are a coffee connoisseur, we are  Koffielandiërs.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Boosted Sales for Shops",
            description: "Koffieland participating shops can allow Koffielandiërs to redeem drinks with DigiBeans, increasing engagement and profitability by reducing reliance on big koffie. Use Beans to interact with the Koffie ecosystem. Interact with users and businesses, and be among the first to join Koffieland.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          
          {
            title: "Rewards for Baristas",
            description: "Baristas are the unsung heroes of the industry, the mouthpiece, and the frontline. Reward baristas with DigiTips for their skills and dedication through our unique reward system. Earn recognition and highlight their vital role in creating an exceptional coffee experience.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Roasters Delight",
            description: "Become a Koffielandeer Roastmaster and expand your reach. Reduce overhead and create greater value while spreading your unique footprint. Engage with our community, enhance your brand, and take part in revolutionizing the coffee industry.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        // Add more features as needed
      ]
    },
    {
      category: "BEC",
      features: [
        {
          title: "Bean Exchange Commission",
          description: " Overseeing and ensuring the GOOD and transparent movement of beans within the Koffie revolution. Each bean is securely represented as a blockchain token, guaranteeing authenticity and traceability.",
          footer: "Discover",
          imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
        },
        {
            title: "Mission",
            description: "Our mission is to disrupt the koffie industry by partnering with coffee-producing countries. We value every coffee cherry at one pennyat farm gate, enhancing the experience and value for both producers and consumers, and enriching the entire coffee ecosystem.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        {
            title: "Good Trade™ Smart Contracts",
            description: "Ensure transparency and goodness in every transaction from farm to cup with our Proof of Concept smart contract deployed on the XDC testnet. This contract monitors and manages lots based on 4K beans per pound.",
            footer: "Discover",
            imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
          },
          {
            title: "DigiBeans for social interactions",
            description: "Use DigiBeans to post, like, comment, create and advertise your products, increasing visibility within the community and driving engagement to grow your business. Stimulate Koffieland's economy, add value to the DigiBean.",
            footer: "Discover",
            imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
          },
          {
            title: "Traceability",
            description: "For consumers, this contract guarantees that the coffee they enjoy is sourced ethically and transparently. By scanning a code, they can trace their coffee's journey from the farm to their cup, reinforcing trust and encouraging responsible consumption.",
            footer: "Discover",
            imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
          },
          
          
        // Add more features as needed
      ]
    },
    {
      category: "AI-Powered Features",
      features: [
        {
          title: "Smart Storefront",
          description: "Leverage the power of our AI Salesbot to effortlessly manage your sales. Simply add your items and let our expertly engineered AI handle the rest, driving sales and maximizing your revenue.",
          footer: "Explore",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        {
            title: "AI Koffie Connoisseur",
            description: " Sip smarter! Get coffee tips and advice perfectly brewed to match your taste. Discover a whole new world of coffee, one personalized suggestion at a time.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Generated Ads",
            description: "Let our Ad Bot generate your ads effortlessly. Use your DigiBeans to boost ad views and watch your sales soar! Engage with more customers and maximize your reach.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Empowerment",
            description: "Empower people without access to new AI technology by building tailored AI services for various industries, enabling equitable technological advancement.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        
        // Add more features as needed
      ]
    },
    {
      category: "Journey",
      features: [
        {
          title: "Company",
          description: "Founded in 2012 as Good Kupa Koffie, we discovered the hardships faced by koffie farmers. Dedicated to disrupting unfair trade practices, we’ve been on a mission to make a change. This mission has evolved into an unexpected journey, leading us to create Koffieland and transform the coffee industry.",
          footer: "Join Us",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        
        {
            title: "Where We're Coming From",
            description: "Rediscovering our early childhood koffie roots, we learned to grow, process, and roast coffee, ultimately opening a coffee shop and roastery. Our journey started with a passion for coffee and a commitment to fairness.",
            footer: "Join Us",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Where We're Going",
            description: "Leveraging my extensive IT experience and corporate knowledge, I created Koffieland. Our goal is to transform the coffee industry with innovative technology and transparent practices.",
            footer: "Join Us",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "The Journey Continues",
            description: "Embark on this exciting journey with us as we continue to carry out our mission. Become a part of Koffieland and help transform the coffee industry for the better. Together, we can make a meaningful impact from farm to your cup.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          
          
        // Add more features as needed
      ]
    },
    {
      category: "KaaF™ - Koffie as a Function",
      features: [
        {
          title: "Koffie Function",
          description: "Wherever it's introduced, it has spelled innovation. It has been the world's most radical drink in that its function has always been to make people think. And when people begin to think, they become agents of change.",
          footer: "Discover",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        {
            title: "Economic Function",
            description: "Using DigiBeans to increase the value of the koffie bean and put that value back in the hands of farmers with our tech. Reimagining exchange for value, empowering all stakeholders.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Social Function",
            description: "Uniting Koffielanders to transform the socio-economic landscape of the koffie industry, igniting innovation across sectors. Use your DigiBeans to like, share, and comment—spreading the word and uniting cups to challenge big coffee.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Political Function",
            description: "Partnering with koffie-growing countries, fostering economic growth, and empowering stakeholders by adding value and advocating for good trade practices.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Execute",
            description: "Continue the disruptive and innovative journey of koffie consumption with Koffieland. Empower change and drive the revolution in every cup.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        // Add more features as needed
      ]
    },
    
  ];
  
  const primaryText = [
    {
      primary: "Proof of Concept (MVP)",
      secondary: (
        <>
          Contract Deployed: XDC Testnet<br />
          <Link to='https://explorer.apothem.network/address/0x8fE95c384ff8b6E8EEdb28d786988D39f6e3bD01#readContract' target='_blank' rel='noreferrer noopener' style={{ textDecoration: 'none', color: '#077336', fontWeight: 600 }}>
            View on BlocksScan
          </Link><br />
          Weight of Coffee: <b>23K lbs</b><br />
          Quantity of Beans: <b>92,000,000</b>

        </>
      )
    },
    {
      primary: "Calculation Details",
      secondary: (
        <>
          Cherries per Pound: 2,000<br />
          Beans per Cherry: 2<br />
          Total Beans per Pound: 4,000
        </>
      )
    },
   
  ];

const CustomAppBar = ({   }) => {
  
  const classes = useStyles();
  const scrollRefs = useRef([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [scrolled, setScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isVideo, setIsVideo] = useState(false); // Set this to 'true' if you want video by default
  const scroll = (index, direction) => {
    const currentScrollRef = scrollRefs.current[index];
    if (currentScrollRef) {
      currentScrollRef.scrollLeft += direction === 'left' ? -250 : 250;
    }
  }; 
  const navigate = useNavigate();
  const googleOneTapLogin = () => {
    navigate('/login');
  }

  const [open, setOpen] = useState(false);
  const [selectedBean, setSelectedBean] = useState(null);

  // Function to handle opening the dialog
  const handleOpen = (coffeeBean) => {
    setSelectedBean(coffeeBean); // Set the selected coffee bean details
    setOpen(true); // Open the dialog
  };

  // Function to handle closing the dialog
  const handleClose = () => {
    setOpen(false); // Close the dialog
    setSelectedBean(null); // Reset the selected coffee bean
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  const drawerItems = (
    <List>
      
      <ListItem button component={Link} to="/login" onClick={toggleDrawer(false)}>
        <ListItemText variant='h6' sx={{ fontWeight: 600}} primary="Login" />
      </ListItem>
     


    </List>
  );
  const sectionStyles = {
    padding: '60px 0',
   
    paddingLeft: '20px'
  };
  
  const scrollContainerStyle = {
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'hidden',
    
    paddingBottom: '20px',
    scrollbarWidth: 'none', // Firefox
    msOverflowStyle: 'none' // Internet Explorer 10+
  };

  return (
    <React.Fragment>
      {/* Transparent AppBar */}
      <AppBar
      sx={{backgroundColor: 'transparent', boxShadow: 'none', borderBottom: '0px solid #f0f0f0', padding: '0.1rem 0' }}
      className={clsx(classes.appBar, {
        [classes.appBarScrolled]: scrolled,
      })}
    >
      <Toolbar className={classes.toolbar}>
      <Box className={classes.boxContainer}>
      <img src='https://storage.googleapis.com/app_darkendimg/assets/kland.png' alt='kland'className={classes.logo} />
      <Box className={classes.ribbon}>
        <div  className={classes.ribbonText}>
          BETA
        </div>
        
      </Box>
     

    </Box>
        {isMobile ? (
          <>
            
              <MenuIcon onClick={toggleDrawer(true)} sx={{color: '#077336'}} fontSize="large" />
            
              <Drawer
  anchor="right"
  open={drawerOpen}
  onClose={toggleDrawer(false)}
  PaperProps={{
    style: {
      width: '100%',
      height: '100%', // Ensures it takes up full screen height
    },
  }}
>
  <IconButton onClick={toggleDrawer(false)} sx={{ alignSelf: 'flex-start' }}>
    <ArrowBack fontSize="medium" />
  </IconButton>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
    <Typography variant="h6" sx={{ fontWeight: 600 }}>
  <Login />
    </Typography>
  </Box>
</Drawer>
          </>
        ) : (
          <div className={classes.desktopMenu}>
           
              <Link to="/company" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant='h6' ></Typography>
              </Link>
              <Link to="/koffieland" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='h6' ></Typography>
              </Link>
              <Link to="/koffieland" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='h6' ></Typography>
              </Link>
              <Link to="/koffieland" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant='h6' ></Typography>
              </Link>
              <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button variant='h5' sx={{ fontWeight: 700, width: '200px' ,color: '#000', backgroundColor: '#33f107',borderRadius: 10, border: 'none' ,'&:hover': { backgroundColor: '#33f107', color: '#000' } }}>Login</Button>
              </Link>
              <Display />
          </div>
        )}
        
      </Toolbar>
    </AppBar>

      {/* Hero Section */}
      <section style={{ }}>
      <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden',
              backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#FFD54D',  // Dark mode: dark background, Light mode: white background

       }}>
        <img
          src={
            isMobile
              ? 'https://storage.googleapis.com/app_darkendimg/assets/beanmobile.png' // Mobile image
              : 'https://storage.googleapis.com/app_darkendimg/assets/beanteam.png' // Desktop image
          }
          alt="Hero Image"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            
            objectFit: 'cover',
            transform: 'translate(-50%, -50%)',
          }}
        />

        <Container
          style={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <Typography
            color="white"
            variant="h2"
            fontWeight={900}
            paddingTop={5}
            sx={{ mt: { xs: 10, sm: 20 }, textShadow: '2px 2px 4px rgba(0,0,0, 2)' }}
          >
            Beanheadz™
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, padding: 1 }}>
          <CoinFlip />
          </Box>
          <Typography
            color="white"
            variant="h4"
            fontWeight={900}
            paddingTop={2}
            sx={{ textShadow: '2px 2px 4px rgba(0,0,0,2)' }}
          >
            KOFFIELAND
          </Typography>
          <Typography color="white" variant="h6" sx={{ mb: 2, mt: { xs: 4, sm: 4 } }}>
            Be part of the revolutionary journey and make your coffee count.
          </Typography>
         
          <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                border: 'none',
                borderRadius: 5,
                minWidth: 200,
                color: '#fff',
                backgroundColor: '#077336',
                '&:hover': { backgroundColor: '#33f107', color: '#000' },
              }}
            >
              Discover the experience
            </Button>
          </Link>
          
        </Container>
      </div>
    </section>

   <Divider />

      <section style={sectionStyle()}>
      
          <Container
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 0,
    paddingLeft: 0,
    paddingRight: 0,
    
  }}
>
  <Grid container spacing={0}>
    {coffeeBeans.map((coffeeBean, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Card
          sx={{
            height: '100%',
            width: '100%',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 0,
            boxShadow: 0,
          }}
        >
          {/* CardMedia for the image */}
          <CardMedia
            component="img"
            image={coffeeBean.imageurl}
            alt={coffeeBean.title}
            sx={{
              width: '100%',
              height: 400, // Set height as needed
              objectFit: 'cover',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 5,
            }}
          />

          {/* CardContent for the text */}
          <CardContent
            sx={{
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h5"
              component="div"
              fontWeight={700}
              sx={{ mb: 2 }}
            >
              {coffeeBean.title}
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {coffeeBean.description}
            </Typography>

            <Button
              variant="contained"
              size="small"
              onClick={() => handleOpen(coffeeBean)} // Open dialog with selected coffee bean
              sx={{
                color: '#fff',
                backgroundColor: '#077336',
                borderRadius: 4,
                border: 'none',
                boxShadow: 0,
                '&:hover': { backgroundColor: '#33f107', color: '#fff' },
              }}
            >
              Learn More
            </Button>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
  {/* Fullscreen Dialog */}
  {isMobile && (
  <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {selectedBean?.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 4,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {selectedBean && (
            <div>
              {/* Display selected coffee bean details */}
              <CardMedia
                component="img"
                image={selectedBean.imageurl}
                alt={selectedBean.title}
                sx={{
                  width: '100%',
                  height: 400,
                  objectFit: 'cover',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  mb: 2,
                  borderRadius: 3,
                }}
              />
              
              <Divider sx={{mb: 2}} />
            

  <Card sx={{ display: 'flex', padding: 1 , borderRadius: 3}}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="body1" fontWeight={900}>
            {selectedBean.title}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
                {selectedBean.description}
              </Typography>
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 100}}
        image={selectedBean.coffeebag} 
        alt="Live from space album cover"
      />
    </Card>
  
  
  


              <Divider />
              
             
              <Typography variant="body1" sx={{ fontWeight: 800, mt: 2 }}>
    ${selectedBean.price}
  </Typography>
  <Typography variant="body2" sx={{ mt: 2, fontWeight: 500 }}>
                {selectedBean.digibeans}
              </Typography>
             
              
              {/* Additional content can be placed here */}
            </div>
          )}
         
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            <Button
              variant="contained"
              onClick={googleOneTapLogin}
              sx={{

                color: '#fff',
                backgroundColor: '#077336',
                borderRadius: 4,
                border: 'none',
                boxShadow: 0,
                '&:hover': { backgroundColor: '#33f107', color: '#fff' },
              }}>
                Create Account
              </Button>
            </Box>
        </DialogContent>
      </Dialog>
      )}
      
</Container>
{!isMobile && (
        <Dialog
        
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {selectedBean?.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {selectedBean && (
            <div>
              {/* Display selected coffee bean details */}
              <CardMedia
                component="img"
                image={selectedBean.imageurl}
                alt={selectedBean.title}
                sx={{
                  width: '100%',
                  height: 400,
                  objectFit: 'cover',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  mb: 2,
                }}
              />
              
              <Divider />
              <Box
  sx={{
    display: 'flex',
    justifyContent: 'space-between',  // Ensures image is left and price is right
    alignItems: 'center',
    gap: 2,
    padding: 1,
    
  }}
>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, borderRadius: 2, boxShadow: 0,  marginTop: 0, backgroundColor: '#f0f0f0', }}>
  <img src={selectedBean.coffeebag} alt="coffeebag" width={200} />
  </Box>
  <Typography variant="body1" sx={{ mt: 2 }}>
                {selectedBean.description}
              </Typography>
  
  
</Box>

              <Divider />
              
              <Typography variant="body2" sx={{ mt: 2, fontWeight: 500 }}>
                {selectedBean.digibeans}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 800, mt: 10 }}>
    ${selectedBean.price}
  </Typography>
             
              
              {/* Additional content can be placed here */}
            </div>
          )}
         
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                color: '#fff',
                backgroundColor: '#077336',
                borderRadius: 4,
                border: 'none',
                boxShadow: 0,
                '&:hover': { backgroundColor: '#33f107', color: '#fff' },
              }}>
                Create Account
              </Button>
            </Box>
        </DialogContent>
      </Dialog>
      )}
       
      </section>

      <div>
        
      {categories.map((category, index) => (
        <div>
         <Container><SectionHeader title={category.category} />
         <Box sx={{ justifyContent: 'center', alignItems: 'center', gap: 2, padding: 0 }}>
         <IconButton
                sx={{ zIndex: 1, }}
                onClick={() => scroll(index, 'left')}
              >
                <ArrowBackIosIcon  fontSize='small'/>
              </IconButton>
              <IconButton
                sx={{  zIndex: 1 }}
                onClick={() => scroll(index, 'right')}
              >
                <ArrowForwardIosIcon fontSize='small' />
              </IconButton>
            </Box>
         </Container>
         
        <section key={index} style={scrollContainerStyle} ref={(el) => (scrollRefs.current[index] = el)}>
         
          <Container sx={{ height: '',display: 'flex', paddingLeft: 15, gap: 2, paddingBottom: 0}}>
            {category.features.map((feature, subIndex) => (
                <Box  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, borderRadius: 0, boxShadow: 0,  marginTop: 0 }}>
              
              <Grid key={subIndex} item xs={12} sm={6}>
                <FeatureCard
                  title={feature.title}
                  description={feature.description}
                  footer={feature.footer}
                  imgSrc={feature.imgSrc}
                />
              </Grid>
              
                </Box>  
            ))}
              
          </Container>
        </section>
        </div>
      ))}
      </div>
      <section style={sectionStyle('#f0f0f0', '')}>
  <SectionHeader title="The Koffie Question" />
  <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Box
      sx={{
        gap: 3,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Use column for mobile (xs) and row for desktop (md)
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <VideoPlayer />
      
    </Box>
  </Container>
</section>



      {/* Product Line Section */}
      <section style={sectionStyle('#fff')}>
      <SectionHeader title="KaaF™ - Koffie as a function" />
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FeatureCard
              title="Join the 92M Bean Drive"
              description="Our first proof of concept contract is deployed on the XDC testnet with a quantity of 23K lbs of koffie. Fulfilling this POC contract will finance the update of our app, enable boots on the ground to onboard farmers, and support the rollout of live contracts along with the development of a native app. Join the 92M Bean Drive and be part of Koffieland's 'Unexpected Journey'." 
              footer="No Crypto Wallet Needed, this is The Blockchain"

              
              
            />
          </Grid>
          <Grid item xs={12} sm={6}>
           <CustomizableCard
      title="Smart Koffie"
      primaryText={primaryText}
      imgSrc="path_to_your_image/kcoin.png"
      imgAlt="kcoin"
    />
          </Grid>
        </Grid>
      </Container>
    </section>

      {/* Additional Sections */}
      
     
     
      <section style={sectionStyle()}>
        <SectionHeader title="Koffieland™ Features"  />
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent>
                  <Typography variant="h5" component="div" fontWeight={700}>
                  <img src={feature.imageurl} alt='iconny' width={20}/>  {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
          
       
       
        </Grid>
       
      </Container>
     
        
      </section>
      
      <section style={sectionStyle('#fff', '')}>
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PortalFooter />
        </Container>
        </section>
    </React.Fragment>
  );
};



const sectionStyle = (backgroundColor, backgroundImage) => ({
  
  backgroundImage,
  padding: '0rem',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  
  textAlign: 'center',
  position: 'relative',
  
  '@media (max-width: 600px)': {
    minHeight: 'auto', // Adjust height for mobile
    padding: '1rem 1rem', // Increase padding for mobile
   
  }
});

const gridStyle = {
    display: 'inline-block',
    paddingRight: '16px'
  };

const SectionHeader = ({ title }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left', mb: 2, mt: 5 }}>
      {isMobile ? (
    <Typography variant="body1"  fontStyle='' fontWeight={500} marked="center" paddingBottom={2}>
     {title}
    </Typography>
   ) : (
    <Typography variant="body1"  fontWeight={900} marked="center" paddingBottom={2}>
       {title}
    </Typography>
   )}
  
  </Box>
);

const FeatureCard = ({ title, description, footer,  imgSrc }) => {
    const useStyles = makeStyles((theme) => ({
        bec: {
          width: 300,
        },
        kaaf: {
          maxWidth: '100%',
        },
      }));
  const classes = useStyles();
 

  return (
    <Box
      className="flex-1 flex flex-col justify-center items-center"
      sx={{minWidth: 400, boxShadow: 5,  flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 2 }}
    >
      <Box component="header" sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          variant="h4"
          sx={{ margin: 2, fontWeight: 800,  textShadow: '2px 2px 4px rgba(0,0,0,0)', textAlign: 'center' }}
        >
          {title}
        </Typography>
       
          <Typography
            variant="h6"
            
            sx={{ fontStyle: 'oblique', color: 'text.secondary',fontWeight: 400, mb: 1, textAlign: 'center',  textShadow: '2px 2px 4px rgba(0,0,0,0)' }}
          >
            {description}
          </Typography>
      </Box>
      <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
      <Typography
        variant="body1"
        fontWeight={400}
        fontStyle='oblique'
        textDecoration='underline'
        gutterBottom
        sx={{ textDecoration: 'underline', mt: 1, textAlign: 'center',  textShadow: '2px 2px 4px rgba(0,0,0,0)' }}
      >
        {footer}
      </Typography>
        </Link>
    </Box>
  );
};



const CustomizableCard = ({ title, primaryText, secondaryText, imgSrc, imgAlt }) => {
  return (
    <Card sx={{ padding: '8px', borderRadius: 5, boxShadow: 5 }}>
      <Typography variant="h4" fontWeight={900} gutterBottom>
        {title} <img src={kcoin} alt={imgAlt} width={40} />
        
      </Typography>
      <List>
        {primaryText.map((text, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={<Typography variant="h6" fontWeight={700} fontStyle="oblique">{text.primary}</Typography>}
              secondary={
                <Typography variant="body2" fontWeight={500} fontStyle="oblique">
                  {text.secondary}
                </Typography>
              }
            />
           {}
          </ListItem>
        ))}
          <Link to='/contractdetails' target='_blank' rel='noreferrer noopener' style={{ textDecoration: 'none', color: '#077336', fontWeight: 600 }}>
        <Button variant="contained" size="large" sx={{ mb:1, mt: 1,  border: 'none', borderRadius: 5, minWidth: 200,  backgroundColor: '#077336', '&:hover': { backgroundColor: '#ffc107', color: '#000' } }}>
          View Contract Details
        </Button>
          </Link>
      </List>
     
    </Card>
  );
};
const FeatureCard2 = ({ title, description, footer,  imgSrc }) => {
    const useStyles = makeStyles((theme) => ({
        bec: {
          width: 300,
        },
        kaaf: {
            width: 300,
            height: 600
        },
        imgSrc: {
            width: 300,
            height: 300
          },
      }));
  const classes = useStyles();
    return (
      <Box
        className="flex-1 flex flex-col justify-center items-center"
        sx={{ padding: 0, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5, padding: 1 }}
      >
        <Box component="header" sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 800,  textShadow: '2px 2px 4px rgba(0,0,0,0)', textAlign: 'center' }}
          >
            {title}
          </Typography>
          {imgSrc && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
              <img src={imgSrc} alt="logo" className={classes.kaaf} />
            </Box>
          )}
          <Typography
            variant="body1"
            
            sx={{ color: 'text.secondary',fontWeight: 400, mb: 1, textAlign: 'center',  textShadow: '2px 2px 4px rgba(0,0,0,0)' }}
          >
            {description}
          </Typography>
        </Box>
        <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Button variant="contained" size="large" component="a" sx={{  border: 'none', borderRadius: 5, minWidth: 200, color: '#fff', backgroundColor: '#077336', '&:hover': { backgroundColor: '#ffc107', color: '#000' } }}>
              Get your Digi Beans
            </Button>
            </Link>
        
      </Box>
    );
    
  };

export default CustomAppBar;
