import React from 'react';
import { useQuery, useMutation, } from '@apollo/client';
import { Chip, IconButton, Button, TextField, Dialog, DialogActions, Avatar, DialogContent, DialogContentText, DialogTitle, Typography, Box,
List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { UserContext } from '../../../context/usercontext';
import GroupIcon from '@mui/icons-material/Group'; // icon for Collaborators
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // icon for Collaborating
import { useContext, useState } from 'react';
import gql from 'graphql-tag';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useSpring, animated } from 'react-spring';

   

// GraphQL Queries
const GET_COLLABORATING = gql`
 query GetCollaborating($userId: String!) {
  collaborates(query: { collaboratorID: $userId }) {
    _id
    collaboratingID
  }
}`;

const GET_COLLABORATORS = gql`
query GetCollaborators($userId: String!) {
  collaborates(query: { collaboratingID: $userId }) {
    _id
    collaboratorID
  }
}`;

// GraphQL Mutations
const INSERT_ONE_COLLABORATE = gql`
mutation InsertOneCollaborate($data: CollaborateInsertInput!) {
  insertOneCollaborate(data: $data) {
    _id
    collaboratingID
    collaboratorID
    

  }
}`;

//UserProfiles
const GET_USER_PROFILES = gql`
query GetUserProfiles($_id : [ObjectId!]) {
  userprof(query: { _id: $_id }) {
    _id
  Nickname
  imageurl
  }
}`;



const DELETE_ONE_COLLABORATE = gql`
mutation DeleteOneCollaborate($query: CollaborateQueryInput!) {
  deleteOneCollaborate(query: $query) {
    _id
    collaboratingID
    collaboratorID
  }
}`;



const CollaboratorsDialog = ({ userIds, open, onClose }) => {
  const { loading, data } = useQuery(GET_USER_PROFILES, {
    variables: { userIds },
    skip: !userIds.length,
  });

  return (
    <Box>
      
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Collaborators</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <List>
            {data?.userProfiles.map(user => (
              <ListItem key={user._id}>
                <ListItemAvatar>
                  <Avatar src={user.avatar} />
                </ListItemAvatar>
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
    </Box>
  );
};

// CollaboratorsCount Component
export const CollaboratorsCount = ({ userId }) => {
  const [openDialog, setOpenDialog] = useState(false);

const handleOpenDialog = () => setOpenDialog(true);
const handleCloseDialog = () => setOpenDialog(false);


    const { loading: loadingCollaborating, error: errorCollaborating, data: dataCollaborating } = useQuery(GET_COLLABORATING, {
        variables: { userId },
    });

    const { loading: loadingCollaborators, error: errorCollaborators, data: dataCollaborators } = useQuery(GET_COLLABORATORS, {
        variables: { userId },
    });

    if (loadingCollaborating || loadingCollaborators) return <p>Loading...</p>;
    if (errorCollaborating || errorCollaborators) return <p>Error: {errorCollaborating ? errorCollaborating.message : errorCollaborators.message}</p>;

    const collaboratingCount = dataCollaborating ? dataCollaborating.collaborates.length : 0;
    const collaboratorsCount = dataCollaborators ? dataCollaborators.collaborates.length : 0;
    const collaboratingUserIds = dataCollaborating ? dataCollaborating.collaborates.map(c => c.collaboratingID) : [];
    const collaboratorUserIds = dataCollaborators ? dataCollaborators.collaborates.map(c => c.collaboratorID) : [];
    
    return (
      <div>
      <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: 1,
          borderRadius: 0,
          width: '100%',
      }}>
          <Box onClick={handleOpenDialog} sx={{ display: 'flex',  alignItems: 'center' }}>
              
              <Typography variant="caption" sx={{ padding: 1 }}>
                  Collabing
              </Typography>
              <Typography variant="caption" sx={{ padding: 1 }}>
                  {collaboratingCount}
              </Typography>
          </Box>
          <Box  onClick={handleOpenDialog} sx={{ display: 'flex',  alignItems: 'center' }}>
             
              <Typography variant="caption" sx={{ padding: 1 }}>
                  Collabers
              </Typography>
              <Typography variant="caption" sx={{ padding: 1}}>
                  {collaboratorsCount}
              </Typography>
          </Box>
         
      </Box>
     
  
      <CollaboratorsDialog userIds={[...collaboratingUserIds, ...collaboratorUserIds]} open={openDialog} onClose={handleCloseDialog} />
  </div>
  
    );
};

// CollaborateButton Component
export const CollaborateButton = ({ collaboratorId, collaboratingId, refetchQueries }) => {
    const { user } = useContext(UserContext);
    console.log("Collaborating ID (to collaborate with):", collaboratingId);
    console.log("Collaborator ID (current user):", collaboratorId);
    const [isBouncing, setIsBouncing] = React.useState(false);

  const bounce = useSpring({
    transform: isBouncing ? 'translateY(0px)' : 'translateY(-20px)',
    config: { tension: 500, friction: 10 },
    onRest: () => setIsBouncing(false), // Reset the animation state after it completes
  });

  const handleCollab = () => {
    setIsBouncing(true);
    insertOneCollaborate();
  };
 
    const [insertOneCollaborate, { loading, error }] = useMutation(INSERT_ONE_COLLABORATE, {
        
        variables: { data: { collaboratingID: collaboratingId, collaboratorID: collaboratorId  } },
      
        refetchQueries
    });
   
    
    return <>
     <div>
         <Box sx={{
      display: 'flex',
      
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      borderRadius: 4,
      width: '100%',
      mb: 0,
    }}>
      
     <Typography onClick={handleCollab} variant="caption" sx={{ padding: 1 }}>
      <animated.div style={bounce}>
      <img src="https://storage.googleapis.com/app_darkendimg/assets/collab.png" alt="Collaborate" width="40" height="40" />
      </animated.div>
          Collaborate
      </Typography>
    </Box>
    </div>       
    </>
};

// UncollaborateButton Component
export const UncollaborateButton = ({ collaboratorId, refetchQueries }) => {
    const { user } = useContext(UserContext);
    const [isBouncing, setIsBouncing] = React.useState(false);

  const bounce = useSpring({
    transform: isBouncing ? 'translateY(0px)' : 'translateY(-20px)',
    config: { tension: 500, friction: 10 },
    onRest: () => setIsBouncing(false), // Reset the animation state after it completes
  });

  const handleClick = () => {
    setIsBouncing(true);
    deleteOneCollaborate();
  };
  


    const [deleteOneCollaborate, { loading }] = useMutation(DELETE_ONE_COLLABORATE, {
        variables: { query: { collaboratingID: collaboratorId } },
        refetchQueries,
        
    });

    return <> 
            <div>
         <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      borderRadius: 4,
      width: '100%',
      mb: 2,
    }}>
      
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        flexWrap: 'wrap',
      }}>
        <Typography onClick={handleClick} variant="caption" sx={{ padding: 1 }}>
        <animated.div style={bounce}>
          <img src="https://storage.googleapis.com/app_darkendimg/assets/collab.png" alt="Uncollaborate" width="40" height="40" />
          </animated.div>
          Uncollaborate
          </Typography>
       
      </Box>
      
    </Box>
    </div>
    </>
};
