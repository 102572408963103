import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Card,
  CardContent,
 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  logoImage: {
    width: '70%',
    height: 'auto',
  },
}));


const menuItems = [
  {
    title: 'Company',
    items: [
      { name: 'Our mission', path: '/mission' },
      { name: 'About us', path: '/aboutus' },
      { name: 'Contact us', path: '/contact' },
      { name: 'White Paper', path: '/whitepaper' },
      { name: 'Brand Identity', path: '/brandid' },
      { name: 'Legal', path: '/legal' },
      { name: 'Careers', path: '/careers' },
      { name: 'Technology', path: '/technology' },
      
    ],
  },
  {
    title: 'KOFFIELAND™',
    items: [
      { name: 'Digi Beans', path: '/about' },
      { name: 'Rewards', path: '/rewards' },
      { name: 'Legal', path: '/legal' },
      { name: 'Careers', path: '/careers' },
    ],
  },
  
  {
    title: 'Services',
    items: [
      { name: 'Digi Beans', path: '/digi-beans' },
      { name: 'Rewards Program', path: '/rewards' },
      { name: 'Koffie', path: '/koffie' },
      { name: 'Gear', path: '/gear' },
    ],
  },
];

const termsItems = [
  { name: 'Terms', path: '/terms-of-service' },
  { name: 'Privacy', path: '/privacy-policy' },
];

function Copyright() {
  return (
    <Typography variant="body2"  align="center">
      {'© '}
      <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        Koffieland
      </RouterLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function PortalFooter() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogContent('');
  };
  const logos = [
    { id: 1, src: 'https://storage.googleapis.com/app_darkendimg/assets/kland.png', alt: 'Logo 1' },
    { id: 2, src: 'https://storage.googleapis.com/app_darkendimg/assets/kcoin.png', alt: 'Logo 2' },
    { id: 3, src: 'https://storage.googleapis.com/app_darkendimg/assets/thebec.webp', alt: 'Logo 3' },
    { id: 4, src: 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png', alt: 'Logo 4' },
  ];

  const descriptions = [
    { id: 1, title: 'Koffieland Logo', description: 'The Koffieland logo ' },
    { id: 2, title: 'DigiKoin', description: 'The DigiKoin Logo ' },
    { id: 3, title: 'The BEC', description: 'The BEC Logo' },
    { id: 4, title: 'DigiBean', description: 'The DigiBean Logo' },
  ];
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pt: 8,
        pb: 4,
        mt: 8,
        width: '100%',
        
      }}
    >
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={2}>
            <Box sx={{ textAlign: 'left', mb: 2,backgroundColor: 'transparent' }}>
              <img src="https://storage.googleapis.com/app_darkendimg/assets/kland.png" alt="logo" width={40} />
            </Box>
          </Grid>

          {menuItems.map((menu, index) => (
            <Grid item xs={12} sm={6} md={2} key={index}>
              <Typography variant="h6" marked="left" gutterBottom>
                {menu.title}
              </Typography>
              <List>
                {menu.items.map((item, subIndex) => (
                  <ListItem
                    button
                    key={subIndex}
                    component="a"
                    onClick={() => handleOpenDialog(item.name)}
                    style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer', background: 'transparent' }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}

          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Terms & Conditions
            </Typography>
            <List>
              {termsItems.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  component={RouterLink}
                  to={item.path}
                  style={{ textDecoration: 'none', color: 'inherit', background: 'transparent' }}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption" display="block" align="center">
              {'Powering the future of the Koffie Industry '}
              <RouterLink to='/kaaf' style={{ textDecoration: 'none', color: 'inherit' }}>
                KaaF
              </RouterLink>
              {' from '}
              <RouterLink to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                www.koffieland.io
              </RouterLink>
              {' is licensed by '}
              <RouterLink
                to='/'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Copyright />
              </RouterLink>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            
            aria-label="close"
            sx={{ position: 'absolute', left: 20, top: 8, color: '#077336' }}
          >
            <CloseIcon fontSize='large' />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, height: '100vh' }}>
            <Box sx={{ width: '100%', maxWidth: 800, p: 2 }}>
              <Typography variant="h2" gutterBottom fontWeight={800}>
              {dialogContent}
            </Typography>
          <Typography variant="h6"  fontStyle='oblique' sx={{ fontWeight: 400, mt: 5}}>
            {/* Here you can load the content dynamically based on the dialogContent value */}
            {dialogContent === 'About us' && <p>Koffieland is more than just a coffee company; it's a revolutionary platform that integrates blockchain technology, AI, and social interaction to transform the coffee industry. Founded by Richard Hinds, who is affectionately known as Koffieman, Koffieland aims to address the unfair treatment of coffee farmers by creating a transparent and fair trade system. Our journey began in the coffee fields, where we saw firsthand the challenges faced by farmers. Today, Koffieland connects everyone in the coffee value chain—from farmers and roasters to coffee shops and drinkers—creating a vibrant community dedicated to improving the coffee industry.</p>}
            {dialogContent === 'Our mission' && <p>Our mission is to disrupt the koffie industry by partnering with coffee-producing countries and valuing every coffee cherry at one penny, thus increasing the value of the entire ecosystem. Through the use of innovative technologies like blockchain and AI, we aim to bring transparency, fairness, and efficiency to the coffee supply chain. Koffieland empowers farmers, enhances the consumer experience, and fosters a global community united by a love for coffee. We are committed to revolutionizing the coffee industry by making it more equitable and sustainable for all stakeholders.</p>}
            {dialogContent === 'Contact us' && <p>We love to hear from our community! Whether you have a question, feedback, or just want to share your coffee journey, feel free to reach out to us. You can contact us via email at support@koffieland.com or follow us on our social media channels. Our customer service team is dedicated to providing you with the best possible support and ensuring your experience with Koffieland is exceptional.</p>}
            {dialogContent === 'White Paper' && <p>Our white paper provides an in-depth look at Koffieland’s innovative approach to transforming the coffee industry. It details our use of blockchain technology to create a transparent and fair trade system, the integration of AI for personalized coffee experiences, and our unique DigiBean economy. The white paper also outlines our mission, vision, and the steps we are taking to revolutionize the coffee value chain. Download our white paper to learn more about how Koffieland is setting new standards in the coffee industry.</p>}
            {dialogContent === 'Brand Identity' && 
            
            <Box><p>Koffieland's brand identity is rooted in our commitment to transparency, community, and innovation. Our logo, a stylized coffee leaf, symbolizes growth, sustainability, and our deep connection to coffee farming. Our brand colors—green and brown—reflect the natural origins of coffee and our dedication to environmental responsibility. Our messaging emphasizes the revolutionary impact of our platform, inviting users to join us in transforming the coffee industry. We strive to maintain a consistent and engaging brand presence across all touchpoints.</p>
                    <Container sx={{ padding: '40px 0' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Brand Identity
      </Typography>
      <Grid container spacing={4}>
        {logos.map((logo) => (
          <Grid item xs={12} sm={6} md={3} key={logo.id}>
            <Card>
              <img src={logo.src} alt={logo.alt} className={classes.logoImage} />
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ marginTop: '40px' }}>
        {descriptions.map((desc) => (
          <Card key={desc.id} sx={{ marginBottom: '20px' }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {desc.title}
              </Typography>
              <Typography variant="body1">
                {desc.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
            </Box> } 
            {dialogContent === 'Legal' && <p>At Koffieland, we are committed to maintaining the highest standards of legal and regulatory compliance. Our legal team works diligently to ensure that all our operations, from smart contracts to user interactions, adhere to applicable laws and regulations. We prioritize the protection of user data and transparency in all our dealings. For detailed information on our legal policies, including terms of service, privacy policy, and compliance measures, please visit our legal section.</p>}
            {dialogContent === 'Digi Beans' && <p>DigiBeans are the digital currency of Koffieland, designed to revolutionize the way coffee is traded and consumed. Each DigiBean represents a fraction of a coffee cherry's value, enabling transparent and fair transactions throughout the coffee value chain. Users can earn DigiBeans through various activities, such as purchasing coffee, engaging with the community, and participating in our rewards program. These DigiBeans can be used to purchase products, access exclusive content, and support coffee farmers directly.</p>}
            {dialogContent === 'Rewards Program' && <p>Our rewards program is designed to recognize and reward the contributions of all Koffieland community members. By participating in our platform, users can earn DigiBeans that can be redeemed for products, discounts, and exclusive experiences. Baristas, roasters, and coffee shops can also earn rewards for their contributions to the community, fostering a culture of appreciation and engagement. Join our rewards program and start earning today!</p>}
            {dialogContent === 'Koffie' && <p>At Koffieland, we celebrate everything about koffie. From its rich history and cultural significance to the latest trends in brewing and roasting, we are passionate about sharing our love for coffee. Our platform offers a wealth of resources, including educational content, coffee recipes, and expert advice. Whether you're a casual coffee drinker or a seasoned connoisseur, you'll find something to inspire and delight you in our Koffie section.</p>}
            {dialogContent === 'Gear' && <p>Explore our selection of coffee gear, curated to enhance your brewing experience. From high-quality grinders and espresso machines to unique coffee mugs and accessories, we offer everything you need to make the perfect cup of coffee. Our gear section features products from trusted brands and innovative new tools, ensuring you have access to the best equipment available. Equip yourself with the right tools and elevate your coffee game.</p>}
            {dialogContent === 'Rewards' && <p>Koffieland rewards are more than just perks; they are a way to engage with our community and contribute to the coffee ecosystem. By participating in activities, sharing your coffee journey, and supporting our mission, you can earn valuable rewards. These rewards include DigiBeans, exclusive discounts, access to special events, and more. Our rewards system is designed to recognize your contributions and enhance your experience with Koffieland.</p>}
            {dialogContent === 'Careers' && <p>Join the Koffieland team and be a part of a revolutionary movement in the coffee industry. We are looking for passionate individuals who are eager to make a difference and drive innovation. Whether you're interested in technology, marketing, customer service, or any other field, we offer a dynamic and supportive work environment. Explore our career opportunities and discover how you can contribute to our mission of transforming the coffee industry.</p>}
            {dialogContent === 'Technology' && <p>At Koffieland, we leverage cutting-edge technologies to ensure a seamless and secure experience for our users. Our platform is built on a robust tech stack that includes blockchain technology for transparent and secure transactions, as well as modern web frameworks and cloud services to provide high performance and reliability. This enables us to deliver innovative features and maintain the highest standards of quality and security.</p>}
            {/* Add more conditions based on your requirements */}
          </Typography>
        </Box>
          </Box>
        </DialogContent>
        
      </Dialog>
    </Box>
  );
}
