import React from 'react';
import cx from 'clsx';
import { Card, CardMedia, Avatar, Typography, Chip } from '@mui/material';
import congo from '../../../images/congobag.png';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import ethio from '../../../images/ethioflag.png';
import '../../../context/Ticker.css';
import { UserContext } from '../../../context/usercontext';
import { useContext } from 'react';



const GET_PRODUCTS = gql`
    query Products {
  products {
    Title
    _id
    description1
    description2  
    imageurl
    name
    price
  }
}
`;

export const Koffieticket = React.memo(function PlaneTicketCard() {
  
  const { user } = useContext(UserContext);

  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    
  });
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  console.log(data);

  

  return (
    <div className="ticker-container">
    <div className="ticker-wrapper">
      {data && data.products.map((product, index) => (
        <span key={index} className="ticker-item">

<Chip sx={{  width: '100%', borderRadius: '20px'}}
        avatar={<Avatar alt="Natacha" src={product.imageurl} />}
        label={product.name + " $" + product.price}
        
        
        variant="outlined"
        component={Link}
        to={`/product/${product._id}`}
      />
      
     
   
    </span>
      ))}
      {/* Duplicate the items for a continuous effect */}
      {data && data.products.map((product, index) => (
        <span key={`duplicate-${index}`} className="ticker-item">
           <Chip sx={{ height: '40px'}}>
               <Link className="ticker-link" key={product._id} to={`/product/${product._id}`}>
                   <span className="ticker-symbol">{product.name}</span>
               </Link>
               <span className="ticker-price">${product.price}</span>
           </Chip>
        </span>
      ))}
    </div>
</div>
  );
});

export default Koffieticket;