import { useAnimate, useInView, stagger } from "framer-motion";
import { useEffect } from "react";

function Framer() {
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope, { once: true }); // Trigger animation only once when in view

  useEffect(() => {
    if (isInView) {
      // Animate each list item with a staggered effect
      animate(
        "li", // Target all <li> elements inside the scope
        { opacity: 1, y: 0, scale: 1 }, // Final state: fully visible, no vertical translation, and normal scale
        { delay: stagger(0.2), duration: 0.6, ease: "easeOut" } // Stagger the animations with a 0.2s delay and 0.6s duration
      );
    }
  }, [isInView, animate]);

  return (
    <ul ref={scope} style={{ listStyle: "none", padding: 0 }}>
      <li style={{ opacity: 0, transform: "translateY(20px) scale(0.8)" }}>
        Item 1
      </li>
      <li style={{ opacity: 0, transform: "translateY(20px) scale(0.8)" }}>
        Item 2
      </li>
      <li style={{ opacity: 0, transform: "translateY(20px) scale(0.8)" }}>
        Item 3
      </li>
    </ul>
  );
}

export default Framer;
