import React, { useState, useEffect, useParams } from 'react';
import { 
Card,  List, Typography, Avatar,
Box,  CardContent
} from '@mui/material';
//import { GetKoffie } from '../../queries/koffies';
import { makeStyles } from '@mui/styles';



import { gql, useQuery, useLazyQuery } from '@apollo/client';

import Kupa from '../mods/kupa';
import Espresso from '../mods/espresso';

const GetKoffie = gql`
  query GetKoffie($When: String!) {
    darkroad(query: { When: $When }) {
      _id
      Title
      Who
      What
        When
        
        Why
        How
    }
  }
`;


const barista = () => {
    return {
      "name": "Barista",
      "description": ".",
      "parameters": {
        "type": "object",
        "properties": {
          "Title": {
            "type": "string",
            "description": "Title of Subject"
          },
          "Who": {
            "type": "string",
            "description": "Who is invold in this event."
          },
          "description": {
            "type": "string",
            "description": "a description of What happened in a whitty way."
          },
          "Country": {
            "type": "string",
            "description": "Name of Country if available"
          },

            "primaryImage": 
            {"type": "string",
            "description": "https://i.pravatar.cc/300"
          
          },
        
          "When": 
            {"type": "string",
            "description": "When did this event happen"
          
          }
        
          
        },
        "required": ["name", "Title", "description", "What", "primaryImage", "When", "Who", "Country" ]
      }
    };
  };






  function Anancy() {
    const [When, setWhen] = useState('');
    const [Pounds, setPounds] = useState('');
    const [getKoffie, { loading, data }] = useLazyQuery(GetKoffie);
    const [content, setOutput] = useState('');
    const baristaFunction = barista();  // Get the coffee function

    const handleSubmit = (e) => {
        e.preventDefault();
        getKoffie({ variables: { When } });
      };
    
      useEffect(() => {
        if (data && data.darkroad) {
          fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${"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"}`,
            },
            body: JSON.stringify({
              "messages": [
                { "role": "system", "content": "You are a Funny Personal assistant giving updates on info.  " },
                { "role": "user", "content": `Why did this happen ?: ${JSON.stringify(data.darkroad)}.` },
              ],
              
              'model': 'gpt-4',
              "temperature": 0.9,
              "function_call": baristaFunction,
              'functions': [baristaFunction],
              
            }),
          })
          .then(response => response.json())
          .then(data => {
            let output;
            if (data.choices[0].message.content) {
              // If there's content in the message, use that
              output = { description: data.choices[0].message.content };
            } else if (data.choices[0].message.function_call) {
              // If there's a function call, parse the arguments field
              output = JSON.parse(data.choices[0].message.function_call.arguments);
            }
            setOutput(output);
            console.log('Success:', data);
          })
          .catch((error) => console.error('Error:', error));
        }
      }, [data]);
  
    return (
      <div>
        <h1>Dark Road </h1>
        <p>This is a copy i can use for somthing else GQL setup.</p>
        <form onSubmit={handleSubmit}>
          <input 
            type="text" 
            value={When} 
            onChange={(e) => setWhen(e.target.value)} 
            placeholder="gql settup stil takes year" 
          />
          <button type="submit">Search</button>
        </form>
        <>
        <Kupa />
        <Espresso />
        </>
        {loading && <p>Loading...</p>}
        {content && (
          <div>
             <h2>{content.Title}</h2>
             {content.primaryImage && <img src={content.primaryImage} alt={content.primaryImage} />}
             {content.Who && <li><b>{content.Who}</b></li>}
             {content.When && <li><b>{content.When}</b></li>}
            {content.description && <li>{content.description}</li>}
            {content.What && <p>{content.What}</p>}
            {content.Why && <p>{content.Why}</p>}
            {content.How && <p>{content.How}</p>}
            

            
          </div>
        )}
      </div>
    );
  }
  
  export default Anancy;