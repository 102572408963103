
import React from 'react';
import { useContext,useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Box, Typography } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { UserContext } from '../../context/usercontext';


const GET_MESSAGES_QUERY  = gql`
 query GetMessagesForUser($query: MessageQueryInput) {
  messages(query: $query, limit: 100, sortBy: CREATEDAT_DESC) {
    _id
    senderId
    receiverId
    content
    createdAt
    isRead
    
    }
 }
`;

// Simplified example of a component to display messages
const MessageList = () => {
    const { user } = useContext(UserContext);
    const receiverId = user._id;
  
    
    const { data, loading, error } = useQuery(GET_MESSAGES_QUERY, {
        variables: { 
          query: { 
            receiverId: receiverId,
            
          } 
        }
      });
    
      console.log(user.id);
      console.log(receiverId);
       
      if (!user || user._id !== receiverId) {
        return <p>You are not authorized to view this page.</p>;
      }
      
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error </p>;
  
    return (
         <Box>
      <Typography variant="h5" gutterBottom>
        Messages
      </Typography>
      {data && data.messages.map((message) => (
        <div key={message._id}>
          {/* Render your message component or div */}
            <p>{message.senderId}</p>
            <p>{message.receiverId}</p>
          <p>{message.content}</p>
          <p>{new Date(message.createdAt).toLocaleString()}</p>
          <p>{message.isRead ? 'Read' : 'Unread'}</p>
        </div>
      ))}
    </Box>
    );
     
   
  };
  
export default MessageList;