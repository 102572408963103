// ReusableComponents.js

import React from 'react';

export const LikeButton = ({ handleLike }) => {
  return (
    <button onClick={handleLike}>Like</button>
  );
};

export const LikeCount = ({ likeCount }) => {
  return (
    <span>{likeCount} Likes</span>
  );
};

export const CommentCount = ({ commentCount }) => {
  return (
    <span>{commentCount} Comments</span>
  );
};
