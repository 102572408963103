import React, { useState, useContext, useEffect } from 'react';
import {
  List,
  ListItem,
  Typography,
  Divider,
  Box,
  TextField,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Modal,
  Avatar,
  ListItemAvatar,
    ListItemText,

} from '@mui/material';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../context/usercontext';
import Progress from '../components/mods/progress';
import DoneIcon from '@mui/icons-material/Done';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const SEND_MESSAGE = gql`
  mutation SendMessage($data: MessageInsertInput!) {
    insertOneMessage(data: $data) {
      _id
      content
      createdAt
      isRead
      senderId
      receiverId
      parentId
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($receiverId: ObjectId!) {
    messages(query: { receiverId: $receiverId }) {
      _id
      content
      createdAt
      isRead
      senderId
      receiverId
      parentId
      sendername
      senderimage
    }
  }
`;
export const GET_SENT_MESSAGE = gql`
  query GetMessages($senderId: ObjectId!) {
    messages(query: { senderId: $senderId }) {
      _id
      content
      createdAt
      isRead
      senderId
      receiverId
      parentId
      sendername
      senderimage
    }
  }
`;
const MARK_MESSAGE_AS_READ = gql`
  mutation MarkMessageAsRead($id: ObjectId!) {
    updateOneMessage(
      query: { _id: $id }
      set: { isRead: true }
    ) {
      _id
      isRead
    }
  }
`;

export const GET_REPLIES = gql`
query GetReplies($parentId: ObjectId!) {
  messages(query: { parentId: $parentId }) {
    _id
    content
    createdAt
    isRead
    senderId
    receiverId
    sendername
    senderimage
  }
}

`;

const UPDATE_MESSAGE_ISREAD = gql`
  mutation UpdateMessageIsRead($query: MessageQueryInput, $set: MessageUpdateInput!) {
    updateOneMessage(query: $query, set: $set) {
      _id
      isRead
    }
  }
`;

export const GET_THREAD = gql`
query GetThread($parentId: ObjectId!) {
  parentMessage: message(query: { _id: $parentId }) {
    _id
    content
    createdAt
    isRead
    senderId
    receiverId
  }
  replies: messages(query: { parentId: $parentId }) {
    _id
    content
    createdAt
    isRead
    senderId
    receiverId
  }
}
`;


export default function Deskmessage() {
    const { user } = useContext(UserContext);
    const { Nickname, imageurl } = user.customData;
    const [selectedThread, setSelectedThread] = useState(null);
    const [replyContent, setReplyContent] = useState('');
    const [sendMessage, { loading: sendLoading }] = useMutation(SEND_MESSAGE);
    const [fetchMessages, { loading, data, refetch }] = useLazyQuery(GET_MESSAGES, {
        variables: { receiverId: user.id },
        fetchPolicy: 'network-only', // Ensures the query fetches fresh data
      });
      
      const [fetchSentMessages, { data: sentMessages, refetch: refetchSentMessages }] = useLazyQuery(
        GET_SENT_MESSAGE,
        {
          variables: { senderId: user.id },
          fetchPolicy: 'network-only', // Ensures the query fetches fresh data
        }
      );
      
      const [fetchUnreadReplies, { data: unreadReplies, refetch: refetchReplies }] = useLazyQuery(
        GET_REPLIES,
        {
          fetchPolicy: 'network-only', // Ensures the query fetches fresh data
        }
      );

      const [fetchThread, { loading: threadLoading, data: threadData }] = useLazyQuery(GET_THREAD, {
        fetchPolicy: 'network-only', // Ensures the query fetches fresh data
      });
      
      
    const [replies, setReplies] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)');
  
    // Fetch Messages
    useEffect(() => {
      if (data?.messages || sentMessages?.messages) {
        const combinedMessages = [
          ...(data?.messages || []),
          ...(sentMessages?.messages || []),
        ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setReplies(combinedMessages);
      }
    }, [data, sentMessages]);
  
    useEffect(() => {
      fetchMessages();
      fetchSentMessages();
    }, []);
  
    const [markMessageAsRead] = useMutation(MARK_MESSAGE_AS_READ);
    const [updateParentIsRead] = useMutation(UPDATE_MESSAGE_ISREAD);
  
    
  
    const handleReply = async (parentId, receiverId) => {
      if (!replyContent) {
        alert('Reply content is required.');
        return;
      }
  
      try {
        await sendMessage({
          variables: {
            data: {
              content: replyContent,
              receiverId,
              senderId: user.id,
              parentId,
                sendername: Nickname,
                senderimage: imageurl,
              createdAt: new Date().toISOString(),

            },
          },
        });
       
  
        await updateParentIsRead({
          variables: {
            query: { _id: parentId },
            set: { isRead: false },
          },
        });
        await refetch();
        await refetchSentMessages();
        setReplyContent('');
        closeThread();
      } catch (err) {
        console.error('Error sending reply:', err);
        alert('Failed to send reply.');
      }
    };
  
    const closeThread = () => {
      setSelectedThread(null);
      setReplyContent('');
      refetch();
    };
  
    

    const openThread = async (parentMessage) => {
        try {
          console.log('Opening thread for message:', parentMessage._id);
          const { data: threadData } = await fetchThread({ variables: { parentId: parentMessage._id } });
      
          if (threadData) {
            console.log('Fetched thread data:', threadData);
            const { parentMessage: parent, replies } = threadData;
            setSelectedThread({
              parent,
              thread: [parent, ...replies].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
            });
          }
        } catch (err) {
          console.error('Error opening thread:', err);
        }
      };
  
    useEffect(() => {
      if (threadData) {
        const { parentMessage, replies } = threadData;
        setSelectedThread({
          parent: parentMessage,
          thread: [parentMessage, ...replies].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
        });
      }
    }, [threadData]);
  
    const renderThread = () => {
      if (threadLoading) return <Progress />;
  
      if (!selectedThread) return null;
  
      return (
        <>
          <Box sx={{ mb: 3, p: 2, bgcolor: 'background.default', borderRadius: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {selectedThread.parent.senderId === user.id ? 'You' : selectedThread.parent.sendername}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {selectedThread.parent.content}
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
              {new Date(selectedThread.parent.createdAt).toLocaleString()}
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />
          {selectedThread.thread
            .filter((msg) => msg._id !== selectedThread.parent._id)
            .map((msg) => (
              <Box key={msg._id} sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {msg.senderId === user.id ? 'You' : msg.sendername}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {msg.content}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                  {new Date(msg.createdAt).toLocaleString()}
                </Typography>
              </Box>
            ))}
          <TextField
            fullWidth
            multiline
            rows={3}
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
            placeholder="Write your reply..."
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleReply(selectedThread.parent._id, selectedThread.parent.senderId)}
            disabled={sendLoading}
            sx={{ mt: 2 }}
          >
            {sendLoading ? 'Sending...' : 'Send Reply'}
          </Button>
        </>
      );
    };
  
    if (loading) return <Progress />;
    if (!data?.messages || data.messages.length === 0)
      return <Typography>No messages found.</Typography>;
  
    return (
        
        <Box>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {replies
            .filter((msg) => !msg.parentId)
            .map((message) => (
              <React.Fragment key={message._id}>
                <ListItem
                  alignItems="flex-start"
                  button
                  onClick={() => openThread(message)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt={message.sendername} src={message.senderimage} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, flexGrow: 1 }}>
                          {message.sendername}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                          {new Date(message.createdAt).toLocaleString()}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {message.content}
                      </Typography>
                    }
                  />
                  <Box sx={{ ml: 2 }}>
                    {message.isRead ? (
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                        <DoneIcon fontSize="small" sx={{ mr: 0.5 }} />
                        Read
                      </Typography>
                    ) : (
                      <MailOutlineIcon fontSize="small" color="primary" />
                    )}
                  </Box>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </List>
  
        {selectedThread && (
          isMobile ? (
            <Dialog open onClose={closeThread} fullScreen>
              <DialogTitle>
                Conversation with {selectedThread.parent.sendername}
                <IconButton
                  aria-label="close"
                  onClick={closeThread}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>{renderThread()}</DialogContent>
            </Dialog>
          ) : (
            <Modal open onClose={closeThread} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: 2, width: '50%' }}>
                <Typography variant="h6">
                  Conversation with {selectedThread.parent.sendername}
                </Typography>
                {renderThread()}
              </Box>
            </Modal>
          )
        )}
      </Box>
    );
  }
  