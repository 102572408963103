import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, Box, CardMedia, Chip, Tooltip, CardActionArea } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { gql, useQuery } from '@apollo/client';
import BeanCard from '../beancard';
import { Link } from 'react-router-dom'
import Progress from '../progress';

const GET_LAND = gql`
  query GetLand($isfooter: Boolean!) {
    comms (query: { isfooter: $isfooter }) {
      _id
      name
      imageurl
      category
      message
      message1
      message
      ishome
    }
  }
`;

const StackableFoot = () => {
  const { loading, error, data } = useQuery(GET_LAND, { variables: { isfooter: true } });
  const [cards, setCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (data?.comms) {
      setCards(
        data.comms.map(item => ({
          _id: item._id,
          image: item.imageurl,
          content: item.message,
          sub: item.message1,
          title: item.name,
          visible: true,
          ishome: item.ishome,
        }))
      );
    }
  }, [data]);

  const handleClose = (_id) => {
    setCards(prevCards => prevCards.map(card => card._id === _id ? { ...card, visible: false } : card));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const visibleCards = cards.filter(card => card.visible);

  if (visibleCards.length === 0) {
    return <Typography><BeanCard/></Typography>;
  }

  const cardToDisplay = visibleCards[currentIndex];
  if (loading) return <Typography><Progress/></Typography>;
  return (
    <Card
  sx={{
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    borderRadius: 5,
    backgroundColor: 'transparent', // New gradient for the Card
    border: 'solid 1px ',
    overflow: 'hidden', // Ensure no overflow to maintain image boundaries
  }}
>
  <CardMedia
    component="img"
    sx={{ 
      width: 130 , 
     
      objectFit: 'cover', // Ensures the image covers the entire area without white spaces
      borderRadius: 0, 
      backgroundColor: '#transparent',
    }}
    image={cardToDisplay.image}
    alt="Image description"
  />
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      backgroundColor: '#transparent',
    }}
  >
     
    <IconButton
      onClick={() => handleClose(cardToDisplay._id)}
      sx={{ 
        position: 'absolute', 
        top: 50, 
        right: 0, 
        fontSize: 2, 
        backgroundColor: 'transparent', 
        color: '#077336', 
        
      }}
    >
     
      <ArrowForwardIosIcon fontSize='small'/>
    </IconButton>
    
      

    <CardContent
      sx={{
        flex: '1 0 auto',
        backgroundColor: 'transparent',
        padding: 0.5,
      }}
    >
      <Typography 
        component="div" 
        variant="h6" 
        fontWeight={800}  
        sx={{ textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)' }}
      >
        {cardToDisplay.title}
      </Typography>
      <Typography 
        variant="body1" 
        fontWeight={400} 
        sx={{ textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)' }}
      >
        {cardToDisplay.content}
      </Typography>
      
     
       
     
     
    </CardContent>
    <CardActionArea sx={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: 'transparent', padding: 1 }}>
      <Tooltip title="Shop Now" arrow>
        <Chip
          component={Link}
          to="/store"
          label="Shop Now"
          variant="outlined"
          sx={{ color: '#077336', alignSelf: 'flex-end', padding: 2 }}
        />
      </Tooltip>
    </CardActionArea>
    
    
  </Box>
</Card>

  );
};

export default StackableFoot;
