import React from "react";
import useFileUpload from "./cloud";  // Assuming the hook is in the same directory
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Card, CardMedia } from "@mui/material";
import { gql, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../../context/usercontext';



const UPDATE_IMAGE_URL = gql`
  mutation UpdateImageUrl($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      imageurl
    }
  }
`;



const FileUploadComponent = ( { setImageURL, userId, }) => {
  const { user, } = useContext(UserContext);
  const { Nickname } = user.customData;
  console.log(user);
  const { file, previewURL, handleFileChange, uploadFile } = useFileUpload(setImageURL);
  const [updateImageUrl] = useMutation(UPDATE_IMAGE_URL);
 
  const fileInputRef = React.createRef();

  const handleFabClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadAndSetURL = async () => {
    await uploadFile();
    // The image URL is in the following format:
    const userFolderPath = `profiles/${Nickname}/posts/${file.name}`;
  
    // Upload the file and get the image URL
    const uploadedImageUrl = await uploadFile(file, userFolderPath);
    updateImageUrl({
      variables: {
        query: { _id: userId },
        set: { imageurl: uploadedImageUrl },
      }
    });
  }

  

  return (
    <div>
    {previewURL && (
      <div sx={{ borderRadius: '20px', color: '#fff', width: '100%' }}>
        <CardMedia component="img" alt="Preview" image={previewURL} sx={{ width: '100%', height: 'auto', borderRadius: 10 }} />
      </div>
    )}
       <div>
      <Fab color="primary" aria-label="add" onClick={handleFabClick}>
        <AddIcon />
      </Fab>
      <input 
        type="file" 
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
      <Button onClick={handleUploadAndSetURL}>Upload</Button>
    </div>
  );
};

export default FileUploadComponent;

