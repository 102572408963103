
import React, { useEffect, useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {UserContext} from '../../context/usercontext';

const useStyles = makeStyles((theme) => ({
  // your styles here
}));

export default function Top() {
  const classes = useStyles();
  const { signInWithGoogle } = useContext(UserContext);

  useEffect(() => {
    const handleCredentialResponse = (response) => {
      console.log('Encoded JWT ID token:', response.credential);
      signInWithGoogle({ id_token: response.credential });
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: "213836331022-r0v1mth6ha7603bggo3b4lff361tpr3t.apps.googleusercontent.com",
        callback: handleCredentialResponse,
        auto_select: true,
        theme: "outline",
        locale: "en-US",
        fetch_basic_profile: true,
      });

    

      window.google.accounts.id.prompt();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [signInWithGoogle]);

  return (
    <Box className="flex min-h-[100vh]" sx={{ display: 'flex', minHeight: '100vh', flex: 0, backgroundImage: 'url("https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp")', backgroundSize: 'cover', backgroundPosition: 'center', width : '100%', backgroundRepeat: 'no-repeat', }}>
      <Box className="flex-1 flex flex-col justify-center items-center" sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Box component="header" sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h2" sx={{ fontWeight: 800, color: '#fff', textShadow: '2px 2px 4px rgba(0,0,0,1)', textAlign: 'center' }}>
            Welcome to Koffieland™
          </Typography>
        </Box>
        <Box sx={{ mb: 4, textAlign: 'center', padding: 2, borderRadius: 5 }}>
          <Typography variant="h4" sx={{ fontWeight: 900, mb: 1, textAlign: 'center', color: '#ffd700', textShadow: '2px 2px 4px rgba(0,0,0,1)' }}>
            The People's, people people, drink people
          </Typography>
        </Box>
        <Typography variant="h4" fontWeight={800} gutterBottom sx={{ mt: 1, textAlign: 'center', color: '#fff', textShadow: '2px 2px 4px rgba(0,0,0,1)' }}>
          Kaffeinate
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, fontWeight: 700, textAlign: 'center', color: '#ffd700', textShadow: '2px 2px 4px rgba(0,0,0,0.9)' }}>
          Sign in with your Google account
        </Typography>
        <div id="signin-button" />
      </Box>
    </Box>
  );
}
