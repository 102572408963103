import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, Button, CircularProgress, Box, Typography, Modal, IconButton, Chip, Avatar } from '@mui/material';
import { GET_COFFEE_SHOP_BY_ID, UPDATE_USER_PROF, GET_USER , INSERT_SHOP_REDEEM} from '../../../context/gqllogics';
import { useSpring, animated, config } from 'react-spring';
import confetti from 'canvas-confetti';
import ShareIcon from '@mui/icons-material/Share';
import { UserContext } from '../../../context/usercontext';
import { makeStyles } from '@mui/styles';
import BeanCard from '../../mods/beancard';
import ShareComponent from '../../../constants/share';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontWeight: 900,
    backgroundColor: '#transparent',
    fontSize: 24,
    
    padding: 8,
    },
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    
  },
  
  shareButton: {
    marginTop: theme.spacing(2),
   
    color: '#077336',
    border: '1px solid #077336',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  claimButton: {
    backgroundColor: '#inherit',
    color: 'white',
    border: 'solid 1px ',
    
    marginTop: 10,
    borderRadius: 10,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#45a049',
    },
    '&:disabled': {
      backgroundColor: '#9e9e9e',
      cursor: 'not-allowed',
    },
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    marginBottom: 20,
  },
  shopavatar: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginBottom: 0,
  },
    contitle: {
        textAlign: 'center',
        fontWeight: 900,
        
        fontSize: 24,
        
        },
}));

const CoffeeShopDetails = () => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { _id } = useParams();
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [createConfetti, setCreateConfetti] = useState(false);
  const { loading, error, data } = useQuery(GET_COFFEE_SHOP_BY_ID, {
    variables: { _id },
  });
  const [updateUserProf] = useMutation(UPDATE_USER_PROF);
  const [insertShopRedeem] = useMutation(INSERT_SHOP_REDEEM);
  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    variables: { _id: user.id },
  });
  const [open, setOpen] = useState(true);
  const [congratsOpen, setCongratsOpen] = useState(false);

  const goToProfile = () => {
    navigate('/chat');
  };

  // uspring animation
  const animationStyles = useSpring({
    from: { transform: 'translateY(-100%)', opacity: 0 },
    to: async (next) => {
      // Falls and bounces into place more slowly
      await next({ transform: 'translateY(0%)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
      // Zooms out slowly
      await next({ transform: 'scale(1.2)', opacity: 1, config: { duration: 1000 } });
      // Zooms back in to its original state more slowly
      await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
      // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after zooming back in
      await next({ opacity: 1, config: { duration: 500 } });
    },
    delay: 600,
  });
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const existingBeans = userData?.userprof?.beanCount || 0;
  const newBeanCount = existingBeans + 4000;

  const [hasRedeemed, setHasRedeemed] = useState(false);

  const handleRedeem = async () => {
    if (buttonDisabled || hasRedeemed) return; // Prevent multiple redemptions
  
    setButtonDisabled(true);
    try {
      const beansClaimed = 4000;
  
      const [updateUserResult, insertRedeemResult] = await Promise.all([
        updateUserProf({
          variables: {
            query: { _id: user.id },
            set: { beanCount: newBeanCount },
          },
        }),
        insertShopRedeem({
          variables: {
            data: {
              userId: user.id,
              shopId: _id,
              beanCount: beansClaimed,
              timestamp: new Date().toISOString(),
              uimage: userData?.userprof?.imageurl,
              name: userData?.userprof?.Nickname,
            },
          },
        }),
      ]);
  
      if (updateUserResult.data && insertRedeemResult.data) {
        setHasRedeemed(true); // Mark as redeemed
        setCreateConfetti(true);
        createCoffeeBeanConfetti();
  
        setTimeout(() => {
          setCreateConfetti(false);
          setCongratsOpen(true);
        }, 3000);
      }
    } catch (error) {
      console.error('Error redeeming beans:', error);
    } finally {
      setButtonDisabled(false);
    }
  };
  

  if (loading) return <CircularProgress />;
  if (error) return <p>Error: {error.message}</p>;

  const { Nickname, Title, Koffie_story, imageurl } = data.userprofs[0];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShare = () => {
    navigator.share({
      title: 'Join the Koffie Revolution!',
      text: 'Check out Koffieland and join the coffee revolution!',
      url: window.location.href,
    });
  };

  function createCoffeeBeanConfetti(options = {}) {
    const defaults = {
      scalar: 1.5,
      spread: 80,
      particleCount: 150,
      origin: { y: -0.1 },
      startVelocity: -40,
      zIndex: 1500,
    };

    const mergedOptions = { ...defaults, ...options };

    const coffeeBeanShape = [
      [0.1, 0.1],
      [0.2, 0.2],
    ];

    confetti({
      ...mergedOptions,
      shape: coffeeBeanShape,
      colors: ['#704214', '#704214', '704214'],
    });
  }

  

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Dialog className={classes.content} open={open} onClose={handleClose} fullScreen>
      <DialogTitle className={classes.title}>
      <DialogTitle className={classes.title}>
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 'auto' }}>
      <img className={classes.shopavatar} src={userData?.userprof?.imageurl} alt={Nickname} />
      <Typography variant="caption" sx={{ textAlign: 'center', fontWeight: 800 }}>{userData?.userprof?.Nickname }</Typography>
    </Box>
    <Typography variant="h5" sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 800 }}>
      Claim your DigiBeans
    </Typography>
  </Box>
</DialogTitle>

</DialogTitle>

        <DialogContent className={classes.content}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 10 }}>
          
          <img className={classes.avatar} src={imageurl} alt={Nickname} width={50} />
            <Typography variant="5" gutterBottom sx={{ fontWeight: 800 }}>
              {Nickname|| 'User'}
            </Typography>
            
            <img src="https://storage.googleapis.com/app_darkendimg/assets/roasterbean.webp" alt={Nickname} width={300} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',border: 'solid  0px', borderRadius: 5, mt: 5 }}>
            <Button
  className={classes.claimButton}
  onClick={handleRedeem}
  disabled={buttonDisabled || hasRedeemed}
  sx={{ m: 2 }}
>
  {buttonDisabled ? (
    <CircularProgress size={24} sx={{ color: '#077336' }} />
  ) : hasRedeemed ? (
    'Already Redeemed'
  ) : (
    'Claim 4000 DigiBeans'
  )}
</Button>

          </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={congratsOpen} fullScreen  onClose={handleClose}>
        <animated.div style={fade}>
          <Dialog fullScreen  open={congratsOpen} onClose={handleClose}>
          <animated.div style={fade}>
          <DialogTitle sx={{mt: 5}} className={classes.contitle}>Congratulations!</DialogTitle>
            <DialogContent className={classes.content} >
               <Box sx={{ flex: 1,   mt: 1, mb: 1 }}>
              <BeanCard />
              </Box>
                   
              
              <animated.div style={animationStyles}>
               
              <Typography variant="bodye1" gutterBottom  sx={{ mb: 10}}>
                You have successfully claimed your DigiBeans! 
              </Typography>
              <Typography variant="body1" gutterBottom  sx={{ mb: 1}}>
                You now have DigiBeans <img src="https://storage.googleapis.com/app_darkendimg/iceicebeaine.png" alt="Your Bag" width={40} />  in your account. 
                </Typography>
              
                </animated.div>
              <Typography variant="body1" gutterBottom  sx={{ mt: 1, fontWeight: 700}}>
                Share your success with your friends!
                </Typography>
                <ShareComponent
                url={window.location.href}
                title="Join the Koffie Revolution!"
                description="Check out Koffieland and join the coffee revolution!"
                imageurl={imageurl}
              />
                <Typography variant="body1" gutterBottom  sx={{color: 'text.secondary', mt: 1}}>
                Use your DigiBeans to post like and sell your products. Redeem  drink rewards and discounts at any Koffieland partner. 
                </Typography>

              <Button sx={{mt: 20, border: 'solid  1px #fff', borderRadius: 5 }} className={classes.claimButton} onClick={goToProfile}>
                Continue
              </Button>
            </DialogContent>
            </animated.div>
          </Dialog>
        </animated.div>
      </Dialog>
    </div>
  );
};

export default CoffeeShopDetails;
