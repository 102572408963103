import React from 'react';
import { Card, Grid, Paper, Typography, Box, Avatar } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import congo from '../../images/congobag.png';
import LocationComponent from '../finance/location';
import Barista from '../tests/barista';

const GET_PRODUCTS = gql`
  query Products {
    products {
      Title
      _id
      description1
      description2
      imageurl
      name
      price
      category
    }
  }
`;

const Koffieman = () => {
  const { loading, error, data } = useQuery(GET_PRODUCTS);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{null}</div>;

  // Group products by category
  const productsByCategory = data.products.reduce((acc, product) => {
    if (!acc[product.category]) {
      acc[product.category] = [];
    }
    acc[product.category].push(product);
    return acc;
  }, {});

  return (
    <div>
       
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant="h3" sx={{fontWeight: 900}}>Midnight Oil</Typography>
        </div>
        <LocationComponent/>
      <Barista/>
      {Object.keys(productsByCategory).map(category => (
        <div key={category}>
          <Typography variant="h4" sx={{fontWeight: 900}}> {category}</Typography>
          <Box display="flex" flexWrap="wrap">
            {productsByCategory[category].map(product => (
              <Box key={product._id} m={1}>
                <Link to={`/product/${product._id}`}>
                <Avatar
                    sx={{ border: '5px', borderColor: '#000', width: 80, height: 80 }}
                    alt={product.name}
                    src={product.imageurl}
                    label={product.price}

                  />
                </Link>
                <Typography variant="caption">{product.name}</Typography>
                <Typography variant="body2">${product.price}</Typography>
              </Box>
            ))}
          </Box>
        </div>
      ))}
    </div>
  );
};

export default Koffieman;
