import React from 'react'
import '../../context/aifood.css'

function Aifood() {
  return (
    <div>
       <ul class="cards">
    <li class="cards_item">
      <div class="card" tabindex="0">
        <div class="card_image"><img src="https://assets.codepen.io/652/photo-1468777675496-5782faaea55b.jpeg" /></div>
        <div class="card_content">
          <h2 class="card_title">Farmstand Salad &#x2022; $9</h2>
          <div class="card_text">
            <p>Dig into the freshest veggies of the season! This salad-in-a-jar features a mixture of leafy greens and seasonal vegetables, fresh from the farmer's market.</p>
            <p>Served with your choice of dressing on the side: <strong>housemade ranch, cherry balsamic vinaigrette, creamy chipotle, avocado green goddess, or honey mustard.</strong></p>
            <p class="upcharge">Add your choice of protein for $2 more. </p>
          </div>
        </div>
      </div>
    </li>

    <li class="cards_item">
      <div class="card" tabindex="0">
        <div class="card_image"><img src="https://assets.codepen.io/652/photo-1520174691701-bc555a3404ca.jpeg" /></div>
        <div class="card_content">
          <h2 class="card_title">Ultimate Reuben &#x2022; $18</h2>
          <div class="card_text">
            <p>All great meals take time, but this one takes it to the next level! More than 650 hours of fermenting, brining, aging, and curing goes into each and every one of our legendary Reuben sandwiches.
            </p>
            <p>Every element of this extraordinary sandwich is handcrafted in our kitchens, from the rye bread baked from our secret recipe to the cave-aged Swiss cheese, right down to the pickle. The only thing we didn't make on the premises is the toothpick ( but we're looking into how to do that). </p>
            <p>This unforgettable sandwich has all of the classic Reuben elements: <strong>corned beef, rye bread, creamy Russian dressing, sauerkraut, plus a sweet gherkin pickle.</strong> <em>No substitions please!</em>
            </p>
              <p class="upcharge">Add a side of french fries or sweet potato fries for $2 more, or our housemade pub chips for $1.</p>
          </div>
        </div>
      </div>
    </li>
    <li class="cards_item">
      <div class="card" tabindex="0">
        <div class="card_image"><img src="https://assets.codepen.io/652/photo-1544510808-91bcbee1df55.jpeg" /></div>
        <div class="card_content">
          <h2 class="card_title">Fig &amp; Berry Plate &#x2022; $16</h2>
          <div class="card_text">
            <span class="note">Seasonal.</span>
            <p>A succulent sextet of fresh figs join with a selection of bodacious seasonal berries in this refreshing, shareable dessert.</p>
            <p>Choose your drizzle: <strong>cherry-balsamic vinegar, local honey, or housemade chocolate sauce.</strong> </p>
          </div>
        </div>
      </div>
    </li>
  </ul> 
    </div>
  )
}

export default Aifood