import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Web3 from 'web3';

// Apollo
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
// Realm
import * as Realm from "realm-web";
import { a } from 'react-spring';

const APP_ID = "application-0-jyiid";

const app = new Realm.App(APP_ID);

window.addEventListener('load', async () => {
  // Modern dapp browsers...
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    try {
      // Request account access if needed
      await window.ethereum.enable();
      // Accounts now exposed
      // You can start interacting with the blockchain
    } catch (error) {
      // User denied account access...
      console.error("User denied account access")
    }
  }
  // Legacy dapp browsers...
  else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
    // Accounts always exposed
    // You can start interacting with the blockchain
  }
  // Non-dapp browsers...
  else {
    console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
});



async function getValidAccessToken() {
  if (!app.currentUser) {
    // If no user is logged in, handle the authentication based on the provider (e.g., Google OAuth)
    // You might want to implement additional checks for other providers
    // In this example, we'll assume Google OAuth for simplicity
    const credentials = Realm.Credentials.emailPassword();
    await app.logIn(credentials);
  } else {
    // An already logged in user's access token might be stale.
    // To guarantee that the token is valid, refresh the user's custom data, which also refreshes their access token.
    await app.currentUser.refreshCustomData();
  }

  // Wait for the access token to be available
  return new Promise((resolve) => {
    const checkToken = () => {
      const accessToken = app.currentUser.accessToken;
      if (accessToken) {
        resolve(accessToken);
      } else {
        setTimeout(checkToken, 2); // Check again in 100 milliseconds
      }
    };

    checkToken();
  });
}




// Configure the ApolloClient to connect to your app's GraphQL endpoint
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        accessToken: {
          read() {
            return localStorage.getItem('accessToken') || null;
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  link: new HttpLink({
    uri: `https://realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      localStorage.setItem('accessToken', accessToken);
      options.headers.Authorization = `Bearer ${accessToken}`;
     
      return fetch(uri, options);
    },
  }),
});




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
      <ApolloProvider client={client}>
    <App />
    </ApolloProvider>
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA


serviceWorkerRegistration.register();