import React, { useState, useEffect, useParams } from 'react';
import { 
Card,  List, Typography, Avatar,
Box,  CardContent
} from '@mui/material';
//import { GetKoffie } from '../../queries/koffies';
import { makeStyles } from '@mui/styles';
import { experimentalStyled as styled } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';




import { set } from 'react-hook-form';

const GetKoffie = gql`
  query GetKoffie($When: String!) {
    darkroad(query: { When: $When }) {
      _id
      Title
      Who
      What
        When
        
        Why
        How
    }
  }
`;


const barista = () => {
    return {
      "name": "barista",
      "description": "Give info based on available data.",
      "parameters": {
        "type": "object",
        "properties": {
          "Title": {
            "type": "string",
            "description": "Title of Subject"
          },
          "Who": {
            "type": "string",
            "description": "who is invold in this event."
          },
          "description": {
            "type": "string",
            "description": "Tell a short tale of the events in what happened."
          },
          "Country": {
            "type": "string",
            "description": "Name of the country where the coffee was produced"
          },

            "primaryImage": 
            {"type": "string",
            "description": "The URL of the image"
          
          },
        
          "When": 
            {"type": "string",
            "description": "When did this event happen"
          
          }
        
          
        },
        "required": ["name", "Title", "description", "What", "primaryImage", "When", "Country" ]
      }
    };
  };






  function Aigql() {
    const [When, setWhen] = useState('');
    const [Pounds, setPounds] = useState('');
    const [getKoffie, { loading, data }] = useLazyQuery(GetKoffie);
    const [content, setOutput] = useState('');
    const baristaFunction = barista();  // Get the coffee function

    const handleSubmit = (e) => {
        e.preventDefault();
        getKoffie({ variables: { When } });
      };
    
      useEffect(() => {
        if (data && data.darkroad) {
          fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${"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"}`,
            },
            body: JSON.stringify({
              "messages": [
                { "role": "system", "content": "You are the coolest hippest Barista on the Planet and you LOVE Koffie. Captivate the user with coffee grandure as you take their order." },
                { "role": "user", "content": `What can you tell me about ?: ${JSON.stringify(data.darkroad)}.` },
              ],
              "temperature": 0.5,
              'model': 'gpt-4',
              'functions': [baristaFunction],
              "function_call": {
                "name": "barista",
                "arguments": JSON.stringify(data.darkroad),
              },
              
            }),
          })
          .then(response => response.json())
          .then(data => {
            let output;
            if (data.choices[0].message.content) {
              // If there's content in the message, use that
              output = { description: data.choices[0].message.content };
            } else if (data.choices[0].message.function_call) {
              // If there's a function call, parse the arguments field
              output = JSON.parse(data.choices[0].message.function_call.arguments);
            }
            setOutput(output);
            console.log('Success:', data);
          })
          .catch((error) => console.error('Error:', error));
        }
      }, [data]);
  
    return (
      <div>
        <h1>Dark Road</h1>
        <h6>Enter a year to get a description of the events that happened in that year.</h6>
        <form onSubmit={handleSubmit}>
          <input 
            type="text" 
            value={When} 
            onChange={(e) => setWhen(e.target.value)} 
            placeholder="Year" 
          />
          <button type="submit">Search</button>
        </form>
        {loading && <p>Loading...</p>}
        {content && (
          <Box sx={{ flexGrow: 1 , paddningLeft: 5,}}>
             {content.primaryImage && <img src={content.primaryImage} width={200} alt={content.Title} />}
             <h2>{content.Title}</h2>
            <Typography variant='h6' color='textSecondary'> 
              {content.Who && <b>{content.Who}</b>}
              </Typography>
             {content.When && <b>{content.When}</b>}
            <p>{content.description}</p>
            {content.What && <p>{content.What}</p>}
           

            
          </Box>
        )}
      </div>
    );
  }
  
  export default Aigql;