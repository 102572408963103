import { useContext, useState, useEffect } from 'react';
import { useGoalManagement } from './useGoalManagement'; // Adjust the import to your file structure
import { UserContext } from '../../context/usercontext'; // Adjust the import to your file structure
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Button, Card,Typography, List, ListItem, ListItemIcon, ListItemText, Grid, Stepper, StepLabel, Step, Box,CardContent,
Chip, Drawer, Snackbar, Icon, CardActionArea } from '@mui/material';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import  DoIT  from './doit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
// Import styled
import { styled } from '@mui/system';
import { Close } from '@mui/icons-material';





const DELETE_GOAL = gql`
mutation DeleteGoal($_id: ObjectId!) {
  deleteOneGoal(query: { _id: $_id }) {
    _id
  }
}
`;

const GoalCard = styled(Card)(({ theme }) => ({
  
  borderRadius: '16px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  padding: theme.spacing(2),
  textAlign: 'center',
  maxWidth: 400,
  margin: '0 auto',
}));

const AddGoalButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#077336',
  color: '#fff',
  border: 'none',
  borderRadius: 5,
  '&:hover': {
    backgroundColor: '#5548C8',
  },
}));

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const fabGreenStyle = {
  color: 'common.white',
  bgcolor: green[500],
  '&:hover': {
    bgcolor: green[600],
  },
};

function GoalComponent({ data,}) {
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useContext(UserContext);
  
  const { goals, createGoal, completeStep, completeSubTask,refetch, deleteGoalFromState, updateGoal, error, loading } = useGoalManagement();
  const [primaryGoal, setPrimaryGoal] = useState("");
  const [stepDescription, setStepDescription] = useState("");
  const [subTaskDescription, setSubTaskDescription] = useState("");
  const [editGoal, setEditGoal] = useState(null); // Goal to be edited
  const [editDescription, setEditDescription] = useState("");
  const [showForm, setShowForm] = useState(false); // state to toggle form visibility
  const [deleteGoal] = useMutation(DELETE_GOAL);
  const [goalToDelete, setGoalToDelete] = useState(null);
  const [chipNotification, setChipNotification] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([{ description: "", subTasks: [] }]);
 
   
  const addStep = () => {
    setSteps([...steps, { description: "", subTasks: [] }]);
  };
    
  const addSubtask = (stepIndex) => {
    const newSteps = [...steps];
    newSteps[stepIndex].subTasks.push({ description: "" });
    setSteps(newSteps);
  };

  const updateStepDescription = (stepIndex, newDescription) => {
    const newSteps = steps.map((step, index) => {
      if (index === stepIndex) {
        return { ...step, description: newDescription };
      }
      return step;
    });
    setSteps(newSteps);
  };
  
  const removeGoal = (goalId) => {
    deleteGoal(goals.filter((goal) => goal._id !== goalId));
    refetch();
  };
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
};

const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
};
 

  const openDrawerWithGoal = (goalId) => {
    setSelectedGoalId(goalId);
    setDrawerOpen(true);
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  
  const handleDeleteGoal = async (_id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this goal?");
    if (confirmDelete) {
      try {
        await deleteGoal({ variables: { _id } });
        
        deleteGoalFromState(_id);  // This should remove the goal from the goals array
      } catch (error) {
        console.error("Could not delete goal:", error);
      }
    }
  };
  
  
const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    
  };

//Handle Submit2 

const handleSubmit = async (e) => {
  const stepID = uuidv4();
  const taskID = uuidv4();

  e.preventDefault();
  try {
    const steps = [
      {
        
        description: stepDescription,
        status: "false", // or other default status
        stepID: stepID,
        subTasks: [
          {
            description: subTaskDescription,
            status: "false" ,// or other default status
            taskID: taskID,
            

          }
        ]
      }
    ];

    await createGoal(primaryGoal, user.id, steps);
    // Clear the form fields
    setPrimaryGoal('');
    setStepDescription('');
    setSubTaskDescription('');
    toggleForm();
    alert('Goal inserted successfully');
  } catch (err) {
    console.error('Error inserting goal:', err);
    alert('Failed to insert goal');
  }
};

  

  const handleAddSubTask = () => {
    if (editGoal) {
      // If editing a goal, add subtask to the edited goal.
      const updatedGoal = { ...editGoal };
      updatedGoal.steps[0].subTasks.push({ description: subTaskDescription, });
      updateGoal(updatedGoal);
    }
  };

  const handleEditGoal = (goal) => {
    setEditGoal(goal);
    setEditDescription(goal.primaryGoal);
    
    
  };

  const handleUpdateGoal = async () => {
    if (editGoal) {
      const updatedGoal = { ...editGoal, primaryGoal: editDescription };
      updateGoal(updatedGoal);
      setEditGoal(null);
    }
  };

  if (!goals) {
    return <p>Loading...</p>; // or any loading spinner
  }

  if (!Array.isArray(goals)) {
    console.error("userGoals is not an array:");
    return <p>An error occurred.</p>;
  }

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
// Steps labels




if (loading) {
  return <p>Loading...</p>;
}


  return (

   
    <div style={{ marginTop: 0, marginBottom: 40}}>
       
    {chipNotification && (
        <Chip
          style={{ margin: '20px 0', backgroundColor: 'green', color: 'white', fontWeight: 'bold' }}
          label="Goal added successfully"
          onDelete={() => setChipNotification(false)}
        />
      )}
   
   <Box 
  sx={{ 
    gap: 1,  // Adjust gap between elements as needed
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    marginTop: '5px',
    position: 'relative'  // Add this to handle left and center alignment
  }}
>
  <IconButton 
    onClick={() => window.history.back()} 
    sx={{ 
      position: 'absolute', // Align the back icon to the left
      left: 0, 
      marginLeft: 2 
    }}
  >
    <ArrowBackIcon />
  </IconButton>
  
  <Typography 
    variant="h6" 
    sx={{ 
      fontWeight: 700, 
      marginLeft: '5px', 
    }}
  >
    Goals
  </Typography>
</Box>

      
      
   
    <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, marginLeft: '5px', backgroundColor: 'transparent' }}>Add Goal</Typography>



 
  
<Dialog open={showForm} onClose={toggleForm} fullScreen>

<DialogTitle
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  }}
>
  <IconButton
    onClick={toggleForm}
    sx={{
      position: 'absolute', // Align the back icon to the left
      left: 0, goal: 0,
      marginLeft: 2,
    }}
  >
    <Close />
  </IconButton>

</DialogTitle>

<DialogContent>
  <Box 
    component="form" 
    onSubmit={handleSubmit} 
    sx={{ 
      maxWidth: 400, 
      mx: 'auto', 
      p: 2, 
      border: '0px solid #ccc', 
      borderRadius: 4, 
      display: 'flex',        // Make Box a flex container
      flexDirection: 'column',// Set flex direction to column
      alignItems: 'center' ,
      boxShadow: 0,   // Center items horizontally
      
    }}
  >
    <Typography variant="h6" gutterBottom>Insert New Goal</Typography>

    <TextField
      label="Primary Goal"
      fullWidth
      margin="normal"
      value={primaryGoal}
      onChange={(e) => setPrimaryGoal(e.target.value)}
    />
    <TextField
      label="Step Description"
      fullWidth
      margin="normal"
      value={stepDescription}
      onChange={(e) => setStepDescription(e.target.value)}
    />
    <TextField
      label="Subtask Description"
      fullWidth
      margin="normal"
      value={subTaskDescription}
      onChange={(e) => setSubTaskDescription(e.target.value)}
    />

    <Button 
      type="submit" 
      variant="contained"  
      disabled={loading} 
      sx={{ 
        border: '0px solid #077336',
        borderRadius: 5,
        backgroundColor: '#077336', 
        color: '#fff', // Correct color value for white
        mt: 2          // Add margin to the top for spacing
      }}
    >
      {loading ? 'Inserting...' : 'Insert Goal'}
    </Button>

    {error && <Typography color="error">Error: {error.message}</Typography>}
  </Box>
</DialogContent>

</Dialog>




  


      {goals.length === 0 ? (
        <div >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', padding: 1 }}>
          <GoalCard>
      <CardContent>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          Add a Goal
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Sip coffee and focus on one goal at a time.
        </Typography>
        <Box display="flex" justifyContent="center">
          <AddGoalButton onClick={toggleForm} variant="contained">Add Goal</AddGoalButton>
        </Box>
      </CardContent>
    </GoalCard>
          </Box>
         
        </div>
      ) : (
        <div style={{ padding: 5}}>
  
    {goals.map((goal, index) => (
     <Card raised key={goal._id}  sx={{ marginBottom: '20px', padding: '15px', borderRadius: 5, boxShadow: 5, padding: 2 }}>
     <Typography variant='h6' sx={{ fontWeight: 500}}>
      
       <Typography variant='h6' onClick={() => openDrawerWithGoal(goal._id)}>{goal.primaryGoal}</Typography>
     </Typography>
     
     {/* Render other goal details and actions here */}
     <List>
       <ListItem>
         <ListItemIcon>
           <AutoStoriesOutlinedIcon />
         </ListItemIcon>
         <ListItemText primary="Steps" />
         <div style={{ marginLeft: 'auto' }}>{goal.steps.length}</div>
       </ListItem>
       <ListItem>
         <ListItemIcon>
           <BookmarkBorderOutlinedIcon />
         </ListItemIcon>
         <ListItemText primary="Subtasks" />
         <div style={{ marginLeft: 'auto' }}>{goal.steps[0].subTasks.length}</div>
       </ListItem>
     </List>
     <Grid container spacing={2}>
     
       <Grid item>
         <Chip label="Do It" onClick={() => openDrawerWithGoal(goal._id)}
          sx={{
            borderColor: '#32a852', // Green border color
            borderWidth: '1px', // Border width
            borderStyle: 'solid', // Border style
            backgroundColor: '#fff', // Green background color
          
          }} />
       </Grid>
       <Grid item>
       <Chip
        label="Collaborate"
        onClick={handleClickOpen}
        variant="outlined"
        color="primary"
        sx={{
          borderColor: '#32a852', // Green border color
          borderWidth: '1px', // Border width
          borderStyle: 'solid', // Border style
          backgroundColor: '#fff', // Green background color
        
        }}
      />

      <Dialog onClose={handleClose} open={open}>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <DialogTitle>Coming Soon</DialogTitle>
        <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' style={{ width: 50, height: 50 }} />
        </Box>
        <DialogContent >
          <Typography gutterBottom variant='h6'>
            This feature is coming soon.
          </Typography>
          <Typography gutterBottom variant='body2'>
            We are working on a feature that will allow you to collaborate with friends and family on your goals. Stay tuned!
          </Typography>
        </DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button onClick={handleClose} sx={{backgroundColor: '#077336', color: 'white', border: 'none'}}>Close</Button>
        </Box>
      </Dialog>
       </Grid>
       
       <Grid item>
         <Chip label="Delete" onClick={() => handleDeleteGoal(goal._id)}
          sx={{
            borderColor: '#32a852', // Green border color
            borderWidth: '1px', // Border width
            borderStyle: 'solid', // Border style
            backgroundColor: '#fff', // Green background color
          
          }} />
       </Grid>
       <Grid item>
         <Chip label={editGoal ? "Update" : "Edit"} onClick={() => handleEditGoal(goal)} 
          sx={{
            borderColor: '#32a852', // Green border color
            borderWidth: '1px', // Border width
            borderStyle: 'solid', // Border style
            backgroundColor: '#fff', // Green background color
          
          }}/>
        </Grid>
      
       
     </Grid>
     <Drawer anchor="bottom" open={drawerOpen} onClose={() => setDrawerOpen(false)}
     sx={{
      '& .MuiDrawer-paper': {
        height: '100%',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px'
      }
    }} >
      <div style={{ padding: 5, margin: 10 }}>
     <IconButton onClick={() => setDrawerOpen(false)}>
      <ArrowBackIcon />
    </IconButton>
    </div>
        <div style={{ padding: 10 }}>
         
          <DoIT _id={selectedGoalId} />
          {/* Close button */}
          
        </div>
      </Drawer>
   </Card>
   
    ))}
   
</div>

      )}
    </div>
  );
}

export default GoalComponent;
