import React from 'react'

function CoffeeLoader() {
  return (
    <div class="coffee">
    <div class="cup">
        <div class="liquid"></div>
   </div>
   <div class="handle"></div>
 </div>
  )
}

export default CoffeeLoader