import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Avatar, Box, Typography } from '@mui/material';

const GET_ROAD = gql`
  query {
    darkroads {
      _id
      Who
      What
      When
      Why
      Country
      Title
      ImageUrl
    }
  }
`;

function TheDark() {
  const { loading, error, data } = useQuery(GET_ROAD);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;

  // Group roads by Country
  const roadsByCountry = data.darkroads.reduce((acc, road) => {
    if (!acc[road.Country]) {
      acc[road.Country] = [];
    }
    acc[road.Country].push(road);
    return acc;
  }, {});

  return (
    <div>
      <Typography variant='h3' sx={{fontWeight: 700}}>All about Koffie</Typography>
      {Object.keys(roadsByCountry).map(country => (
        <div key={country}>
          <Typography variant="h4" sx={{ fontWeight: '900' }}>{country}</Typography>
          <Box display="flex" flexWrap="wrap">
            {roadsByCountry[country].map(road => (
              <Box key={road._id} m={1}>
                <Link to={`/darkroad/${road._id}`}>
                  <Avatar
                    sx={{ border: '5px', borderColor: '#000', width: 80, height: 80 }}
                    alt={road.Title}
                    src={road.ImageUrl}
                  />
                </Link>
                <Typography variant="caption">{road.Title}</Typography>
              </Box>
            ))}
          </Box>
        </div>
      ))}
    </div>
  );
}

export default TheDark;
