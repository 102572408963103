import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Input, Select, MenuItem, InputLabel, Chip, Grid, Box,  Slider, Card, CardContent, Typography, IconButton, TextField, CardHeader, Icon, Avatar, Divider, Dialog, DialogActions,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,  
 } from '@mui/material';
import { UserContext } from '../../context/usercontext';
import MailIcon from '@mui/icons-material/Mail';
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined'; // Example icon for beans
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Example icon for costs
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined'; // Example icon for pounds
import BalanceIcon from '@mui/icons-material/Balance';
import RoasterSteps from '../../blockchain/roaster';
import { Modal } from '@mui/material';
import { AgricultureOutlined, Close, CoffeeMakerOutlined, Info, StorefrontOutlined } from '@mui/icons-material';
import styled from '@mui/material/styles/styled';
import { motion, AnimatePresence, useMotionValue, useMotionValueEvent} from 'framer-motion'; 
import { Donut, Basic } from 'react-dial-knob'
import { Bar, Pie } from 'react-chartjs-2';
//import CoffeeProfitCalculator from './coffeshopCalc';
//import FarmerEarnings from './FarmerCalc';




const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
   
    border: '0px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const BeanIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/brkoin.png" alt="beans" style={{ width: 20, height: 20 }} />
    );
  };
 const CherriesIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/cherry.png" alt="cherries" style={{ width: 20, height: 20 }} />
    );
  };
  const RoastIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/roast.png" alt="roast" style={{ width: 20, height: 20 }} />
    );
  };
  const RetailIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/retail.png" alt="retail" style={{ width: 20, height: 20 }} />
    );
  };
  const ShopIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/shop.png" alt="shop" style={{ width: 20, height: 20 }} />
    );
  };
  const BulkIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/bulk.png" alt="bulk" style={{ width: 20, height: 20 }} />
    );
  };
  const FarmerIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/farmcalcl.png" alt="home" style={{ width: 30, height: 30}} />
    );
  };
  const GrossIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/gross.png" alt="gross" style={{ width: 20, height: 20 }} />
    );
  };
  const NetIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/net.png" alt="net" style={{ width: 20, height: 20 }} />
    );
  };
  const CherryCostIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/cherry_cost.png" alt="cherry_cost" style={{ width: 20, height: 20 }} />
    );
  };
  const BeanCostIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/blackbean.webp" alt="bean_cost" style={{ width: 20, height: 20 }} />
    );
  };
  
function KoffieCalc() {
  const CPrice = 2.40; // Coffee price per pound
const Farmgate = CPrice * 0.70; // 40% of the coffee price
const Fairtrade = 1.80; // 60% of the coffee price
const FarmerCost = 1.00; // Cost of production per pound
const FarmerNet = Fairtrade - FarmerCost; // Farmer's earnings per pound

    const { user } = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(true);

    const [cherryIncrement, setCherryIncrement] = useState('');
    const [treeCount, setTreeCount] = useState(0);
    const [beanCount, setBeanCount] = useState(0);
    const [poundCount, setPoundCount] = useState(0);
    const [totalCost, setTotalCost] = useState(CPrice);
    const [cherryCost, setCherryCost] = useState(0);
    const [beanCost, setBeanCost] = useState(0);
    const [cherryCount, setCherryCount] = useState(0);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [roastCost, setRoastCost] = useState(0);
    const [bulkCost, setBulkCost] = useState(0);
    const [retailCost, setRetailCost] = useState(0);
    const [shopCost, setShopCost] = useState(0);
    const [homeCost, setHomeCost] = useState(0);
    const [retailGross , setRetailGross] = useState(0);
    const [bulkGross, setBulkGross] = useState(0);
    const [roastGross, setRoastGross] = useState(0);
    const [shopGross, setShopGross] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerOpen2, setDrawerOpen2] = useState(false);

    const [drawerOpen3, setDrawerOpen3] = useState(false);
    const [drawerOpen4, setDrawerOpen4] = useState(false);
    const [drawerOpen5, setDrawerOpen5] = useState(false);

    //funstion to open roaster modal drawer3
    const handleDrawerToggle3 = () => {
      setDrawerOpen3(true); // Set explicitly to true to open
    };

    // Function to close the modal
    const handleCloseDrawer3 = () => {
      setDrawerOpen3(false); // Set explicitly to false to close
    };

    // Function to open the modal
const handleDrawerToggle2 = () => {
  setDrawerOpen2(true); // Set explicitly to true to open
};

// Function to close the modal
const handleCloseDrawer2 = () => {
  setDrawerOpen2(false); // Set explicitly to false to close
};

    const x = useMotionValue(0); // Motion value for slider's position
    const [displayValue, setDisplayValue] = useState(0); // Display value for the slider   
    useEffect(() => {
      // Delay the appearance of the button to sync with the dialog's transition
      const timer = setTimeout(() => setIsButtonVisible(true), 1000); // Adjusted to 1000ms
      return () => clearTimeout(timer);
    }, []);
    
    const buttonVariants = {
      hidden: { opacity: 0, y: 100 }, // Start the button lower on the Y-axis
      visible: { 
        opacity: 1, 
        y: 0, // Slide up to its final position
        transition: { 
          type: "spring", 
          stiffness: 120, 
          damping: 15, 
          delay: 0.3 // Adjust delay as needed
        }
      },
      hover: { 
        scale: 1.05, // Slightly smaller scale to avoid abruptness
        transition: { duration: 0.3 }
      }
    };

    const handleDrawerToggle = () => {
      setDrawerOpen((prev) => !prev);
      console.log("Drawer toggled. New state:", !drawerOpen);
    };
  
    console.log("Drawer open state:", drawerOpen);

   // Assuming CPrice is a dynamic value from somewhere in your app


const cleanPrice = CPrice / 4000;

  
    useEffect(() => {
      const initialBeanCost = CPrice / 4000;
        const initialCupCost = 3;
        const initialRetailCost = 18.00;
        const initialRoastGross = 10.00;
        const initialBulkGross = 12.00;
        const initialShopGross = 35.00;
        const increment = Number(cherryIncrement); // Convert string to number
        const newCherryCount = increment * 2000;
        const newBeanCount = increment * 4000;
        const newTreeCount = increment * 0.5;
        const newPoundCount = increment * 1;
        const newTotalCost = increment * 1.80;
        const cherryCost = 1.80 / 1625;
        const beanCost =  1.80 / 3250;
        const roastIncrement = initialBeanCost * 1.20;
        const bulkIncrement = roastIncrement * 1.25;
        const retailIncrement =  initialRetailCost /  4000; ;
        const shopIncrement = initialCupCost / 90;
        const homeIncrement = shopIncrement * 1.50;
        const newGross = increment * 20.00;
        const newRoastGross = increment * 10.00;
        const newBulkGross = increment * 12.00;
        const newShopGross = increment * 35.00;
        
        setTreeCount(newTreeCount);
        setBeanCount(newBeanCount);
        setCherryCount(newCherryCount);
        setPoundCount(newPoundCount);
        setTotalCost(newTotalCost);
        setCherryCost(cherryCost);
        setBeanCost(beanCost);
        
        setBeanCost(initialBeanCost);
        setRoastCost(roastIncrement);
        setBulkCost(bulkIncrement);
        setRetailCost(retailIncrement);
        setShopCost(shopIncrement);
        setHomeCost(homeIncrement);
        setRetailGross(newGross);
        setRoastGross(newRoastGross);
        setBulkGross(newBulkGross);
        setShopGross(newShopGross);

    }, [cherryIncrement]); // Removed unnecessary dependencies
    const handleChange = (event, newValue) => {
        setCherryIncrement(newValue);
    }
    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
  
    const handleOpen = (item) => {
      setCurrentItem(item);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setCurrentItem(null);
    };

 //open dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

 
   

    
  
    // Dynamically update the display value when the slider changes
    useMotionValueEvent(x, 'change', (latest) => {
      const roundedValue = Math.round(latest);
      setDisplayValue(roundedValue);
      setCherryIncrement(roundedValue); // Update the increment for cherries
    });
  
    const handleSliderChange = (event, newValue) => {
      setCherryIncrement(newValue);
      x.set(newValue); // Update the motion value to sync with slider change
    };
    const items = [
      { 
        label: 'Trees', 
        value: treeCount, 
        icon: <ForestOutlinedIcon />,
        about: 'The number of coffee trees being cultivated.'
      },
      { 
        label: 'Beans', 
        value: beanCount, 
        icon: <BeanIcon />, 
        about: 'The total count of coffee beans harvested.' 
      }, 
      { 
        label: 'Cherries', 
        value: cherryCount, 
        icon: <ForestOutlinedIcon />, 
        about: 'The number of coffee cherries picked from the trees.' 
      }, 
      { 
        label: 'Pounds', 
        value: poundCount, 
        icon: <BalanceIcon />, 
        about: 'The weight of processed coffee in pounds.' 
      }, 
      
      { 
        label: 'Retail Gross', 
        value: `$${retailGross.toFixed(2)}`, 
        icon: <ScaleOutlinedIcon />, 
        about: 'The gross revenue from retail coffee sales.' 
      },
      { 
        label: 'Bulk Gross', 
        value: `$${bulkGross.toFixed(2)}`, 
        icon: <ScaleOutlinedIcon />, 
        about: 'The gross revenue from bulk coffee sales.' 
      },
      { 
        label: 'Roast Gross', 
        value: `$${roastGross.toFixed(2)}`, 
        icon: <ScaleOutlinedIcon />, 
        about: 'The gross revenue from roasted coffee sales.' 
      },
      { 
        label: 'Shop Gross', 
        value: `$${shopGross.toFixed(2)}`, 
        icon: <ScaleOutlinedIcon />, 
        about: 'The gross revenue from shop coffee sales.' 
      },
    ];

    const beanbreakdown = [
      { 
        label: 'Farmer', 
        value: `$${cherryCost.toFixed(4)}`, 
        icon: <BeanIcon />, 
        about: 'The cost associated with processing coffee cherries.' 
      }, 
      { 
        label: 'Farmer', 
        value: `$${beanCost.toFixed(4)}`, 
        icon: <BeanIcon />, 
        about: 'The cost associated with processing coffee beans.' 
      }, 
      { 
        label: 'Roaster', 
        value: `$${roastCost.toFixed(4)}`, 
        icon: <BeanIcon/>, 
        about: 'The cost involved in roasting the coffee beans.' 
      },
      { 
        label: 'Bulk Step', 
        value: `$${bulkCost.toFixed(4)}`, 
        icon: <BeanIcon />, 
        about: 'The cost involved in packaging coffee in bulk.' 
      },
      { 
        label: 'Retail Step', 
        value: `$${retailCost.toFixed(4)}`, 
        icon: <BeanIcon />, 
        about: 'The cost involved in preparing coffee for retail sales.' 
      },
      { 
        label: 'Coffee Shop', 
        value: `$${shopCost.toFixed(4)}`, 
        icon: <BeanIcon />, 
        about: 'The cost involved in selling coffee in shops.' 
      },
      
    ];
    

// Sample data for the chart
const data = {
  labels: ['Green Coffee Beans', 'Roasting & Packaging', 'Miscellaneous', 'Net Profit'],
  datasets: [
    {
      data: [3.5, 2.5, 0.5, 3.5], // The breakdown of the costs
      backgroundColor: ['#077336', '#ffab91', '#81c784', '#64b5f6'], // Colors for each section
      hoverBackgroundColor: ['#ffb74d', '#ff8a65', '#66bb6a', '#42a5f5'], // Hover colors
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.label}: $${tooltipItem.raw.toFixed(2)} per lb`;
        },
      },
    },
  },
};

const CoffeeRoasterAnalysis = () => {
  const greenCoffeeCost = 3.5;
  const roastingPackagingCost = 2.5;
  const miscCost = 0.5;
  const wholesalePrice = 10;
  const totalCost = greenCoffeeCost + roastingPackagingCost + miscCost;
  const netProfit = wholesalePrice - totalCost;
  const netMargin = ((netProfit / wholesalePrice) * 100).toFixed(2);

  // Example data for Pie chart
  const data = {
    labels: ['Green Coffee Beans', 'Roasting & Packaging', 'Miscellaneous' , 'Net Profit'],
    datasets: [
      {
        label: 'Cost Breakdown',
        data: [greenCoffeeCost, roastingPackagingCost, miscCost, netProfit],
        backgroundColor: ['#addeb8', '#3d0c02', '#ffd700', '#077336'],
        hoverOffset: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to adjust its size
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <Box sx={{ padding: 0, width: '100%' }}>
      

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent sx={{ borderRadius: 5, boxShadow:0 }}>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: 800 }}>
                Cost Breakdown
              </Typography>
              <TableContainer component={Paper} sx={{  }}>
                <Table aria-label="cost breakdown table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Cost ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Green Coffee Beans</TableCell>
                      <TableCell align="right">{greenCoffeeCost}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Roasting & Packaging</TableCell>
                      <TableCell align="right">{roastingPackagingCost}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Miscellaneous</TableCell>
                      <TableCell align="right">{miscCost}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><strong>Total Cost</strong></TableCell>
                      <TableCell align="right"><strong>{totalCost}</strong></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: 800 }}>
                Profit and Margin
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="profit and margin table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Value ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Wholesale Price</TableCell>
                      <TableCell align="right">{wholesalePrice}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Net Profit</TableCell>
                      <TableCell align="right">{netProfit}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><strong>Net Margin (%)</strong></TableCell>
                      <TableCell align="right"><strong>{netMargin}%</strong></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: 'center', marginTop: 4, height: { xs: '200px', sm: '300px' } }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Cost vs Profit Visualization
        </Typography>
        <Pie  options={options}  data={data}/>
      </Box>
    </Box>
  );
};



    const FarmerEarnings = () => {
      // Data for the chart
      const data = {
        labels: ['Farmer Earnings', 'Farmer Costs', 'Net Profit'],
        datasets: [
          {
            label: 'Amount ($)',
            data: [Farmgate, FarmerCost, FarmerNet], // Farmer earnings, costs, and net profit
            backgroundColor: ['#4caf50', '#f44336', '#2196f3'],
          },
        ],
      };
    
      // Chart options
      const options = {
        responsive: true,
        plugins: {
          legend: { display: true, position: 'top' },
          title: { display: true, text: 'Farmer Profit Analysis (75% C - Price)' },
        },
      };
    
      return (
       
        <Grid container spacing={2} sx={{  }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ }}>
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 800, marginBottom: 2 }}>
                  Coffee Farmer Profit Analysis
                </Typography>
                <Typography variant="body1">
                  This analysis shows the farmer's earnings based on receiving 75% of the C - price per pound.
                </Typography>
                <Box mt={2}>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Average C-Price:</strong> ${CPrice}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Fairtrade Price:</strong> ${Fairtrade}
                  </Typography>
                  
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Average Farmer's Earnings:</strong> ${Farmgate} 
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong> Average Farmer's Costs:</strong> ${FarmerCost} per pound
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Average Net Profit:</strong> ${FarmerNet} per pound
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
    
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Bar data={data} options={options} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      );
    };
    
    const CoffeeProfitCalculator = () => {
      // Input values
      const cupsPerBatch = 50; // Number of cups per 2 lbs of coffee
      const pricePerCup = 3.5; // Price per cup of coffee
      const coffeeCostPerLb = 10; // Wholesale cost of 1 lb of coffee
      const milkCostPerGallon = 4; // Cost of 1 gallon of milk
      const laborCostPerHour = 15; // Labor cost per hour
      const cupCost = 0.20; // Cost per cup (cup/lid/sleeve)
      const overheadCostPerCup = 0.50; // Miscellaneous overhead cost per cup
    
      // Calculations
      const revenue = cupsPerBatch * pricePerCup;
      const coffeeCost = 2 * coffeeCostPerLb; // 2 lbs of coffee
      const milkCost = (50 * 2) / 128 * milkCostPerGallon; // Milk cost for 32 milk-based drinks (8 oz each)
      const cupsCost = cupsPerBatch * cupCost;
      const laborCost = laborCostPerHour; // Assume 1 hour of labor for 64 cups
      const overheadCost = cupsPerBatch * overheadCostPerCup;
      const totalCosts = coffeeCost + milkCost + cupsCost + laborCost + overheadCost;
      const netProfit = revenue - totalCosts;
    
      
    
      return (
        <div style={{  }}>
          <h4>Coffee Shop Average Earning on Beans</h4>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Description</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Amount ($)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Revenue (50 12 0z cups @ $3.50)</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{revenue.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Cost of Coffee (2 lbs @ $10/lb)</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{coffeeCost.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Cost of Milk (50 drinks, 2 oz each)</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{milkCost.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Cost of Cups/Lids/Sleeves</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{cupsCost.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Labor Cost (1 hour @ $15)</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{laborCost.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Miscellaneous Overhead</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{overheadCost.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px', fontWeight: 'bold' }}>Total Costs</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>{totalCosts.toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px', fontWeight: 'bold' }}>Net Profit</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>{netProfit.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }; 
    return (
      
      <Dialog fullScreen open={openDialog} onClose={() => setOpenDialog(false)}>
       
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, width: '100%' }}>
        <Close onClick={() => window.history.back()} />
                <Typography variant="h6" sx={{ fontWeight: 700, textAlign: 'center',  }}>
                Bean Counter
                </Typography>
                </Box>
            <FormControl sx={{boxShadow: 0,}} >
                
                {/* Other inputs remain unchanged, ensuring readOnly inputs are not directly editable */}

                <Box sx={{ flexGrow: 1, padding: 0,   }}>
                         
             
                <AnimatePresence>
                
                <Grid container spacing={2} sx={{ padding: 2 }}>
  {items.map((item, index) => (
    <Grid item xs={3} sm={6} md={4} key={item.label}>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.5, ease: 'easeOut', delay: index * 0.2 }}
        style={{ padding: '10px', borderRadius: '5px', textAlign: 'center', fontSize: '12px', }}
        onClick={() => handleOpen(item)}
      >
        
        {/* Custom Circular Shape */}
        <Box 
          sx={{ 
            width: '80px', 
            height: '80px', 
            borderRadius: '50%', 
            border: '0px solid ', // The color of the circle
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            position: 'relative',
            background: 'conic-gradient(#74bc92 0% 60%, #ddd 60% 100%)', // This is the progress effect
          }}
        >
          <motion.div
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              top: 0,
              left: 0,
              background: `conic-gradient(#74bc92 0% ${item.value}%, #ddd ${item.value}% 100%)`,
            }}
            animate={{ background: `conic-gradient(#74bc92 0% ${item.value}%, #ddd ${item.value}% 100%)` }}
            transition={{ duration: 1.5, ease: 'easeInOut' }} // Smooth animation
          />
          {/* The value inside the circle */}
          <Typography 
            variant="body1" 
            fontWeight={600} 
            sx={{ position: 'absolute',  }} // Value inside the circle
          >
            {item.value}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingTop: 2 }}>
          <Typography variant="caption">{item.label}</Typography>
          
        </Box>
      </motion.div>
    </Grid>
  ))}
</Grid>

    </AnimatePresence>
<FormControl fullWidth>

        <Divider sx={{ width: '100vw', position: 'relative', left: '50%', right: '50%', transform: 'translateX(-50%)' }} />   
               
   <Modal  open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
             <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '5px' }}>
               <Typography id="modal-modal-title" variant="h6" component="h2">
                 {currentItem?.label}
               </Typography>
               <Typography id="modal-modal-description" sx={{ mt: 2,  }}>
                 {currentItem?.value}
               </Typography>
               <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                 {currentItem?.about}
               </Typography>
             </Box>
           </Modal>
           <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1, borderRadius: 5, boxShadow: 0, mt: 0, mb: 0 }}>
              <Typography variant="body2" sx={{ fontWeight: 600,  }}>
                Earning per Bean through Value Chain
              </Typography>
              </Box>
           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', padding: 1, border: 'solid 0px #000', borderRadius: 5, mt: 0}}>
           
    {/*  <Grid container spacing={2}>
  {beanbreakdown.map((item, index) => (
   <Grid item xs={3} sm={4} key={index}>
   <Card onClick={() => handleOpen(item)} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, 
                borderRadius: '10px', boxShadow: 0, mt: 0 }}>
                
     <Typography   variant="caption" sx={{ fontWeight: 600,  }}>
       {item.label}
     </Typography>
     <Typography variant="body2" sx={{ fontWeight: 300  }}>
       {item.value}
     </Typography>
     
   </Card>
 </Grid>
  ))}
</Grid>
//  */}
    </Box>
           </FormControl>
           
           <Grid container spacing={2} sx={{ padding: 2 }}>
      {/* Coffee Shop Breakdown */}
      

      {/* Farmer Breakdown */}
      <Grid item xs={6} md={4}>
        <Chip 
          onClick={handleDrawerToggle2} 
          label="Farmer Analysis" 
          icon={<AgricultureOutlined/>} 
          sx={{ width: '100%' }} 
        />
      </Grid>
    <Grid item xs={6} md={4}>
        <Chip
          onClick={handleDrawerToggle3}
          label="Roaster Analysis"
          icon={<CoffeeMakerOutlined />}
          sx={{ width: '100%' }}
        />
      </Grid>

      <Grid item xs={6} md={4}>
        <Chip 
          onClick={handleDrawerToggle} 
          label="Coffee Shop Analysis" 
          icon={<StorefrontOutlined />} 
          sx={{ width: '100%' }} 
        />
      </Grid>

      {/* Total Cost */}
      <Grid item xs={6} md={4}>
       
      </Grid>
    </Grid>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, width: '100%' }}>
     <Typography variant="h6" sx={{ fontWeight: 700, textAlign: 'center',  }}>
        Farmer Earnings Quick View
      </Typography>
      </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, width: '100%' }}>
    
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
        <Chip 
          label={`C - price $${CPrice}`} 
          
          sx={{ 
            backgroundColor: '#ebecf0', 
            color: '#077336', 
            borderRadius: 5, 
            cursor: 'default',
          }} 
        />
         </Grid>
     
      <Grid item xs={4} md={4}>
      <Chip label={`Gross $${totalCost}`}  sx={{ backgroundColor: '#ebecf0', color: '#077336', borderRadius: 5, cursor: 'default' }} />
      </Grid>
      <Grid item xs={4} md={4}>
      <Chip 
    label={`$${Farmgate}`} 
    icon={<FarmerIcon />} 
    sx={{ 
      backgroundColor: '#ebecf0', color: '#077336', borderRadius: 5, cursor: 'default' 
    }} 
  />
      </Grid>
      </Grid>
      </Box>
          <Modal sx={{boxShadow: 5}} open={drawerOpen} onClose={handleDrawerToggle} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box 
        sx={{ 
          position: 'relative', 
          width: 400, 
          bgcolor: 'background.paper', 
          p: 2, 
          m: 'auto', 
          mt: '10%',
          borderRadius: 5,
          boxShadow: 24,
          
        }}
      >
        {/* Close Icon inside the modal */}
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
          Coffee Shop Analysis
        </Typography>
            <CoffeeProfitCalculator />
            </Box>
          </Modal>
          
          <>
   
   

    {/* Modal */}
    <Modal 
      open={drawerOpen2} 
      onClose={handleCloseDrawer2} // Close the modal on outside click
      aria-labelledby="modal-modal-title" 
      aria-describedby="modal-modal-description"
    >
      <Box 
        sx={{ 
          position: 'relative', 
          width: 400, 
          bgcolor: 'background.paper', 
          p: 2, 
          m: 'auto', 
          mt: '10%',
          borderRadius: 5,
          boxShadow: 24,
        }}
      >
        {/* Close Icon inside the modal */}
        <IconButton 
          onClick={handleCloseDrawer2} 
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>

        {/* Modal Content */}
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Farmer Earnings
        </Typography>
        <FarmerEarnings />
      </Box>
    </Modal>
  </>   

  <>
  <Modal
      open={drawerOpen3}
      onClose={handleCloseDrawer3}
      aria-labelledby="coffee-roaster-analysis-title"
      aria-describedby="coffee-roaster-analysis-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%' },
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 1,
          borderRadius: '8px',
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        <IconButton
          onClick={handleCloseDrawer3}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
        <Typography id="coffee-roaster-analysis-title" variant="h6" component="h2" sx={{ marginBottom: 0 }}>
          Coffee Roaster Analysis
        </Typography>
        <CoffeeRoasterAnalysis />
      </Box>
    </Modal>
  </>  
        </Box> 
        <AnimatePresence>
  {isButtonVisible && (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      
      variants={buttonVariants}
      style={{ 
        position: 'fixed',
        bottom: 0, 
        right: 16, 
        left: 16, // Add left to stretch to the right
        zIndex: 1300 
      }}
    >
     
     <Divider sx={{ width: '100vw', position: 'relative', left: '50%', right: '50%', transform: 'translateX(-50%)' }} />   
    
      <DialogActions  sx={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center', padding: 1,  width: '100%', mb: 2, mt: 0 }}>
      <Box 
  sx={{ 
    padding: 0, 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'center',  // Center vertically
    alignItems: 'center',       // Center horizontally
    width: '60%',              // Ensure Box takes full width
    height: '100%',             // Ensure Box takes full height
    position: 'relative'        // Set the position to relative for absolute positioning of chips
  }}
>
  {/* Left Chip */}
 

  {/* Centered Donut */}
  <Donut
    diameter={100}
    min={0}
    max={100}
    step={2}
    value={cherryIncrement}
    theme={{
        donutColor: '#077336',
    }}
    onValueChange={setCherryIncrement}
    ariaLabelledBy={'my-label'}
  />

  {/* Right Chip */}
 
 
</Box>
<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, width: '100%' }}>
  <Typography variant="caption" sx={{  }}>
    Spin Dial to Count Beans
  </Typography>

  <img src="https://storage.googleapis.com/app_darkendimg/newton.png" alt="beans" style={{ width: 120, height: 120 }} />
  </Box>

    </DialogActions>
    </motion.div>
  )}
</AnimatePresence>
            </FormControl>
            
            
        
    </Dialog>
    );
}

export default KoffieCalc;
