import React, { useState } from 'react';
import '../../context/EarthMode.css'; // Make sure to create this CSS file with the defined styles
import useKupToken from '../../blockchain/useKupToken';


const Contracts = () => {
  const [earthMode, setEarthMode] = useState(false);
  const { totalCherries } = useKupToken();
  const toggleEarthMode = () => {
    setEarthMode(!earthMode);
  };

  return (
    <div className={earthMode ? 'earth-mode-container' : 'light-mode-container'}>
      <h1>Welcome to My App</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus aliquam,
        velit at auctor sagittis, neque velit aliquet odio.
      </p>
      <button onClick={toggleEarthMode}>
        {earthMode ? 'Switch to Light Mode' : 'Switch to Earth Mode'}
      </button>
    </div>
  );
};

export default Contracts;
