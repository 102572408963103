import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const DevelopmentCard = () => {
  return (
    <Card sx={{
      maxWidth: 400,
      margin: 'auto',
      mt: 5,
      boxShadow: 3,
      borderRadius: 2,
      background: 'linear-gradient(135deg, #e0e0e0, #f5f5f5)',
    }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#ffeb3b',
          borderRadius: '50%',
          width: 80,
          height: 80,
          mb: 2
        }}>
          <ConstructionIcon sx={{ fontSize: 50, color: '#f57c00' }} />
        </Box>
        <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: '#424242' }}>
          Crafting Delivery System
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1, textAlign: 'center', color: '#616161' }}>
          This feature is currently in development. Stay tuned for updates!
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DevelopmentCard;
