import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Card, Avatar, Typography, Grid, LinearProgress, Button, 
  Fab, Dialog, DialogActions, Chip, DialogTitle, DialogContent, CardContent, DialogContentText, 
Drawer, CardActions, 
} from '@mui/material';
import { gql } from '@apollo/client';


import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale , } from 'chart.js/auto'; 
// Register the moment adapter
import moment from 'moment';
import { registerAdapterDateFns } from 'chartjs-adapter-moment'; 
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Progress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/usercontext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import kcoin from '../../images/kcoin.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GaugeChart from 'react-gauge-chart'
import Flavloader from './flavloader';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Chron from '../../pages/chron';

import PriceTicket from '../finance/prods/pricefeed';
import BeanCard from './beancard';
import GoalCard from '../finance/adds/goalcard';


const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';
const minted = 'https://storage.googleapis.com/app_darkendimg/assets/kbean.png';
const koffie = 'https://storage.googleapis.com/app_darkendimg/assets/cherrybranch.png';

const useStyles = makeStyles((theme) => ({
  progressAndChart: {
    display: 'flex', 
    
  },
  progressContainer: {
    width: 300, 
    height: 30,
    border: '1px solid #ddd', 
    borderRadius: 5,
  },
  progressFiller: {
    backgroundColor: 'lightgreen', 
  },
  chartContainer: {
     width: '100%',
  },
  chart: {
    height: 400,
  },
  progressText: {
    textAlign: 'center',
    fontWeight: 700,
  },
  progressPercent: {
    fontWeight: 700,
    color: 'green',
  },
  progressLabel: {
    fontWeight: 400,
    color: 'black',
  },
  progressChip: {
    borderColor: 'green',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'white',
  },
  progressChipLabel: {
    color: 'green',
  },
  progressChipIcon: {
    color: 'green',
  },
  progressChipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
  },
  progressChipText: {
    fontWeight: 400,
    color: 'green',
  },
  progressChipValue: {
    fontWeight: 700,
    color: 'green',
  },
  progressChipIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
  },
  progressChipLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  needlelenth: {
    width: 400,
  },
}));




// Register the moment adapter

ChartJS.register(CategoryScale);


function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}


const GET_TOP_USERS = gql`
  query GetTopUsers {
    userprofs {
      flavorprofile
      Nickname
      tasteProfile
      description
      name
      purchaseLink
      Koffie_story
      usertype
      _id
      country
      beanCount 
      imageurl
    }
  }
`;

const TopBean = () => {
  const navigate = useNavigate();
 

  
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl, _id } = user.customData
  const classes = useStyles();
  const [chartReady, setChartReady] = useState(false); 
  const { data, loading, error } = useQuery(GET_TOP_USERS, {
    fetchPolicy: "network-only", // Ignores the cache and fetches directly from the network
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  

  const handleNavigate = () => {
    navigate('/beanwatch');
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const renderDrawer = () => (
    <Drawer
      anchor="right" // You can change the anchor if needed
      open={isDrawerOpen}
      onClose={handleDrawerClose}
    >
     
      
      {/* Content of the drawer goes here */}
      <Box sx={{  padding: 2 }}>
       
          
          
      <IconButton onClick={handleDrawerClose}>
        <CloseIcon />
      </IconButton>
        <Chron  />
      
      
        </Box>
        <Box sx={{ flexDirection: 'row',  justifyContent: 'center', mt: 2 }}>
        <Card sx={{ maxWidth: 345,  }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Tryanny
          </Typography>
          
          </CardContent>
          <Typography variant="h4" sx={{
      fontWeight: 300,
      color: 'text.secondary',
      textAlign: 'right', // AligExploren text to the right
      paddingRight: 2,
      paddingTop: 1,
          }}>
            At a time like this, scorching irony, not convincing argument, is needed.
          </Typography>
            
          <CardActions>
            <Button size="small">Share</Button>
            <Button size="small">Learn More</Button>
          </CardActions>
          </Card>
        </Box>
    </Drawer>
  );
  




  
  const handlePresidentialButtonClick = (user) => {
    if (user._id !== _id) {
      setOpenDialog(true);
    } else {
      navigate('/us');
    }
  };
  
  const handleBoardButtonClick = (user) => {
    if (user._id !== _id) {
      setOpenDialog(true);
    } else {
      navigate('/us');
    }
  };
  
const open = () => {
  setOpenDialog1(true);
};
  
  const upgrade = () => {
    setOpenUpgrade(true);
  };

  useEffect(() => {
    // Assuming your data populates chartData somehow
    // When you receive your data and update chartData, also:
    setChartReady(true); 
  }, [data]); // Or another dependency depending on when you receive data 

      
  if (loading) return <Flavloader/>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  
  // Ensure data.userprof exists and is an array, then sort by beanCount and slice top 5
  const { userprofs: topUsers } = data;

  // Calculate total bean count across all usersUpgrade
  const totalBeanCount = topUsers.reduce((acc, user) => acc + user.beanCount, 0);
  console.log(totalBeanCount);

  // Sort users based on bean count (descending order)
  // Create a copy of topUsers arrayprogressPercent
const topUsersCopy = [...topUsers];

// Sort the copy based on bean count (descending order)
topUsersCopy.sort((a, b) => b.beanCount - a.beanCount);

// Take the top five users from the sorted copy
const topFiveUsers = topUsersCopy.slice(0, 100);
//display Top bean user
const isPresidential = topUsersCopy.slice(0, 1);
const isBoard = topUsersCopy.slice(1, 5);

const tokens = 91520000 ;
const progressPercent = (totalBeanCount / tokens) * 100; // Multiply by 100 to get percentage

const isSmartCitizen = beanCount?.$numberInt > 500;
const availabletokens = tokens - totalBeanCount;
const normalizedProgress = progressPercent * 100;
const cabinet = [
  { name: 'Secratary of State', role: 'state' , Link: '/',  },
  { name: 'Secratary of Defense', role: 'defense', Link: '/chat' ,  },
  { name: 'Secratary of Treasury',role: 'treasury', Link: '/us',  },
  { name: 'Secratary of Education',  role: 'education', Link: '/us',},
  { name: 'Secratary of Energy',  role: 'energy', Link: '/us', },
  { name: 'Secratary of Agriculture', role: 'agriculture', Link: '/us',  },
  { name: 'Secratary of Commerce',role: 'commerce', Link: '/us',  },
  { name: 'Secratary of Labor', role: 'labor',Link: '/us' ,},
  { name: 'Secratary of Health and Human Services',role: 'health',Link: '/us' ,},
  { name: 'Secratary of Housing and Urban Development',  role : 'urban', Link: '/us', },
  { name: 'Secratary of Transportation',role: 'transportation' ,  Link: '/us', },
  { name: 'Secratary of the Interior',role: 'interior', Link: '/us',  },
  { name: 'Secratary of Veterans Affairs', role: 'veterans', Link: '/us',  },
  { name: 'Secratary of Homeland Security,', role: 'homeland' , Link: '/us' ,},
];
 // Chart Data Preparation (assuming you want to visualize beanCounts of top users)
 const sortedUsers = topFiveUsers.sort((a, b) => a.beanCount - b.beanCount);
 const chartData = {
   datasets: [
     {
       label: 'Top Users Bean Count',
       data: sortedUsers.map((user) => ({
         x: user.Nickname, // Use nicknames on X-axis
         y: user.beanCount,
         img: user.imageurl,
         r: 20, // Adjust the base radius 
         progressPercent: (user.beanCount / tokens) * 100, 
       })),
     },
   ],
 };
<Typography variant="body1" color='text.secondary' sx={{ fontWeight: 600 }}>
{formatBeanCount(totalBeanCount)} In Circulation
</Typography>

const BarChartWithProgress = ({ chartData }) => {
  const data = {
    labels: chartData.datasets[0].data.map((dataPoint) => dataPoint.x), // User nicknames
    datasets: [
      {
        type: 'scatter',
        label: 'Top Users Bean Count', 
        data: chartData.datasets[0].data.map((dataPoint) => dataPoint.y), // Bean Counts
        backgroundColor: '#32a852', // Green color with transparency
       
       
        
        render: (args) => {
         
          const { chart } = args;
          const { ctx, chartArea } = chart;
          const { data } = args.dataset;
          data.forEach((dataPoint, index) => {
            const { x, y, progressPercent } = dataPoint;
            const { left, right, top, bottom } = chartArea;
            const xCoord = left + (right - left) * chart.data.datasets[0].data[index].x / chart.data.labels.length;
            const yCoord = top + (bottom - top) * chart.scales.y.getValueForPixel(y);
            ctx.save();
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`${x}: ${formatBeanCount(y)}`, xCoord, yCoord - 20);
            ctx.fillText(`${progressPercent.toFixed(2)}%`, xCoord, yCoord + 20);
            ctx.restore();
          });
        }
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category', // Use 'category' scale for the x-axis
        ticks: {
          callback: (value) => value, // Display the unique identifier as the x-axis label
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => formatBeanCount(value),
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        color: 'black',
        
        font: {
          weight: 'bold',
        },
        formatter: (value, context) => {
          return `${context.dataset.data[context.dataIndex].x}: ${formatBeanCount(context.dataset.data[context.dataIndex].y)}`;
        },
      },
    },
    elements: {
      point: {
        radius: 3, // Adjust the radius to make the points bigger
        hoverRadius: 12, // Adjust the hover radius if needed
        hitRadius: 15, // Adjust the hit radius if needed
      },
    },
  };
  

  return <Scatter data={data} options={options} />;
};
console.log(topFiveUsers);
console.log(totalBeanCount);
console.log(tokens);
console.log(chartData);

BarChartWithProgress.propTypes = {
  chartData: PropTypes.shape({
    datasets: PropTypes.array.isRequired,
  }).isRequired,
};


  
  return (
    <>
   

   <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        borderRadius: 0,
        boxShadow: 0,
        marginTop: 0,
        position: 'relative',
        width: '100%', // Ensures full viewport width
        height: '60vh',
        zIndex: 1,
        overflow: 'hidden', // Prevents overflow
      }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src="https://storage.googleapis.com/app_darkendimg/assets/pick1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'absolute',
        top: '15px',
        right: '-3px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: 0,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        padding: 0,
      }}>
        <Card sx={{ display: 'flex', flexDirection: 'column', padding: 1, alignItems: 'center', backgroundColor: 'transparent', mb: 0 }}>
          <Typography variant="body2" sx={{ fontWeight: 600, color: 'black', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}>
            23K lbs
          </Typography>
          <img src={koffie} alt="Minted" width={20} style={{ boxShadow: '10px' }} />
          <Typography variant="caption" color="black" sx={{ fontWeight: 300 }}>
            Goal
          </Typography>
        </Card>
        <Card sx={{ display: 'flex', flexDirection: 'column', padding: 1, alignItems: 'center', backgroundColor: 'transparent', mb: 0 }}>
          <Typography variant="body2" sx={{ fontWeight: 600, color: 'black', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}>
            {formatBeanCount(availabletokens)}
          </Typography>
          <img src={minted} alt="Minted" width={20} style={{ boxShadow: '10px' }} />
          <Typography variant="caption" color="black" sx={{ fontWeight: 300 }}>
            Available
          </Typography>
        </Card>
        <Card sx={{ display: 'flex', flexDirection: 'column', padding: 1, alignItems: 'center', backgroundColor: 'transparent', mb: 0 }}>
          <Typography variant="body2" sx={{ fontWeight: 600, color: 'white', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}>
            {formatBeanCount(totalBeanCount)}
          </Typography>
          <img src={coffeebeans} alt="Beans" width={20} style={{ boxShadow: '10px' }} />
          <Typography variant="caption" color="black" sx={{ fontWeight: 300 }}>
            Circulating
          </Typography>
        </Card>
        
        <Card sx={{ display: 'flex', flexDirection: 'column', padding: 1, alignItems: 'center', backgroundColor: 'transparent' }}>
    <Typography variant="body2" sx={{ fontWeight: 800, color: 'white' }}>
      {progressPercent < 100 && ( // Adjust condition if needed
        <Typography variant="caption" sx={{ fontWeight: 600, color: '#000', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}>
          {progressPercent.toFixed(2)}% {/* This will now show 80.00% */}
        </Typography>
      )}
    </Typography>
    <Typography variant="caption" sx={{ fontWeight: 300, color: '#000', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}>
      Progress
    </Typography>
  </Card>
      </Box>

      <Grid container spacing={2} justifyContent="center" onClick={open}>
        <Grid item xs={12} sm={12} md={12} container direction="column" alignItems="left" sx={{ padding: 0 }}>
          <Typography variant="h2" sx={{  fontWeight: 800, color: 'white', paddingTop: 0, mb: 0, mt: 30, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.9)' }}>
            KaaF
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 700, color: 'white', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.9)' }}>
            Koffie as a Function
          </Typography>
          <Button  onClick={handleNavigate} sx={{ marginTop: 1, backgroundColor: "#ffc107", border: 'solid 1px #000', borderRadius: 10, padding: 0, width: 250 }}>
            <Typography variant="h6" sx={{ fontWeight: 700, color: '#000', textShadow: '2px 2px 8px rgba(0, 0, 0, ,0.9)'  }}>
              View Function
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
    


    
      
<Dialog fullScreen open={openDialog1} onClose={() => setOpenDialog1(false)} sx={{}}>
<Box sx={{
  display: 'flex',
  flexDirection: 'row',  // Change to 'row' for horizontal layout
  justifyContent: 'space-between',
  padding: 0,
  borderRadius: 0,
  boxShadow: 0,
  marginTop: 0,
  
}}>
  
  <Typography variant="h6" sx={{ fontWeight: 800, color: '#077336', textAlign: 'left', mt: 2, mb: 0, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          Commodity Watch
        </Typography>
        <IconButton
    onClick={() => setOpenDialog1(false)}
    sx={{
      marginLeft: 0,  // Ensures there's no additional space on the left
      color: 'primary.main',  // Optional: change the icon color if needed
      backgroundColor: 'transparent'  // Ensures the button background is transparent
    }}
  >
    <CloseIcon />
  </IconButton>
</Box>

<DialogTitle>
 
      <PriceTicket />
        
</DialogTitle>
<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 1, borderRadius: 5, border: 'solid 1px #077336',  marginTop: 0 }}>


    <Box sx={{ display: 'flex', alignItems: 'center',  }}>
    
    <Box sx={{  display: 'flex', alignItems: 'center', padding: 0,  boxShadow: 0, backgroundColor: 'transparent', marginTop: 2 }}>  
    
    <Grid item xs={12} container direction="column" alignItems="center" sx={{mt: 1, padding: 2}}>
    
<Typography variant="body2" sx={{ fontWeight: 800, color: 'white' }}>
      {progressPercent < 100 && ( // Adjust condition if needed
        <Typography variant="h4" sx={{ fontWeight: 900, color: '#077336', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}>
          {progressPercent.toFixed(2)}% {/* This will now show 80.00% */}
        </Typography>
      )}
    </Typography>
    <Typography variant="body1" sx={{ fontStyle: 'italic', fontWeight: 400, color: 'text.secondary', textAlign: 'center', mt: 0, mb: 0, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)' }}>
      of 100%
    </Typography>
</Grid>
  
    </Box>
    <BeanCard />
    
    
  </Box>
  
  <Dialog open={openUpgrade} onClose={() => setOpenUpgrade(false)}>
    <IconButton
      onClick={() => setOpenUpgrade(false)}
      sx={{
        marginLeft: 0,  // Ensures there's no additional space on the left
        color: 'primary.main',  // Optional: change the icon color if needed
        backgroundColor: 'transparent' , // Ensures the button background is transparent
        position: 'absolute', top: 8, right: 8, fontSize: 2, 
      }}
    >
      <CloseIcon />
    </IconButton>
        <DialogTitle variant='h5' sx={{fontWeight: 600}}>Upgrade to Smart Citizen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Become a lifetime Smart Citizen and enjoy all the benefits of being a citizen of KoffieLand with your first Purchase of 1lb, 4000 KoffieBeans.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Chip label="Upgrade" onClick={() => navigate('/store')} sx={{ backgroundColor: '#32a852', color: '#fff' }} />
        </DialogActions>
      </Dialog>
  

  <Grid container spacing={2} sx={{mt: 0}} > 
  <Grid item xs={4} container direction="column" alignItems="center"> {/* Center items vertically */}
  
    <EmojiEventsIcon color='gold' sx={{color: '#a57c00', fontSize: '30px'}}/>
  
  <Typography variant="body1" sx={{ fontWeight: 500 }}>
    {formatBeanCount(tokens)}
  </Typography>
  <Typography variant="body2" color='text.secondary' sx={{ fontWeight: 400 }}>
    Goal
  </Typography>
</Grid>
  <Grid item xs={4} container direction="column" alignItems="center">
 
 <img src={minted} alt='bean' width={30} />

<Typography variant="body1" sx={{ fontWeight: 500 }}>
 {formatBeanCount(availabletokens)} 
</Typography>
<Typography variant="body2" color='text.secondary' sx={{ fontWeight: 400 }}>
 Available
</Typography>
</Grid>


<Grid item xs={4} container direction="column" alignItems="center">
<img src={coffeebeans} alt='bean' width={30} />
    <Typography variant="body" sx={{ fontWeight:500 }}>
    {formatBeanCount(totalBeanCount)} 
  </Typography>
  
  <Typography variant="body2" color='text.secondary' sx={{ fontWeight: 400 }}>
    Circulating
  </Typography>
</Grid>
</Grid>

  </Box>

  {chartReady &&  
        
        <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, borderRadius: 5, boxShadow: 0 , mt: 2}}
        > {/* Apply to the chart container */} 
          <BarChartWithProgress chartData={chartData} />
        </Box>
      } 

      <GoalCard />
     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, borderRadius: 5, boxShadow: 0, marginTop: 0,

    }}>
    
      <Typography variant="h6" color='text.secondary' sx={{ fontWeight: 600, marginBottom: '16px' }}>
        Big Bean Club
      </Typography>
      
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {isPresidential.map((user, index) => (
          <div key={index} style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginRight: '16px' }}>
            <Avatar src={user.imageurl} alt={user.Nickname} sx={{ width: 100, height: 100, border: '4px solid', borderColor:'#000' }} />
            <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 400 }}>
              {user.Nickname} is the President of KoffieLand
            </Typography>
            <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 800 }}>
            {formatBeanCount(user.beanCount)}
            </Typography>
            <Chip
             sx={{
              borderColor: '#32a852', // Green border color
              borderWidth: '1px', // Border width
              borderStyle: 'solid', // Border style
              backgroundColor: '#fff', // Green background color
            
            }}
            label="Access" onClick={() => handlePresidentialButtonClick(user)} />
          </div>
          
        ))}
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>You Don't Have Access</DialogTitle>
        <DialogContent>
          <Typography variant="body1">You are not a Presidential member and do not have access to this feature.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 400, marginTop: '16px', marginBottom: '8px' }}>
        KoffieLand Board Members
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {isBoard.map((user, index) => (
          <div key={index} style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginRight: '16px' }}>
            <Link to={`/userprof/${user._id}`}>
            <Avatar label={user.beanCount} src={user.imageurl} alt={user.Nickname} sx={{ width: 60, height: 60, border: '4px solid', borderColor:'#32a852' }} />
            </Link>
            <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 400 }}>
              {user.Nickname} 
            </Typography>
            <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 800 }}>
            {formatBeanCount(user.beanCount)}
            </Typography>
            <Chip 
             label="Access" onClick={() => handleBoardButtonClick(user)} 
             sx={{
              borderColor: '#32a852', // Green border color
              borderWidth: '1px', // Border width
              borderStyle: 'solid', // Border style
              backgroundColor: '#fff', // Green background color
            
            }}/>
          </div>
        ))}
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, mb: 1, borderRadius: 0, boxShadow: 0, overflow: 'auto',  }}>
  <Grid container spacing={2} justifyContent="center">
    {topFiveUsers.map((user) => (
      <Grid item xs={2} sm={4} md={2} key={user._id}>
        <Card sx={{ display: 'grow', padding: 1, alignItems: 'center' }}>
        <Link to={`/userprof/${user._id}`}>
          <Avatar src={user.imageurl} alt={user.Nickname} sx={{ width: 40, height: 40 }} />
          </Link>
          <Typography variant="caption">{user.Nickname}</Typography>
          <img src={coffeebeans} alt='bean' width={20} />
          <Typography variant="body2" sx={{ fontWeight: 700 }}>{formatBeanCount(user.beanCount)} </Typography>
        </Card>
      </Grid>
    ))}
  </Grid>
</Box>
    </Box>

    


      </Dialog>
      
   
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>You Don't Have Access</DialogTitle>
        <DialogContent>
          <Typography variant="body1">You are not a Presidential member and do not have access to this feature.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      
    
    </>
  );
};

export default TopBean;
