import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const coin = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';

const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: '#00cc99',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center', // Centers the button horizontally
        alignItems: 'center', // Centers the button vertically if needed
        width: '100px',
        height: '40px',
        borderRadius: 20,
        backgroundColor: '#3ff107', // Green color, you can change it as per your theme
        color: 'black',
        border: 'none',
        marginTop: 30,
        fontWeight: 500,
        padding: 1,
        '&:hover': {
            backgroundColor: '#fff', // Darken the color a bit when hovering
        },
    },
}));




function BeanIcon () {
    return (
        <img src={coin} alt="Bean Icon" style={{width: 15, height: 15, marginBottom: 10, marginLeft: 4}} />
    )
}

function CustomTabPanel(props) {
    
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{padding: 0, borderRadius: 0, boxShadow: 0, mt: 1, }}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RewardsBanner() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ paddingTop: 1, justifyContent: 'center', alignItems: 'center'  , mb: 1 , mt: 0, border: 'solid 0px #000'}}>
    
      <Box>
        
        <Tabs
        
        variant='fullWidth'
      

        TabIndicatorProps={{
            style: {
              backgroundColor: "#077336",
              height: 4,
              borderRadius: 0,
              
          },
          }}
        value={value} onChange={handleChange} aria-label="basic tabs example" >
          <Tab  label="1000" {...a11yProps(0)}   />
          <Tab  label="2000" {...a11yProps(1)} />
          <Tab  label="3000" {...a11yProps(2)} />
          <Tab label="4000" {...a11yProps(3)} />
        </Tabs>
       
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      padding: 0, 
      borderRadius: 10, 
      boxShadow: 0, 
      marginTop: 0, 
      position: 'relative', 
      width: 'calc(100% + 1px)', // Adjust the calculation based on actual body padding
      height: '100%',
      marginLeft: '-1px', 
      marginRight: '-1px' ,
      
      zIndex: 1,
    }}>
      <video 
  autoPlay 
  loop 
  muted 
  playsInline 
  style={{ 
    position: 'absolute', 
    padding: 0,
    marginLeft: '-1px', 
    marginRight: '-1px',
    height: '100%', 
    objectFit: 'cover', 
    zIndex: -1,
    width: 'calc(100% + 1px)', // Adjust the calculation based on actual body padding
  }}>
  <source src="https://storage.googleapis.com/app_darkendimg/assets/redeem.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        height: '100%', 
        padding: 2,
        boxShadow: 0,
        paddingTop: 0,
        margin: 0,
        zIndex: 1,
        mt: 2
    }}>
            <Typography variant="h6" gutterBottom color='white' fontWeight={900} sx={{textShadow: '2px 2px 8px rgba(0, 0, 0, 0.9)'}}>
                1000 Beans
            </Typography>
            <Typography variant="h6" gutterBottom color='white' fontWeight={700} sx={{textShadow: '2px 2px 8px rgba(0, 0, 0, 0.9)'}}>
                Redeem Beans for Drinks
               
            </Typography>
           
            </Box>
    </Box>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', 
        backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/assets/shot.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 200,
        padding: 2,
        boxShadow: 0,
        paddingTop: 0,
        margin: 0,

    }}>
            <Typography variant="h6" gutterBottomcolor='white' >
                1000 Beans
            </Typography>
            <Typography variant="h5" gutterBottom color='white' fontWeight={700}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
               
            </Typography>
            
        </Box>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', 
        backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/assets/reward1.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 200,
        
        boxShadow: 0,
        paddingTop: 2,
        margin: 0,

    }}>
            <Typography variant="h6" gutterBottom color='white'>
                1000 Beans
            </Typography>
            <Typography variant="body1" gutterBottom color='white'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
               
            </Typography>
           
        </Box>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', 
        backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/assets/reward1.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 200,
        padding: 2,
        boxShadow: 0,
        paddingTop: 0,
        margin: 0,

    }}>
            <Typography variant="h6" gutterBottom color='white'>
                1000 Beans
            </Typography>
            <Typography variant="body1" gutterBottom color='white'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
               
            </Typography>
            </Box>

      </CustomTabPanel>
    </Box>
  );
}
