import React, { useRef } from 'react';
import { useSprings, animated, useSpring } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';
import treeImage from '../../images/tree.png';
import pestImage from '../../images/ladybug.jpg';
import { to } from 'react-spring';





const Box = React.forwardRef((props, ref) => {
    const [{ scale }, set] = useSpring(() => ({ scale: 1 }));
    return (
      <animated.div
        ref={ref}
        onMouseEnter={() => set({ scale: 1.2 })}
        onMouseLeave={() => set({ scale: 1 })}
        style={{
          height: '100px',
          width: '100px',
          border: '1px solid black',
          transform: scale.to(s => `scale(${s})`)
        }}
      >
        Drop here
      </animated.div>
    );
  });
  
const Bug = ({ id, onDrop, bind }) => (
  <animated.img
    {...bind(id)}
    src={pestImage}
    alt="Pest"
    className="pest animated"
    width={50}
  />
);

const BerryCrush = () => {
  const [cherries, setCherries] = React.useState(5);
  const [bugs, setBugs] = React.useState(3);
  const [score, setScore] = React.useState(0);


  const handleCrush = () => {
    setScore(prevScore => prevScore + 1);
    setBugs(prevBugs => prevBugs - 1);
  };
  
  const boxRef = useRef();

  const order = useRef(Array.from({ length: bugs }, (_, index) => index));
  const [springs, api] = useSprings(bugs, index => ({
    x: 0,
    y: 50 * index,
    scale: 1
  }));

  const bind = useDrag(({ evnet,args: [originalIndex], down, movement: [mx, my],  }) => {
    
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = Math.round((50 * curIndex + my) / 50);
    const newOrder = order.current.slice();
    newOrder.splice(curIndex, 1);
    newOrder.splice(curRow, 0, originalIndex);
    api.start(index => {
      if (index === originalIndex) {
      if (!down) {
  if (boxRef.current) {
    const box = boxRef.current.getBoundingClientRect();
    if (mx >= box.left && mx <= box.right && my >= box.top && my <= box.bottom) {
      handleCrush();
    }
  }
}

        return {
          x: down ? mx : 0,
          y: down ? my + 50 * curRow : 50 * newOrder.indexOf(originalIndex),
          scale: down ? 1.2 : 1
        };
      } else {
        return {
          y: 50 * newOrder.indexOf(index)
        };
      }
    });
    if (!down) order.current = newOrder;
  });
  

  return (
    <div>
      <div className="tree-container">
        <img src={treeImage} alt="Tree" width={25} className="tree" />
        {springs.map(({ x, y, scale }, index) => (
          <Bug
            key={index}
            id={index}
            onDrop={handleCrush}
            bind={bind}
            style={{
                transform: to([x, y, scale], (x, y, s) => `translate3d(${x}px, ${y}px, 0) scale(${s})`)

            }}
          />
        ))}
        <Box ref={boxRef} onDrop={handleCrush} />
      </div>
      <div>
        <button onClick={() => console.log("Game over. Your final score: " + score)}>
          Exit
        </button>
      </div>
    </div>
  );
};

export default BerryCrush;
