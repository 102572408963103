import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, TextField, Typography, CircularProgress, CardMedia } from '@mui/material';
import { gql } from '@apollo/client';

// Updated GraphQL query
const GET_ALL_SHOPS = gql`
  query GetAllShops {
    koffieshops {  
      _id
      busname
      address1
      address2
      city
      state 
      zip
      imageurl
      bio
      digibeansavail
      drinksavail
    }
  }
`;

const QueryShopPage = () => {
  const { loading, error, data } = useQuery(GET_ALL_SHOPS, {
    fetchPolicy: 'cache-and-network',
  });
  const [searchTerm, setSearchTerm] = useState('');

  // Confirm response structure
  console.log('GraphQL Response:', data);

  // Handle data shape dynamically
  const shops = Array.isArray(data?.koffieshops)
    ? data.koffieshops
    : data?.koffieshops
    ? [data.koffieshops]
    : [];

  const filteredShops = shops.filter((shop) =>
    !searchTerm || // Show all shops if searchTerm is empty
    shop.busname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    shop.zip?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <Box sx={{ p: 1 }}>
     
      <TextField
        label="Search by Name or ZIP Code"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 3 }}
      />

      {filteredShops.length > 0 ? (
        filteredShops.map((shop) => (
          <Box
            key={shop._id}
            sx={{
              p: 2,
              border: '1px solid #ccc',
              borderRadius: 1,
              mb: 2,
             
            }}
          >
            <CardMedia component="img" height="140" image={shop.imageurl} alt={shop.busname} />
            <Typography variant="h6">{shop.busname}</Typography>
            <Typography>
              {shop.address1}, {shop.city}, {shop.state}, {shop.zip}
            </Typography>
            <Typography variant="body2">{shop.bio}</Typography>
          </Box>
        ))
      ) : (
        <Typography>No shops found.</Typography>
      )}
    </Box>
  );
};

export default QueryShopPage;
