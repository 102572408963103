import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import habish from '../../images/habish.jpg';
import { gql, useQuery } from '@apollo/client';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';
import DarkComments from '../mods/darkcomments';

const Item = styled(Paper)(({ theme }) => ({  
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CoffeeImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});


const GET_KRONS = gql `
query{
    darkroads {
    _id
    Who
    What
    When
    Why
}
}
`
;
const CoffeePage = (darkroad) => {
  const { user } = useContext(UserContext);
  const { loading, data, error } = useQuery(GET_KRONS);
  const [isOpen, setIsOpen] = useState(false);
  

  const userComments = ["Comment 1", "Comment 2", "Comment 3"];

  const handleCommentButtonClick = () => {
    setIsOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };


  
  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Discover Coffee Varieties
      </Typography>
      <Grid container spacing={2}>
        {data && data.darkroads.map((darkroads) => (
          <Grid item xs={12} sm={6} md={4} key={darkroads._id}>
            <Item>
              <Typography variant="h6" gutterBottom>
                {darkroads.Who}
              </Typography>
              <CoffeeImage src={habish} alt='habish' />
              <Typography>{darkroads.What}</Typography>
              <IconButton aria-label="like">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="comment" onClick={handleCommentButtonClick}>
                <CommentIcon />
                
              </IconButton>
            </Item>
          </Grid>
        ))}
      </Grid>
      <Drawer anchor="bottom" open={isOpen} onClose={handleCloseDrawer}>
        <List>
        <DarkComments darkroad={darkroad}/>
        </List>
        <TextField
          id="outlined-multiline-flexible"
          label="New Comment"
          multiline
          rows={4}
          variant="outlined"
          style={{ margin: '0.5em', width: '95%' }}
        />
        <Button onClick={handleCloseDrawer} variant="contained" color="primary" style={{ margin: '0.5em' }}>
          Submit
        </Button>
      </Drawer>
    </div>
  );
};

export default CoffeePage;
