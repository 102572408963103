import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


const guideSteps = [
    { text: 'Roasted where it grows', imageUrl: 'https://storage.googleapis.com/app_darkendimg/path/to/destination/ideogram%20(6).jpeg' },
     ];
export default function HilltopDialog() {
  const [open, setOpen] = useState(false);

  // Content for the Hilltop Dialog
  //const content = 'Welcome to Hilltop! Enjoy your stay.';

  useEffect(() => {
    if (!sessionStorage.getItem('hilltopDone')) {
      setOpen(true);
      sessionStorage.setItem('hilltopDone', 'true');
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
   
      open={open}
      onClose={handleClose}
      
    >
      <DialogTitle variant='h4' fontWeight={800} sx={{color: '#077336'}}>Disrupt Big Koffie </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          padding: 2,
        }}
      >
        <DialogContentText>
            {guideSteps[0].text}
         <img src='https://storage.googleapis.com/app_darkendimg/darkpicture/Artboard.png' alt="Guide step" style={{ width: '100%', height: 'auto', borderRadius: 10 }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}  sx={{backgroundColor: '#077336', color: '#fff', fontWeight: 800}}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
