import { Typography, Box, Card, CardContent, Grid, Fab, useMediaQuery, Button, Modal } from '@mui/material';
import React, { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { gql, useMutation } from '@apollo/client';
import { UserContext } from '../../../context/usercontext';
import StoreAdd from '../adds/storeadd';
import beanify from '../../../images/beaniefy.png';
import Faq from '../../../pages/faq';

const UPDATE_USER_PROF = gql`
  mutation UpdateUserprof($query: UserprofQueryInput!, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      tasteProfile
      description
      name
      country
      usertype
      beanCount
      imageurl
      profileBuilt
      waitlist
    }
  }
`;

function Services() {
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [updateUserprof] = useMutation(UPDATE_USER_PROF);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleJoinWaitlist = async () => {
    try {
      await updateUserprof({
        variables: {
          query: { _id: user._id }, // Ensure the query is using the logged-in user's ID
          set: { waitlist: true }
        }
      });
      setOpen(true); // Open the modal on success
    } catch (error) {
      console.error("Error joining waitlist", error);
    }
  };

  return (
    <Box sx={{ padding: 2, backgroundColor: 'transparent', borderRadius: 4, boxShadow: 3 }}>
      <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold" gutterBottom sx={{ textAlign: 'center', textShadow: '1px 1px 1px #d1d1d1' }}>
        Attention Coffee Shops & Roasters!
      </Typography>
      <Typography variant="h6" fontWeight={600} color="text.secondary" gutterBottom>
        🌟 Enhance the Value of Your Beans with Koffieland 🌟
      </Typography>
      <Typography variant="body1" gutterBottom>
        Are you a coffee shop or roaster looking to add more value to your existing beans? Join the Koffieland ecosystem and elevate your business to new heights!
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                ✅ Boost Customer Engagement
              </Typography>
              <Typography variant="body2">
                Enhance the customer experience with unique digital interactions.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                ✅ Expand Your Reach
              </Typography>
              <Typography variant="body2">
                Connect with a global community of coffee enthusiasts.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                ✅ Increase Transparency
              </Typography>
              <Typography variant="body2">
                Showcase the origins and quality of your beans with our trustless, transparent smart contracts.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                ✅ Boost Customer Loyalty
              </Typography>
              <Typography variant="body2">
                Engage customers with unique digital interactions and drink rewards.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                ✅ Seamless Integration
              </Typography>
              <Typography variant="body2" >
                Easily add your existing bean lots to our platform.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
               <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt='beans' style={{ width: 30, height: 30 }} /> Koffieland Beans
              </Typography>
              <Typography variant="body2">
                Get the best beans directly from the source.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
        <Fab variant="extended" onClick={handleJoinWaitlist} sx={{ backgroundColor: 'green', color: 'white', ":hover": { backgroundColor: 'darkgreen' } }}>
          Join Waitlist
        </Fab>
      </Box>
      <Typography variant="caption" display="block" align="center" sx={{ marginTop: 2, ":hover": { color: 'green' } }}>
        Koffieland – Where every bean tells a story.
      </Typography>
      
            
      <Modal open={open} onClose={handleClose}>
        <Box sx={{position: 'fixed',   width: 300, height: 200, backgroundColor: 'white', borderRadius: 4, boxShadow: 3, padding: 2,
         display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
         border: '0px solid green', margin: 'auto', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
         }}>
          <Typography variant="h5" fontWeight="bold" gutterBottom color={'green'}>
            Success! <img src={beanify} alt="beanify" style={{ width: 30, height: 30 }} />
          </Typography>
          <Typography variant="body1" gutterBottom color={'black'}>
            You have successfully joined the waitlist. Stay Tuned.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose} sx={{ marginTop: 2 , backgroundColor: 'green', ":hover": { backgroundColor: 'darkgreen' } }}>
            Close
          </Button>
        </Box>
      </Modal>
      <Typography
        variant="h4"
        fontWeight="bold"
        gutterBottom
        sx={{ textAlign: 'center', textShadow: '1px 1px 1px #d1d1d1', marginTop: 3 }}
      >
        Frequently Asked Questions
      </Typography>
      <Faq />
    </Box>
  );
}

export default Services;
