import React from 'react'
import ContractDetails from '../../components/profile/contractdetails'
import MarketUI from '../funstuff'
import PriceTicket from '../../components/finance/prods/pricefeed'
import GoalCard from '../../components/finance/adds/goalcard'
import { Container } from '@mui/material'

function DeskCoin() {
  return (
    <Container>
      <PriceTicket/>
      <GoalCard/>
      
        <ContractDetails/>
    </Container>
  )
}

export default DeskCoin