import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container>
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h3" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Effective date: June 20, 2024
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Koffieland. These terms of service outline the rules and regulations for the use of our app and website. By accessing or using the service, you agree to be bound by these terms. If you disagree with any part of the terms, you may not access the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          The service and its original content, features, and functionality are and will remain the exclusive property of Koffieland and its licensors. The service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Koffieland.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the terms, which may result in immediate termination of your account on our service. You are responsible for safeguarding the password that you use to access the service and for any activities or actions under your password, whether your password is with our service or a third-party service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the terms. If you wish to terminate your account, you may simply discontinue using the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall Koffieland, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from the service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These terms shall be governed and construed in accordance with the laws of the State of Georgia, United States, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these terms will not be considered a waiver of those rights. If any provision of these terms is held to be invalid or unenforceable by a court, the remaining provisions of these terms will remain in effect. These terms constitute the entire agreement between us regarding our service and supersede and replace any prior agreements we might have had between us regarding the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Changes to These Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, please contact us at support@goodkoffie.com or by mail to: Good Koffie, 123 Coffee St, Atlanta, GA, 30301.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
