import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Card, Avatar, Typography, Tooltip,
Divider, 
} from '@mui/material';
import { gql } from '@apollo/client';
import { motion } from 'framer-motion';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale , } from 'chart.js/auto'; 
// Register the moment adapter
import moment from 'moment';
import { registerAdapterDateFns } from 'chartjs-adapter-moment'; 
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Progress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/usercontext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import kcoin from '../../images/kcoin.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GaugeChart from 'react-gauge-chart'
import Flavloader from './flavloader';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Chron from '../../pages/chron';

import PriceTicket from '../finance/prods/pricefeed';
import BeanCard from './beancard';
import GoalCard from '../finance/adds/goalcard';
import { Popover } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';
const minted = 'https://storage.googleapis.com/app_darkendimg/assets/kbean.png';
const koffie = 'https://storage.googleapis.com/app_darkendimg/assets/cherrybranch.png';

const useStyles = makeStyles((theme) => ({
  progressAndChart: {
    display: 'flex', 
    
  },
  progressContainer: {
    width: 300, 
    height: 30,
    border: '1px solid #ddd', 
    borderRadius: 5,
  },
  progressFiller: {
    backgroundColor: 'lightgreen', 
  },
  chartContainer: {
     width: '100%',
  },
  chart: {
    height: 400,
  },
  progressText: {
    textAlign: 'center',
    fontWeight: 700,
  },
  progressPercent: {
    fontWeight: 700,
    color: 'green',
  },
  progressLabel: {
    fontWeight: 400,
    color: 'black',
  },
  progressChip: {
    borderColor: 'green',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'white',
  },
  progressChipLabel: {
    color: 'green',
  },
  progressChipIcon: {
    color: 'green',
  },
  progressChipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
  },
  progressChipText: {
    fontWeight: 400,
    color: 'green',
  },
  progressChipValue: {
    fontWeight: 700,
    color: 'green',
  },
  progressChipIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
  },
  progressChipLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  needlelenth: {
    width: 400,
  },
}));




// Register the moment adapter

ChartJS.register(CategoryScale);


function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}


const GET_TOP_USERS = gql`
  query GetTopUsers {
    userprofs {
      flavorprofile
      Nickname
      tasteProfile
      description
      name
      purchaseLink
      Koffie_story
      usertype
      _id
      country
      beanCount 
      imageurl
    }
  }
`;

const BeanTracker = () => {
  const navigate = useNavigate();
 

  
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl, _id } = user.customData
  const classes = useStyles();
  const [chartReady, setChartReady] = useState(false); 
  const { data, loading, error } = useQuery(GET_TOP_USERS, {
    fetchPolicy: "network-only", // Ignores the cache and fetches directly from the network
  });
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState('');

  const handleOpenPopover = (event, breadcrumbText) => {
    setAnchorEl(event.currentTarget);
    setBreadcrumb(breadcrumbText);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setBreadcrumb('');
  };
  
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Stagger each child animation by 0.2s
      },
    },
  };


  useEffect(() => {
    // Assuming your data populates chartData somehow
    // When you receive your data and update chartData, also:
    setChartReady(true); 
  }, [data]); // Or another dependency depending on when you receive data 

      
  if (loading) return <Flavloader/>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  
  // Ensure data.userprof exists and is an array, then sort by beanCount and slice top 5
  const { userprofs: topUsers } = data;

  // Calculate total bean count across all usersUpgrade
  const totalBeanCount = topUsers.reduce((acc, user) => acc + user.beanCount, 0);
  console.log(totalBeanCount);

  // Sort users based on bean count (descending order)
  // Create a copy of topUsers arrayprogressPercent
const topUsersCopy = [...topUsers];

// Sort the copy based on bean count (descending order)
topUsersCopy.sort((a, b) => b.beanCount - a.beanCount);

// Take the top five users from the sorted copy
const topFiveUsers = topUsersCopy.slice(0, 100);
//display Top bean user
const isPresidential = topUsersCopy.slice(0, 1);
const isBoard = topUsersCopy.slice(1, 5);

const tokens = 91520000 ;
const progressPercent = (totalBeanCount / tokens) * 100; // Multiply by 100 to get percentage

const isSmartCitizen = beanCount?.$numberInt > 500;
const availabletokens = tokens - totalBeanCount;
const items = [
    {
      value: "23K",
      label: "Goal",
      tooltip: "This represents our goal",
      breadcrumb: "Our target weight for production.",
    },
    {
      value: formatBeanCount(availabletokens),
      label: "Available",
      tooltip: "Tokens available for use",
      breadcrumb: "Tokens ready to be used or redeemed.",
    },
    {
      value: formatBeanCount(totalBeanCount),
      label: "Circulating",
      tooltip: "Total beans currently circulating",
      breadcrumb: "Beans actively in circulation.",
    },
    {
      value: `${progressPercent.toFixed(2)}%`,
      label: "Progress",
      tooltip: "Your current progress towards the goal",
      breadcrumb: "The percentage of our goal achieved.",
    },
  ];

 // Chart Data Preparation (assuming you want to visualize beanCounts of top users)
 const sortedUsers = topFiveUsers.sort((a, b) => a.beanCount - b.beanCount);
 const chartData = {
   datasets: [
     {
       label: 'Top Users Bean Count',
       data: sortedUsers.map((user) => ({
         x: user.Nickname, // Use nicknames on X-axis
         y: user.beanCount,
         img: user.imageurl,
         r: 20, // Adjust the base radius 
         progressPercent: (user.beanCount / tokens) * 100, 
       })),
     },
   ],
 };
<Typography variant="body1" color='text.secondary' sx={{ fontWeight: 600 }}>
{formatBeanCount(totalBeanCount)} In Circulation
</Typography>

const BarChartWithProgress = ({ chartData }) => {
  const data = {
    labels: chartData.datasets[0].data.map((dataPoint) => dataPoint.x), // User nicknames
    datasets: [
      {
        type: 'scatter',
        label: 'Top Users Bean Count', 
        data: chartData.datasets[0].data.map((dataPoint) => dataPoint.y), // Bean Counts
        backgroundColor: '#32a852', // Green color with transparency
       
       
        
        render: (args) => {
         
          const { chart } = args;
          const { ctx, chartArea } = chart;
          const { data } = args.dataset;
          data.forEach((dataPoint, index) => {
            const { x, y, progressPercent } = dataPoint;
            const { left, right, top, bottom } = chartArea;
            const xCoord = left + (right - left) * chart.data.datasets[0].data[index].x / chart.data.labels.length;
            const yCoord = top + (bottom - top) * chart.scales.y.getValueForPixel(y);
            ctx.save();
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`${x}: ${formatBeanCount(y)}`, xCoord, yCoord - 20);
            ctx.fillText(`${progressPercent.toFixed(2)}%`, xCoord, yCoord + 20);
            ctx.restore();
          });
        }
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category', // Use 'category' scale for the x-axis
        ticks: {
          callback: (value) => value, // Display the unique identifier as the x-axis label
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => formatBeanCount(value),
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        color: 'black',
        
        font: {
          weight: 'bold',
        },
        formatter: (value, context) => {
          return `${context.dataset.data[context.dataIndex].x}: ${formatBeanCount(context.dataset.data[context.dataIndex].y)}`;
        },
      },
    },
    elements: {
      point: {
        radius: 3, // Adjust the radius to make the points bigger
        hoverRadius: 12, // Adjust the hover radius if needed
        hitRadius: 15, // Adjust the hit radius if needed
      },
    },
  };
  

  return <Scatter data={data} options={options} />;
};
console.log(topFiveUsers);
console.log(totalBeanCount);
console.log(tokens);
console.log(chartData);

BarChartWithProgress.propTypes = {
  chartData: PropTypes.shape({
    datasets: PropTypes.array.isRequired,
  }).isRequired,
};


  
  return (
    <>
   

   <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        borderRadius: 0,
        boxShadow: 0,
        marginTop: 0,
        position: 'relative',
        width: '100%', // Ensures full viewport width
       
        zIndex: 1,
        overflow: 'hidden', // Prevents overflow
      }}
    >
     
      
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        //alignItems: 'flex-end',
        //position: 'absolute',
       gap: 2,
        //backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: 0,
        //boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        padding: 0,
      }}>
       <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      style={{ display: 'flex', flexDirection: 'row', gap: 6 }}
    >
      {items.map((item, index) => (
        <motion.div key={index} variants={cardVariants}>
          <Tooltip title={item.tooltip}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}
              >
                {item.value}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 300 }}
              >
                {item.label}
              </Typography>
              {/* Question mark button for breadcrumb */}
              <IconButton
                onClick={(e) => handleOpenPopover(e, item.breadcrumb)}
                size="small"
                sx={{ marginTop: 0 }}
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Box>
            </Card>
          </Tooltip>
        </motion.div>
      ))}

      {/* Popover for breadcrumb */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ padding: 2, maxWidth: 300 }}>
          <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
            {breadcrumb}
          </Typography>
        </Box>
      </Popover>
    </motion.div>
      </Box>

     <Divider sx={{ width: '100%', margin: 0 }} />
    </Box>
    
  
    </>
  );
};

export default BeanTracker;
