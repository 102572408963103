import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import StoryCard from '../mods/comments';
import {  Button, Drawer, } from '@mui/material'; 
import { UserContext } from '../../context/usercontext';
import { useContext, useState } from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    borderRadius: '12px',  // Rounded corners
  },
  media: {
    height: 200,
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    padding: '10px'
  }
});





const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ddd',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const GET_STORYS = gql`
    query{
        krons (limit: 50){
            year 
            What
            ImageUrl
            _id
        }
    }
`;



export default function Work(kron) {
  const { user } = useContext(UserContext);
  const { data, error, loading } = useQuery(GET_STORYS);
  const [yearFilter, setYearFilter] = React.useState("");
  const [sortedData, setSortedData] = React.useState([]);
  
  const [currentKronId, setCurrentKronId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  

  const handleCommentButtonClick = (kronId) => {
    setCurrentKronId(kronId);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    
    setIsDrawerOpen(false);
  };

  React.useEffect(() => {
    if (data) {
      let filteredData = data.krons.filter((kron) => kron.year.includes(yearFilter));
      setSortedData(filteredData.sort((a, b) => b.year - a.year));
    }
  }, [data, yearFilter]);

  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  return (
    <div>
      
      <Box sx={{ flexGrow: 1 }}>
      {/* ... other content ... */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {sortedData.map((kron) => (
          <Grid xs={4} sm={4} md={3} key={kron._id}>
            <Card key={kron._id} sx={{ maxWidth: 400 }}>
              {/* ... other content ... */}
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
                <IconButton
                  aria-label="show more"
                  onClick={() => handleCommentButtonClick(kron._id)}
                >
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleCloseDrawer}>
        {/* Render the selected kron's details here */}
        <Typography paragraph>
          <StoryCard kron={sortedData.find((kron) => kron._id === currentKronId)} />
        </Typography>
        <Button onClick={handleCloseDrawer} color="primary">
          Close
        </Button>
      </Drawer>
    </Box>
    </div>

  );
}
