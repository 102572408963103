import React from 'react'
import { Box, Typography, Card, CardActions, CardContent, Divider } from '@mui/material'
import { LocalDrink } from '@mui/icons-material'

function BlackIvy() {
  return (
    <div>
        <Box sx={{
              p: 2, 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
              backgroundColor: '#000', // Light green background color
              backgroundImage: `url(${''})`, // Add your background image here
              backgroundSize: 'cover', // Adjust background size to cover the container
              backgroundPosition: 'center', // Adjust background position
              backgroundRepeat: 'no-repeat', // Prevent background from repeating
              height: '100%', // Adjust height to fill the viewport
             
              borderRadius: 0,
          }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5, backgroundColor: 'fff' }}>
<img src="https://storage.googleapis.com/app_darkendimg/assets/newivy.png" alt="Black Ivy" style={{ width: '100px', height: '100px', borderRadius: 0, borderRadius: 5, backgroundColor: 'fff'}} />


                    </div>
                 <Typography variant="h5" sx={{ mt: 2, fontWeight: 700, textAlign: 'center', color: '#fff' }}>
                 BLACK IVY™
                  </Typography>
                  </div>
                 
                 
                  <Typography variant="body1" sx={{ mt: 0, mb: 6, fontWeight: 500, textAlign: 'center', padding: 2, color: '#fff' }}>
                  "Experience 'Black Ivy'—a tribute to the ancient heritage of the coffee tree and its sacred bond with human origins.</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0, mt: 0 }}>  
       
                  <CardActions>
                  <img src="https://storage.googleapis.com/app_darkendimg/assets/kaff.png" alt="Kaffeine" style={{ marginTop: 14, width: '40px', height: '40px', borderRadius: 5, }} /> 
                  
                    <Typography variant='h6'sx={{fontWeight: 800, color: '#fff'}}>
                    <p
                    style={{fontWeight: 400, fontSize: '2.5rem', padding: 0, margin: 0, display: 'inline-block', color: '#fff'}}
                    >^</p>∣ψ⟩=E∣ψ⟩  
 
                    </Typography>
                 
                  </CardActions> 
                  <Card sx={{
        display: 'flex',
        position: 'relative',
        height: 'auto',
        width: '100%',
        
        borderRadius: 5,
        backgroundColor: 'tranparent',
        border: '3px solid #000',
        padding: 0,
      }}>
        <div
          
          style={{ width: '100px', padding: 0, borderRadius: 2, backgroundColor: '#ebecf0' }} // Adjust width to fit the card properly
       >
        <img src="https://storage.googleapis.com/app_darkendimg/assets/kaff.png" alt="Black Ivy" style={{ width: '100px', height: '100px', borderRadius: 5,  }} />
        </div>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          backgroundColor: '#fff',
          padding: 0,
        }}>
         
             
          <CardContent sx={{
            flex: '1 0 auto',
            backgroundColor: 'transparent' // Ensure background is transparent here if needed
          }}>
            <Typography component="div" variant="h6" fontWeight={700}>
              Warning
            </Typography>
            <Typography variant="body2" fontWeight={400} >
              If you gaze for long into this potion, the potion gazes also into you.
            </Typography>
          </CardContent>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 1,
            pb: 1
          }}>
            {/* Additional controls or content can go here */}
          </Box>
        </Box>
      </Card>
      
                  </Box>
             </Box>
    </div>
  )
}

export default BlackIvy