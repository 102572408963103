import React from 'react'
import { Typography, Box, Card, CardActions, CardContent, Chip, CardMedia, Avatar , Grid, Drawer, IconButton,
List, ListItem, ListItemText
} from '@mui/material'
import { useSpring, animated, config } from 'react-spring'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Koffieticket from './prods/coffee';
import SunburstChart from '../tests/burst';
import AiGen2 from './adds/aifeed2';


function Beanomics
() {
    const [open, setOpen] = React.useState(false);
    const [props, set] = useSpring(() => ({
        transform: 'scale(1)',
       
      }));
      
      const animationStyles = useSpring({
        from: { transform: 'translateY(-100%)', opacity: 0 },
        to: async (next) => {
          // Falls and bounces into place more slowly
          await next({ transform: 'translateY(0%)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
          // Zooms out slowly
          await next({ transform: 'scale(1.2)', opacity: 1, config: { duration: 1000 } });
          // Zooms back in to its original state more slowly
          await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
          // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after zooming back in
          await next({ opacity: 1, config: { duration: 500 } });
        },
        delay: 600,
      });
      const handleOpen = () => {
        setOpen(true);
      }
        const handleClose = () => {
            setOpen(false);
        }


  return (
    <div>
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem', marginLeft: '-1px', marginRight: '-1px'  }}>
       <Card sx={{ 
    display: 'flex', 
    width: 'calc(100% + 1px)', // Adjust the calculation based on actual body padding
          
    backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/prize.webp')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 400,
    borderRadius: 0,
    boxShadow: 0,
    paddingTop: 0,
    margin: 0,
    overflow: 'hidden', // Ensure no scrollbars appear due to negative margins
  }}>
      <CardContent>
       

        
        <Grid container direction="column" alignItems="center" spacing={2}>
         
          <Grid item>
            <animated.div style={animationStyles}>
          <Typography gutterBottom variant="h3" component="div" sx={{
  fontWeight: 800, 
  color: 'white', 
  textShadow: '4px 4px 8px #000000',  
}}>
  Use Your DigiBeans to Redeem Prizes
</Typography>
</animated.div>
          </Grid>
          <Grid item>
          <Chip size='large'  type="submit" variant="contained" label='Learn More' sx={{ backgroundColor: "#1a960f", color: "#fff"}} onClick={handleOpen} />
          </Grid>
        </Grid>
       
      </CardContent>
    </Card>
        </Box>
       
        <Drawer anchor='bottom' open={open} onClose={handleClose}  sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '20px 20px 0 0', // Top left, top right, bottom right, bottom left
          
        },
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem',   }}>
    
    <Card sx={{ 
    display: 'flex', 
    width: 'calc(100% + 5px)', // Adjust the calculation based on actual body padding
          
    backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/prize.webp')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 200,
    borderRadius: 0,
    boxShadow: 0,
    paddingTop: 0,
    margin: 0,
    overflow: 'hidden', // Ensure no scrollbars appear due to negative margins
  }}>
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: 1 }}>
    
      <ArrowBackIosIcon onClick={handleClose} sx={{ color: "#fff" , textShadow: '4px 4px 8px #000000', }} />
   
    </Box>
    <CardContent>
    <animated.div style={animationStyles}>
          <Typography gutterBottom variant="h5" component="div" sx={{
  fontWeight: 700, 
  color: 'white', 
  textShadow: '4px 4px 8px #000000',  
}}>
 Use Your DigiBeans to Redeem Prizes
</Typography>
</animated.div>
    </CardContent>
  </Card>
  </Box>
 <Box>
    <Box sx={{ padding: 2 }}>
    
 <Koffieticket />
 <Card sx={{ borderRadius: 2, padding: 1 }}>
  <CardContent>
    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 600 , }}>
      Beanomics
    </Typography>

<List sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0 }}>
  <ListItem>
    <Avatar alt="Remy Sharp" src="https://storage.googleapis.com/app_darkendimg/assets/brkoin.png" />
    <ListItemText primary="Bean Market" secondary="Bean Market" />
  </ListItem>
 
</List>

    <AiGen2/>
    
  </CardContent>
  <CardActions>
    <Chip size='large'  type="submit" variant="contained" label='Learn More' sx={{ backgroundColor: "#1a960f", color: "#fff"}} />
  </CardActions>
  </Card>
</Box> 
 </Box>
   
  </Drawer>

    </div>
  )
}

export default Beanomics
