import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { Box, Card, CardMedia, Typography, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    featuredStoriesContainer: {
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
        padding: theme.spacing(0),
        scrollbarWidth: 'none', /* Firefox */
        '-ms-overflow-style': 'none', /* IE and Edge */
        '&::-webkit-scrollbar': {
            display: 'none', /* Chrome, Safari, and Opera */
        },
    },
    featuredStoryCard: {
        marginRight: theme.spacing(2),
        flex: '0 0 auto',
        width: 50, // Adjust as needed
        borderRadius: 10,
    },
}));

const GET_FEATURED_STORIES = gql`
    query GetFeaturedStories($Category: String!) {
        krons(query: {Category: $Category}) {
            year
            What
            ImageUrl
            countryName
            _id
        }
    }
`;

const SpecificStoriesComponent = ({ storyIds }) => {
    const Category = "Featured"; // Replace with the actual category name
    const classes = useStyles();

    const { loading, error, data } = useQuery(GET_FEATURED_STORIES, {
        variables: { Category },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Box>
            
            <Box className={classes.featuredStoriesContainer}>
                {data.krons.map((story) => (
                    <Card key={story._id} className={classes.featuredStoryCard}>
                        
                        <Avatar src={story.ImageUrl} width={60}/>
                        <Box>
                            <Typography variant="caption">{story.year}</Typography>
                        </Box>
                        <Box>
                        <Typography variant="body2">{story.countryName}</Typography>
                        </Box>
                    </Card>
                ))}
                <Box>
                    <Typography variant="body2" sx={{color: 'freen'}}>View all</Typography>
                    </Box>
            </Box>
        </Box>
    );
};

export default SpecificStoriesComponent;
