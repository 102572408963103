import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import koffieman from '../../images/koffieman1.png';
import { Typography } from '@mui/material';
import mug from '../../images/mug.png';

const Flavloader = ({ src }) => {
  const [bounce, setBounce] = useState(false);
  const [bounceCount, setBounceCount] = useState(0);
  const bounceAnimation = useSpring({
    transform: bounce
      ? 'translateY(-10px) scale(1.1)'
      : 'translateY(0px) scale(1)',
    config: { duration: 2000 },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (bounceCount < 50) {
        setBounce((prevState) => !prevState);
        setBounceCount((prevState) => prevState + 1);
      } else {
        clearInterval(interval);
        setTimeout(() => {
          setBounceCount(0);
          interval && setInterval(() => {
            setBounce((prevState) => !prevState);
            setBounceCount((prevState) => prevState + 5);
          }, 2500);
        }, 1000);
      }
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, [bounceCount]);

  return (
    <div style={{
      display: 'flex',  // Makes the div a flex container
      justifyContent: 'center',  // Centers horizontally in the flex container
      alignItems: 'center',  // Centers vertically in the flex container
      height: '100%',  // You might need to set a specific height
      padding: 1
  }}>
      <animated.img
          src='https://storage.googleapis.com/app_darkendimg/assets/kland.png'
          alt="Coffee Man"
          className="koffiman-image"
          style={{ ...bounceAnimation, width: 50, height: 70 }}
      />
  </div>
  

  );
};

export default Flavloader;