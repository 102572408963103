import React, { useState, useEffect } from 'react';
import { 
Card,  List, Typography, Avatar,
Box,  CardContent
} from '@mui/material';
import beans from '../../images/coffeebeans.png';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';




const dataguy = () => {
    return {
      "name": "dataguy",
      "description": "Data processor , returning data.",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "Name of the data"
          },
          
          "description": {
            "type": "string",
            "description": "desription of the data"
          },
          
            "primaryImage": 
            {"type": "string",
            "description": "The URL of the image"
          
          }
        
          
        },
        "required": ["name",  "description",  "primaryImage" ]
      }
    };
  };






const Lamda = () => {
  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const [objectID, setObjectID] = useState(''); // New state for objectID
  const [objectData, setObjectData] = useState(null); // New state for objectData
  const [isButtonClicked, setButtonClicked] = useState(false);  
  const [ratethis, setRatethis] = useState(''); // New state for ratethis
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

const dataguyFunction = dataguy();  // Get the art function

const handleSubmit = () => {
  setIsLoading(true);  // Start loading before the fetch request

  // Fetch data from the Metropolitan Museum's Collection API
  fetch(`https://data.mongodb-api.com/app/application-0-jyiid/endpoint/oracleai`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      // Now 'data' holds the object data from the Metropolitan Museum's Collection API
      // We'll pass this data to the OpenAI API next

      fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${"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"}`,
        },
        body: JSON.stringify({
          "messages": [
            { "role": "system", "content": "Hi, i'm here to help you analyze sort and respond to data." },
            { "role": "user", "content": `hi can you help me with this: ${JSON.stringify(data)}.` },
          ],
          'model': 'gpt-4',
          "temperature": 0.2,
          "function_call": dataguyFunction,
          'functions': [dataguyFunction],
          
        }),
      })
        .then(response => response.json())
        .then(data => {
          let output;
          if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
            output = { description: data.choices[0].message.content };
          } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
            output = JSON.parse(data.choices[0].message.function_call.arguments);
          }
          setOutput(output);
          setIsLoading(false);
          console.log('Success:', data);
        })
        .catch(error => console.error('Error:', error));
    })
    .catch((error) => console.error('Error:', error));
};

  
  return (
    <Box sx={{ flex:'grow' }}>
      <Typography variant="h4" align="center" sx={{color:"#000", backgroundColor:'#d61a09'}}>
        <div sx={{ mr: 1 }}>
          Serverless AI
        </div>
        
          </Typography>

<input
          type="text"
          value={ratethis}
          onChange={(e) => setRatethis(e.target.value)}
          placeholder="what's at the endpoint"
        />
<div>      
{isLoading ? (
      <></>
    ) : (
      <>
            <div className="App">
          
            <div className='grid-item'>
            <Typography variant="h6" >                   
                  </Typography>
                  </div>           
                  {content && (
  <Card >
    <CardContent>
      <Avatar  src={content.primaryImage} />
      <Typography  align="center">{content.name}</Typography>
      <Typography >{content.artistName}</Typography>
      <Typography variant="h6">{content.country}</Typography>     
      <Typography >{content.description}</Typography>
     <Card sx={{borderRadius:'20px'}}>
     <img src={content.primaryImage} alt='artwork' width={400} />
      </Card>
      {/* ... other properties ... */}
    </CardContent>
    {/* ... rest of your component ... */}
  </Card>
            )}	
          </div>
             </>
    )}         
        </div>    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Fab variant="extended"  aria-label="add" sx={{color:"#000", backgroundColor:'#d61a09'}} onClick={handleSubmit}>
    <div sx={{ mr: 1 }}>
      <img src={beans} width={30}  alt="Bean" />
    </div>
   Function?
  </Fab>
</div>
    </Box>
  );
};

export default Lamda;