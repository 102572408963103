import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Card, CardContent, Typography, Paper, Grid, Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import TelegramIcon from '@mui/icons-material/Telegram';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    transition: 'transform 0.2s ease-in-out',
    '&:active': {
      transform: 'scale(1.2)',
    },
  },
  liked: {
    color: 'red',
  },
  notLiked: {
    color: 'green',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  countText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));


const GET_COMMENTS = gql`
  query GetComments($contentId: ObjectId!) {
  comments(query: { contentId: $contentId }) {
    
    _id
    content
    contentId
    contentType
    name
    image
    author
    
  }
   
}
`;

const GET_LIKES = gql`
  query GetLikes($contentId: ObjectId!) {
    likes(query: { contentId: $contentId }) {
  _id
    contentId
    contentType
    user
    }
  }
`;



const CREATE_COMMENT_MUTATION = gql`
  mutation InsertComment($data: CommentInsertInput!) {
    insertOneComment(data: $data) {
      _id
    }
  }
`;

const CREATE_LIKE_MUTATION = gql`
  mutation InsertLike($data: LikeInsertInput!) {
    insertOneLike(data: $data) {
      _id
    }
  }
`;

function StoryCard({ kron }) {
    const { user } = useContext(UserContext);
    const {Nickname, imageurl} = user.customData;
    const [liked, setLiked] = useState(false);
  const [comment, setComment] = useState('');
  const classes = useStyles();
  const [createComment] = useMutation(CREATE_COMMENT_MUTATION);
  const [createLike] = useMutation(CREATE_LIKE_MUTATION);
  const { data: commentsData, loading: commentsLoading } = useQuery(GET_COMMENTS, {
    variables: { contentId: kron._id }
  });
  
  const { data: likesData, loading: likesLoading } = useQuery(GET_LIKES, {
    variables: { contentId: kron._id }
  });

    const commentCount = commentsData?.comments.length;
    const likeCount = likesData?.likes.filter(like => like.contentId === kron._id).length;


    const handleComment = () => {
        createComment({
          variables: {
            data: {
              content: comment,
              author: user.id, // the logged in user id
              contentId: kron._id,
              contentType: 'kron',
              name: user.customData.Nickname,
              image: user.customData.imageurl

            }
          },
          update: (cache, { data: { insertOneComment } }) => {
            // Read the data from the cache for this query
            const data = cache.readQuery({
              query: GET_COMMENTS,
              variables: { contentId: kron._id }
            });
      
            // Write the data back to the cache with the new comment at the end of the list
            cache.writeQuery({
              query: GET_COMMENTS,
              data: {
                ...data,
                comments: [...data.comments, insertOneComment]
              },
              variables: { contentId: kron._id }
            });
          }
        });
        setComment('');
      };
      

  const handleLike = () => {
    createLike({
      variables: {
        data: {
          user: user.id, // the logged in user id
          contentId: kron._id,
          contentType: 'kron'
        }
      },
      update: (cache, { data: { insertOneLike } }) => {
        // Read the data from the cache for this query
        const data = cache.readQuery({
          query: GET_LIKES,
          variables: { contentId: kron._id }
        });
  
        // Write the data back to the cache with the new like at the end of the list
        cache.writeQuery({
          query: GET_LIKES,
          data: {
            ...data,
            likes: [...data.likes, insertOneLike]
          },
          variables: { contentId: kron._id }
        });
      }
    });
  };

  return (

    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Comments Section */}
      <div style={{ flex: 1, overflowY: 'auto', paddingBottom: '60px' }}>
        <Paper
          sx={{
            my: 1,
            mx: 'auto',
            p: 2,
          }}
        >
          {commentsData?.comments.map((comment) => (
            <Grid
              key={comment._id}
              container
              direction="column"
              wrap="nowrap"
              spacing={1}
              sx={{ mb: 0 }}
            >
              {/* Avatar and Name Row */}
              <Grid item>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Avatar src={comment.image} />
                  <Typography variant="body1" fontWeight="bold">
                    {comment.name}
                  </Typography>
                </Box>
              </Grid>
  
              {/* Comment Content Row */}
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    ml: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >
                    {comment.content}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Paper>
      </div>
  
      {/* Likes and Info */}
      <Typography
        variant="body2"
        color="text.secondary"
        className={classes.container}
        sx={{ padding: '10px' }}
      >
        <IconButton
          className={classes.iconButton}
          onClick={handleLike}
        >
          <FavoriteIcon
            className={liked ? classes.liked : classes.notLiked}
          />
        </IconButton>
        <div>
          {likeCount} Likes, {commentCount} Comments
        </div>
      </Typography>
  
      {/* Sticky Comment Box */}
      <Box
        style={{
          position: 'sticky',
          bottom: 0,
         
          padding: '10px 0',
        }}
      >
        <TextareaAutosize
          style={{
            width: '80%',
            background: '#51b27182',
            color: '#000',
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            outline: 'none',
            margin: '10px',
          }}
          aria-label="empty textarea"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={`Comment on ${kron.year}`}
        />
        <TelegramIcon
          fontSize="large"
          width="50px"
          onClick={handleComment}
          sx={{ mb:1, cursor: 'pointer' }}
        />
      </Box>
    </div>
  </div>
  
  
  );
}

export default StoryCard;
