import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/usercontext';
import { 
Card,  List, Typography, Avatar,
Box,  CardContent, TextareaAutosize, Skeleton,Drawer, ListItem, ListItemText, CardMedia, CardActions, CardHeader, IconButton, Button, Grid, Container, Link
} from '@mui/material';
import Fab from '@mui/material/Fab';
import bean from '../../images/coffeebeans.png';
import { BorderAllOutlined } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { ArrowUpward } from '@mui/icons-material';
import logo from '../../images/gklogo.png';
import Koffieticket from '../finance/prods/coffee';
import { makeStyles } from '@mui/styles';
import MainSt from '../../pages/mainstreet';
import SunburstChart from './burst';
import MessageList from '../profile/message';
import Display from '../profile/settings/displaymode';
import { CollaborateButton, CollaboratorsCount, UncollaborateButton } from '../profile/settings/CollaborateComponents';
import Map from '../finance/reviews/reviewshop';
import CoffeeShopSearch from '../finance/reviews/searchshop';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    alignItems: 'center', // Aligns items vertically center
    width: '100%',
  },
  input: {
    flex: 1, // Allows the input to grow and fill available space
    borderRadius: 15,
    padding: 10,
    fontSize: 14,
    position: 'relative',
    '&::placeholder': {
      // Style your placeholder text here
      color: 'grey',
      fontWeight: 'lighter',
      fontStyle: 'italic',
      fontSize: ".7rem",
    },
  },
  sendIcon: {
    position: 'absolute',
    right: 10,
    top: 15,
    color: '#999',
    cursor: 'pointer',
  },
  image: {
    marginRight: 2,
    backgroundColor: '#000',
    width: 50,
    height: 50,
    borderRadius: '10px',
  },
  
});




const barista = () => {
    return {
      "name": "barista",
      "description": "Your Persona is a super kool Barista.",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "Person ordering"
          },
          "specials": {
            "type": "string",
            "description": "Recommend a special if one is on the menu."
          },
          "description": {
            "type": "string",
            "description": "Overall description of combination of drink and food."
          },
          "Drink": {
            "type": "string",
            "description": "https://source.unsplash.com/random"
          },
          "Food": {
            "type": "string",
            "description": "https://source.unsplash.com/random"
          },
          "Pasties": {
            "type": "string",
            "description": "https://source.unsplash.com/random"
          },
          "primaryImage": {
            "type": "string",
            "description": "https://source.unsplash.com/random"
          }
        },
        "required": ["name", "description", "specials", "primaryImage", "Food", "Drink", "Pasties"]
      }
    };
  };
    




const Barista = () => {
  const emojis  = ['🚀', '⚡️', '🎉', '🎊', '🥳', '🤩', '✨'];
  const classes = useStyles();
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const [objectID, setObjectID] = useState(''); // New state for objectID
  const [objectData, setObjectData] = useState(null); // New state for objectData
  const [isButtonClicked, setButtonClicked] = useState(false);  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [ratethis, setRatethis] = useState(''); // New state for ratethis
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { user } = useContext(UserContext);
 
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

const baristaFunction = barista();  // Get the art function

const handleSubmit = () => {
  setIsLoading(true);  // Start loading before the fetch request

 

      fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${"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"}`,
        },
        body: JSON.stringify({
          "messages": [
            { "role": "system", "content": "You are the 'Koffieman' a fearless koffie conosuier/sales/deliver person. You deliver koffie and goodies to college studens for their all night study grind. You are whitty and encourge the students to drink koffie and study hard" },
            { "role": "user", "content": `What items do you have tonight: ${JSON.stringify(ratethis)}.` },
          ],
          "temperature": 0.2,
          'model': 'gpt-4',
          'functions': [baristaFunction],
          "function_call": baristaFunction,
          
        }),
      })
        .then(response => response.json())
        .then(data => {
          let output;
          if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
            output = { description: data.choices[0].message.content };
          } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
            output = JSON.parse(data.choices[0].message.function_call.arguments);
          }
          setOutput(output);
          setIsLoading(false);
          console.log('Success:', data);
        })
        .catch(error => console.error('Error:', error));
    };

    const clickedItems = ['Item 1', 'Item 2', 'Item 3'];
  
  return (
    <Box sx={{ flex:'grow', padding: 0 }}>
      
        
      <div className={classes.inputContainer}>
      
      <IconButton onClick={() => setIsDrawerOpen(true)} className={classes.image}>
       <MenuIcon  />
      </IconButton>
      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Typography variant="h4" align="center" sx={{ mb: 2, fontWeight: 500 }}>
          Shortcut to KoffieLand
        </Typography>
        <MessageList/>
        <div>{emojis}</div>
        <div>
  <CollaboratorsCount userId={user.id} />
  
</div>
       
        <List>
          {clickedItems.map((item, index) => (
            <ListItem key={index} >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
        <Display/>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CardMedia component="img" alt="Coffee Bean" image='https://storage.googleapis.com/app_darkendimg/darkpicture/aisapicker.png' sx={{ width: 350, height: '50vh' }} />
        </Box>
      </Drawer>
  <TextareaAutosize
    aria-label="minimum height"
    minRows={1}
    placeholder="OLIVER (On-Line Interactive Vicarious Expediter and Responder)"
    InputProps={{
      className: classes.input,
    }}
    className={classes.input}
    type="text"
    value={ratethis}
    onChange={(e) => setRatethis(e.target.value)}
  />
  <IconButton className={classes.sendIcon} onClick={handleSubmit}>
    <ArrowUpward />
  
  </IconButton>
</div>

<div>      
{isLoading ? (
      <Card sx={{ maxWidth: 400, minHeight: 250, m: 2 }}>
      <Skeleton variant="rectangular" width={400} height={118} />
      <CardContent>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
      </CardContent>
    </Card>
    ) : (
      <>
            <div className="App">
          
                    
                  {content && (
  <Card >
    <CardContent>
      <Avatar  src={content.primaryImage} />
      <Typography variant='h3' align="center">{content.name}</Typography>
      <Card sx={{ borderRadius: 6 }}>
     <img src={content.primaryImage} alt='artwork' width='100%' />
      </Card>
      <Typography >{content.speacials}</Typography>
     
 
      <Typography >{content.Pastries}</Typography>
      <Typography >{content.description}</Typography>
      
      
      {/* ... other properties ... */}
    </CardContent>
    {/* ... rest of your component ... */}
    <Koffieticket/>
  </Card>
            )}	
           
          </div>
             </>
    )}    
            
        </div>    
     
    </Box>
  );
};

export default Barista;