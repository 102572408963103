import React from 'react';
import { Box, Typography,  Paper, CardMedia, Card, Grid, 
	Chip, Button, List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import congo from '../../images/congobag.png';
import Kceromony from '../../images/kceromony.png';
import bill from '../../images/bill.jpg';
import rita from '../../images/rita.jpg';
import { styled } from '@mui/material/styles';
import { textAlign } from '@mui/system';


const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	boxShadow: 'none'
  }));

const useStyles = makeStyles(() => ({
  root: {
	padding: '10px',
    maxWidth: 400,
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
      backgroundImage: `url(${''})`, // Replace with the URL of your image
    backgroundSize: 'cover', // Cover the entire card with the image
    backgroundRepeat: 'no-repeat', // Do not repeat the image
    backgroundPosition: 'center', // Center the image
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
	margin: '0px' /* or a smaller value than what's currently set */
	
    
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  }
}));


const Product = ({ onAddToBag }) => {
 
  const product = {
    id: 'prod_123',
    name: 'Whole Bean Koffie',
    price: 25.00,
    image: {congo}
  };

  const classes = useStyles();

  return (
    
    <Stack spacing={2}  >
      
    
        <Card className={classes.root} sx={{maxWidth: '400px'}}>
	<CardMedia sx={{ width:'200x', height:'400px'}} className={classes.content} image={congo} title="Paella dish" />
	<Typography 
  className={classes.brandCardHeader} 
  style={{ marginBottom: 0 }}  // Add this line
>
  {product.name}
</Typography>
<Typography 
  variant='heading' 
  style={{ marginTop: 0 }}  // Add this line if needed
>
  Your Prescription
</Typography>
<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
<Grid item xs={6}>
          <Item >
			<Typography variant='h6' sx={{ textAlign: 'left'}} >
			1lb
			</Typography>
			<Typography variant='body1' sx={{ textAlign: 'left'}} >
				Medium Roast
			</Typography>
		  </Item>
        </Grid>
        <Grid item xs={6}>
          <Item sx={{fontWeight: 'bold', fontSize: '16px',}}>Best Served Black</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
			<Typography variant='h6' sx={{ textAlign: 'left'}} >
			One Cup a Day
			</Typography>
			<Typography variant='body1' sx={{ textAlign: 'left'}} >
				Keeps the brain farts away.
			</Typography>
		  </Item>
        </Grid>
        <Grid item xs={6}>
          <Item sx={{fontWeight: 'bold', fontSize: '34px'}}>${product.price}</Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{fontWeight: 'normal', fontSize: '18px', textAlign: 'left'}}>
           <List sx={{ }}>
            <Typography variant='h6' sx={{ textAlign: 'left'}} >
           Beanefits  
           </Typography>
           </List>
           <List>
           <Typography variant='body2' sx={{ textAlign: 'left'}} >
           Cognition, Memory, Mood, Energy, Fat Loss, Physical Performance, and more.
           </Typography>
           </List>
           <List>
           <Typography variant='body2' sx={{ textAlign: 'left'}} >
            100% Good Trade&trade;, Sun Dried, Process and Roasted at Farm Gate.
           </Typography>
           </List>
            </Item>
          </Grid>
		</Grid>

	
    <Grid container justifyContent="center" alignItems="center" >
	<Button  style={{backgroundColor: '#426b52', width:'200px', color: '#fff'}} type="button" onClick={() => onAddToBag(product)}>Add to Cart</Button>
  </Grid>
</Card>

</Stack>
  );
};

export default Product;
