import React, { useContext, useEffect, useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, Drawer, TextField, Box, Typography, CardMedia, Card, Chip, Snackbar, Divider, Fab,Modal, useMediaQuery, Grid } from '@mui/material';
import { UserContext } from '../../../context/usercontext';
import Progress from '../../../components/mods/progress';
import { useItem } from '../../finance/mangeshop/useitems';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useProdUpload from '../../profile/produpload';
import ClaimBeans from '../../mods/dialogue/claimbeans';
import { ArrowBack } from '@mui/icons-material'
//import AddItem from '../components/finance/mangeshop/additem'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import habisha from '../../../images/rlady.jpg';
import upload from '../../../images/upload.png';
import useFileUpload from '../cloud';
import styled from '@mui/material/styles/styled';
import coffeebeans from '../../../images/coffeebeans.png';
import { Link } from 'react-router-dom';
import { Collapse, CardContent, CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import MultiFileUpload from '../productui';
import BeanReport from '../../finance/prods/beanreport';
import BeanCard from '../../mods/beancard';
import QRCodeRoaster from '../../mods/roastqrcode';




const ROASTER_BY_USERID = gql`
  query RoasterByUserID($userID: ObjectId!) {
    roaster(query: { userID: $userID }) {
    
      _id
     busname
     bio
      userID
      address1
      address2
      city
      state
      zip
      imageurl
      imageurl2

      
    }
  }
`;

const INSERT_ROASTER = gql`
  mutation InsertRoaster($data: RoasterInsertInput!) {
    insertOneRoaster(data: $data) {
      _id
      busname
      bio
      userID
      address1
      address2
      city
      state
      zip
      imageurl
      imageurl2
    }
  }
`;

const UPDATE_ROASTER = gql`
  mutation UpdateRoaster($query: RoasterQueryInput, $set: RoasterUpdateInput!) {
    updateOneRoaster(query: $query, set: $set) {
      _id
      busname
      bio
      userID
      address1
      address2
      city
      state
      zip
      imageurl
      imageurl2
    }
  }
`;


const CREATE_KUPSTER = gql`
  mutation CreateKupster($data: KupsterInsertInput!) {
    insertOneKupster(data: $data) {
      _id
      favorite_koffie
      Title
      userID
    }
  }
`;

const UPDATE_ONE_KUPSTER = gql`
  mutation UpdateUserprof($query: KupsterQueryInput!, $set: KupsterUpdateInput!) {
    updateOneKupster(query: $query, set: $set) {
      _id
      favorite_koffie
      Title
      userID
      
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const MY_ADDS = gql`
  query MyAdds ($userID: String!) {
    aiadds (query: {userID: $userID}) {
    Title
		When
		_id
		description
		price
		primaryImage
		productID
		userID
    beanamount
  }
}
  
`; 

const UPDATE_ISADD = gql`
  mutation UpdateOneAiadd($query: AiaddQueryInput!, $set: AiaddUpdateInput!) {
    updateOneAiadd(query: $query, set: $set) {
      _id
      isAdd
      # Include other fields you might want to return
    }
  }
`;

const DELETE_ONE_AIADD = gql`
  mutation DeleteOneAiadd($query: AiaddQueryInput!) {
    deleteOneAiadd(query: $query) {
      _id
      
    }
  }
`;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function RoasterDash() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {user} = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [OpenModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const [isDrawerOpen2, setIsDrawerOpen2] = useState(false);

  //open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  //close modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //handle isdraweropen1
  const handleDrawerOpen2 = () => {
    setIsDrawerOpen1(true);
  };

  const handleDrawerClose2 = () => {
    setIsDrawerOpen1(false);
  };

  const handleQrDrawerOpen = () => {
    setIsDrawerOpen2(true);
  };

  const handleQrDrawerClose = () => {
    setIsDrawerOpen2(false);
  }

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const { data: aiAdds, loading: aiLoading, error: aiError } = useQuery(MY_ADDS,
    {variables: { userID: user ? user.id : null } });
    const [updateIsAdd] = useMutation(UPDATE_ISADD);

 const { Nickname } = user.customData;
  console.log("User from context:", Nickname);

  console.log("Querying with userID:", user.id);
  console.log("Querying with userID:", user.id);
  const { loading, error, data, refetch } = useQuery(ROASTER_BY_USERID, {
    variables: { userID: user ? user.id : null },
  });
   // Ensure that the user ID is available
  useEffect(() => {
    console.log("User from context:", user);
  }, [user]);

  const { file, previewURL, handleFileChange, uploadFile } = useFileUpload(); // Use your custom hook
    const [showDialog, setShowDialog] = useState(false);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const [postSubmitted, setPostSubmitted] = useState(false); // Added state for postSubmitted
    const fileInputRef = React.createRef();
    const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
      variables: { _id: user.id },
    });
    const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
    const [updateKupster] = useMutation(UPDATE_ONE_KUPSTER);
  // Add this line to handle errors
    
    //Delete one Add
    const [deleteOneAiadd] = useMutation(DELETE_ONE_AIADD,
      { refetchQueries: [{ query: MY_ADDS, variables: { userID: user ? user.id : null } }] });
      
    // Add this line to handle errors
    const handleUpdateIsAdd = (_id) => {
      updateIsAdd({
        variables: {
          query: { _id: _id }, // Specify which document to update
          set: { isAdd: true } // Set isAdd to true
        }
      })
      if (beanCount < 1) {
        setShowDialog(true); // This will automatically show the dialog
        return;
      }
      decrementBeanCount({
        variables: { _id: user.id, beanCount_inc: -200 }
      })
  
      .then(response => {
        console.log('Update successful', response);
        handleOpenModal();
        // Perform any actions after successful update, e.g., refresh data or UI
      })
      .catch(error => {
        console.error('Error updating isAdd', error);
      });
    };
  
    const handleDeleteAdd = async (item) => {
      try {
        const { data } = await deleteOneAiadd({
          variables: {
            query: { _id: item._id },
          },
        });
  
        if (data.deleteOneAiadd) {
          setMessage(`Deleted: ${data.deleteOneAiadd.name}`);
          // Optionally, update local state or refetch queries here
        }
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    };

    useEffect(() => {
        if (beanData && beanData.userprof) {
            setBeanCount(beanData.userprof.beanCount);
        }
    }, [beanData]);

  const [editProduct, setEditProduct] = useState(null);  // For storing product being edited
  const [openEditDialog, setOpenEditDialog] = useState(false);  // For controlling edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);  // For controlling delete dialog
  const { products, handleInsertProduct, handleUpdateProduct, handleDeleteProduct } = useItem();
  const [createBarista] = useMutation(CREATE_KUPSTER,
    {
      refetchQueries: [{ query: ROASTER_BY_USERID, variables: { userID: user ? user.id : null } }],
      variables: { _id: user.id },
    });


  const query = { userID: user ? user.id : null }; // assuming you identify users by an _id

  const dataExists = data && data.kupster && data.kupster.userID; // Example condition
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
  
    // Prepare the input data
    const input = {
      flavor_profile: formData.get('Title'),
      favorite_koffie: formData.get('favorite_koffie')
     
    };
  
    // Check if the data exists (you need a way to determine this)
    if (dataExists) { // Implement your logic to determine if data exists
      // Update the profile
      updateKupster({ variables: { data } })
        .then(response => {
          console.log('Profile Updated:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not update profile:', error);
        });
    } else {
      createBarista({ variables: { data } })
        .then(response => {
          console.log('New Profile Created:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not create profile:', error);
        });
    }
  };
  
  // Drawer with Form
  
  

  const startEditing = (product) => {
    setEditProduct(product);
    setOpenEditDialog(true);
  };

  const startDeleting = (product) => {
    setEditProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    // Call your update function here, e.g.
    handleUpdateProduct(editProduct);
    setOpenEditDialog(false);
  };

  const handleDelete = () => {
    // Call your delete function here, e.g.
    handleDeleteProduct(editProduct._id);
    setOpenDeleteDialog(false);
  };


 
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  

 // Function to determine cols and rows based on some condition, you can adjust this logic
 const getColsAndRows = (product) => {
  // You could replace this with your actual logic to determine featured products
  return product.featured ? { cols: 2, rows: 2 } : { cols: 1, rows: 1 };
};

const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setOpenSnackbar(true);
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};

 const formik = useFormik({
  initialValues: {
    productName: '',
    productDescription: '',
    productPrice: '',
    // productImage: '', // This can be removed if you're not using it
  },
  onSubmit: async (values, { resetForm }) => {
    // Directly call uploadFile function to upload the image
    
    const uploadedImageURL = await uploadFile(file, user.id);

    // Check if the upload was successful
    if (!uploadedImageURL) {
      console.error('File upload failed: no image URL returned');
      handleCloseDialog();
      return;
    }
    // Create the new product item with the uploaded image URL
    const newProduct = {
      name: values.productName,
      description1: values.productDescription,
      price: values.productPrice,
      imageurl: uploadedImageURL, // Use the uploaded image URL here
      userID: user.id, // Make sure this is the correct way to access the user's ID
    };
      
    // Insert the product item
    handleInsertProduct(newProduct);

    // Reset the form and close any modals/drawers
    resetForm(); // You can use the formik bag's resetForm function
    handleCloseDialog();
  },
  // ... You can add validationSchema or validate function if needed
});


  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  useEffect(() => {
    if (user && user.user && user.user.id) {
      // Trigger refetch or some other action
    }
  }, [user]);
  
  
    
  console.log("Data from query:", data);
  const handleFabClick = () => {
    fileInputRef.current.click();
};


const [formData, setFormData] = useState({
  busname: '',
  bio: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  imageurl: '',
  imageurl2: ''
});
const [selectedRoaster, setSelectedRoaster] = useState(null); // Track the roaster being edited

const [insertRoaster] = useMutation(INSERT_ROASTER);
const [updateRoaster] = useMutation(UPDATE_ROASTER);

// Open the drawer and set form data for edit or add
const handleDrawerOpen1 = (roaster = null) => {
  if (roaster) {
    setSelectedRoaster(roaster);
    setFormData({
      busname: roaster.busname || '',
      bio: roaster.bio || '',
      address1: roaster.address1 || '',
      address2: roaster.address2 || '',
      city: roaster.city || '',
      state: roaster.state || '',
      zip: roaster.zip || '',
      imageurl: roaster.imageurl || '',
      imageurl2: roaster.imageurl2 || ''
    });
  } else {
    setSelectedRoaster(null);
    setFormData({
      busname: '',
      bio: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      imageurl: '',
      imageurl2: ''
    });
  }
  setIsDrawerOpen(true);
};

const handleDrawerClose1 = () => {
  setIsDrawerOpen(false);
  setSelectedRoaster(null);
};

// Handle form field changes
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({ ...prevData, [name]: value }));
};

// Handle form submission for adding or updating
const handleSubmit1 = async (e) => {
  e.preventDefault();
  try {
    const roasterData = {
      ...formData,
      userID: user.id, // Include userID for insertion
    };

    if (selectedRoaster) {
      // Update existing roaster
      await updateRoaster({
        variables: {
          set: roasterData,
          query: { _id: selectedRoaster._id },
        },
      });
    } else {
      // Insert new roaster with userID
      await insertRoaster({
        variables: { data: roasterData },
      });
    }
    // Close the drawer, refetch data, and reset form
    handleDrawerClose1();
    refetch();
  } catch (error) {
    console.error("Error saving Roaster:", error);
  }
};

  // Add this line to handle errors
  if (error) return <div>Error: {error.message}</div>;

  if (loading) return <div><Progress/></div>;
  
  return (
    <div>
       <Grid container spacing={2} justifyContent="center" alignItems="stretch">
  <Grid item xs={12} md={12}>
    
     <BeanCard />
    
  </Grid>
</Grid>
<Divider />
   <div style={{   marginTop: '16px' }}>
   <div >
  {data && data.roaster
    ? Array.isArray(data.roaster)
      ? data.roaster.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              
              borderRadius: "8px",
              marginBottom: "16px",
              padding: "16px",
            }}
          >
            {/* Left Column: Business Info */}
            <div style={{ flex: 1 }}>
              {item.imageurl && (
                <img
                  src={item.imageurl}
                  alt="Roaster"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "8px",
                    marginBottom: "16px",
                  }}
                />
              )}
              <Typography variant="h6" gutterBottom>
                {item.busname || "No Roaster"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.address1 || "No Address"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`${item.city || "No City"}, ${item.state || "No State"} ${item.zip || "No Zip"}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.bio || "No Bio"}
              </Typography>
            </div>

            {/* Right Column: QR Code */}
            <div style={{ marginLeft: "16px", textAlign: "center" }}>
              {item.qrcode ? (
                <img
                  src={item.qrcode}
                  alt="QR Code"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                    
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    
                    borderRadius: "8px",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  Generate QR Code to convert your coffee bags to DigiBeans.
                </Typography>
              )}

              {/* Edit Button */}
              <Chip
                label="Edit"
                onClick={() => handleDrawerOpen1(item)}
                sx={{ marginTop: "16px" }}
              />
              
            </div>
          </div>
        ))
      : (
          <Card
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              
              borderRadius: "8px",
              padding: "16px",
            }}
          >
            {/* Left Column: Business Info */}
            <CardContent sx={{ flex: 1 }}>
              {data.roaster.imageurl && (
                <CardMedia
                  component="img"
                  sx={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                    marginBottom: "16px",
                  }}
                  image={data.roaster.imageurl}
                  alt="Roaster Image"
                />
              )}
              <Typography gutterBottom variant="h5" component="div">
                {data.roaster.busname || "No Roaster"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data.roaster.address1 || "No Address"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`${data.roaster.city || "No City"}, ${data.roaster.state || "No State"} ${data.roaster.zip || "No Zip"}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data.roaster.bio || "No Bio"}
              </Typography>
            </CardContent>

            {/* Right Column: QR Code */}
            <div style={{ marginLeft: "16px", textAlign: "center" }}>
              {data.roaster.qrcode ? (
                <CardMedia
                onClick={handleQrDrawerOpen}
                  component="img"
                  sx={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                  image={data.roaster.qrcode}
                  alt="QR Code"
                />
              ) : (
                <Typography
                  variant="body2"
                  onClick={handleQrDrawerOpen}
                  color="text.secondary"
                  style={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px dashed #ddd",
                    borderRadius: "8px",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  Generate QR Code to convert your coffee bags to DigiBeans.
                </Typography>
              )}


              {/* Edit Button */}
              <Chip
              label="Edit"
                onClick={() => handleDrawerOpen1(data.roaster)}
                sx={{ marginTop: "16px", backgroundColor: '#077336',  }}
              />
              
            </div>
          </Card>
        )
    : (
        <Card style={{ textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>
            Options
          </Typography>
          <Typography variant="subtitle1">
            Set up your custom settings for a better experience
          </Typography>
          <Chip label="Add Roastery" style={{ margin: "10px 0" }} onClick={() => handleDrawerOpen1()} />
        </Card>
      )}
</div>
<Divider sx={{my: 2 }} />
<Drawer anchor="left" open={isDrawerOpen2} onClose={handleQrDrawerClose}>
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: 3,
      gap: 3,
      height: "100vh",
    }}
  >
    {/* Header with Close Button */}
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography variant="h6" component="h2" fontWeight={800}>
        QR Code Generator
      </Typography>
      <IconButton onClick={handleQrDrawerClose}>
        <CloseIcon />
      </IconButton>
    </Box>

    {/* Catchy Message */}
    <Box sx={{ textAlign: "center", mb: 3 }}>
      <Typography variant="h5" fontWeight={700} gutterBottom>
        Convert Your Coffee Bags into DigiBeans!
      </Typography>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        Reward your customers with DigiBeans for every bag of Koffie they purchase.
        Each bag sold earns 4000 Koffie Beans for your customers to use in Koffieland!
      </Typography>
      <Typography variant="body1" color="text.primary" fontWeight={500}>
        Empower your coffee brand and engage customers like never before.
      </Typography>
    </Box>

    {/* QR Code Generator */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: 3,
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
      }}
    >
       <QRCodeRoaster onClose={() => {
    setIsDrawerOpen(false);
    refetch(); // Ensure the parent component updates
  }} />
    </Box>
  </Box>
</Drawer>


      {/* Render Drawer for Adding/Editing */}
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleDrawerClose1} height= '100vh'>
        <Box
          sx={{ width: '100%', padding: 2, hegith: '100vh' }}
          role="presentation"
          component="form"
          noValidate
          onSubmit={handleSubmit1}
        >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1, marginBottom: 1 }}>
  <IconButton onClick={handleDrawerClose1}>
    <CloseIcon />
  </IconButton>
  <Typography variant="h6" component="h2" fontWeight={800}>
    {selectedRoaster ? "Edit  Roastery" : "Add New Roastery"}
  </Typography>
</Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 , minWidth: '100%', mb: 5, height: '100vh'}}>
          <TextField size='small'  name="busname" label="Business Name" value={formData.busname} onChange={handleChange} fullWidth />
          <TextField size='small' name="bio" label="Bio" value={formData.bio} onChange={handleChange} fullWidth />
          <TextField size='small'  name="address1" label="Address 1" value={formData.address1} onChange={handleChange} fullWidth />
          <TextField size='small'  name="address2" label="Address 2" value={formData.address2} onChange={handleChange} fullWidth />
          <TextField size='small'  name="city" label="City" value={formData.city} onChange={handleChange} fullWidth />
          <TextField size='small'  name="state" label="State" value={formData.state} onChange={handleChange} fullWidth />
          <TextField size='small'  name="zip" label="Zip" value={formData.zip} onChange={handleChange} fullWidth />
          <TextField size='small'  name="imageurl" label="Image URL" value={formData.imageurl} onChange={handleChange} fullWidth />
          <TextField size='small'  name="imageurl2" label="Secondary Image URL" value={formData.imageurl2} onChange={handleChange} fullWidth />

          <Button type="submit" variant="contained" color="primary">
            {selectedRoaster ? "Update Roastery" : "Add Roastery"}
          </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
    
    
    <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}>
    <Card sx={{ 
  height: '10vh', 
  width: '100%',
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-between', 
  padding: '0 10px',
  
}}>
  <Typography variant="h6">
   Manage Shop
  </Typography>
  
  <Chip
    label="Manage Shop"
    onClick={handleDrawerOpen}
    backgroundColor="#2fa849"
    style={{ cursor: 'pointer', backgroundColor: '#2fa849', color: '#fff' }}
  />
</Card>

</Box>

 

    
      
     <div>
   {isMobile && (
          <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "100%" }}
      >
        <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" sx={{fontWeight: 600, margin: 0}} >
              {Nickname}'s Collection 
            
            </Typography>
    </Box>
          
    
          <Box sx={{ display: 'flex',  padding: 1,justifyContent: 'center', alignItems: 'center' }}>
          
          <Card sx={{ padding: 2, marginBottom: 3, borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img 
          src='https://storage.googleapis.com/app_darkendimg/newton.png' 
          alt={user.name} 
          width={100} 
          style={{ opacity: 0.9, borderRadius: '50%' }} 
        />
       
            <Chip label="Update payment method" onClick={handleDrawerOpen2} /> 
      </Box>
      <CardContent sx={{ textAlign: 'center', borderRadius: 5, border: 'solid 0px #d1d1d1', marginTop: 0 }}>
        <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
          In Koffieland, the economy thrives on artisanal craftsmanship. Each product stands out with its unique character and quality, embodying the irreplaceable touch of human hands. 
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt:2 }}> 
            <Fab size='medium'  onClick={handleOpenDialog} aria-label="add" sx={{  }} >
            <AddIcon />
            </Fab>  
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary', mt: 1 }}>
              Add Product
            </Typography>   
            
            </Box> 
      </CardContent>
      
    </Card>
    <Drawer anchor="bottom" open={isDrawerOpen1} onClose={handleDrawerClose2}>
    <Box 
  sx={{ 
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    padding: 3, 
    alignItems: 'center',
    justifyContent: 'space-between' 
  }}
>
  <IconButton onClick={handleDrawerClose2}>
    <ArrowBack />
  </IconButton>
  <Typography variant="h6" sx={{ fontWeight: 600, ml: 'auto' }}>
    Payment Method
  </Typography>
</Box>

      <Box sx={{ width: '100%', padding: 0, }}>
       
        <BeanReport />
      </Box>
    </Drawer>
          <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} height= '100vh'>
          <div style={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
  <IconButton onClick={handleCloseDialog} aria-label="Go back">
    <ArrowBack />
  </IconButton>
</div>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' , padding: 2 }}>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>Add Product</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      X50 <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' width={30} /> beans
    </Box>
  </Box>
</Box>

         <MultiFileUpload />
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
          
          <Typography variant="h6" sx={{ fontWeight: 400, textAlign: 'center', margin: 2 }}>
                      Let our AI Smart Feed help you sell your products
          </Typography>
          <Divider variant='middle'  />
          </Box>
          
          </Dialog>
         
          <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent sx={{ padding: 2 ,}}>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
    label="Product Name"
    fullWidth
    value={editProduct?.name || ''}
    onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
    sx={{ mb: 2, mt: 2 }}  
  />
  <TextField
    label="Product Price"
    fullWidth
    value={editProduct?.price || ''}
    onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
    sx={{ mb: 2 }} 
  />
  <TextField
    label="Product Description"
    fullWidth
    value={editProduct?.description1 || ''}
    onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
    sx={{ mb: 2 }}  
  />
         
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Cancel</Button>
          <Button onClick={handleEdit} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <div>
  {products.length > 0 ? (
    <List sx={{ marginBottom: 6 }}>
      {products.map((product, index) => (
        <Card key={index} sx={{ padding: 0, marginBottom: 0 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <img src={product.imageurl} alt={product.name} width={40}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={product.description1}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  ) : (
    <Card sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        You have no items to edit
      </Typography>
    </Card>
  )}
</div>

      
    </div>
      </Drawer>
)}
</div>


    
      <ImageList
  sx={{
   
    overflowY: 'auto', // Allows scrolling within the ImageList if needed
    boxShadow: 0,
    borderRadius: 5,
    padding: 2,
    bgcolor: '#transparent',
  }}
  rowHeight={100}
  gap={1}
  cols={4}
  
>
  

      {products.map((product) => {
        const { cols, rows } = getColsAndRows(product);

        return (
          <Link to={`/product/${product._id}`} key={product._id}>
          <ImageListItem variant key={product.id} cols={cols} rows={rows}
          sx={{}}
          >
            <img
              src={product.imageurl}
              alt={product.name}
              loading="lazy"
              style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 15, padding: 5 }}
            />
            <ImageListItemBar
            sx={{background: 'none'}}
              title={product.name}
              subtitle={'$' + product.price}
              position="bottom"
             
            />

          </ImageListItem>
          </Link>
        );
      })}
    </ImageList>
    
      <Card sx={{
        height: 'auto', // Changed from '10vh' to 'auto' to accommodate the list
        width: '100%',
        display: 'flex',
        flexDirection: 'column', // Changed to 'column' to stack items vertically
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 5,
       
        
      }}>
         <CardMedia sx={{ textAlign: 'center', width: '100%', padding: 0, mt: 2, mb: 2, bgcolor: 'transparent',  }}>
            <Typography variant="h6" gutterBottom sx={{fontWeight:700, textAlign: 'left', }}>
            Add generated add to live feed/market
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ textAlign: 'left', }}>
            The AI smart cart has generated some smart sales for you. Add them to the live feed to generate user interest.
            </Typography>
          </CardMedia>
          {aiAdds && aiAdds.aiadds.length > 0 ? (
  Array.isArray(aiAdds.aiadds) ? (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 , padding: 1}}>
      {aiAdds.aiadds.map((item, index) => (
        <Card key={index} sx={{ width: '100%', flexDirection: 'column', boxShadow: 5, borderRadius: 5,  padding: 1, border: 'solid 1px'}}>
          <CardMedia
            component="img"
            height="150"
            image={item.primaryImage}
            alt={item.Title ? item.Title : "No Title"}
            style={{ objectFit: 'cover', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
          />
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {item.Title ? item.Title : "No Title"}
            </Typography>
            <Typography variant="body1" color='textSecondary'
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1, /* number of lines to show */
              WebkitBoxOrient: 'vertical'
            }}
            >
              {item.description ? item.description : "No Description"}
            </Typography>
          </CardContent>
          <CardActions>
            <Chip label="Add to Market" onClick={() => handleUpdateIsAdd(item._id)} />
            <Chip label="Remove" onClick={() => handleDeleteAdd(item)} />
            <Box sx={{ flexGrow: 1 }} />
            <Chip
              label="500"
              avatar={<Avatar src={coffeebeans} alt="Bean" />}
              variant="outlined"
            />

            <IconButton
              onClick={() => handleExpandClick(index)}
              aria-expanded={expandedIndex === index}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                {item.description ? item.description : "No Description"}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: 400 }}>
        <CardContent>
          <Typography variant="body1">
            {aiAdds.aiadds.description ? aiAdds.aiadds.description : "No Description"}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography variant="body1">
            {aiAdds.aiadds.price ? aiAdds.aiadds.price: "No Price"}
          </Typography>
        </CardActions>
      </Card>
    </div>
  )
) : (
  <Card sx={{ textAlign: 'center', width: '100%', padding: '20px', marginTop: '10px' }}>
    <Typography variant="h6" gutterBottom>
      No Smart Sales Generated Yet
    </Typography>
    <Typography variant="body2" color="green">
      <Link to="/post" style={{ textDecoration: 'none', color: 'inherit' }}>
        Post and share to generate user interest
      </Link>
    </Typography>
  </Card>
)}


      </Card>
      <Modal open={OpenModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
   
  }}>
   <Box sx={{ width: 400, padding: 3,  borderRadius: 5, backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff', border: 'solid 1px #d1d1d1' }}>
   <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
   <Typography variant="h6" sx={{ fontWeight: 600, textAlign: 'left' }}>
     MarketPlace
   </Typography>
   <IconButton onClick={handleCloseModal}>
     <CloseIcon />
   </IconButton>
 </Box>
 
     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
       <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='newton' width={80} />
     
     </Box>
     <Typography variant="body1" sx={{ textAlign: 'center', margin: 2,  }}>
       Your Item has been added to the market. Users can now view and purchase your product from the live feed.
     </Typography>
    
   </Box>
 </Modal>   
  </div>
  )
}

export default RoasterDash;