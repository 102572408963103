import React, { useState } from 'react';
import { 
  Tabs, Tab, Box, Card, CardContent, Typography, 
  Dialog, DialogContent, Grow, Drawer, Button 
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function MobileLayout() {
  const [tabValue, setTabValue] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDrawerOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
    console.log("Drawer toggled. New state:", !drawerOpen);
  };

  console.log("Drawer open state:", drawerOpen);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Tab 1" />
        <Tab label="Tab 2" />
        <Tab label="Tab 3" />
        <Tab label="Tab 4" />
        <Tab label="Tab 5" />
        <Tab label="Tab 6" />
        <Tab label="Tab 7" />
      </Tabs>

      {[0, 1, 2, 3,4,5,6,7].map((index) => (
        <TabPanel key={index} value={tabValue} index={index} sx={{ p: 2 }}>
          {[1, 2, 3,4,5,6,7].map((card) => (
            <Card key={card} onClick={() => handleCardClick(card)} sx={{p: 1, mb: 2, boxShadow: 5 }}>
              <CardContent sx={{ p: 2, borderRadius: 10 }} >
                <Typography>Card {card} Content</Typography>
              </CardContent>
            </Card>
          ))}
        </TabPanel>
      ))}

      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <Typography variant="h6">Selected Card: {selectedCard}</Typography>
          <Button onClick={handleDrawerToggle}>Open Drawer</Button>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogContent>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{ zIndex: 1300 }} // Ensure the Drawer appears on top
        >
          <Box sx={{ width: 250, bgcolor: 'background.paper' }}>
            <ArrowBack onClick={handleDrawerToggle} />
            <Typography variant="h6">Drawer Content</Typography>
          </Box>
        </Drawer>
      </Dialog>
    </Box>
  );
}
