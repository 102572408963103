import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql, useMutation } from '@apollo/client'
import { UserContext } from '../../context/usercontext';
import { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Typography, Select , Radio, RadioGroup, FormControlLabel, IconButton} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import { Container } from '@mui/material';

import { MenuItem } from '@mui/material';
import { Close } from '@mui/icons-material';




const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 450,
    height: '100%',
    padding: 5,
    margin: 10,
    borderRadius: 10,
    // This is an example of a light top shadow style
    transition: '0.3s',
  },
  content: {
    padding: 2,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardActionArea: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',

  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#2d881b',
    width: '100%',
    border: 'none',
    padding: 10,
  
  },
  TextField: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    backgroundColor: '#bce3c0',
    
  },
  drawerPaper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBox: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 2,
    
    
  },
  signInButtonContainer: {
    width: '100%', // Ensures that the container takes full width
    display: 'flex', // Using flex to center the child items
    justifyContent: 'center', // Centers items horizontally
    alignItems: 'center', // Centers items vertically
    padding: 2, // Adds padding around the container
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '50%',
    height: '40px',
    borderRadius: 20,
   
    color: 'white',
    border: 'none',
    marginTop: 30,
    fontSize: 20,
    fontWeight: 700,
  },
}));




const FEED_BACK = gql`
  mutation InsertFeedback($data: FeedInsertInput!) {
  insertOneFeed(data: $data) {
    Title
    _id
    clarity
    claritydetailis
    dislike
    error
    errordetails
    guide
    guidedetails
    like
    nav
    navdetails
    overall
    userID
    features
  }
}

`;

  

function Feedback() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [insertOneFeed, { loading, error }] = useMutation(FEED_BACK);
  const [feedback, setFeedback] = useState({
    Title: "",
    clarity: "How would you rate the clarity of the app's language?",
    claritydetailis: "",
    dislike: "",
    error: "",
    errordetails: "",
    guide: "",
    guidedetails: "",
    like: "",
    nav: "",
    navdetails: "",
    overall: "",
    userID: "",
    features: "",
    
  });
 
  const { user } = useContext(UserContext);
  const classes = useStyles();
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFeedback(prevFeedback => ({
      ...prevFeedback,
      [name]: value === "Yes" ? true : value === "No" ? false : value
    }));
  };
  
  
  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      await insertOneFeed({ variables: { data: { ...feedback, userID: user.id } } });
      setFeedback({
        Title: "",
        clarity: "",
        claritydetailis: "",
        dislike: "",
        error: "",
        errordetails: "",
        guide: "",
        guidedetails: "",
        like: "",
        nav: "",
        navdetails: "",
        overall: "",
        userID: "",
        features: "",
      });
      navigate('/land');
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setSubmitting(false);
     
    }
  };

const goToFaqs = () => {
  navigate('/faq');
}

  return (
   
      
            
            <Box 
            width="100%"
            boxShadow={0}
              component="form"
              onSubmit={e => {
                e.preventDefault();
                insertOneFeed({ variables: { data: { ...feedback, userID: user.id } } });
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                mb: 8,
             
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', mt: 1 ,mb: 4, }}>
  <IconButton
    sx={{
      position: 'absolute',
      left: 0,
      color: 'text.secondary',
      fontSize: '1rem',
    }}
    onClick={() => window.history.back()}
  >
    <Close />
  </IconButton>
  <Typography
    variant="h6"
    sx={{
      fontWeight: 700,
      textAlign: 'center',
      color: 'text.secondary',
      width: '100%', // Ensure the text takes the full width
    }}
  >
    Help Us Improve
  </Typography>
  <Button onClick={goToFaqs} sx={{ borderRadius: 5, backgroundColor: '#3ff107', color: '#000', border: 'none', ":hover": { backgroundColor: '#ffc107' }}}
                >
                  FAQ
                </Button>
</Box>
           
            
              
              
                <Typography variant="body2" gutterBottom>
                Was the app's navigation intuitive and easy to understand?
                </Typography>

                <RadioGroup
  row
  aria-labelledby="navigation-question"
  name="nav"
  value={feedback.nav ? "Yes" : "No"}
  onChange={handleInputChange}
>
  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
  <FormControlLabel value="No" control={<Radio />} label="No" />
</RadioGroup>
                          
             
                <Typography variant="body2" gutterBottom>
                (If no, please provide details)
                </Typography>
                <TextField
                className={classes.TextField}
                  type="text"
                  fullWidth
                  id="navdetails"
                  name="navdetails"
                  value={feedback.navdetails}
                  onChange={handleInputChange}
                />
             
             
             <Typography variant="body2" gutterBottom>
  Were instructions or guidance provided when needed?
</Typography>
<RadioGroup
  row
  name="guide"
  value={feedback.guide ? "Yes" : "No"}
  onChange={handleInputChange}
>
  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
  <FormControlLabel value="No" control={<Radio />} label="No" />
</RadioGroup>
             
                <Typography variant="body2" gutterBottom>
                (If no, please provide details)
                </Typography>
                <TextField
                className={classes.TextField}
                  type="text"
                  fullWidth
                  
                  name="clarity"
                  value={feedback.guidedetails}
                 
                  onChange={handleInputChange}
                />
              
                <Typography variant="body2" gutterBottom>
                Did you encounter any confusing or unclear language within the app?
                </Typography>
                <RadioGroup
  row
  aria-labelledby="navigation-question"
  name="clarity"
  value={feedback.clarity ? "Yes" : "No"}
  onChange={handleInputChange}
>
  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
  <FormControlLabel value="No" control={<Radio />} label="No" />
</RadioGroup>
             
             (If yes, please provide examples)
                < TextField
                className={classes.TextField}
                  type="text"
                  fullWidth
                  id="claritydetailis"
                  name="claritydetailis"
                  value={feedback.claritydetailis}
                  
                  onChange={handleInputChange}
                />
                 
                 <Typography variant="body2" gutterBottom>
                Were error messages clear and helpful?
                </Typography>
                <RadioGroup
  row
  aria-labelledby="navigation-question"
  name="error"
  value={feedback.error ? "Yes" : "No"}
  onChange={handleInputChange}
>
  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
  <FormControlLabel value="No" control={<Radio />} label="No" />
</RadioGroup>
              
                
                <Typography variant="body1" color="initial">
                (If no, please provide details)
                </Typography>
                <TextField
                className={classes.TextField}
                  type="text"
                  fullWidth
                  id='errordetails'
                  name='errordetails'
                  value={feedback.errordetails}
                 
                  onChange={handleInputChange}
                />
               <Typography variant="body2" gutterBottom>
  How would you rate your overall experience with the app?
</Typography>

  <RadioGroup
    row
    aria-label="overall-experience"
    name="overall"
    value={feedback.overall}
    onChange={handleInputChange}
  >
    <FormControlLabel
      value="Very satisfied"
      control={<Radio />}
      label={<><img src="https://storage.googleapis.com/app_darkendimg/assets/goodemoj.png" width={30} alt="Very satisfied" /> Very satisfied</>}
    />
    <FormControlLabel
      value="Satisfied"
      control={<Radio />}
      label={<><img src="https://storage.googleapis.com/app_darkendimg/assets/sat.jpg" width={30} alt="Satisfied" /> Satisfied</>}
    />
    <FormControlLabel
      value="Neutral"
      control={<Radio />}
      label={<><img src="https://storage.googleapis.com/app_darkendimg/assets/neutral-face.png" width={30} alt="Neutral" /> Neutral</>}
    />
    <FormControlLabel
      value="Dissatisfied"
      control={<Radio />}
      label={<><img src="https://storage.googleapis.com/app_darkendimg/assets/dis.png" width={30} alt="Dissatisfied" /> Dissatisfied</>}
    />
    <FormControlLabel
      value="Very dissatisfied"
      control={<Radio />}
      label={<><img src="https://storage.googleapis.com/app_darkendimg/assets/verynot.webp" width={30} alt="Very dissatisfied" /> Very dissatisfied</>}
    />
  </RadioGroup>

                 
                
                  <Typography variant="body2" gutterBottom>
                  What did you like most about the app?
                </Typography>
                  <TextField
                  className={classes.TextField}
                    type="text"
                    fullWidth
                    id="like"
                    name='like'
                    
                    value={feedback.like}
                    onChange={handleInputChange}
                  />
                
                  <Typography variant="body2" gutterBottom>
                  What did you like least about the app?
                </Typography>
                  <TextField
                  className={classes.TextField}
                    type="text"
                    fullWidth
                    id="dislike"
                    name="dislike"
                    value={feedback.dislike}
                   
                    onChange={handleInputChange}
                  />

               <Typography variant="body2" gutterBottom>
                What features would you like to see added to the app?
                </Typography>
                <TextField
                className={classes.TextField}
                  type="text"
                  fullWidth
                  id='features'
                  name="features"
                  value={feedback.features}
                  onChange={handleInputChange}
                />
               
              
              
               
               <Typography variant="body2" gutterBottom>
                Do you have any other feedback or suggestions?
                </Typography>
                <TextField
                className={classes.TextField}
                  type="text"
                  fullWidth
                  id="Title"
                  name="Title"
                  value={feedback.Title}
                  onChange={handleInputChange}
                />
               
          <Box className={classes.buttonContainer}>
        <button
        variant="contained"
          className={classes.button}
          onClick={handleSubmit}
          disabled={submitting || loading}
        >
          {submitting || loading ? <CircularProgress size={24} /> : 'Submit'}
        </button>
      </Box>
            </Box>
          
        
  )
}

export default Feedback