

import React, { useState, useEffect } from 'react';
import { 
Card,  List, Typography, Avatar, ListItem, ListItemText, ListItemAvatar,
Box,  CardContent, Skeleton, 
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import { gql, useLazyQuery } from '@apollo/client';


const list = [
  { id: 1, title: 'Indica' },
  { id: 2, title: 'Sativa' },
  { id: 3, title: 'Kush' },
];

const myVariable = () => {
  const timestamp = new Date().toISOString();
  const name = 'Izzy';
  const primaryImage = 'https://storage.googleapis.com/app_darkendimg/path/to/destination/ideogram%20(6).jpeg';

  const data = {
    koffies: [
      { Country: 'Indica', description: 'Kool Rasta Vibe description' },
      { Country: 'Sativa' , description: 'Kool Surfer Vibe description' },
      { Country: 'Kush' , description: 'Kool Urban Vibe description' },
      { Country: 'Hybrid' , description: 'Kool Geeky Vibe description' },
    ],
  };
  
  // Converting list items to a string
  const collectdata = data.koffies.map((item) => item.description).join(', ');

  return `${name}: ${timestamp} ${primaryImage} [${collectdata}]`;
};

// This will return a string like "Izzy: 2023-04-05T12:00:00.000Z https://... [Indica, Sativa, Kush]"

  
  // a gql query

  const GetKoffie = gql`
  query GetKoffie($Country: String!) {
    koffies(query: { Country: $Country }) {
      _id
      
        Country
        Pounds
    }
  }
`;

const coffee = () => {
    return {
        "name": "GetKoffie",
        "description": "Recommend a coffee",
        "parameters": {
            "type": "object",
            "properties": {
                "Koffie": {
                    "type": "string",
                    "description": "tell me about the coffee"
                },
            },
            "required": ["Koffie"]  
        }
    };
};


   
  

const legbo = () => {
    return {
      "name": "myVariable",
      "description": "You are a whitty bot that can generate a unique description for the collected data.",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name to include in the greeting"
          },
            "timestamp": {
            "type": "string",
            "description": "The current time in EST timezone"
            },
            "primaryImage":
            {"type": "string",
            "description": "primaryImage"
        },
       "description": 
        {"type": "string",
        "description": "You are a whitty bot that can generate a unique description for the collected data"
      },
        

        },
        "required": ["name", "timestamp", "primaryImage", "description"]
      }
    };
  };
  






const Legbo = () => {
  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const [objectID, setObjectID] = useState(''); // New state for objectID
  const [objectData, setObjectData] = useState(null); // New state for objectData
  const [isButtonClicked, setButtonClicked] = useState(false);  
  const [ratethis, setRatethis] = useState(''); // New state for ratethis
  const message = myVariable();
  const [getKoffie, { loading, data }] = useLazyQuery(GetKoffie);
    const [Country, setCountry] = useState(''); // New state for When
  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

const legboFunction = legbo();  // Get the art function
const coffeeFunction = coffee(); // Get the coffee function

const handleSubmit = (e) => {
  setIsLoading(true);  // Start loading before the fetch request
  e.preventDefault();
  getKoffie({ variables: { Country } });
 

      fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${"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"}`,
        },
        body: JSON.stringify({
          "messages": [
            { "role": "system", "content": "Based on the collected data output a unique and whitty descrption." },
            { "role": "user", "content": `Tell me somthing Kool: ${JSON.stringify(message)}.` },
          ],
          "temperature": 0.2,
          'model': 'gpt-4',
          "function_call": legboFunction,

          
          
          
        }),
      })
        .then(response => response.json())
        .then(data => {
          let output;
          if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
            output = { description: data.choices[0].message.content };
          } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
            output = JSON.parse(data.choices[0].message.function_call.arguments);
          }
          setOutput(output);
          setIsLoading(false);
          console.log('Success:', data);
        })
        .catch(error => console.error('Error:', error));
    };

  
  return (
    <Box sx={{ flex:'grow' }}>
      <Typography variant="h4" >
        AI GQL Functions T+K
      </Typography>

<input
          type="text"
          value={Country}
          onChange={(e) => setCountry(e.target.value)}
          placeholder="Enter something to rate"
        />
<div>      
{isLoading ? (
      <>
      <Card sx={{ maxWidth: 400, minHeight: 250, m: 2 }}>
        <Skeleton variant="rectangular" width={400} height={118} />
        <CardContent>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="60%" />
        </CardContent>
      </Card></>
    ) : (
      <>
            <div className="App">
          
            <div className='grid-item'>
            <Typography variant="h6" >                   
                  </Typography>
                  </div>           
                  {content && (
  <Card >
    <CardContent>
      <Avatar  src={content.primaryImage} />
      <Card>
     <img src={content.primaryImage} alt='artwork' width='100%'  />
      </Card>
      <Typography  variant='h3' align="center">{content.name}</Typography>
      <Typography variant='h5'>{content.message}</Typography>
        <Typography variant='h5'>{content.timestamp}</Typography>
        <Typography variant='h5'>{content.Koffie}</Typography>
        <Typography variant='h5'>{content.description}</Typography>
      
      
    
      
    
      {/* ... other properties ... */}
    </CardContent>
    {/* ... rest of your component ... */}
  </Card>
            )}	
          </div>
             </>
    )}         
        </div>    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Fab className='lightmode-container' variant="extended"  aria-label="add" sx={{color:"#fff",}} onClick={handleSubmit}>
    <div sx={{ mr: 1 }}>
      <img src={''} width={30}  alt="Bean" />
    </div>
    What's Your Flavor
  </Fab>
</div>
    </Box>
  );
};

export default Legbo;