import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useQuery,  } from '@apollo/client';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { DialogActions, TextField , DialogContent, Button, Icon} from '@mui/material';
import { Link } from 'react-router-dom';
import StoryCard from '../components/mods/comments';
import {  Drawer } from '@mui/material'; 
import { UserContext } from '../context/usercontext';
import { useContext, useState } from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Grow from '@mui/material/Grow';
import { animated } from 'react-spring';
import ArrowBack from '@mui/icons-material/ArrowBack';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});


const useStyles = makeStyles(() => ({
  root: {
    
    borderRadius: 4,
    boxShadow: 'none',
      transition: '0.3s',
      backgroundColor: 'transparent !important',
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    width: '100%',
    minHeight: 500,
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  commentsContainer: {
    maxHeight: '400px',  // Set a max height that works for you
    overflowY: 'auto',  // Enable vertical scrolling
  },
  stickyArticle: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: 'white',  // Set a background color to cover any content scrolling behind it
  },
}));






const GET_STORYS = gql`
    query{
        krons (limit: 600){
            year 
            What
            ImageUrl
            _id
            countryName
        }
    }
`;




export default function Dhome(kron) {
  const { user } = useContext(UserContext);
  const { data, error, loading } = useQuery(GET_STORYS);
  const [yearFilter, setYearFilter] = React.useState("");
  const [sortedData, setSortedData] = React.useState([]);
  const [expandedCards, setExpandedCards] = React.useState({});
  
  const classes = useStyles();

   const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setDialogOpen(true);
  };

 // Function to handle closing the dialog
 const handleDialogClose = (id) => {
  setExpandedCards(prevState => ({
    ...prevState,
    [id]: false,
  }));
};

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
    console.log("Drawer toggled. New state:", !drawerOpen);
  };

  console.log("Drawer open state:", drawerOpen);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
    setDialogOpen(false);
  };


  React.useEffect(() => {
    if (data) {
      let filteredData = data.krons.filter((kron) => kron.year.includes(yearFilter));
      setSortedData(filteredData.sort((a, b) => b.year - a.year));
    }
  }, [data, yearFilter]);

  

  if (loading) return <p></p>;
  if (error) return <p>Error :</p>;

 

  return (
    <div>
      
    <Box sx={{ flexGrow: 1 ,  }}>
    
      <TextField sx={{}} type="text" value={yearFilter} onChange={(e) => setYearFilter(e.target.value)} placeholder="Filter by Year" />
      
      
        <Box display="flex" flexWrap="wrap"sx={{height: '100vh'}}>
          {sortedData.map((kron) => (
            <Box key={kron._id} m={1}sx={{ }}>
              
                <Card onClick = {() => handleExpandClick(kron._id)} className={classes.root}>
                  
                  <CardMedia
                    component="img"
                    height="350"
                    image={kron.ImageUrl}
                    alt="Paella dish"
                    sx={{borderRadius: 4}}
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    {kron.countryName}
                    </Typography>
                  </CardContent>
                </Card>
              
              <Typography variant="caption">{kron.year}</Typography>
              
             
                <ChatBubbleOutlineIcon sx={{backgroundColor: 'transparent'}} onClick={() => handleExpandClick(kron._id)}/>
           
                <Dialog fullScreen  TransitionComponent={Transition} sx={{height: '100vh'}} open={expandedCards[kron._id]} onClose={() => handleExpandClick(kron._id)}>
                <animated.div className={classes.stickyArticle}>
    <div >
    <div>
          <IconButton onClick={() => handleDialogClose(kron._id)}>
            <ArrowBack />
          </IconButton>
          {/* Other content */}
        </div>
      
      <Card className={classes.root}>
        <Typography variant="h6" component="div">
          {kron.year}
        </Typography>
        <CardMedia
          component="img"
          height="194"
          image={kron.ImageUrl}
          alt="Paella dish"
          sx={{borderRadius: 4}}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {kron.countryName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {kron.What}
          </Typography>
        </CardContent>
        <DialogContent>
          <Typography variant="h6">Selected Card: {selectedCard}</Typography>
          <Button onClick={handleDrawerToggle}>Open Drawer</Button>
          
        </DialogContent>
      </Card>
    </div>
    <div className={classes.commentsContainer}>
      <StoryCard kron={kron} />
      
    </div>
    </animated.div>
    <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{ zIndex: 1300 }} // Ensure the Drawer appears on top
        >
          <Box sx={{ width: 250, bgcolor: 'background.paper' }}>
            <ArrowBack onClick={handleDrawerToggle} />
            <Typography variant="h6">Drawer Content</Typography>
          </Box>
        </Drawer>
  </Dialog>
            </Box>
          ))}
        </Box>
        
      </Box>
    </div>
    

  );
}
