import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    padding: '20px',
  },
  header: {
    marginBottom: '20px',
  },
  goal: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
});

const SalesTracker = () => {
  const classes = useStyles();
  const totalGoal = 23000; // 23,000 lbs
  const monthlyGoal = 3833; // 23,000 / 6 months
  const dailyGoal = 3833 / 30;
  const hourlyGoal = dailyGoal / 24;
  const [sales, setSales] = useState(0);
  const [hourlySales, setHourlySales] = useState(0);
  const [viralBoost, setViralBoost] = useState(false);

  useEffect(() => {
    // Fetch current sales from your backend or e-commerce platform
    axios.get('/api/sales')
      .then(response => {
        setSales(response.data.totalSales);
        setHourlySales(response.data.hourlySales);
      })
      .catch(error => console.error('Error fetching sales data:', error));

    const interval = setInterval(() => {
      // Fetch updated sales data every hour
      axios.get('/api/sales')
        .then(response => {
          setSales(response.data.totalSales);
          setHourlySales(response.data.hourlySales);
        })
        .catch(error => console.error('Error fetching sales data:', error));
    }, 3600000); // 1 hour in milliseconds

    return () => clearInterval(interval);
  }, []);

  const handleViralBoost = () => {
    setViralBoost(true);
    // Implement logic for viral boost (e.g., run flash sale, engage influencers)
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.header}>Koffie Sales Tracker</Typography>
      <Typography className={classes.goal}>Total Goal: {totalGoal} lbs</Typography>
      <Typography className={classes.goal}>Monthly Goal: {monthlyGoal.toFixed(2)} lbs</Typography>
      <Typography className={classes.goal}>Daily Goal: {dailyGoal.toFixed(2)} lbs</Typography>
      <Typography className={classes.goal}>Hourly Goal: {hourlyGoal.toFixed(2)} lbs</Typography>
      <Typography>Current Sales: {sales} lbs</Typography>
      <Typography>Sales This Hour: {hourlySales} lbs</Typography>
      <Typography>Progress: {((sales / totalGoal) * 100).toFixed(2)}%</Typography>
      <Button variant="contained" color="primary" onClick={handleViralBoost}>Activate Viral Boost</Button>
      {viralBoost && <Typography>Viral Boost Activated!</Typography>}
    </div>
  );
};

export default SalesTracker;
