import React, { useContext } from 'react';
import { Card, Box, Typography } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { UserContext } from '../../../context/usercontext';
import '../../../context/revfeed.css';
import {CardContent, CardMedia } from '@mui/material';

const GET_PRODUCTS = gql`
  query Products {
    products {
      Title
      _id
      description1
      description2  
      imageurl
      name
      price
    }
  }
`;

const GET_KOFFIE_COUNTRY = gql`
  query allKoffieCountry {
    koffies {
      countryName
      _id
      flag
    }
  }
`;

const quotes = [
  "Coffee is a beverage that puts one to sleep when not drank.",
  "Wherever it's introduced, it has spelled revolution. It has been the world's most radical drink in that its function has always been to make people think. And when people begin to think, they become dangerous to tyrants.",
  
  "Behind every successful person is a substantial amount of coffee.",
  "Coffee is the best thing to douse the sunrise with.",
  "To me, the smell of fresh-made coffee is one of the greatest inventions.",
];

export const RevFeed = React.memo(function PlaneTicketCard() {
  const { user } = useContext(UserContext);
  const { loading, error, data } = useQuery(GET_PRODUCTS);
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_KOFFIE_COUNTRY);
  
  if (loading || loading2) return <p>Loading...</p>;
  if (error || error2) return <p>Error :</p>;

  return (
    <div className="ticker-container3">
    <div className="ticker-wrapper3">
    {data2 && data2.koffies.map((koffie, index) => (
        <span key={index} className="ticker-item3">

<Card sx={{ maxWidth: 50 }}>
    <CardMedia
        component="img"
        height="50"
        image={koffie.flag}
        alt="green iguana"
    />
    <CardContent>
        <Typography gutterBottom variant="body1" component="div">
            {koffie.countryName}
        </Typography>
    </CardContent>
</Card>
        </span>
    ))}

{/* Duplicate the items for a continuous effect */} 
{data2 && data2.koffies.map((koffie, index) => (
        <span key={index} className="ticker-item3">

<Card sx={{ maxWidth: 50 }}>
    <CardMedia
        component="img"
        height="50"
        image={koffie.flag}
        alt="green iguana"
    />
    <CardContent>
        <Typography gutterBottom variant="caption" sx={{ display: 'flex', justifyContent: 'center' }} component="div">
            {koffie.countryName}
        </Typography>
    </CardContent>
</Card>
        </span>
    ))}

    </div>
    </div>
    
    );
     
     
   
});

export default RevFeed;
