import React, { useState, useEffect } from 'react';
//import Product from './Product';
import Buyit from './prods/buyit';
import FromAdds from '../finance/prods/fromadds';



import { useBag } from '../../context/BagContext';
import Progress from '../mods/progress';
import ShopAd from './adds/shopadd';
import { Store } from '@mui/icons-material';
import StoreAdd from './adds/storeadd';
import { Box } from '@mui/material';
//import Prod1 from './prods/prod';

const MainComponent = (props) => {
    const { itemsInBag, setItemsInBag } = useBag();
    
  const handleAddToBag = (product) => {
    const newItemsInBag = [...itemsInBag, product];
    setItemsInBag(newItemsInBag);
   
  };
  
  return (
    <Box sx={{paddingBottom: 10}}> 
      
              <Buyit onAddToBag={handleAddToBag} />
             <StoreAdd/>
           
    </Box>
  );
};

export default MainComponent;
