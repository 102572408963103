import React from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardContent, CardMedia, Grid, Typography, Chip, CardActions, Box, Switch, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import Sub from './prods/sub';
import { makeStyles } from '@mui/styles';
import bean from '../../images/brkoin.png';
import StoreAdd from './adds/storeadd';
import { useState } from 'react';
import { motion } from 'framer-motion';
import QRScannerDialog from '../scanner';

const useStyles = makeStyles({
    transparentChip: {
        backgroundColor: '#077336',
        border: '1px solid',
        
        color: '#32a852',
    },
});

export const MY_ADDS = gql`
  query MyAdds($isAdd: Boolean!) {
    aiadds(query: { isAdd: $isAdd }) {
      Title
      When
      _id
      description
      price
      primaryImage
      productID
      userID
      isAdd
      imageurl
    }
  }
`;
 
export const GET_IMAGE = gql`
  query GetImage($_id: ObjectId!) {
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
        Nickname
    }
  }
`;

function UserMarket() {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(true);
    const [activeItems, setActiveItems] = useState({}); // Object to track the active state of each item

    const { loading, error, data } = useQuery(MY_ADDS, {
        variables: { isAdd: true },
    });

    const handleToggle = (id) => {
        setActiveItems((prev) => ({
          ...prev,
          [id]: !prev[id], // Toggle the state for the specific item
        }));
      };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (


        <div style={{  display: 'flex', flexDirection: 'column', gap: 10 , padding: 4, marginBottom: 80, marginTop: 5}}>
             <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
              alignItems: 'center',
               gap: 1,
                boxShadow: 1,
                 borderRadius: 2,
                  padding: 2,
                   m: 1,
                   backgroundColor: '#E84393',
             }}>
              <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }} // Start state (invisible and below)
      animate={{ opacity: 1, y: 0 }} // End state (fully visible and in place)
      transition={{ duration: 0.8, ease: 'easeOut' }} // Animation timing
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', // Vertically align the image and text
        width: '100%',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 300, mb: 1 , fontStyle: 'italic',  }}>
        Build the Bean economy when you purchase from Koffielandiers
      </Typography>
      <img
        src="https://storage.googleapis.com/app_darkendimg/lilbeanie.png"
        alt="Beancoin"
        style={{ width: 80, height: 80, borderRadius: '50%' }}
      />
    </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  <Switch
    checked={isActive}
    onChange={() => setIsActive(!isActive)}
    sx={{
      backgroundColor: 'transparent',
      '& .MuiSwitch-thumb': {  },
      '& .MuiSwitch-track': { backgroundColor: '#077336' },
      '& .MuiSwitch-root': { width: 100, height: 40, backgroundColor: 'transparent' },
      '& .MuiSwitch-switchBase': { padding: 1, backgroundColor: 'transparent' },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#077336' }
    }}
  />
  <Typography variant="caption" sx={{ fontWeight: 600 }}>
    {isActive ? 'Fiat - purchase with currency' : 'BEC - exchange for bean tokens'}
  </Typography>
</Box>
        
                    
                    </Box>
                    
                  
            <div>
              <Divider />
    <Grid container spacing={2}>
      {data.aiadds.map((add) => (
        <Grid item key={add._id} xs={6} sm={6} md={4}>
          <Card sx={{ boxShadow: 0, borderRadius: 0, paddingBottom: 0 }}>
            <Link to={`/fromadds/${add._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <CardMedia
                component="img"
                height="150"
                image={add.primaryImage}
                alt={add.Title}
                sx={{ objectFit: 'cover', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
              />
            </Link>
            <CardContent sx={{ padding: 1, borderRadius: '0 0 5px 5px' }}>
              <Link to={`/fromadds/${add._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography
                  gutterBottom
                  variant="body2"
                  fontWeight={500}
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {add.Title}
                </Typography>
              </Link>
              <UserImage userID={add.userID} />
              <CardActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                {!activeItems[add._id] ? (
                  <Link to={`/frombeans/${add._id}`} style={{ textDecoration: 'none' }}>
                    <Chip
                      label='2k'
                      icon={<img alt="Kup" src="https://storage.googleapis.com/app_darkendimg/assets/madbean.png" width={25} />}
                      sx={{
                        backgroundColor: '#d1e2f4',
                        border: '1px solid #d1e3f4',
                        borderRadius: 5,
                        cursor: 'pointer',
                      }}
                    />
                  </Link>
                ) : (
                  <Chip
                    label='2k'
                    icon={<img alt="Kup" src="https://storage.googleapis.com/app_darkendimg/assets/bikerbean.png" width={25} />}
                    sx={{
                      backgroundColor: '#e0e0e0',
                      color: 'grey',
                      border: '1px solid #d1e3f4',
                      borderRadius: 5,
                      cursor: 'default',
                    }}
                  />
                )}

                {activeItems[add._id] ? (
                  <Link to={`/fromadds/${add._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Chip
                      label={`$${add.price}`}
                      sx={{
                        backgroundColor: '#d1e3f4',
                        borderRadius: 5,
                        cursor: 'pointer',
                      }}
                    />
                    {add.ProductID}
                  </Link>
                ) : (
                  <Chip
                    label={`$${add.price}`}
                    sx={{
                      backgroundColor: '#e0e0e0',
                      color: 'white',
                      border: 'none',
                      borderRadius: 5,
                      cursor: 'default',
                    }}
                  />
                )}
              </CardActions>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                <Switch
                  checked={activeItems[add._id] || false} // Use the active state for the specific item
                  onChange={() => handleToggle(add._id)} // Toggle the active state for this item
                  sx={{
                    backgroundColor: 'transparent',
                    '& .MuiSwitch-thumb': {  },
                    '& .MuiSwitch-track': { backgroundColor: '#077336' },
                    '& .MuiSwitch-root': { width: 100, height: 40, backgroundColor: 'transparent' },
                    '& .MuiSwitch-switchBase': { padding: 1, backgroundColor: 'transparent' },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#077336' },
                  }}
                />
                <Typography variant="caption" sx={{  fontWeight: 700 }}>
                  {activeItems[add._id] ? 'Fiat' : 'BEC'}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </div>
            
           
        </div>
    );
}

// Component to fetch and display user image
function UserImage({ userID }) {
    const { loading, error, data } = useQuery(GET_IMAGE, {
        variables: { _id: userID },
    });

    if (loading) return <img src="https://via.placeholder.com/30" alt="Loading user" style={{ width: 30, height: 30, borderRadius: 50 }} />;
    if (error) return <img src="https://via.placeholder.com/30" alt="Error loading user" style={{ width: 30, height: 30, borderRadius: 50 }} />;

    const userImage = data.userprofs[0]?.imageurl || 'https://via.placeholder.com/30';
    const name = data.userprofs[0]?.Nickname || 'User';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <img src={userImage} alt="user" style={{ width: 20, height: 20, borderRadius: '50%' }} />
            <Typography variant="caption" sx={{ mb: 0 }}>
                {name}
            </Typography>
        </Box>
    );
}

export default UserMarket;
