import React from 'react';
import styled from "styled-components";
import { Avatar, Chip, Button as MuiButton } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom'

const GET_USERS_BY_TYPE = gql`
  query GetUsersByType($userType: String!) {
  userprofs(query: { usertype: $userType }) {
    _id
    Nickname
    Title
    Koffie_story
    usertype
    imageurl
  }
}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const UsersList = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 8px;
  gap: 16px; // Adjust the gap as needed

  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox
`;


const UserCard = styled.div`
  min-width: 120px; // Adjust the width as needed
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // This adds a subtle shadow to the card
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FollowButton = styled(MuiButton)`
  && {
    margin-top: 8px;
  }
`;

function FarmerCard({ userType="prodman" }) {
  const { loading, error, data } = useQuery(GET_USERS_BY_TYPE, {
    variables: { userType },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading users</div>;

  return (
    <Container>
      <UsersList>                 
        {data.userprofs.map((user) => (
          <UserCard key={user._id}>
            <Link to={`/prodman/${user._id}`}>
              <Avatar
                sx={{ border: '2px solid black', width: 60, height: 60 }}
                alt={user.Nickname}
                src={user.imageurl}
              />
            </Link>
            <div>{user.Nickname}</div>
            <div>{user.Title}</div>
           <Chip label="Contract"  />
          </UserCard>
        ))}
      </UsersList>
    </Container>
  );
}

export default FarmerCard;
