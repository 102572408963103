import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import Typeit from '../mods/typeit';
import CoffeePage from './des';
import { 
Card, Chip, List, Paper, Typography, Avatar, Button,
Box, Divider, CardContent
} from '@mui/material';
import { App } from 'realm-web';
import '../../App.css';
import bean from '../../images/coffeebeans.png';
import Flavloader from '../mods/flavloader';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import kup from '../../images/steamkup.jpg';
import koffieman from '../../images/koffieman1.png';
import ThreeScene from '../mods/particles';
import '../../context/EarthMode.css';
import { Link } from 'react-router-dom';
import { ListAlt } from '@mui/icons-material';
import {useQuery, gql, useMutation } from '@apollo/client';
import Aifood from './aifood';
import Header from '../nav/mobilefarmer';
import { UserContext } from '../../context/usercontext'
import { useContext } from 'react';



//Mutate Koffie
const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      name
      description
      country
      flavorprofile
      purchaseLink
    }
  }
`; 


const GetKoffie = gql`
  query GetKoffie($Country: String!) {
    koffy(query: { Country: $Country }) {
      _id
     Country
    }
  }
`;

const kupakoffie = () => {
    return {
      "name": "kupakoffie",
      "description": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name of the coffee"
          },
          "country": {
            "type": "string",
            "description": "The country of origin of the coffee"
          },
          "description": {
            "type": "string",
            "description": "Short Whitty Description of the coffee"
          },
          "tasteProfile": {
            "type": "string",
            "description": "How i like my coffee"
          },
          "purchaseLink": {
            "type": "string",
            "description": "/koffie/country "
          },
          
        },
        "required": ["name", "country", "description", "tasteProfile",  "purchaseLink"]
      }
    };
  };


  const useStyles = makeStyles(({ palette }) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    card: {
      borderRadius: 12,
      minWidth: 256,
      textAlign: 'center',
      transition: '0.3s',
    },
    avatar: {
      width: 150,
      height: 150,
      margin: 'auto',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      color: '#1f1e1d',
      marginBottom: '0.875em',
    },
    statLabel: {
      fontSize: 12,
      color: "#ddd",
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 4,
      letterSpacing: '1px',
      fadeIn: 'ease-in 5s',
      visibility: 'visible',
    },
  }));



const SunburstChart = () => {
  const { user, customData, updateMongoDBData } = useContext(UserContext);


// ... use customData as needed ...

  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const styles = useStyles();
  const [addStory] = useMutation(UPDATE_USERPROF);
  const [isButtonClicked, setButtonClicked] = useState(false);
  const { updateCustomData } = useContext(UserContext);
  const [country, setCountry] = useState(''); // Default value
  const { loading, error, data } = useQuery(GetKoffie, {
    variables: { Country: country }, // Use the state variable here
  });
  
  
  const ChartData = [
    {
        maxdepth: 6,
        insidetextorientation: 'radial',
      type: "sunburst",
      labels: ["Coffee", "Aroma", "Brown Sugary",  "Dry Distillation",  "Taste", "Sour",  "Soury", "Enzymatic", "Sweet", "Salt", "Bitter", "Carbony", "Spicy", "Resinous" , "Ashey", "Smokey", "Burnt", "Charred", "Tarry", "Pipe Tobacco", "Warming", "Pungent", "Thyme", "Clove", "Cedar", "Pepper", "Tupeny", "Medicinal", "Ceniolic", "Camphoric", "Piney", "Blackcurrant-like", "Chocolatey", "Caramelly", "Nutty", "Vanilla-like", "Chocolate-like", "Syrup-like", "Candy-like","Malt-like", "Nut-like", "Butter", "Swiss", "Dark Chocolate", "Bakers", "Maple Syrup", "Honey", "Roasted Almond", "Roasted Hazlenut", "Herby", "Fruity", "Flowery", "Leguminous", "Alliaceous", "Berry-like", "Citrus", "Fragrant", "Floral", "Onion", "Garlic", "Cucumber", "Garden Peas", "Blackberry", "Apricot", "Lemon", "Apple", "Coriander Seeds", "Cardamon Caraway", "Tea Roase", "Coffee Blossom", "Acidity", "Mellow", "Winey", "Harsh", "Pungenty", "Rough", "Sharp", "Bland", "Astringent", "Soft", "Neutral", "Creosol", "Phenolic" , "Caustic", "Alkeline", "Tangy", "Tart", "Acid", "Hard", "Mild", "Delicate", "Piquant", "Nippy"],
      parents: ["", "Coffee", "Aroma",  "Aroma",  "Coffee", "Taste", "Sour",  "Aroma", "Taste", "Taste", "Taste", "Dry Distillation", "Dry Distillation", "Dry Distillation", "Carbony", "Carbony", "Ashey", "Ashey", "Smokey", "Smokey" , "Spicy", "Spicy", "Pungent", "Pungent", "Warming", "Warming", "Resinous", "Resinous", "Medicinal", "Medicinal", "Tupeny", "Tupeny", "Brown Sugary", "Brown Sugary", "Brown Sugary" , "Chocolatey", "Chocolatey", "Caramelly", "Caramelly", "Nutty", "Nutty", "Vanilla-like", "Vanilla-like", "Chocolate-like", "Chocolate-like", "Syrup-like", "Syrup-like", "Candy-like", "Candy-like", "Enzymatic","Enzymatic", "Enzymatic", "Herby", "Herby", "Fruity", "Fruity", "Flowery", "Flowery", "Alliaceous", "Alliaceous", "Leguminous", "Leguminous", "Berry-like", "Berry-like", "Citrus", "Citrus", "Fragrant", "Fragrant", "Floral", "Floral", "Sweet", "Sweet", "Sour", "Bitter", "Bitter", "Sharp", "Salt", "Salt", "Sharp", "Bland", "Bland", "Pungenty", "Pungenty", "Harsh", "Harsh", "Winey", "Winey", "Soury", "Soury", "Mellow", "Mellow", "Acidity", "Acidity"  ], 
        marker: {line: {width: 2},
        colors: [ "#876545", "#dbd225", "#ab9628", "#e6845a", "#88c292", "#73e067", "#73e067", "#e6845a", "#800000", "#008000", "#000080", "#808000", "#f5a327", "#000080", "#808000", "#f5a327", "#f5a329", "#808000", "#f5a327", "#33bbff", "#fdff33", "#808000", "#f5a327", "#f5a329", "#808000", "##ab9628", '#f127f5', "#fdff33", "#f127f5", "#f5a327","#a37814", "#806a37", "#cfbe06 ", "#d4570f"],
      
    }
    }
  ];

  
  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  
  
  

  const layout = {
    width: windowSize.width * .97, 
    margin: {l: 0, r: 0, b: 0, t: 0}, 
    showlegend: false,
    ModeBarButtons: '#fff', 
    paper_bgcolor: "transparent", 
    plot_bgcolor: "#000", 
    font: {color: "#000"}
  };
  

  const handleClick = (data) => {
    if (data.points && data.points.length > 0) {
        const label = data.points[0].label;
        
        setClickedItems([...clickedItems, { label, }]);
        setButtonClicked(true);
    }
};


const coffeeFunction = kupakoffie();  // Get the coffee function

  const handleSubmit = () => {
    const profileData = clickedItems.map(item => item.label).join(', ');
    setIsLoading(true);  // Start loading before the fetch request

  
    fetch('https://api.openai.com/v1/chat/completions', { // replace with your server URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${"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"}`,

      },
      body: JSON.stringify({
        
        "messages": [
           
            {"role": "system", "content": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink!."},
            {"role": "user", "content": "This is my flavor Profile: " + profileData + "."},
            ],
            
        'model': 'gpt-4',  // or 'davinci-codex', depending on your preference
        'functions': [coffeeFunction],
        'temperature': 0.5,
       
        
        
      }),
    })
    .then(response => response.json())
    .then(data => {
      let contentData;
      if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
        contentData = {
          name: data.choices[0].message.content.name,
          country: data.choices[0].message.content.country,
          flavorprofile: data.choices[0].message.content.tasteProfile,
          description: data.choices[0].message.content.description,
          purchaseLink: data.choices[0].message.content.purchaseLink,
        };
      } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
        contentData = JSON.parse(data.choices[0].message.function_call.arguments);
      }
  
      setOutput(contentData); // Render the content on the frontend
      console.log('Success:', contentData);
  
       // Here, you incorporate the handleUpdate function
       let newData = {
        country: contentData.country,
        description: contentData.description,
        flavorprofile: contentData.tasteProfile,
        name: contentData.name,
        
    };
    
    if(contentData.purchaseLink) {
        newData.purchaseLink = contentData.purchaseLink;
    }
     
    updateMongoDBData(contentData);
;  // Assuming you've brought in this function from your UserContext

        
   
  
      setIsLoading(false); // End loading after receiving the response
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false); // End loading if there's an error
    });
      
  };
  
  

  return (
    <Box sx={{ flex:'grow' }}>
      
      <Plot
        data={ChartData}
        layout={layout}
        onHover={handleClick}
        config={{ displayModeBar: false }} // This line hides the mode bar
      />
     
      <Typography variant='h5' textAlign={"center"}sx={{color:"#6ba53e"}}>
      What's Your Flavor?
      </Typography>
      {clickedItems.map((item, index) => (
 <Chip className='lightmode-container'
 key={index}
 label={item.label}
 avatar={<Avatar alt="Kup" src={kup} sizes='10' />}
 sx={{backgroundColor:"#6ba53e", color:"#fff", m:1}}
/>

))}

<div>      
{isLoading ? (
      <Flavloader />
    ) : (
      <>
               
          <div className="App">
          
            <div className='grid-item'>
            <Typography variant="h6" >
                    
                  </Typography>
                  </div>
           
                  {content && (
  <Card className={styles.card}>
    <CardContent>
      <Avatar className={styles.avatar} src={koffieman} />
      <Typography className={styles.heading} align="center">{content.name}</Typography>
      <Typography className={styles.subheader}>{content.tasteProfile}</Typography>
      <Typography variant="h6">{content.country}</Typography>
      
      <Typography className={styles.statValue}>{content.description}</Typography>
      <Link to={`/koffie/${content.country}`} >
  <Button>
    Purchase
  </Button>
</Link>

      {/* ... other properties ... */}
    </CardContent>
   <Header/>
  </Card>
            )}	


          </div>
        
      </>
    )}       
        
        </div>
      
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Fab className='lightmode-container' variant="extended"  aria-label="add" sx={{color:"#fff",}} onClick={handleSubmit}>
    <div sx={{ mr: 1 }}>
      <img src={bean} width={30}  alt="Bean" />
    </div>
    What's Your Flavor
  </Fab>
</div>


      <div>
      <CoffeePage/>
      </div>
    </Box>
  );
};

export default SunburstChart;
