import React, { useState } from 'react';
import { MobileStepper, Paper, Typography, Button, Box, Chip, Pagination,  Drawer } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';

const steps = [
    {
      label: "Following Sucks, Collaborate",
      title: "We collaborate with farmers to bring you the best coffee.",
      content: " I you are a follower your'e in the wrong place. The Koffie APP is for collaboration. We are a community of coffee lovers, farmers, and roasters, hell bent on disrupting the value chain",
      imgPath: "https://storage.googleapis.com/app_darkendimg/hilltop.png",
    },
   
    {
      label: "Connect",
      content: "Our mission is to revolutionize the coffee industry by connecting you directly with farmers. By bypassing BIG COFFEE, we empower local communities and create a ripple effect of positive change—socially, politically, and economically. Coffee stirs the mind and awakens thought. Throughout history, it's been a catalyst for conversation, innovation, and even revolution—fueling ideas that challenge tyranny.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/hilltop.png",
    },
    {
      label: "Smart Coffee",
      content: "We leverage blockchain technology to lock in coffee beans, ensuring transparency and trust in every transaction. It's not about cryptocurrency—it's about creating a fair and secure system for all.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/hilltop.png",
    },
    {
      label: "Market Place",
      content: "Invest in green beans, and join us as we roast and retail premium coffee. Share in the profits with our farmers, disrupt the status quo, and help us reshape the course of history. Your choice can lead to global change.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/hilltop.png",
    },
    
    {
      label: "Disrupt",
      content: "Embrace a future where the coffee industry is just the beginning. Together, we can address wider issues and find solutions that benefit everyone, everywhere. It starts with a bean, but it grows into a movement.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/hilltop.png",
    }
  ];
  

function AppGuide() {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100hv' }}>
         <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%', // Full width of the viewport
          height: '100%', // Full height of the viewport
          overflow: 'hidden',
        },
      }}
    >
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        containerStyle={{ height: 'calc(100% - 0px)' }} // Adjust height to account for pagination and skip chip
      >
        {steps.map((step, index) => (
          <div key={step.label} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Paper square elevation={0} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight:1, paddingLeft: 2, paddingTop: 5  }}>
             
              {step.imgPath && <img src={step.imgPath} alt={step.label} style={{ width: '100%', maxHeight: '60vh', objectFit: 'contain' }} />}
              <Typography variant="h5" sx={{ mb: 1 }}>{step.label}</Typography>
              <Typography variant="h6" sx={{ mb: 2 }}>{step.title}</Typography>
              <Typography sx={{ mb: 2 }}>{step.content}</Typography>
            </Paper>
          </div>
        ))}
      </SwipeableViews>
      <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, py: 2, backgroundColor: 'background.paper' }}>
        <Pagination count={maxSteps} page={activeStep + 1} onChange={(_, value) => handleStepChange(value - 1)} sx={{ justifyContent: 'center', display: 'flex' }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Chip label={`Skip ${activeStep + 1} of ${maxSteps}`} onClick={handleClose} />
        </Box>
      </Box>
    </Drawer>
    </Box>
  );
}

export default AppGuide;
