import React, { useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';

const GetKoffie = gql`
 query Koffy($query: KoffyQueryInput) {
  koffy(query: $query) {
    _id
    Country
    Pounds
  }
}
`;


function KoffieSearch() {
  const [getKoffie, { loading, data, error }] = useLazyQuery(GetKoffie);
  const [Country, setCountry] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    getKoffie({ variables: { Country } });
  };

  if (loading) console.log("Loading...");
  if (error) console.log(`Error! ${error.message}`);
  if (data) console.log("Data: ", data);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          value={Country} 
          onChange={(e) => setCountry(e.target.value)} 
          placeholder="Enter country" 
        />
        <button type="submit">Search</button>
      </form>
      {loading && <p>Loading...</p>}
      {data && data.koffy && (
        <div>
          <h2>{data.koffy.Pounds}</h2>
          <p>{data.koffy.Country}</p>
        </div>
      )}
        
     
    </div>
  );
}

export default KoffieSearch;
