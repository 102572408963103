import React, { useState, useContext } from 'react';
import { Web3ModalContext } from '../constants/Web3ModalProvider';
import useKupToken from './useKupToken';
import { Button, TextField, Box, Stack, Card, Typography } from '@mui/material';




function RoasterSteps() {
    const { account, connect } = useContext(Web3ModalContext);
    const { startRoasting, processStep } = useKupToken(account);

    const handleClick = async (action, step) => {
        // ...
        if (action === 'roasting') startRoasting(step);
       
      };

    const [supplyInLbs, setSupplyInLbs] = useState('');
    const [strain, setStrain] = useState('');
    const [elevation, setElevation] = useState('');
    const [profile, setProfile] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!account) {
            await connect();
        }

       

        try {
            await startRoasting(processStep );
            alert('Supply submitted successfully');
            
            processStep('');
        } catch (error) {
            console.error('An error occurred:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (

        <div style={{ paddingBottom: 5 }}>
        <Card sx={{ flexGrow: 1, overflow: 'hidden', px: 3, padding: '10px', borderRadius: '20px' }}>

    
        <Stack spacing={2} direction="row" sx={{flexWrap: 'wrap',  gap: '16px 8px'}}>
        <Button
         sx={{
            backgroundColor: "#45c463 !important", 
            borderRadius: "20px !important",
            color: "#fff !important",
          }}
        onClick={() => handleClick('roasting', 1)}>Start Hulling</Button>
  <Button sx={{
        backgroundColor: "#45c463 !important", 
        borderRadius: "20px !important",
        color: "#fff !important",
      }} onClick={() => handleClick('roasting', 2)}>Start Sorting</Button>
  <Button sx={{
        backgroundColor: "#45c463 !important", 
        borderRadius: "20px !important",
        color: "#fff !important",
      }} onClick={() => handleClick('roasting', 3)}>Start Grading</Button>
       <Button sx={{
        backgroundColor: "#45c463 !important", 
        borderRadius: "20px !important",
        color: "#fff !important",
      }} onClick={() => handleClick('roasting', 4)}>Start Roasting</Button>
       <Button sx={{
        backgroundColor: "#45c463 !important", 
        borderRadius: "20px !important",
        color: "#fff !important",
      }} onClick={() => handleClick('roasting', 5)}>Start Packaging</Button>
       <Button sx={{
        backgroundColor: "#45c463 !important", 
        borderRadius: "20px !important",
        color: "#fff !important",
      }} onClick={() => handleClick('accept')}>Accept Contract</Button>
      </Stack>
        </Card>
       

        </div>
        
    );
}

export default RoasterSteps;
