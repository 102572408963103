import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import beans from '../../../images/coffeebeans.png';
import { useMutation, gql } from '@apollo/client';
import { UserContext } from '../../../context/usercontext';
import './rain.css'
import { Typography, Chip, List, ListItem,ListItemIcon, ListItemText, Divider,   } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd'; // Icon for posting
import ProfileIcon from '@mui/icons-material/AccountCircle'; // Icon for AI flavor profile
import CommentIcon from '@mui/icons-material/Comment'; // Icon for replying to comments
import StoreIcon from '@mui/icons-material/Store'; // Icon for AI salesman store
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
        beanCount
      
    }
  }
`; 


const ClaimBeans = () => {
    const { user } = useContext(UserContext);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const [beanRainVisible, setBeanRainVisible] = useState(false);  // New state variable to control the visibility of the bean rain animation
  const [open, setOpen] = useState(true); // State to control the dialog
  const [updateOneUserprof, ] = useMutation(UPDATE_USERPROF);
  // Function to show the dialog
  const showDialog = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle "Get Beans" action
  const handleGetBeans = async () => {
    // Logic to claim kupa koffie (100 beans)
    const variables = {
        query: { _id: user.id },
        set: {
         
          beanCount: beanCount + "100",
          

        }
      };
    
      try {
        await updateOneUserprof({ variables });
        console.log("Successfully updated the user profile.");
      } catch (error) {
        console.log("An error occurred while updating the user profile:", error);
      }

    // Decrement the beanCount by 100
   // Show the bean rain dialog
   setBeanRainVisible(true);

   // Hide the bean rain dialog after 1 second
   setTimeout(() => {
     setBeanRainVisible(false);
     handleClose();
   }, 3000);
 
    // Close the dialog
    handleClose();
  };

  return (
    <div>
      {/* Your existing components and logic here */}

      {/* Button to trigger the dialog for demonstration */}
      

      {/* The Dialog component */}
      <Dialog fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogTitle> 
        <Typography variant='h3' sx={{fontWeight: 700, paddingTop: 20}}>
        <img src={beans} alt='bean' width={40}/>    Get Beans  
        </Typography>
        </DialogTitle>
        <DialogContent>
        <List>
      <ListItem>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary="5 beans per post" />
      </ListItem>
        <Divider />
      <ListItem>
        <ListItemIcon>
          <ProfileIcon />
        </ListItemIcon>
        <ListItemText primary="20 beans for AI flavor profile" />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText primary="10 beans to reply to comments" />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary="50 beans for every item added to your AI salesman store" />
      </ListItem>
      <Divider />
    </List>
        </DialogContent>
        <DialogActions>
        
          <Chip label="Get Beans" onClick={handleGetBeans} sx={{ backgroundColor: '#3ca120', margin: '10px 0', padding: 1, fontWeight: 500 }} />
        </DialogActions>
      </Dialog>
       {/* The Dialog component for raining beans */}
       <Dialog open={beanRainVisible} fullWidth={true} maxWidth={"md"}>
      <div className="bean-rain">
        <div className="centered-message">
          <Typography variant="h3" gutterBottom sx={{fontWeight: 500, color: '#fff'}}>
           "Congrats, your beans are in your bag, grind on"
          </Typography>
        </div>
      </div>
    </Dialog>
    </div>
  );
};

export default ClaimBeans;
