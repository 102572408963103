import React from 'react';
import {  Typography,  Paper, CardMedia, Card, Grid, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, DialogContentText,
 Button, List, Chip, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import congo from '../../../images/congobag.png';
import { styled } from '@mui/material/styles';
import { gql,  useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom'
import Progress from '../../mods/progress';
import baggy from '../../../images/baggy.png';
import minted from '../../../images/brkoin.png';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { UserContext } from '../../../context/usercontext';
import { useContext, useState } from 'react';
import DevelopmentCard from '../../mods/dev';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Close from '@mui/icons-material/Close';
import ReactImageMagnify from 'react-image-magnify';






const GET_PROD = gql`
  query Prod ($_id: ObjectId!)  {
  aiadd (query: {_id: $_id}) {
   When
   price
    description
    imageurl
    imageurl1
    imageurl2
    imageurl3
    imageurl4
    primaryImage
    _id
    Title
  
  }
}
`; 

export const INSERT_CART_MUTATION = gql`
mutation InsertOneBeancart($data: BeancartInsertInput!) {
  insertOneBeancart(data: $data) {
    userID
    productId
    price
    beanies
    name
    quantity
    options {
      size
      color
    }
  }
}
`;

export const GET_CART_ITEMS = gql`
  query GetCart($userID: ObjectId!) {
    beancarts(query: { userID: $userID }) {
      userID
      productId
      quantity
      options {
        size
        color
      }
    }
  }
`;

const Item = styled(Paper)(({ theme }) => ({
	
	padding: theme.spacing(1),
	textAlign: 'center',

	boxShadow: 'none'
  }));

const useStyles = makeStyles(() => ({
  root: {
	padding: '10px',
   
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
      backgroundImage: `url(${''})`, // Replace with the URL of your image
    backgroundSize: 'cover', // Cover the entire card with the image
    backgroundRepeat: 'no-repeat', // Do not repeat the image
    backgroundPosition: 'center', // Center the image
     
  },
  content: {
    padding: 10,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
	margin: '0px' /* or a smaller value than what's currently set */
	
    
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    // Add styles for the button here
    backgroundColor: '#32a852',
    color: 'white',
    borderRadius: 20,
    padding: 10,
    border: 'none',
    width: '40%',
    height: 40,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#077336',
    },
  },
}));


const FromBeans = ({ onAddToBag }) => {
    const { user } = useContext(UserContext);
    const classes = useStyles();
    const { _id } = useParams()
    const [isClicked, setIsClicked] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [OpenModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  // Handle Open Modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

    const { loading, data, error } = useQuery(GET_PROD, {
        variables: { _id: _id }
      });
     
     // Modify handleOpen to set the selected image URL
const handleOpen = (imageUrl) => {
  setOpen(true);
  setSelectedImageUrl(imageUrl);
};

// Modify handleClose to reset the selected image URL
const handleClose = () => {
  setOpen(false);
  setSelectedImageUrl(null);
};
      const [insertCart, ] = useMutation(INSERT_CART_MUTATION);

 
    if (loading) return <p><Progress/></p>;
if (error) return <p>Error: {error.message}</p>;

 const product = {
    id: data.aiadd._id, 
    name: data.aiadd.Title,
    price: data.aiadd.price,
    image: data.aiadd.primaryImage, // adjust this according to your schema
    imageurl1: data.aiadd.imageurl1,
    imageurl2: data.aiadd.imageurl2,
    imageurl3: data.aiadd.imageurl3,
    imageurl4: data.aiadd.imageurl4,
    description: data.aiadd.description,
   
};
  
const handleClick = () => {
  setIsClicked(true);
  // Reset the animation state after a short delay
  setTimeout(() => setIsClicked(false), 300);
};

const handleAddToCart3 = async (product) => { 
  const cartData = {
    query: { userID: user.id },  // Assuming user.id is securely fetched
    data: {
      userID: user.id,  // Possibly redundant; ensure alignment with your backend logic
      productId: product.id,
      quantity: 1,
      name: product.name,
      beanies: '4000',
      price: product.price,
      imageurl: product.image,  // Adjust this according to your schema
      options: {  // Options need to be nested within each cart item
        size: "M",
        color: "Red"
      }
    }
  };

  try {
    await insertCart({
      variables: cartData
    });
    onAddToBag(product);  // UI feedback action
  } catch (error) {
    console.error("Error inserting cart:", error);
    // Optionally handle error in the UI
  }
};

  return (
    
    <Stack spacing={2} sx={{paddingTop: 5}} >
     <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', }}>
      <ArrowBackIos  sx={{ fontSize: 30, color: 'black', marginLeft: 2 }} onClick={() => window.history.back()} />
     <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 800, color: 'text.secondary'  }}>
      {product.name}
      </Typography>
      </Box>
      <Grid item xs={12}>
          <Item >
          <Box 
   onClick={() => handleOpen(selectedImageUrl || product.image)}
  sx={{ 
    display: 'flex',
    flex: 'Grow', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundImage: `url(${selectedImageUrl || product.image})`, // Use the selected image URL or the default image
    backgroundSize: 'contain', // Image will be fully visible but may leave white space
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: 0,
    height: '400px',
    width: '100%',
    padding: 0,
    transition: '0.3s',
    margin: 0,
    position: 'relative',
  }}
>
  
  <div style={{ position: 'absolute', top: 55, right: 10 }}>
    <IconButton onClick={() => handleOpen(product.price)} sx={{background: '#ffc107'}}>
      <Typography variant='h6' color= 'white' fontWeight={700}>${product.price}</Typography> 
    </IconButton>
  </div>
</Box>
{/* Add the Add to Cart button */}

<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 1, paddingBottom: 0, paddingLeft: 1 }}>
<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
    {/* Conditionally render each thumbnail only if the image URL exists */}
    {product.imageurl1 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl1)}>
        <img src={product.imageurl1} alt="thumbnail 1" width={50} />
      </Box>
    )}
    {product.imageurl2 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl2)}>
        <img src={product.imageurl2} alt="thumbnail 2" width={50} />
      </Box>
    )}
    {product.imageurl3 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl3)}>
        <img src={product.imageurl3} alt="thumbnail 3" width={50} />
      </Box>
    )}
    {product.imageurl4 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl4)}>
        <img src={product.imageurl4} alt="thumbnail 4" width={50} />
      </Box>
    )}
  </Box>
    <Box 
        sx={{ 
            alignSelf: 'flex-end',
            borderRadius: 20,
            backgroundColor: 'transparent',
            color: '#fff', 
        }} 
    >
        <IconButton sx={{ background: '#077336' , mb: 2, mr: 2}}>
            <AddShoppingCart onClick={() => handleAddToCart3(product)} edge="end" size="small" sx={{ 
                display: 'inline-flex', 
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.3s, color 0.3s',
                transform: isClicked ? 'scale(1.2)' : 'scale(1)',
                color: isClicked ? '#077336' : '#fff',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                zIndex: 1000,
            }}/>
        </IconButton>
    </Box>
</Box>

<Dialog 
      open={open} 
      onClose={handleClose} 
      
      sx={{ padding: 0 }}
    >
      <Box sx={{ position: 'relative', height: 'auto', width: '100%' }}>
        {/* Close button to close the dialog */}
        <IconButton 
          onClick={handleClose} 
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
        >
          <Close />
        </IconButton>
        
        {/* Magnifier for the image */}
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: 'Product Image',
              isFluidWidth: true,
              src: selectedImageUrl, // Small version of the image
            },
            largeImage: {
              src: selectedImageUrl, // High-resolution version of the image
              width: 1200, // You can adjust the size of the large image
              height: 800, // You can adjust the size of the large image
            },
            enlargedImageContainerDimensions: {
              width: '150%', 
              height: '150%',
            },
            style: { objectFit: 'cover' },
          }}
        />
         
          <Typography variant='caption'sx={{ position: 'absolute', bottom: 10, left: 10, zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }} >
            Click image to magnify
            </Typography>
        

      </Box>
     
    </Dialog>
		  </Item>
      
        </Grid>


        <Card  sx={{}}>
        <CardMedia sx={{
  
  paddingTop: '56.25%', // Consider removing this line if you're setting height explicitly below
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100%',
  borderRadius: '0px',
  boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
  transition: '0.3s',
  backgroundImage: `url(${product.image})`, // Replace with the URL of your image
  backgroundSize: 'cover', // Ensures the whole image fits within the box, might show empty space if aspect ratio differs
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  
  marginBottom: '10px',
  // Set a specific height for the CardMedia to ensure it's not zero or too small
  height: '300px', // Adjust this value based on your layout requirements
}} />

	<Typography 
   
  style={{ marginBottom: 0 }}  // Add this line
>
  
  
</Typography>

<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
<Grid item xs={6}>
          <Item >
			<Typography variant='h6' sx={{ textAlign: 'left'}} >
			Description
			</Typography>
			<Typography variant='body1' sx={{ textAlign: 'left'}} >
				{product.description}
			</Typography>
		  </Item>
        </Grid>
        <Grid item xs={6}>
        <Item sx={{fontWeight: 'bold', fontSize: '34px', }}><img src={minted} alt='Minted' width={20} /> 2k</Item>
        <DevelopmentCard />

        </Grid>
       
        <Grid item xs={12}>
          <Item sx={{fontWeight: 'normal', fontSize: '18px', textAlign: 'left'}}>
           <List sx={{ }}>
            <Typography variant='caption' sx={{ textAlign: 'left'}} >
           You agree to exchange your tokens  for real world products. Beans are refunded if the product is not delivered or is not as described.
                      </Typography>
            <Typography variant='caption' sx={{ textAlign: 'left'}} >
              You can only buy this product once. 
                         </Typography>
            <Typography variant='boby1' sx={{ textAlign: 'left'}} >
                More Terms and Conditions coming soon.
                            </Typography>
           </List>
           
            </Item>
            
          </Grid>
          <Grid container justifyContent="center" alignItems="center" paddingBottom={10} >
	<button className={classes.button} onClick={() => handleAddToCart3(product)} >
    Add to Beab Bag <img src={baggy} alt='bean' width={20} />
  </button>

  </Grid>
		</Grid>

	
    
</Card>

</Stack>
  );
};

export default FromBeans;
