import { createContext, useState, useEffect } from "react";
import * as Realm from "realm-web";
//import { APP_ID } from "../constants/realm/constants";
import { useNavigate } from 'react-router-dom';



const APP_ID = process.env.REACT_APP_APP_ID;
//require('dotenv').config()
// Connect to your MongoDB Realm app
const app = new Realm.App(APP_ID);


export const UserContext = createContext();

export const fetchDataFromMongoDB = async () => {
  const user = app.currentUser;

  const mongodb = user.mongoClient("mongodb-atlas");
  const collection = mongodb.db("darkjourney").collection("darkroads");

  const documents = await collection.find({});
  return documents;
};


export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [customData, setCustomData] = useState(null);
    
    
  const updateMongoDBData = async (contentData) => {
    try {

       // Define the payload
       const payload = {
        userId: app.currentUser.id,
        newData: {
            name: contentData.name,
            country: contentData.country,
            flavorprofile: contentData.flavorprofile,
            description: contentData.description,
            // ... and any other fields you want to update ...
        }
      };
      console.log("Sending payload:", payload);
      console.log("Sending payload to Realm function:", payload);


        // Call the Realm function
        const result = await user.callFunction("updateUserData", [payload]);



        console.log("Update result:", result);

        if (result.modifiedCount === 0) {
            console.warn("No documents were updated. Possible reasons: No match found OR data is identical to existing data.");
        } else {
            console.log(`Successfully updated document: ${result.modifiedCount}`);
        }
    } catch (error) {
        console.error("Failed to update data:", error);
    }
};

  
  const updateCustomData = (newData) => {
    setCustomData(prevData => ({ ...prevData, ...newData }));
  }
  
  
  const updateNickname = (Nickname) => {
    if (!app.currentUser) return false;
    try {
      // Update the user state with the new nickname
      setUser({ ...app.currentUser, profile: { ...app.currentUser.profile,  } });
      return true;
    } catch (error) {
      throw error;
    }
  };
  
 const emailPasswordLogin = async (email, password) => {
  const credentials = Realm.Credentials.emailPassword(email, password);
  const authedUser = await app.logIn(credentials);

  // After successful login, refresh the custom data
  await authedUser.refreshCustomData();
  

  // Now the custom data is up-to-date
  console.log(authedUser.customData); // log the custom data to the console

  
  return authedUser;
};

  
const createPaymentIntent = async (items) => {
  if (!app.currentUser) {
    throw new Error("User must be logged in to create a payment intent.");
  }

  try {
    const clientSecret = await app.currentUser.functions.stripe(items, app.currentUser.id);
    return clientSecret;
  } catch (error) {
    console.error("Failed to create payment intent:", error);
    throw error;
  }
};


  // Function to signup user into our Realm using their email & password
  const emailPasswordSignup = async (email, password) => {
    try {
      await app.emailPasswordAuth.registerUser({ email, password });
      
      // Log the user in to get their context
      const credentials = Realm.Credentials.emailPassword(email, password);
      const authedUser = await app.logIn(credentials);
  
      // Refresh custom data for this new user
      await app.currentUser.refreshCustomData();
      
      // Now newUser should have the context, including ID and custom data
      return authedUser;
    } catch (error) {
      throw error;
    }
  };
  


  
  
  const customSignup = async ( email, password) => {
    try {
      
      const result = await app.functions.callFunction("signup", [ email, password]);
      
      // Handle result, like setting user state or navigating to another page
      return result;
    } catch (error) {
      console.error("Error calling Realm function:", error);
      throw error;
    }
  };
  
  const sendResetPasswordEmail = async (email) => {
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail(email);
    } catch (error) {
      throw error;
    }
  };
  
  //Sign in With Google
 



  // Inside your component or hook
  const navigate = useNavigate();
  const signInWithGoogle = async (token, redirectUrl) => {
    try {
      const credentials = Realm.Credentials.google({ idToken: token.id_token });
      console.log("Google credentials created:", credentials);
  
      const newUser = await app.logIn(credentials);
      console.log("Logged in to Realm app as:", newUser);
  
      setUser(newUser);
      console.log("User context set:", newUser);
  
      await newUser.refreshCustomData();
      console.log("Custom data refreshed:", newUser.customData);
  
      // Determine the navigation path based on profile completion
      if (newUser.customData.profileBuilt) {
        console.log("Profile is built, navigating to /logic");
        window.location.href =  "/logic";
      } else {
        console.log("Profile not built, navigating to /sigma");
        window.location.href =  "/sigma";
      }
    } catch (error) {
      console.error("Failed to sign in with Google:", error);
      throw error;
    }
  };
  
  
  // Sign in with Facebook
  
  const signInWithFacebook = async (accessToken) => {
    try {
      // Create credentials for Facebook authentication
      const credentials = Realm.Credentials.facebook(accessToken);
  
      // Log in to MongoDB Realm using the Facebook credentials
      const newUser = await app.logIn(credentials);
      setUser(newUser); // Assuming setUser is a function to update your user context
      await newUser.refreshCustomData();
  
      // Navigation logic based on profile completion
      if (newUser.customData.profileBuilt) {
        
        navigate("/land");
      } else {
        navigate("/builder");
      }
    } catch (error) {
      console.error("Failed to sign in with Facebook:", error);
      throw error;
    }
  };
  



  

  

  // Function to fetch-user(if the user is already logged in) from local storage
  const fetchUser = async () => {
    
    if (!app.currentUser) return false;
    try {
      
      await app.currentUser.refreshCustomData();
      
      setUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      throw error;
    }
  };
  
  
  
  // Function to logout user from our Realm
  const logOutUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.logOut();
      // Setting the user to null once loggedOut.
      setUser(null);
      return true;
    } catch (error) {
      throw error
    }
  }

  return <UserContext.Provider value={{  user, createPaymentIntent,  setUser, fetchUser, emailPasswordLogin, emailPasswordSignup, logOutUser, sendResetPasswordEmail, fetchDataFromMongoDB, customData, setCustomData, updateCustomData, updateMongoDBData , customSignup, signInWithGoogle}}>
    {children}
  </UserContext.Provider>;
}