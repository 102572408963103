import React from 'react';
import {  Typography,  Paper, CardMedia, Card, Grid, Avatar, CardContent,  CardActionArea ,CardActions,
 Button, List, Icon, Box, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import congo from '../../../images/congobag.png';
import { styled } from '@mui/material/styles';
import { gql,  useQuery , useMutation} from '@apollo/client';
import { useParams } from 'react-router-dom'
import Progress from '../../mods/progress';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import { UserContext } from '../../../context/usercontext';
import { useContext } from 'react';
import { BagContext } from '../../../context/BagContext';
import { Link } from 'react-router-dom';
import AllKoffie from '../../../pages/worldkoffie';

//SEt this component to datk mode
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';






const GET_PROD = gql`
  query Prod ($_id: ObjectId!)  {
  product (query: {_id: $_id}) {
   name
    price
    description1
    description2
    description3
    description4
    imageurl
    farmer
    _id
  
  }
}
`; 

const UPDATE_CART_MUTATION = gql`
mutation UpdateOneCart($query: CartQueryInput, $set: CartUpdateInput!) {
  updateOneCart(query: $query, set: $set) {
    userId
    items {
      productId
      quantity
      options {
        size
        color
      }
    }
  }
}
`;

const UPSERT_CART_MUTATION = gql`
mutation UpsertOneCart($query: CartQueryInput, $data: CartInsertInput!) {
  upsertOneCart(query: $query, data: $data) {
    userID
    cart {
      productId
      quantity
      options {
        size
        color
      }
    }
  }
}

`;

// Insert one cart insertOneCart(data: CartInsertInput!): Cart

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    beanies
    name
    quantity
    options {
      size
      color
    }
  }
}
`;


export const GET_ROASTERIES = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
      Koffie_story
    }
  }
`;

export const GET_CART_ITEMS = gql`
  query GetCart($userID: ObjectId!) {
    cart(query: { userID: $userID }) {
      userID
      productId
      quantity
      options {
        size
        color
      }
    }
  }
`;

const Item = styled(Paper)(({ theme }) => ({
	
	padding: theme.spacing(1),
	textAlign: 'center',

	boxShadow: 'none'
  }));

const useStyles = makeStyles(() => ({
  root: {
	padding: '10px',
   
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
      backgroundImage: `url(${''})`, // Replace with the URL of your image
    backgroundSize: 'cover', // Cover the entire card with the image
    backgroundRepeat: 'no-repeat', // Do not repeat the image
    backgroundPosition: 'center', // Center the image
     
  },
  content: {
    padding: 10,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
	margin: '0px' /* or a smaller value than what's currently set */
	
    
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    // Add styles for the button here
    backgroundColor: '#32a852',
    color: 'white',
    borderRadius: 20,
    padding: 10,
    border: 'none',
    width: '40%',
    height: 40,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#077336',
    },
  },
}));


const DigiLabel = ({ onAddToBag }) => {
  
 
  const { user } = useContext(UserContext);
    const classes = useStyles();
    const { _id } = useParams()
    const { itemsInBag, setItemsInBag } = useContext(BagContext);
    const { loading, data, error } = useQuery(GET_PROD, {
        variables: { _id: _id }
      });
     
      const [upsertCart, ] = useMutation(UPSERT_CART_MUTATION);
      const [updateCart, ] = useMutation(UPDATE_CART_MUTATION);
      const [insertCart, ] = useMutation(INSERT_CART_MUTATION);
 
    if (loading) return <p><Progress/></p>;
if (error) return <p>Error: {error.message}</p>;

 const product = {
    id: data.product._id, 
    name: data.product.name,
    price: data.product.price,
    image: data.product.imageurl, // adjust this according to your schema
    description1: data.product.description1,
    description2: data.product.description2,
    description3: data.product.description3,
    description4: data.product.description4,
    farmer: data.product.farmer,
};


const handleAddToCart3 = async (product) => { 
  const cartData = {
    query: { userID: user.id },  // Assuming user.id is securely fetched
    data: {
      userID: user.id,  // Possibly redundant; ensure alignment with your backend logic
      productId: product.id,
      quantity: 1,
      name: product.name,
      beanies: '4000',
      price: product.price,
      imageurl: product.image,  // Adjust this according to your schema
      options: {  // Options need to be nested within each cart item
        size: "M",
        color: "Red"
      }
    }
  };

  try {
    await insertCart({
      variables: cartData
    });
    onAddToBag(product);  // UI feedback action
  } catch (error) {
    console.error("Error inserting cart:", error);
    // Optionally handle error in the UI
  }
};


const handleAddToCart2 = async (product, quantity = 1, options = { size: "M", color: "Red" }) => {
  const cartData = {
    query: { userID: user.id },  // Assuming user.id is securely fetched
    data: {
      userID: user.id,  // Possibly redundant; ensure alignment with your backend logic
      cart: [{
        productId: _id,
        quantity: quantity,
        options: {  // Options need to be nested within each cart item
          size: options.size,
          color: options.color
        }
      }]
    }
  };

  try {
    await upsertCart({
      variables: cartData
    });
    onAddToBag(product);  // UI feedback action
  } catch (error) {
    console.error("Error upserting cart:", error);
    // Optionally handle error in the UI
  }
};




const handleAddToCart = async (product) => {
  const cartUpdate = {
    query: { userId: user.id }, // Replace with actual user ID logic
    set: {
      items: [{
        productId: product.id,
        quantity: 1, // Or dynamic quantity
        options: {
          size: "M", // These should be dynamic or removed if not used
          color: "Red"
        }
      }]
    }
  };
  
  try {
    await updateCart({
      variables: cartUpdate
    });
    onAddToBag(product);
  } catch (error) {
    console.error("Error updating cart:", error);
  }
};
  
  return (
    
    <Stack spacing={2} sx={{paddingTop: 5, }} >
      
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', }}>
      
     <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 800, color: 'text.secondary'  }}>
      {product.name}
      </Typography>
      </Box>
        <Card  sx={{}}>
      

	<Typography 
   
  style={{ marginBottom: 0 }}  // Add this line
>
  
  
</Typography>

<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
<Grid item xs={12}>
          <Item >
			<CardMedia component="img" image={product.image} alt="Congo" sx={{ maxWidth: '100px' ,  objectFit: 'contain', alignItems: 'center', justifyContent: 'center', alignContent: 'center'  }} />
      
		  </Item>
      
        </Grid>

        
       
        <Grid item xs={4}>
        <Item sx={{fontWeight: 'bold', fontSize: '34px', }}>${product.price}</Item>
        </Grid>
        <Grid item xs={8}>
          <Item >
          <button className={classes.button} onClick={() => handleAddToCart3(product)} >
    Add to Bag
  </button>
      </Item>
       
       
        </Grid>
        <Grid item xs={12}>
          <Item >
          <Typography variant='h6' sx={{ textAlign: 'left'}} >
			Description
			</Typography>
			<Typography variant='body1' sx={{ textAlign: 'left'}} >
				{product.description1}
			</Typography>
      </Item>       
        </Grid>
        <Grid item xs={12}>
          <Item sx={{fontWeight: 'normal', fontSize: '18px', textAlign: 'left'}}>
           <List sx={{ }}>
            <Typography variant='h6' sx={{ textAlign: 'left'}} >
           Beanefits  
           </Typography>
          
           </List>
           <List>
           <Typography variant='body2' sx={{ textAlign: 'left'}} >
           {product.description2}
           </Typography>
           </List>
           <List>
           
           </List>
            </Item>
            
          </Grid>
          <Grid item xs={6}>
        <Item >
      <div style={{ borderRadius: 5, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: '4px', }}>
      {/* Place text and chip at the bottom */}
     
      <div style={{ display: 'flex', alignItems: 'center' }}>
       <Typography variant="body2" color="text.secondary" sx={{fontWeight: 700}}>
        Roast Master
      </Typography>
      
      </div>

      <UserImage userID={product.description4}  />
      </div>

    </Item>
    
     
        </Grid>
        <Grid item xs={6}>
        
    <Item >
      <div style={{borderRadius: 5, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: '4px', }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
       <Typography variant="caption" color="text.secondary" sx={{fontWeight: 700}}>
        Farmer
      </Typography>
      
      </div>
      <FarmerImage UserID={product.farmer}  />
      
    </div>
      </Item>
     
        </Grid>
          
		</Grid>

	<AllKoffie _id={_id} />
    
</Card>

</Stack>
  );

  
}

// Component to fetch and display </></> image
function UserImage({ userID }) {
  const { loading, error, data } = useQuery(GET_ROASTERIES, {
      variables: { _id: userID },
  });

  if (loading) return <Avatar src="https://via.placeholder.com/30" alt="user" style={{ width: 60, height: 60,  }} />;
  if (error) return <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderBlock: 1, borderRadius: 5}}>
        
  <img src="https://storage.googleapis.com/app_darkendimg/assets/farmericon.jpg" alt="user" style={{ width: 40, height: 40, borderRadius: 50 }} />
    <CardContent>
      <Typography variant="boy1" fontWeight={500}>
        Coming Soon
      </Typography>
      </CardContent>
      </Card>;
  const userimage = data.userprofs[0]?.imageurl || 'Unknown';
  const username = data.userprofs[0]?.Nickname || 'Unknown';
  const userstory = data.userprofs[0]?.Koffie_story || 'Unknown';



  return (
    <>
    
    <Box display="flex" >
    
      <Avatar src={userimage} alt={username} style={{ width: 60, height: 60, borderRadius: 50 }} />
     
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', }}>
      <Typography variant="caption"  sx={{ paddingLeft: 1 , fontWeight: 700, alignContent: 'left', textAlign: 'left' }}>
        {username}
      </Typography>
     
      <Typography variant="caption" color="text.secondary" sx={{ paddingLeft: 1 , }}>
        {userstory}
      </Typography>
      </Box>
    </Box>
    
    </>
    
  );
          
}

// Component to fetch and display </></> Farmer image
function FarmerImage({ userID }) {
  const { loading, error, data } = useQuery(GET_ROASTERIES, {
      variables: { _id: userID },
  });

  if (loading) return <img src="https://via.placeholder.com/30" alt="user" style={{ width: 60, height: 60, borderRadius: 50 }} />;
  if (error) return <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderBlock: 1,  borderRadius: 5}}>
        
  <img src="https://storage.googleapis.com/app_darkendimg/assets/farmericon.jpg" alt="user" style={{ width: 40, height: 40, borderRadius: 50 }} />
    <CardContent>
      <Typography variant="boy1" fontWeight={500}>
        Coming Soon
      </Typography>
      </CardContent>
      </Card>;

  const farmerimage = data.userprofs?.imageurl || 'Unknown';
  const farmername = data.userprofs?.Nickname || 'Unknown';
  const farmerstory = data.userprofs?.Koffie_story || 'Unknown';

  

  return (
    <>
    
    <Box display="flex" >
    
      <Avatar src={farmerimage} alt={farmername} style={{ width: 60, height: 60, borderRadius: 50 }} />
     
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', }}>
      <Typography variant="caption"  sx={{ paddingLeft: 1 , fontWeight: 700, alignContent: 'left', textAlign: 'left' }}>
        {farmername}
      </Typography>
     
      <Typography variant="caption" color="text.secondary" sx={{ paddingLeft: 1 , }}>
        {farmerstory}
      </Typography>
      </Box>
    </Box>
    
    </>
    
  );
          
}


export default DigiLabel;
