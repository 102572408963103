import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, Card, Typography, Grid, Button, Divider } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import KComments from '../mods/kstorycomments';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useStoryData } from './comquery';
import { makeStyles } from '@mui/styles';
import { LikeCount, CommentCount, LikeButton } from './stoybuttons';
import { GET_COMMENTS, GET_LIKES, CREATE_LIKE_MUTATION } from '../mods/kstorycomments'; 
import useInfiniteScroll from '../../queries/listarray';
import Product from '../../components/finance/Product';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Box } from '@mui/material';
import Update from '../profile/update';
import Userpost from '../profile/userpost';
import bean from '../../images/coffeebeans.png';
import mug from '../../images/mug.png';
import PostAndUploadComponent from '../posts/post';



const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 400,
    borderRadius: 0,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
      backgroundColor: 'transparent !important',
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  }
}));


const GET_STORYS = gql `

query{
    userprofs {
    Koffie_story
    Nickname
    _id
    usertype
    flavorprofile
    imageurl
}
}
`
;

const GET_ALL = gql `
 query GetAllComments {
  comments {
    _id
    content
    contentId
    contentType
    author
  }
}
`;

const GET_ALL_LIKES = gql `
  query GetAllLikes {
  likes {
    _id
    contentId
    contentType
    user
  }
}
`;





const UserProfList = ({ userprof }) => {
  const [items, setItems] = useState([]);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
    const { user } = useStoryData();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentUserprofId, setCurrentUserprofId] = useState(null);
  const { loading, error, data } = useQuery(GET_STORYS);
    const [theData, setTheData] = useState([]);
    const classes = useStyles();
    const { data: allCommentsData } = useQuery(GET_ALL);
  const { data: allLikesData } = useQuery(GET_ALL_LIKES);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (data && data.userprofs.length) {
      setItems(data.userprofs.slice(0, 8));
    }
  }, [data]);

  function fetchMoreListItems() {
    if (!data || items.length >= data.userprofs.length) return;
    setIsFetching(true);
    setTimeout(() => {
      const moreItems = data.userprofs.slice(items.length, items.length + 5);

      setItems((prevState) => ([...prevState, ...moreItems]));
      setIsFetching(false);
    }, 1500);
  }

  const handleCommentButtonClick = (userprofId) => {
    setCurrentUserprofId(userprofId);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentUserprofId(null);
  };
  // Queries for comments and likes
  const { data: commentsData } = useQuery(GET_COMMENTS, {
    variables: { contentId: userprof?._id },
    skip: !userprof
  });

  const { data: likesData } = useQuery(GET_LIKES, {
    variables: { contentId: userprof?._id },
    skip: !userprof
  });

  // Mutation for likes
  const [createLike] = useMutation(CREATE_LIKE_MUTATION);

  // Calculate counts
  const commentCount = commentsData?.comments.length;
  const likeCount = likesData?.likes.filter(like => like.contentId === userprof?._id).length;

  // Handle like button click
  const handleLike = () => {
    if (userprof) {
      createLike({
        variables: {
          data: {
            user: user.id,
            contentId: userprof._id,
            contentType: 'userprof'
          }
        }
      });
    }
  };


  if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :</p>;
    

    

// Retrieve the user profile by ID
//const currentUserprof = userprofs ? userprofs.find((userprof) => userprof._id === currentUserprofId) : null;

// ...



  return (
    <>
    <div>
      {/* Your existing page content */}
      
      <IconButton 
        style={{
          position: 'fixed',
          right: '10px',
          bottom: '5%',
          
          transform: 'translateY(-50%)',
          boxShadow: '0px 14px 80px rgba(50, 45, 58, 0.5)',  // This is an example of a light top shadow style
        }}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <img src={mug} alt='addit' width='40px'/>
      </IconButton>
      <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle} sx={{minHeight: '200hv'}}>
        <PostAndUploadComponent/>  {/* Your Update component */}
      </Drawer>
    </div>
    <Grid  spacing={1} justifyContent="center">
       {items.map((userprof, index) =>  {
        const comments = allCommentsData?.comments.filter(comment => comment.contentId === userprof._id);
        const likes = allLikesData?.likes.filter(like => like.contentId === userprof._id);

        return (
          <Box mb={2}> {/* mb = margin-bottom */}
          <Card className={classes.root}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
    <Avatar src={userprof.imageurl} />
    <Typography 
      component={Link}
      to={`/userprof/${userprof._id}`}
      sx={{ 
        fontSize: '1.2rem', 
        fontWeight: '200', 
        textDecoration: 'none', 
        color: 'green', 
        marginLeft: '10px'  // Add some space between Avatar and Nickname
      }}
    >
      {userprof.Nickname}
    </Typography>
  </div>
          
            <img src={userprof.imageurl} alt='the user' width='100%' />
            <Typography variant="body1">{userprof.Koffie_story}</Typography>
            
            <span>{likes ? likes.length : 0} Likes</span>
<span>{comments ? comments.length : 0} Comments</span>

          <Button onClick={handleLike}>Like</Button>
            <IconButton aria-label="comment" onClick={() => handleCommentButtonClick(userprof._id)}>
              <ChatBubbleOutlineIcon />
            </IconButton>
            </Card>
             {/* Show Product component every 8 posts */}
          {(index + 1) % 8 === 0 && (
            <div>
              <Product />
            </div>
          )}
        </Box>
      );
            
      })}
      
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleCloseDrawer}>
      {currentUserprofId && (
  <KComments userprof={data.userprofs.find((userprof) => userprof._id === currentUserprofId)} />
)}

        <Button onClick={handleCloseDrawer} variant="contained" color="primary">
          Close
        </Button>
        
      </Drawer>
      
      <>
      {isFetching && 'Fetching more list items...'}
        </>
    </Grid>
    </>
  );
};

export default UserProfList;
