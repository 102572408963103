import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, Divider, Typography } from '@mui/material';

const Tree = () => {
  const coffeeData = {
    'Coffee Origins': [],
    'History of Coffee': [],
    'Major Coffee Producing Countries': [],
    'Species and Varieties of Coffee': [],
    'Coffee Cultivation': ['Coffee Farming', 'Coffee Plantation Conditions', 'Harvesting and Processing'],
    'Coffee Beans': ['Anatomy of the Coffee Cherry and Bean', 'Types of Coffee Beans', 'Grading and Sorting', 'Roasting and Grinding'],
    'Roasting and Grinding': ['The Roasting Process', 'Types of Roasts', 'Grinding Coffee Beans', 'Packaging and Distribution'],
    'Brewing Techniques': ['Espresso', 'Drip Coffee', 'French Press', 'Cold Brew', 'Pour Over', 'Moka Pot, etc.'],
    'Coffee Equipment': ['Espresso Machines', 'Grinders', 'Filters', 'Coffee Makers, etc.'],
    'Coffee Tasting': ['Coffee Flavors and Aromas', 'Coffee Cupping', 'Pairing Coffee with Food'],
    'Coffee Culture': ['Cafe Culture Around the World', 'Specialty Coffee Movement', 'Coffee in Art and Literature'],
    'Coffee and Health': ['Health Benefits and Risks of Coffee', 'Caffeine and Its Effects', 'Decaf Coffee'],
    'Sustainability and Fair Trade': ['Environmental Impact of Coffee Production', 'Fair Trade and Direct Trade', 'Organic and Shade-Grown Coffee'],
    'Coffee Industry': ['Major Coffee Companies', 'Coffee Market Trends', 'The Future of Coffee'],
    'Zelinsky UN Meeting': ['Youre Fighting a War, This Is Your Coffee',' The Coffee Industry Is Worse Than Ever. We fix  ']
  }

  return (
    <List>
      {Object.entries(coffeeData).map(([key, values]) => (
        <div key={key}>
          <Typography variant="h6" gutterBottom>
            {key}
          </Typography>
          {values.map((value) => (
            <ListItem key={value}>
              <ListItemText primary={value} />
            </ListItem>
          ))}
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default Tree;
