import React from 'react'
import '../../context/steamer.css';

function Kupa() {
  return (
    <div id="container">
	<div class="steam" id="steam1"> </div>
	<div class="steam" id="steam2"> </div>
	<div class="steam" id="steam3"> </div>
	<div class="steam" id="steam4"> </div>

	<div id="cup">
		<div id="cup-body">
			<div id="cup-shade"></div>
		</div>
		<div id="cup-handle"></div>
	</div>

	<div id="saucer"></div>

	<div id="shadow"></div>
</div>
  )
}

export default Kupa