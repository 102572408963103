// BagContext.js
import React, { createContext, useContext, useState } from 'react';

export const BagContext = createContext();

export const BagProvider = ({ children }) => {
  const [itemsInBag, setItemsInBag] = useState([]);

  
  
   console.log("BagProvider itemsInBag:", itemsInBag); // Add this line
  return (
    <BagContext.Provider value={{ itemsInBag, setItemsInBag, itemCount: itemsInBag.length,}}>
      {children}
    </BagContext.Provider>
  );
};

export const useBag = () => useContext(BagContext);

