import React, { useContext } from 'react';
import useKupToken from './useKupToken';
import { Web3ModalContext } from '../constants/Web3ModalProvider';
import { useState } from 'react';
import { Card, Button, Input, Select, Typography, Box, TextField, Grid, Chip, CardActions } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';


function Initcon() {
  const { account, connect } = useContext(Web3ModalContext); // get the account and connect function from your context
  const { initiateRequest, setFarmer, setShipper, setRoaster, acceptContract, addToBasket } = useKupToken(account);

  const [farmerAddress, setFarmerAddress] = useState('');
  const [shipperAddress, setShipperAddress] = useState('');
  const [roasterAddress, setRoasterAddress] = useState('');

  const [selectedCherries, setSelectedCherries] = useState(0);
  
  const cherriesOptions = [
    { pounds: '5', cherries: 10000 },
    { pounds: '10', cherries: 20000 },
    // add more options as needed
  ];

  const handleAddToBasket = async (action) => {
    console.log('Account:', account); // log the account
    if (!account) {
        await connect();
        console.log('Connecting...');
    }
    try {
        if(action === 'addcherries') {
            await addToBasket(selectedCherries);
            console.log('Cherries added successfully!');
        }
    } catch (error) {
        console.error('Failed to add cherries:', error);
    }
    
};



  const handleClick = async (action) => {
    if (!account) {
      await connect();
    }
    if(action === 'initiate') initiateRequest();
    if(action === 'farmer') setFarmer(farmerAddress);
    if(action === 'shipper') setShipper(shipperAddress);
    if(action === 'roaster') setRoaster(roasterAddress);
    if(action === 'accept') acceptContract();
    if(action === 'addcherries') addToBasket(selectedCherries);
  };

  return (
    <>
     <Box  sx={{ flexGrow: 1, overflow: 'hidden', px: 0 , padding: 1 }}>
     <Card sx={{
    
    boxShadow: "0",
      padding: 1,
    width: "100% !important",
  }}>
    <Grid container spacing={2}>
        <Grid item xs={8}>
            <Typography variant="h6" sx={{ fontWeight: 700}}>Activate Contract</Typography>
        </Grid>
        <Grid item xs={4}>
            <Chip label="Initiate" onClick={() => handleClick('initiate')} />
        </Grid>

        {['farmer', 'shipper', 'roaster'].map((type) => (
            <React.Fragment key={type}>
                <Grid item xs={8}>
                    <TextField variant="outlined" type="text" placeholder='Enter XDC Wallet Addr' value={type === 'farmer' ? farmerAddress : (type === 'shipper' ? shipperAddress : roasterAddress)} onChange={e => type === 'farmer' ? setFarmerAddress(e.target.value) : (type === 'shipper' ? setShipperAddress(e.target.value) : setRoasterAddress(e.target.value))} />
                </Grid>
                <Grid item xs={4}>
                   <Chip label={type} onClick={() => handleClick(type)} />
                </Grid>
            </React.Fragment>
        ))}

        <Grid item xs={12}>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }}>
        <Typography variant="body2" sx={{ }}>Admin accepts farmer contract, minting the beans </Typography>
        <Box >
          
           <Chip label="Accept Contract" onClick={() => handleClick('accept')}sx={{ display: 'flex', justifyContent: 'flex-end' }} />
           </Box>
        </CardActions>
        </Grid>
    </Grid>
</Card>

      <div>
      <Card 
      sx={{
       
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1) !important",
        padding: 2,
        width: "100% !important",
        
        
      }}
      >
      <Select
      sx={{ width: '100%', height: '100%',  borderRadius: '10px', border: '1px solid #ccc', color: '#ccc' }}
      placeholder='Select weight'
  value={selectedCherries}
  onChange={(e) => {
    setSelectedCherries(e.target.value);
    console.log(e.target.value);
  }}
  >
  
  {cherriesOptions.map((option, index) => (
    <MenuItem key={index} value={option.cherries}>
      {option.pounds} lbs
    </MenuItem>
  ))}
</Select>
<CardActions sx={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }}>
        <Typography variant="body2" sx={{ }}>Buy Koffie via minted tokens to start journey </Typography>
        <Box >
          
        <Chip label="Add Cherries" onClick={() => handleAddToBasket('addcherries')} />
           </Box>
        </CardActions>

    </Card>
        </div>
        </Box>
    </>
  )
}

export default Initcon;
