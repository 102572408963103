import React, { useState } from 'react';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import coffeebeans from '../../../images/coffeebeans.png';
import Circle from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import Slider from '@mui/material/Slider';
import CoffeeMakerOutlined from '@mui/icons-material/CoffeeMakerOutlined';
import LocalCafeOutlined from '@mui/icons-material/LocalCafeOutlined';
import LocalDiningOutlined from '@mui/icons-material/LocalDiningOutlined';
import LocalDrinkOutlined from '@mui/icons-material/LocalDrinkOutlined';
import LocalBarOutlined from '@mui/icons-material/LocalBarOutlined';

// ... other icon imports

// ... icon mapping (unchanged)
const icons = {
    consumerIcon: <Circle/>,
    retailerIcon: <CoffeeMakerOutlined />,
    roasterIcon: <LocalCafeOutlined />,
    exporterIcon: <LocalDiningOutlined />,
    processorIcon: <LocalDrinkOutlined />,
    farmerIcon: <LocalBarOutlined />,

    // ... other icon mappings
  };

const data = {
    "value_chain_costs": {
      "consumer": 16.0,
      "retailer": 10.0,
      "roaster": 5.0,
      "exporter": 3.3333333333333335,
      "processor": 1.3333333333333335,
      "farmer": 1.6,
      
      
    },
    "coffee_yield": {
      "Espresso_Double_Shot": {
        "Espresso_Cups": 25,
        "Coffee_Mugs": 5,
        "Travel_Mugs": 3,
        "Liters_Brewed": 1.51,
        "Ounces_Brewed": 51,
         
      },
      "Drip_Pour_Over": {
        "Espresso_Cups": 153,
        "Coffee_Mugs": 30,
        "Travel_Mugs": 19,
        "Liters_Brewed": 9.08,
        "Ounces_Brewed": 306
      },
      "French_Press": {
        "Espresso_Cups": 89,
        "Coffee_Mugs": 17,
        "Travel_Mugs": 11,
        "Liters_Brewed": 5.3,
        "Ounces_Brewed": 179
      },
      "Moka_Pot": {
        "Espresso_Cups": 122,
        "Coffee_Mugs": 24,
        "Travel_Mugs": 15,
        "Liters_Brewed": 7.26,
        "Ounces_Brewed": 245
      },
      "Cold_Brew_Concentrate": {
        "Espresso_Cups": 80,
        "Coffee_Mugs": 16,
        "Travel_Mugs": 10,
        "Liters_Brewed": 4.73,
        "Ounces_Brewed": 159
      }
    },
    "c_price_per_lb": 1.63
  };
  
const KoffieVisualizer = () => {
  const [cPrice, setCPrice] = useState(1.63); // Initial c-price

  // Calculate value chain costs dynamically
  const calculateValueChainCosts = (cPrice) => {
    // Adjust the following ratios as per your cost distribution model
    return {
      consumer: cPrice * 4, 
      retailer: cPrice * 2.5,
      roaster: cPrice * 1.5,
      exporter: cPrice,
      processor: cPrice * 0.5,
      farmer: cPrice, 
    };
  };

  const valueChainCosts = calculateValueChainCosts(cPrice);

  return (
    <div>
      {/* ... Value Chain Costs section */}

      <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
        C Price per Pound
      </Typography>
      <Paper elevation={2} style={{ padding: '1rem', textAlign: 'center' }}>
        <Typography variant="body1">${cPrice.toFixed(2)} / lb</Typography>
        <Box sx={{ width: 200, margin: '1rem auto' }}>
          <Slider
            value={cPrice}
            onChange={(event, newValue) => setCPrice(newValue)}
            step={0.01} // Penny increments
            min={0.5}   // Set a minimum
            max={3}     // Set a maximum
          />
        </Box>
      </Paper>

      {/* ... Coffee Yield section  */}
      <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
        Coffee Yield per Method
      </Typography>
      {Object.entries(data.coffee_yield).map(([method, details]) => (
        <div key={method}>
          <Typography variant="h5" gutterBottom>
            {method.split('_').join(' ')}
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(details).map(([key, value]) => (
              <Grid item xs={2} sm={4} md={2} key={key}>
                <Paper elevation={2} style={{ padding: '0rem', textAlign: 'center' }}>
                    <IconButton>
                    <img src={coffeebeans} alt='bean' width={20}/>
                    </IconButton>

                  <Typography variant="caption">{key.split('_').join(' ')}</Typography>
                  <Typography variant="body1"sx={{fontWeight: 700}}>{value}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default KoffieVisualizer;
