import React from 'react';
//import '../../../context/Ticker.css';
import { Button, Card, Grid, Paper } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import congo from '../../../images/congobag.png';

 const GET_PRODUCTS = gql`
    query Products {
  products {
    Title
    _id
    description1
    description2  
    imageurl
    name
    price
  }
}
`;
    

const Ticker = () => {
    const { loading, error, data } = useQuery(GET_PRODUCTS);
    if (loading) return <div>Loading...</div>
    if (error) return <div> {null}</div>
    console.log(data);
    
    

  return (
    <div>
  <Grid container spacing={2} sx={{backgroundColor: '#b6e0ca', flexgrow: 'grow'}}>
    {data && data.products.map((product, index) => (
      <Grid item xs={6} key={index} >
        <Paper elevation={3} >
          <Card sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#e4fad9', borderRadius: 0 }}>
            {/* You can add an image here */}
            <img src={congo} alt="congo koffie" style={{ width: '100%', height: 'auto' }} />
            <Button sx={{ backgroundColor: '#000', borderRadius: 0, padding: 0, margin: 0, fontSize: '0.5rem', fontWeight: 'bold', color: 'black' }}>
              Add
            </Button>
            <Link className="ticker-link" key={product._id} to={`/product/${product._id}`}>
              <span className="ticker-symbol">{product.name}</span>
            </Link>
            <span className="ticker-price">${product.price}</span>
            
          </Card>
        </Paper>
      </Grid>
    ))}
  </Grid>
</div>
  
  );
};

export default Ticker;
