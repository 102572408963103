import React, { useState, useEffect } from "react";
import useInfiniteScroll from "../queries/listarray";
import {Avatar, Card, CardContent, Typography }from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import KComments from "../components/mods/kstorycomments";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Product from "../components/finance/Product";

   
  const useStyles = makeStyles(() => ({
    root: {
      maxWidth: 400,
      borderRadius: 20,
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
        transition: '0.3s',
        backgroundColor: 'transparent !important',
       
    },
    content: {
      padding: 5,
      spacing: 8,
    },
    brandCardHeader: {
      // Add styles for BrandCardHeader here
    },
    textInfoContent: {
      // Add styles for TextInfoContent here
    }
  }));

const GET_KRONS = gql `

query{
    userprofs {
    Koffie_story
    Nickname
    _id
    usertype
    flavorprofile
}
}
`
;

const ScrollIt = (userprof) => {
  const { _id } = useParams()
    const { data, error, loading } = useQuery(GET_KRONS);
    const [items, setItems] = useState([]);
    const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [expandedCards, setExpandedCards] = React.useState({});
    

   
  
    const handleClickOpen = () => {
      setOpen(true);
     
    };
  
    const handleClose = (id) => {
      setOpen(false);
      setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
      
    };
    
   

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open, expandedCards]);
  
    useEffect(() => {
      if (data && data.userprofs.length) {
        setItems(data.userprofs.slice(0, 8));
      }
    }, [data]);
  
    function fetchMoreListItems() {
      if (!data || items.length >= data.userprofs.length) return;
      setIsFetching(true);
      setTimeout(() => {
        const moreItems = data.userprofs.slice(items.length, items.length + 10);

        setItems((prevState) => ([...prevState, ...moreItems]));
        setIsFetching(false);
      }, 1500);
    }
  
    return (
        <Stack  spacing={2}  >
        <h1>Red Pill Moments</h1>
        {items.map((userprofs, index) => (
           <React.Fragment key={index}>
         <Card key={userprofs._id} className={classes.root}>
      {/* Card content */}
      <CardContent className={classes.content}>
        <Typography className={classes.brandCardHeader} variant="h6" component="h2">
          {userprofs.Nickname}
        </Typography>
        <Typography variant="body2" color="text.secondary" component="p" className={classes.textInfoContent}>
          Koffie Story: {userprofs.Koffie_story}
        </Typography>
      </CardContent>
      <Typography className={classes.overlay}
              component={Link}
              to={`/userprof/${userprofs._id}`}
               sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
               >
              more
            </Typography>
      {/* Comment button and dialog */}
      <div>
        <Button onClick={handleClickOpen}>
          <ChatBubbleOutlineIcon />
          Comment
        </Button>
        <Dialog
        
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
         
          aria-expanded={expandedCards[userprof._id] || false}
        >
          <DialogTitle id="scroll-dialog-title">Comments</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="scroll-dialog-description">
              <KComments userprof={userprof} /> {/* Pass user profile to comments component */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Card>
    {(index + 1) % 10 === 0 && <div><Product/></div>}
    </React.Fragment>
         
        ))}
        {isFetching && 'Fetching more list items...'}
        </Stack>
    );
  };
  

export default ScrollIt;
