import { useState } from 'react';
import axios from 'axios';

const useFileUpload = () => {
  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState('');
  const [uploadURL, setUploadURL] = useState('');
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Update valid file types
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const validVideoTypes = ['video/mp4', 'video/quicktime']; // Added support for .MOV files
      
      if (validImageTypes.includes(selectedFile.type) || validVideoTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
        const objectURL = URL.createObjectURL(selectedFile);
        setPreviewURL(objectURL);
        setError('');
      } else {
        setError('Only images and videos (MP4, MOV) are allowed.');
        setFile(null);
        setPreviewURL('');
      }
    }
  };
  

  const handleRemoveFile = () => {
    setFile(null);
    setPreviewURL('');
    setUploadURL('');
    setError('');
  };

  const uploadFile = async (file, userId) => {
    if (!file) {
      console.error('No file selected for upload');
      console.log("User ID:", userId);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);

    try {
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded:', response.data);
      if (response.data && response.data.url) {
        setUploadURL(response.data.url);
        return response.data.url;
      } else {
        console.error('No URL returned from server');
      }
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  return { file, previewURL, uploadURL, handleFileChange, uploadFile, handleRemoveFile, error };
};

export default useFileUpload;
