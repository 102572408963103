import React, { useState } from 'react';
import Web3 from 'web3';
import tokenize from '../images/tokenize.png';
import { Button } from '@mui/material';

function ConnectButton() {
  const [account, setAccount] = useState(null);

  const connect = async () => {
    
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  };

  const disconnect = () => {
    setAccount(null);
  };

  return (
    <div>
      {account ? (
        <div>
          <p>Connected: {account}</p>
          <Button onClick={disconnect} sx={{ backgroundColor: 'green', color: '#000' }}>
            Disconnect
          </Button>
        </div>
      ) : (
        <Button onClick={connect} sx={{ backgroundColor: 'green', color: '#000' }}>
          Connect
        </Button>
        
      )}
    </div>
  );
}

export default ConnectButton;
