import { useState } from 'react';
import axios from 'axios';

const useFileUpload2 = () => {
  const [files, setFiles] = useState([null, null, null, null]); // Up to 4 files
  const [previewURLs, setPreviewURLs] = useState(['', '', '', '']); // Previews for 4 files
  const [uploadURLs, setUploadURLs] = useState(['', '', '', '']); // Uploaded URLs for 4 files
  const [error, setError] = useState('');

  const handleFileChange1 = (e, index) => {
    const input = e.target;
    if (input && input.files && input.files[0]) {
      const selectedFile = input.files[0];
  
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (validImageTypes.includes(selectedFile.type)) {
        // Create new arrays for files and preview URLs
        const newFiles = [...files];
        const newPreviewURLs = [...previewURLs];
  
        // Update the file and preview for the specific index
        newFiles[index] = selectedFile;
        newPreviewURLs[index] = URL.createObjectURL(selectedFile);
  
        // Update state with the new arrays
        setFiles(newFiles);
        setPreviewURLs(newPreviewURLs);  // This updates the preview
  
        setError('');  // Clear any error messages
      } else {
        setError('Only image files (JPEG, PNG, GIF) are allowed.');
      }
    }
  };
  
  
  
  

  const handleRemoveFile1 = (index) => {
    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);
  
    const newPreviewURLs = [...previewURLs];
    newPreviewURLs[index] = '';
    setPreviewURLs(newPreviewURLs);
  
    const newUploadURLs = [...uploadURLs];
    newUploadURLs[index] = '';
    setUploadURLs(newUploadURLs);
    setError('');
  };
  
  

  const uploadFiles1 = async (userId) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      if (file) {
        formData.append(`file${index + 1}`, file);
      }
    });
    formData.append('userId', userId);
  
    try {
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Files uploaded:', response.data);
      if (response.data && response.data.urls) {
        setUploadURLs(response.data.urls); // Assume server returns an array of URLs
      } else {
        console.error('No URLs returned from server');
      }
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };
  

  return { files, previewURLs, uploadURLs, handleFileChange1, uploadFiles1, handleRemoveFile1, error };
};

export default useFileUpload2;
