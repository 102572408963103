import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Card, CardContent, Typography, Paper, Grid, Avatar, TextField, List } from '@mui/material';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import TelegramIcon from '@mui/icons-material/Telegram';
import { LikeButton, LikeCount, CommentCount,  } from '../tests/stoybuttons';



export const GET_STORYS = gql`
  query{
    userprofs {
    Koffie_story
    Nickname
    _id
    usertype
    flavorprofile
    imageurl
}
}
`;

export const GET_COMMENTS = gql`
  query GetComments($contentId: ObjectId!) {
    comments(query: { contentId: $contentId }) {
      _id
      content
      contentId
      contentType
      author
    }
  }
`;

export const GET_LIKES = gql`
  query GetLikes($contentId: ObjectId!) {
    likes(query: { contentId: $contentId }) {
      _id
      contentId
      contentType
      user
    }
  }
`;

export const CREATE_COMMENT_MUTATION = gql`
  mutation InsertComment($data: CommentInsertInput!) {
    insertOneComment(data: $data) {
      _id
    }
  }
`;

export const CREATE_LIKE_MUTATION = gql`
  mutation InsertLike($data: LikeInsertInput!) {
    insertOneLike(data: $data) {
      _id
    }
  }
`;

function KComments({ userprof }) {
  
    const { user } = useContext(UserContext);
    
  const [comment, setComment] = useState('');

  const [createComment] = useMutation(CREATE_COMMENT_MUTATION);
  const [createLike] = useMutation(CREATE_LIKE_MUTATION);
  const { data: commentsData, loading: commentsLoading } = useQuery(GET_COMMENTS, {
    variables: { contentId: userprof._id }
  });
  
  const { data: likesData, loading: likesLoading } = useQuery(GET_LIKES, {
    variables: { contentId: userprof._id }
  });

  if (!userprof) return <div>Loading...</div>;
    const commentCount = commentsData?.comments.length;
    const likeCount = likesData?.likes.filter(like => like.contentId === userprof._id).length;


    const handleComment = () => {
        createComment({
          variables: {
            data: {
              content: comment,
              author: user.id, // the logged in user id
              contentId: userprof._id,
              contentType: 'userprof'
            }
          },
          update: (cache, { data: { insertOneComment } }) => {
            // Read the data from the cache for this query
            const data = cache.readQuery({
              query: GET_COMMENTS,
              variables: { contentId: userprof._id }
            });
      
            // Write the data back to the cache with the new comment at the end of the list
            cache.writeQuery({
              query: GET_COMMENTS,
              data: {
                ...data,
                comments: [...data.comments, insertOneComment]
              },
              variables: { contentId: userprof._id }
            });
          }
        });
        setComment('');
      };
      

  const handleLike = () => {
    createLike({
      variables: {
        data: {
          user: user.id, // the logged in user id
          contentId: userprof._id,
          contentType: 'userprof'
        }
      }
    });
  };

  return (

    <div style={{position: 'relative', }}> 
    <div>
    <Paper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
        }}
      >
      {commentsData?.comments.map((comment) => (
        <Grid key={comment._id} container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar>W</Avatar>
          </Grid>
          <Grid item xs>
            <Typography>
            <p style={{ wordWrap: "break-word" }}>{comment.content}</p>
            </Typography>
          </Grid>
        </Grid>
         ))}
      </Paper>
    </div>
    <Typography variant="body2" color="text.secondary">
  {likeCount} Likes, {commentCount} Comments
</Typography>


  
    <Card key={userprof._id} sx={{ maxWidth: 400 }}>
      {/* ...existing content... */}
      <LikeCount likeCount={likeCount} />
    <CommentCount commentCount={commentCount} />
    <LikeButton handleLike={handleLike} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          <Link key={userprof._id} to={`/userprof/${userprof._id}`}> </Link>
        </Typography>
      </CardContent>
    </Card>
  
    <div style={{ position: 'sticky', bottom: '0px', background: '#fff' }}>
    <TextareaAutosize style={{ width: '80%', background: '#51b27182', color: '#000', padding: '10px', borderRadius: '5px', border: 'none', outline: 'none', margin: '10px' }}
    aria-label="empty textarea" 
  value={comment}
  onChange={e => setComment(e.target.value)}
  placeholder={`Comment on ${userprof.Koffie_story}`}
/>
<TelegramIcon width='50px' onClick={handleComment}/>
    
    </div>
    
      <button onClick={handleLike}>Like</button> 
  </div>
  
  );
}

export default KComments;
