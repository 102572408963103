import React, { useEffect, useContext, useState } from 'react';
import { UserContext,  } from '../../context/usercontext';
import { Box, Typography,  Drawer, MobileStepper, Dialog, IconButton} from '@mui/material';
import kcoin from '../../images/kcoin.png';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SwipeableViews from 'react-swipeable-views';
import { Close, CloseSharp, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Akup from '../mods/cssimages/akup';
import { Container } from '@mui/material';


const imageUrl = "https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp";

const backgroundImages = [
  'https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp',
  'https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp',
  'https://storage.googleapis.com/app_darkendimg/assets/getbean.jpg',
];

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 2,
    paddingLeft: 0,
    paddingRight: 2,
    borderRadius: 5,
    boxShadow: 10,
    position: 'relative', // Add position relative for absolute positioning of ribbon
   
    
  },
  logo: {
    width: 100,
    borderRadius: 0,
   
  },
  ribbon: {
    position: 'absolute',
    bottom: 0, // Adjust the position of the ribbon vertically
    left: 60, // Adjust the position of the ribbon horizontally
    backgroundColor: '#d1f0d2', // Adjust the background color of the ribbon
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 2, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
    
   
  },
  ribbonText: {
    color: 'black', // Adjust the text color of the ribbon
    fontWeight: 600, // Adjust the font weight of the ribbon text
    fontSize: 16, // Adjust the font size of the ribbon text
  },
  
  contentBox: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  signInButtonContainer: {
    width: '100%', // Ensures that the container takes full width
    display: 'flex', // Using flex to center the child items
    justifyContent: 'center', // Centers items horizontally
    alignItems: 'center', // Centers items vertically
    padding: theme.spacing(4), // Adds padding around the container
    backgroundColor: 'transparent',
    
  
  },
  stepperContainer: {
    width: '100%', // Ensures the container takes full width
    display: 'flex',
    justifyContent: 'center', // Centers the stepper horizontally
    padding: theme.spacing(1),
    backgroundColor: 'transparent', // Optional: makes the background of the stepper transparent
  },
}));



// This component will be used to render the Google Sign-In button
// It will use the Google Identity Services library to render the button

//This will use makeStyle to style the button



// This component will be used to render the Google Sign-In button


const GoogleSignIn = () => {
  const { signInWithGoogle } = useContext(UserContext);
  const classes = useStyles();
  const [open, setOpen] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const OpenModal = () => {
    setOpen(true);
  };

  const CloseModal = () => {
    setOpen(false);
  };
  
  
  const steps = [
    <div style={{ 
   
    
    }}>
    <Box  sx={{
           flexGrow: 1,
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           paddingRight: 2,
           paddingLeft: 2,
           paddingTop: 0,
           backgroundColor: 'transparent',
           
           
                 width: '100%',
          
          
        }}
      >
    
      <Typography variant="h5" gutterBottom sx={{
    color: 'white', 
    fontWeight: 700, 
    textShadow: '2px 2px 8px rgba(0,0,0,0.6)'  // Adjust shadow size and blur for better visibility
}}>
    Welcome to Koffieland™
</Typography>
<Typography variant="body1" sx={{
    color: 'white', 
    textShadow: '1px 1px 4px rgba(0,0,0,0.5)'  // Slightly lighter shadow for smaller text
}}>
    Bringing the world together over koffie.
</Typography>
<Typography variant="h3" sx={{
    color: 'white', 
    fontWeight: 700, 
    textShadow: '2px 2px 8px rgba(0,0,0,0.6)',  // Consistent with the header for uniformity
    mb: 20
}}>
    Get Caffeinated
</Typography>

    </Box>
  </div>,
   


    <div><Box sx={{ flexGrow: 1, }}>
    <Box fullScreen  sx={{ mb: 0,
  
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 2,
    paddingLeft: 2,
    paddingTop: 0,
    backgroundColor: 'transparent',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundImage: `url(${backgroundImages[2]})`,
   backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    
          width: '100%',
    
 
  }}>
   
    
    <Box className={classes.boxContainer}>
      <img src={kcoin} alt='log--koin' className={classes.logo} />
      <Box className={classes.ribbon}>
        <div  className={classes.ribbonText}>
          BETA
        </div>
        
      </Box>
     

     
    </Box >

    <Typography variant="body2" component="h1" gutterBottom sx={{mt: 0}}>
        Welcome to Koffieland™
      </Typography>
    <Typography variant="subtitle1" sx={{ mb: 1, textAlign: 'center' }}>
        Bringing the world together over koffie
      </Typography>
      <Typography variant="h4" fontWeight={400} gutterBottom sx={{ mt: 0, textAlign: 'center' }}>
        Build a better Koffieland™
      </Typography>
     
    </Box>
    
  </Box></div>
  ];
  
  useEffect(() => {
    // This function will now call the signInWithGoogle from your UserContext
   // In your GoogleSignIn component

const handleCredentialResponse = (response) => {
  console.log('Encoded JWT ID token:', response.credential);
  // Wrap the string token into an object with 'idToken' property
  signInWithGoogle({ id_token: response.credential });
};


    // Load the Google Identity Services library
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: "213836331022-r0v1mth6ha7603bggo3b4lff361tpr3t.apps.googleusercontent.com", // Replace with your actual client ID
        callback: handleCredentialResponse, // This callback is used for the credential response
        auto_select: true,
        theme: "outline",
        locale: "en-US",
        fetch_basic_profile: true,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signin-button"), // Ensure this ID matches the element below
        { theme: "outline", 
        size: "large",
        text: "continue_with",
        shape: "pill",
        width: "auto",
        locale: "en-US",
        prompt_parent_id: "signin-button",
        borderRadius: "20px",

        }
      );

     
    };
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [signInWithGoogle]); // Add signInWithGoogle to the dependency array

  return (

    <Box
    fullScreen
    anchor="bottom"
    open={true} // Control this with state to show/hide
    
  
    
sx={{ backgroundColor: 'transparent',  // Ensure the dialog is on top of everything else

}} 
    
  >
  
    <Box sx={{
  backgroundColor: 'transparent !important', // Using !important to ensure priority
}}>
  <div id="signin-button" ></div>
  <Typography variant="body2" color='text.secondary' sx={{
    fontWeight: 400,
    textAlign: 'center',
    backgroundColor: 'transparent !important' // Applying transparency here as well
  }}>
    Sign in with your Google account
  </Typography>
  <Typography
  variant="body2"
  color="text.secondary"
  sx={{
    fontWeight: 400,
    textAlign: 'center',
    backgroundColor: 'transparent !important',
    mt: 2,
  }}
>
  By signing in, you agree to our{' '}
  <Typography
  variant='body2'
    component="span"
    onClick={OpenModal}
    sx={{
      color: 'primary.main',
      textDecoration: 'none',
      cursor: 'pointer',
    }}
  >
    Terms and Conditions
  </Typography>
</Typography>

 <Dialog fullScreen open={open} onClose={CloseModal} sx={{ backgroundColor: 'transparent !important' }}>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
      <IconButton onClick={CloseModal}>
        <CloseSharp />
      </IconButton>
    </Box>
 <Container>
      <Box sx={{ marginTop: 1 }}>
        <Typography variant="h3" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Effective date: June 20, 2024
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Koffieland. These terms of service outline the rules and regulations for the use of our app and website. By accessing or using the service, you agree to be bound by these terms. If you disagree with any part of the terms, you may not access the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          The service and its original content, features, and functionality are and will remain the exclusive property of Koffieland and its licensors. The service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Koffieland.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the terms, which may result in immediate termination of your account on our service. You are responsible for safeguarding the password that you use to access the service and for any activities or actions under your password, whether your password is with our service or a third-party service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the terms. If you wish to terminate your account, you may simply discontinue using the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall Koffieland, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from the service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These terms shall be governed and construed in accordance with the laws of the State of Georgia, United States, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these terms will not be considered a waiver of those rights. If any provision of these terms is held to be invalid or unenforceable by a court, the remaining provisions of these terms will remain in effect. These terms constitute the entire agreement between us regarding our service and supersede and replace any prior agreements we might have had between us regarding the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Changes to These Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, please contact us at support@goodkoffie.com or by mail to: Good Koffie, 123 Coffee St, Atlanta, GA, 30301.
        </Typography>
      </Box>
    </Container>
  </Dialog>
</Box>

   
   
    
  </Box>
    
  );
};

export default GoogleSignIn;