import React, { useState, useEffect } from 'react';
import { TextField, Button, List, ListItem, ListItemText,
     ListItemSecondaryAction, IconButton, Typography, Container, Grid } from '@mui/material';

import CoffeeImage from '../../images/coffeebeans.png'; // replace with your image path

import { DeleteOutline } from '@mui/icons-material';
import DarkroadSearch from '../tests/darkfinder';

const Thething = () => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');
  const [timeLeft, setTimeLeft] = useState(3600);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const addTodo = () => {
    setTodos([...todos, newTodo]);
    setNewTodo('');
  };

  const removeTodo = (index) => {
    const newTodos = [...todos];
    newTodos.splice(index, 1);
    setTodos(newTodos);
  };

  return (
    <Container>
        <Grid item xs={12} md={6}>
        <Typography variant="h4" gutterBottom>The Thing</Typography>
          <Typography variant="h4" gutterBottom> {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}</Typography>
          <img src={CoffeeImage} alt="Coffee" style={{width:'50px'}} />
        </Grid>
      <Typography variant="h6" gutterBottom>Accomplish Your Dreams</Typography>
      <Typography variant="heading1" gutterBottom>With just a cup of black coffee and an hour of focus, you can achieve anything.Because " Attention is all you need"</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
       
        
          <TextField value={newTodo} onChange={e => setNewTodo(e.target.value)} label="New Todo" fullWidth />
          <Button variant="contained" color="primary" onClick={addTodo}>Add Todo</Button>
          <List>
            {todos.map((todo, index) => (
              <ListItem key={index}>
                <ListItemText primary={todo} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => removeTodo(index)}>
                    <DeleteOutline />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <DarkroadSearch/>
        </Grid>
      
      </Grid>
    </Container>
  );
};

export default Thething;
