import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Divider,
} from '@mui/material';
import {
  PeopleAltOutlined,
  TrendingUpOutlined,
  BarChartOutlined,
  AttachMoneyOutlined,
  MenuBookOutlined,
  TrendingUpRounded,
  PersonOutlined,
  ShowChartOutlined,
} from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
      },
      cardItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center items horizontally
        justifyContent: 'center', // Center items vertically
        textAlign: 'center', // Center text
        // Add other styles as needed
      },
      cardIcon: {
        marginBottom: theme.spacing(1), // Adds spacing below the icon
      },
  }));
  
  const MarketUI = () => {
    const classes = useStyles();
  

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Koffie Trade Economics
            </Typography>
          </Toolbar>
       
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={4}>
                <Grid item xs={2} className={classes.cardItem}>
  <PeopleAltOutlined className={classes.cardIcon} />
  <Typography>Stocks</Typography>
</Grid>

                  <Grid item xs={2} className={classes.cardItem}>
                    <TrendingUpOutlined className={classes.cardIcon} />
                    <Typography>ETFs</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cardItem}>
                    <BarChartOutlined className={classes.cardIcon} />
                    <Typography>Options</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cardItem}>
                    <AttachMoneyOutlined className={classes.cardIcon} />
                    <Typography>Crypto</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cardItem}>
                    <MenuBookOutlined className={classes.cardIcon} />
                    <Typography>Learn</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={2} className={classes.cardItem}>
                    <TrendingUpRounded className={classes.cardIcon} />
                    <Typography>IPO</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cardItem}>
                    <PersonOutlined className={classes.cardIcon} />
                    <Typography>Robo</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cardItem}>
                    <ShowChartOutlined className={classes.cardIcon} />
                    <Typography>Calendar</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cardItem}>
                    <AttachMoneyOutlined className={classes.cardIcon} />
                    <Typography>paperTrade</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.cardItem}>
                    <BarChartOutlined className={classes.cardIcon} />
                    <Typography>Screener</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Divider style={{ margin: '16px 0' }} />
          
        </Grid>
      </div>
    );
  };
  export default MarketUI;