import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Avatar, Box, Typography, Grid, IconButton, TextField, useMediaQuery } from '@mui/material';
import Progress from '../components/mods/progress';
import KTabs from '../components/nav/tabular';
import { useTheme } from '@mui/material/styles';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import MarketRoll from '../components/nav/marketroll';
import StackableFoot from '../components/mods/displays/stackfoot';

const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';

function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 10000) { // for numbers between 1000 and 9999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place
  } else {
    return Math.round(number / 1000) + 'k'; // round to nearest thousand for numbers 10,000 and above
  }
}

const GET_ALL_USERS = gql`
  query GetAllUsers {
    userprofs {
      _id
      Nickname
      Title
      beanCount
      Koffie_story
      usertype
      imageurl
    }
  }
`;

const UserCard = ({ user }) => (
  <Grid item xs={3} sm={3} md={3} lg={3} sx={{padding: 0}}>  {/* Adjust grid settings to fit 4 items per row */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: 5,
        boxShadow: 0,
        padding: 1,  // Reduced padding
        p: 1,  // Reduced padding
        border: 0,
      }}
    >
      <Link to={`/${user.usertype}/${user._id}`}>
        <Avatar
          sx={{ border: '1px solid black', width: 60, height: 60 }}  // Smaller avatar
          alt={user.Nickname}
          src={user.imageurl}
        />
      </Link>
      <Typography variant="subtitle2" gutterBottom>
        {user.Nickname}
      </Typography>
      <IconButton size="small" sx={{ padding: '0px' }}>  
        <img src={coffeebeans} alt='bean' width={15}/> 
      </IconButton>
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        {formatBeanCount(user.beanCount)} 
      </Typography>
    </Box>
  </Grid>
);

function Explorer() {
  const { loading, error, data } = useQuery(GET_ALL_USERS);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  if (loading) return <Progress />;
  if (error) return <Typography>Error fetching data</Typography>;

  const filteredUsers = data.userprofs.filter(user =>
    user.Nickname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Group filtered users by userType
  const usersByType = filteredUsers.reduce((acc, user) => {
    if (!acc[user.usertype]) {
      acc[user.usertype] = [];
    }
    acc[user.usertype].push(user);
    return acc;
  }, {});

  return (

    
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', mt: 1, mb: 10 }}>
        {!isMobile && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      <div style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 1000, mb: 1 }}>
       <StackableFoot />
      </div>
    </Box>
      }
      <Link to="/user-market" style={{textDecoration: 'none'}}>
      <Typography variant="body2" sx={{ fontWeight: 300, color: 'green', mb: 2, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' }}>
        Explorer User Market 
      </Typography>
      
      </Link>
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', }}>
        <MarketRoll />
      </Box>
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          mb: 0,
          mt: 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
          },
        }}
      />
      <Box sx={{ p: 2 , padding: 0}}>
        {Object.keys(usersByType).map(userType => (
          <Box key={userType} sx={{ p: 2, padding: 1  }}>
            <Typography variant="h6" sx={{ fontWeight: 400,  mb: 1 }}>
              {userType.toUpperCase()}
            </Typography>
            <Grid container spacing={1}>
              {usersByType[userType].map(user => (
                <UserCard key={user._id} user={user} />
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Explorer;
