import { UserContext } from "../../context/usercontext";
import { Button, TextField, Box, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GoogleOneTap from "../mods/googauth";
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  boxContainer: {
   display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
     padding: theme.spacing(1),
     borderRadius: 5,
     boxShadow: 10,
     position: 'relative', // Add position relative for absolute positioning of ribbon
   },
   logo: {
     borderRadius: 0,
     margin: '0 auto',
     display: 'block',
     width: 80,
     height: 80,
     
   },
   logoWrapper: {
     display: 'flex',
     justifyContent: 'space-between',
     alignItems: 'center',
     width: '100%', // Ensure the wrapper takes the full width
     paddingLeft: theme.spacing(2),
     paddingRight: theme.spacing(2),
     marginBottom:   theme.spacing(2),
   },
   ribbon: {
     position: 'absolute',
     bottom: 0, // Adjust the position of the ribbon vertically
     left: 20, // Adjust the position of the ribbon horizontally
     backgroundColor: '#96ff00', // Adjust the background color of the ribbon
     padding: theme.spacing(0.05),
     borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
     borderWidth: 1, // Adjust border width
     borderStyle: 'solid', // Adjust border style
     borderColor: '#fff', // Adjust border color
   },
   ribbonText: {
     color: 'black', // Adjust the text color of the ribbon
     fontWeight: 900, // Adjust the font weight of the ribbon text
     fontSize: 10, // Adjust the font size of the ribbon text
   },
   hideScrollbar: {
     overflowY: 'auto',
     scrollbarWidth: 'none', // For Firefox
     '-ms-overflow-style': 'none', // For Internet Explorer and Edge
     '&::-webkit-scrollbar': {
       display: 'none', // For Chrome, Safari, and Opera
     },
   },
   menuBox: {
   
     padding: 2,
     display: 'flex',
     width: '100%',
     margin: 'auto',
     justifyContent: 'space-around',
     marginTop: 20,
     
     
    
     '&:hover': {
       backgroundColor: theme.palette.action.hover,
     },
     '& a': {
       textDecoration: 'none',
       color: theme.palette.text.primary,
       '&:hover': {
         textDecoration: 'none',
       },
     },
   },
   menuItem: {
     fontSize: 16,
     fontWeight: 500,
     '&:hover': {
       color: theme.palette.primary.main,
     },
   },
   logoContainer: {
     paddingLeft: theme.spacing(0),
     paddingTop: theme.spacing(2),
   },
   walletContainer: {
     paddingRight: theme.spacing(5),
   },
   menuTransition: {
     transition: 'fade 0.5s',
   },
   buttonContainer: {
     display: 'flex',
     justifyContent: 'center', // Centers the button horizontally
     alignItems: 'center', // Centers the button vertically if needed
     width: '50%',
     height: '40px',
     borderRadius: 20,
     backgroundColor: '#077336', // Green color, you can change it as per your theme
     color: 'white',
     border: 'none',
     marginTop: 30,
     fontSize: 20,
     fontWeight: 700,
   },
 
   
 }));

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const classes = useStyles();
    const { user, fetchUser, emailPasswordLogin, setUser } = useContext(UserContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [form, setForm] = useState({
      email: "",
      password: "",
      userType: ""
    });
  
    const onFormInputChange = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };
  
    const loadUser = async () => {
      if (!user) {
        const fetchedUser = await fetchUser();
        if (fetchedUser) {
          redirectNow();
        }
      }
    }
  
    useEffect(() => {
      loadUser(); 
    }, []);
  
    const redirectNow = () => {
      const redirectTo = location.search.replace("?redirectTo=", "");
      navigate(redirectTo ? redirectTo : "/post");
    }
  
    const onSubmit = async (event) => {
      try {
        const user = await emailPasswordLogin(form.email, form.password);
        if (user) {
          const customUserData = await user.refreshCustomData();
          console.log(customUserData);
          setUser({ ...user, Nickname: customUserData.Nickname }); // Assuming that the username is stored under "username" in custom user data.
          
          const fetchedUser = await fetchUser();  // Fetch user after successful authentication
          navigate("/post", { state: { user: fetchedUser }});  // Pass fetched user to Logic via state
        }
      } catch (error) {
        alert('Oops, Invalid Creds. Please try again...');
      }
    };
    

    return (
      <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row', // Separate column for mobile, row for desktop
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: isMobile ? 2 : 5, // Adjust padding for mobile and desktop
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobile ? '100%' : '40%', // Take less width on desktop
          textAlign: 'center',
          padding: isMobile ? 1 : 2,
        }}
      >
        <img
          style={{
            width: isMobile ? '100px' : '400px', // Smaller for mobile, larger for desktop
            height: isMobile ? '100px' : '250px',
          }}
          src={
            theme.palette.mode === 'dark'
              ? isMobile
                ? 'https://storage.googleapis.com/app_darkendimg/assets/klandwhite.png'
                : 'https://storage.googleapis.com/app_darkendimg/assets/bigwhitekland.png'
              : isMobile
              ? 'https://storage.googleapis.com/app_darkendimg/assets/kland.png'
              : 'https://storage.googleapis.com/app_darkendimg/assets/biggreenkland.png'
          }
          alt="Koffieland"
        />
        <Typography
          variant="h3"
          sx={{
            color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
            fontWeight: 'bold',
            fontSize: isMobile ? '2rem' : '4rem',
            marginTop: '1rem',
            position: 'relative',
          }}
        >
          Koffieland
          <Typography
            component="span"
            sx={{
              fontSize: '3.0 rem',
              fontWeight: 'normal',
              position: 'absolute',
              top: '-0.5rem',
              right: '-1rem', // Adjust for trademark position
            }}
          >
            ™
          </Typography>
        </Typography>
      </Box>
    
      {/* Form Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Ensure alignment for both mobile and desktop
          justifyContent: 'center',
          width: isMobile ? '100%' : '40%', // Adjust width for mobile and desktop
          padding: isMobile ? 1 : 2, // Separate padding for both views
          gap: isMobile ? 1 : 2, // Add consistent spacing
        }}
      >
        <Typography
          variant="h3"
          sx={{
            display: isMobile ? 'none' : 'block', // Show only on desktop
            color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
            fontWeight: 'bold',
            fontSize: '2rem',
            textAlign: 'center',
          }}
        >
          Welcome to Koffieland
        </Typography>
    
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: isMobile ? '100%' : '350px',
            alignItems: 'center', // Center form elements
          }}
        >
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            name="email"
            size="small"
            value={form.email}
            onChange={onFormInputChange}
            sx={{
              marginBottom: '1rem',
              borderRadius: 1,
              width: '100%',
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            size="small"
            name="password"
            value={form.password}
            onChange={onFormInputChange}
            sx={{
              marginBottom: '1rem',
              borderRadius: 1,
              width: '100%',
            }}
          />
          <Button
            onClick={onSubmit}
            sx={{
              mb: 2,
              color: theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',
              borderRadius: 5,
              backgroundColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#077336',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? '#f0f0f0' : '#055a28',
              },
              width: '100%',
            }}
          >
            Login
          </Button>
        </form>
    
        <GoogleOneTap />
      </Box>
    </Box>
    
    
  );
}

export default Login;