import React from 'react'
import styled from "styled-components";
import {Avatar, Typography} from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../context/usercontext';
//import '../../styles/styles.css'
import { useQuery, gql } from '@apollo/client';

import { Link } from 'react-router-dom'
import Flavloader from '../mods/flavloader';


export const MY_ADDS = gql`
  query MyAdds($isAdd: Boolean!) {
    aiadds(query: { isAdd: $isAdd }) {
      Title
      When
      _id
      description
      price
      primaryImage
      productID
      userID
      isAdd
      imageurl
    }
  }
`;



const ScrollContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  background: transparent;
  
  top: 0;
  overflow: hidden;
`;

const Scroll = styled.div`
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto;
  white-space: no-wrap;
  scroll-behavior: smooth;
  scrollbar-width: none;
  background: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Other = styled.div`
  display: flex;
  
  
  background: transparent;
  
  border: white solid 0px;
  
  
`;

const Button = styled.button`

background: transparent;
padding: 5px;
border:  none 5px;


`;


function MarketRoll() {

    
  const { loading, error, data } = useQuery(MY_ADDS, {
    variables: { isAdd: true },
  });
     

    if (loading) return <div><Flavloader/></div>
    
    if (error) return <div> {null}</div>
    



    return (
      <>
     <></>
          <ScrollContainer >   
        <Scroll>  
          <Other  >                 
            {data.aiadds.map((aiadd) => (
              <div key={aiadd._id} >
                <Link to={`/fromadds/${aiadd._id}`} >
                  <Button>
                    <Avatar src={aiadd.imageurl} alt={aiadd.description} sx={{ width: 80, height: 80 }} />
                    <Typography variant="caption" gutterBottom color='green'>
                      ${aiadd.price}
                    </Typography>
                  </Button>
                </Link>
              </div>
            ))}
         </Other>
        </Scroll>
        
      </ScrollContainer>
    
       </>
      
    )
}

export default MarketRoll





