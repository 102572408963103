import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_USERS_BY_TYPE = gql`
  query GetUsersByType($userType: String!) {
  userprofs(query: { usertype: $userType }) {
    _id
    Nickname
    Title
    cherryCount
    Koffie_story
    usertype
    
  }
}

`;

function UsersList({ userType="prodman" }) {
    const { loading, error, data } = useQuery(GET_USERS_BY_TYPE, {
        variables: { userType },
      });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      {data.userprofs.map((userprofs) => (
        <div key={userprofs._id}>
            <p>{userprofs.Nickname}</p>
            <p>{userprofs.Title}</p>
            <p>{userprofs.cherryCount}</p>
            <p>{userprofs.Koffie_story}</p>
            <p>{userprofs.usertype}</p>

        </div>
      ))}
    </div>
  );
}

export default UsersList;