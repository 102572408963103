
//import '../../styles/styles.css';
import { useQuery, gql } from '@apollo/client';
import { UserContext } from '../context/usercontext';
import { useKron } from '../queries/krons';
import { useParams } from 'react-router-dom'
import React, { useState, useEffect, useContext } from 'react';
import { Container, styled } from '@mui/material';
import { useMutation } from '@apollo/client';
import StoryCard from '../components/mods/comments';
import Progress from '../components/mods/progress';







function KronDet() {
  const { user } = useContext(UserContext);
  



      
  

  
  
const { _id } = useParams()
   
    const { loading, data, error } = useKron(_id);

if (loading) return <div><Progress/></div>

if (error) return <div>Unexpected Error: {error.message}</div>


    
    return (
        <Container maxWidth={'lg'} >
            <div className='grid-item'>
       {data.kron.year}
      </div>
        <div className='grid-item'>
        {data.kron.What}
       </div>
       <div>
       
       </div>
       </Container>
    )
}

export default KronDet






