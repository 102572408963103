import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Card, Avatar, Typography, Grid, LinearProgress, Button, Fab, Dialog, DialogActions, Chip, DialogTitle, DialogContent, CardContent, DialogContentText } from '@mui/material';
import { gql } from '@apollo/client';
import coffeebeans from '../../images/brkoin.png';
import minted from '../../images/kbean.png';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale , } from 'chart.js/auto'; 
// Register the moment adapter
import moment from 'moment';
import { registerAdapterDateFns } from 'chartjs-adapter-moment'; 
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Progress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/usercontext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PriceTicket from '../finance/prods/pricefeed';
import kcoin from '../../images/kcoin.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GaugeChart from 'react-gauge-chart'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


const useStyles = makeStyles((theme) => ({
  progressAndChart: {
    display: 'flex', 
    
  },
  progressContainer: {
    width: 300, 
    height: 30,
    border: '1px solid #ddd', 
    borderRadius: 5,
  },
  progressFiller: {
    backgroundColor: 'lightgreen', 
  },
  chartContainer: {
     width: '100%',
  },
}));




// Register the moment adapter

ChartJS.register(CategoryScale);


function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}


const GET_USER = gql`   
    query Userprof ($_id: ObjectId!)  {
        userprof (query: {_id: $_id}) {
            flavorprofile
            Nickname
            tasteProfile
            description
            name
            purchaseLink
            Koffie_story
            usertype
            _id
            country
            beanCount 
            imageurl
            profileBuilt
        }
    }
`;


const BeanCount = () => {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl, _id } = user.customData
  const classes = useStyles();
 
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  //get user by _id
  const { data, error, refetch, loading } = useQuery(GET_USER, {
    variables: { _id: user?.id },
});

useEffect(() => {
  if (user?.id) {
    refetch();
  }
}, [user?.id, refetch]);
  if (loading) return <p></p>;
  if (error) return <p>Error :</p>;

  const topUsers = data.userprofs;
  
  
const open = () => {
  setOpenDialog1(true);
};
  
const upgrade = () => {
  setOpenUpgrade(true);
};

const isSmartCitizen = data.userprof?.beanCount > 500;

  return (
    <>
<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, borderRadius: 0, boxShadow: 0,  marginTop: 0 }}>
  

    <Box sx={{ display: 'flex', alignItems: 'center',  }}>
   
    {isSmartCitizen ? (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
            
        <img src='https://storage.googleapis.com/app_darkendimg/assets/kcoin.png' alt="Bean" width={30} />
        <Typography variant="caption" sx={{ fontWeight: 700, marginRight: '8px', mb: 2,  textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)'}}>
          {data.userprof?.beanCount}
        </Typography>
      </Box>
    ) : (
    
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
            
            <img src={kcoin} alt="Bean" width={30} />
            <Typography variant="caption" sx={{ fontWeight: 700, marginRight: '8px', mb: 2,  textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)'}}>
              {data.userprof?.beanCount}
            </Typography>
          </Box>
    )}
     <Dialog open={openUpgrade} onClose={() => setOpenUpgrade(false)}>
    <IconButton
      onClick={() => setOpenUpgrade(false)}
      sx={{
        marginLeft: 0,  // Ensures there's no additional space on the left
        color: 'primary.main',  // Optional: change the icon color if needed
        backgroundColor: 'transparent' , // Ensures the button background is transparent
        position: 'absolute', top: 8, right: 8, fontSize: 2, 
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogTitle variant='h5' sx={{fontWeight: 600}}>Upgrade to Smart Citizen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Become a lifetime Smart Citizen and enjoy all the benefits of being a citizen of KoffieLand with your first Purchase of 1lb, 4000 KoffieBeans.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Chip label="Upgrade" onClick={() => navigate('/store')} sx={{ backgroundColor: '#32a852', color: '#fff' }} />
        </DialogActions>
      </Dialog>
  </Box>
  </Box>
  
    </>
  );
};

export default BeanCount;
