import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBeanBag } from '../../context/beanBagContext';
import { Card, Typography, Button, Box } from '@mui/material';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useEffect, useContext } from 'react';
import { UserContext } from '../../context/usercontext';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    beancarts(query: { userID: $userID }) {
      userID
      beanies
      productId
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;


export const DELETE_ONE_ITEM = gql`
mutation DeleteOneItem($_id: ObjectId!) {
  deleteOneBeancart(query: {_id: $_id}) {
    _id
  }
}
`;  

const BeanBaggy = () => {
  const { user } = useContext(UserContext);
  const { itemsInBag, setItemsInBag } = useBeanBag();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const goToStore = () => {
    navigate('/store');
  };

  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id },
    skip: !user.id,
  });
  console.log("cartData:", cartData);

  useEffect(() => {
    if (cartData && cartData.beancarts) {
      const newItemsInBag = cartData.beancarts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
        };
      });
      setItemsInBag(newItemsInBag);
    }
  }, [cartData]);

  
  const [deleteOneItem] = useMutation(DELETE_ONE_ITEM);

  const total = itemsInBag.reduce((sum, item) => sum + item.price, 0);

  const handleDeleteItem = (indexToDelete) => {
    deleteOneItem({ variables: { _id: itemsInBag[indexToDelete]._id } });
    const newItems = itemsInBag.filter((_, index) => index !== indexToDelete);
    setItemsInBag(newItems);
  };

  return (
    <div style={{ paddingBottom: 65 }}>
      {error ? (
        <div>
          <p>{error}</p>
          <Button onClick={goToStore}>Continue Shopping</Button>
        </div>
      ) : (
        <div>
          <Card sx={{ borderRadius: 0 }}>
            <Typography variant="h6" gutterBottom>
              Bean Bag
            </Typography>
          </Card>
          {itemsInBag.map((item, index) => (
            <Card
              key={index}
              sx={{
                borderRadius: 10,
                padding: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&:hover': { backgroundColor: '#e4fad9' },
              }}
            >
              <Box 
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundImage: `url(${item.imageurl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: 100,
                  height: 50,
                  justifyContent: 'space-between',
                }}
                />
              {item.name} ${item.price.toFixed(2)}
              <IconButton onClick={() => handleDeleteItem(index)} edge="end" size="small">
                <CloseIcon />
              </IconButton>
            </Card>
          ))}
          <Card sx={{ borderRadius: 0, textAlign: 'right' }}>
            <Typography variant="h6" gutterBottom>
              Total: ${total.toFixed(2)}
            </Typography>
            {/* Add a button to proceed to custom checkout */}
            <Button onClick={() => navigate('')}
            sx={{ backgroundColor: '#077336', color: 'white', borderRadius: 10, border: 'none' }}
            >COMING SOON!</Button>
          </Card>
        </div>
      )}Proceed to Custom Checkout
    </div>
  );
};

export default BeanBaggy;