//A nice landing page with login and signup options at top and responsive design with sectctions for features, testimonials, and pricing.

import React from 'react'
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material'
import { Link } from 'react-router-dom'



function Home() {

  return (
    <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
            <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Koffie App
            </Typography>
            <Link to="/login" sx={{ textDecoration: 'none' }}>
            <Button color="inherit">Login</Button>
            <Button color="inherit">Sign Up</Button>
            </Link>
            </Toolbar>
        </AppBar>
        <Container>
            <Typography variant="h4" component="h1" gutterBottom>
            Welcome to Koffie App
            </Typography>
            <Typography variant="body1" gutterBottom>
            A nice landing page with login and signup options at top and responsive design with sectctions for features, testimonials, and pricing.
            </Typography>
        </Container>
    </Box>
  )
}


export default Home