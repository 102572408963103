import { gql, useQuery}from '@apollo/client'

const GET_KRONS = gql `

query{
    krons {
  Category
  Country
  Title
  What
  _id
  year
  ImageUrl
}
}
`
;

const GET_KRON = gql `

query aKron($_id: ObjectId!){

kron (query: {_id: $_id}){

  
_id
Category
Title
year
What
ImageUrl

  }

}
`
;

const GET_USER = gql `

query aUser($_id: ObjectId!){

userprof (query: {_id: $_id}){

  
_id
author
flavorprofile
Nickname
Title
Koffie_story
usertype


  }

}
`
;

export const useUser = (_id) => {
  const { data, error, loading } =useQuery(GET_USER, {
    variables: {
      _id
      
    }
  });

    return {
      data,
      error,
      loading,
    };
};
export const useKron = (_id) => {
  const { data, error, loading } =useQuery(GET_KRON, {
    variables: {
      _id
      
    }
  });

    return {
      data,
      error,
      loading,
    };
};

export const GetKrons = () => {
  const { data, error, loading,  } =useQuery(GET_KRONS, {
    
  });

    return {
      data,
      error,
      loading,
    };
};