import React, { useEffect, useState } from 'react';
import axios from 'axios';

// This function will parse the CSV data into objects




function parseCSVData(csvData) {
    const lines = csvData.split("\n");
    const result = [];
    const headers = lines[0].split(",").map(header => header.trim());
  
    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i].split(",");
  
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j] && currentline[j].trim();
      }
  
      result.push(obj);
    }
  
    return result; // This will be an array of objects
  }
  

const Comtrade = () => {
  const [data, setData] = useState([]);

  

  useEffect(() => {
    fetch('/api/tradingeconomics/data')
      .then(response => response.text()) // Handle as text
      .then(text => {
        const jsonData = parseCSVData(text); // Use the parseCSVData function
        setData(jsonData);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  return (
    <div>
      <h1>Trading Economics Data</h1>
      {/* Render your data here */}
      {data.map((item, index) => (
        <div key={index}>
          {/* Display data elements */}
        </div>
      ))}
    </div>
  );
};

export default Comtrade;
