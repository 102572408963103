import { gql, useQuery} from '@apollo/client';



const GET_FARMERS = gql `

query{

farmprofs {

  FarmName
    First_Name
    Last_Name
    imageurl
    Location
    Hectare
    Trees
    
    Cherries
    Production

  _id

}

}
`
;

const GET_FARMER  =  gql `

query ($_id: ObjectId!){
  userprof (query: {_id: $_id}){
    _id
    Nickname
    Title
    Koffie_story
    usertype
    imageurl
        
  }
}

`;


export const GetFarmers = () => {
    const { data, error, loading } =useQuery(GET_FARMERS, {
      
    });
  
      return {
        data,
        error,
        loading,
      };
  };
  
  export const useFarmer = (_id) => {
    const { data, error, loading } =useQuery(GET_FARMER, {
      variables: {
        _id,
      }
    });
  
      return {
        data,
        error,
        loading,
      };
  };