import React, { useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';

const SEARCH_DARKROAD = gql`
  query SearchDarkroad($query: DarkroadQueryInput) {
    darkroads(query: $query) {
      _id
      When
      What
     
    }
  }
`;

function DarkroadSearch() {
  const [getDarkroad, { loading, data }] = useLazyQuery(SEARCH_DARKROAD);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    getDarkroad({ variables: { query: { When: searchTerm } } }); // adjust this according to your DarkroadQueryInput structure
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <input 
          type="text" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
          placeholder="Enter search term" 
        />
        <button type="submit">Search</button>
      </form>
      {loading && <p>Loading...</p>}
      {data && data.darkroads.map(darkroad => (
        <div key={darkroad._id}>
          <h2>{darkroad.When}</h2>
          <p>{darkroad.What}</p>
          {/* render other fields */}
        </div>
      ))}
    </div>
  );
}

export default DarkroadSearch;
