// ShareComponent.js
import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => ({
  shareContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  shareButton: {
    margin: theme.spacing(1),
  },
}));

const ShareComponent = ({ url, title, description }) => {
  const classes = useStyles();
    const image = 'https://source.unsplash.com/random/800x600';

  return (
    <div className={classes.shareContainer}>
         <Helmet>
    <title>{title}</title>
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />
  </Helmet>
      <FacebookShareButton url={url} quote={title} className={classes.shareButton}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} className={classes.shareButton}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title} summary={description} className={classes.shareButton}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={url} title={title} className={classes.shareButton}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </div>
  );
};

export default ShareComponent;
