import { useFarmer } from '../queries/someq';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { CardActionArea, Chip, Grow, List, styled } from '@mui/material';

import { useParams } from 'react-router-dom'
import beans from '../images/coffeebeans.png'
import { UserContext } from '../context/usercontext';
import { 
Avatar,
Grid,
Card,
CardMedia,
GridItem,
CardContent,
Stack,
Paper,
Typography,
Divider,
Box,
ListItem,
ListItemIcon, 
ListItemText,
ListItemSecondaryAction,
Slider,
Drawer,
Pagination,
IconButton,
Dialog,
} from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//import KoffieB from '../components/mods/beanjump';
import ButtonBase from '@mui/material/ButtonBase';
import Prod1 from '../components/finance/prods/prod';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Koffieticket from '../components/finance/prods/coffee';
import Process from '../blockchain/process';
import Progress from '../components/mods/progress';
import HilltopDialog from '../components/mods/snacks/hilltop';
import dadskoffie from '../images/dadskoffie.jpg';
import ImageSlider from '../components/mods/farmslider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useQuery, gql  } from '@apollo/client';
import { Link } from 'react-router-dom'
import { get } from 'react-hook-form';
import Darkswipe from '../components/mods/darkswipe';
import ContractDetails from '../components/profile/contractdetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Header from '../components/nav/mobilefarmer';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 700, // Duration for the grow-in effect
        exit: 500,  // Duration for the shrink-out effect
      }}
      easing={{
        enter: 'cubic-bezier(0.4, 0, 0.2, 1)', // Custom easing function
        exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
      }}
    />
  );
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Item = styled(Paper)(({ theme }) => ({
  
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  
}));

const FARMER_BY_USERID = gql`
  query KupsterByUserID($userID: ObjectId!) {
    farmprof(query: { userID: $userID }) {
      Title
      _id
      Hectare
      Location
      userID
      ImageUrl
      Elevation
      Income
      Production
      Trees
      Process
      countryName
      Outlet
      Employees
      imagelist
    }
  }
`;

const GET_USER_PROD_QUERY = gql`
  query GetUserGoals($userID: String!) {
  products(query: {userID: $userID}) {
    
    _id
    userID
    name
    price
    description1
    imageurl
    
  }
}  
`;

const GET_KOFFIE_BY_COUNTRY = gql `

query aKoffie($countryName: String!){

koffy (query: {countryName: $countryName}){
  countryName
  flag
   altitudeRange {
    low
    high
  }
  coffeeProduction {
    annualOutput
    globalRank
  }
  harvestSeason
  keyFacts
  imageurl 
  history
  climate
  social
  political
  varieties
  wet
  dry
  economics
  hybrid
  pounds
  varieties 
  flavorProfile
    _id

  }

}
`
;

const options = [];
for (let i = 0; i <= 50; i++) {
    options.push(<option key={i} value={i}>{i}</option>);
}



function ProdManMobile() {
  const { _id } = useParams()
  
  const { user } = useContext(UserContext);
  const { data: getFarmerData, loading: loadingFarmer, error: errorFarmer } = useQuery(FARMER_BY_USERID, {
    variables: { userID: _id },
    fetchPolicy: 'cache-and-network',  // Ensure the policy fetches from the network after cache
  });
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const touchStartX = useRef(0);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = useState({});
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [drawerOpen1, setDrawerOpen1] = useState(false);
  const [expanded, setExpanded] = React.useState();
  const [openDialog, setOpenDialog] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expandedCards, setExpandedCards] = React.useState({});


  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  const [countryName, setCountryName] = useState(null);

  const { loading: loadingKoffie, data: koffieData, error: koffieError } = useQuery(GET_KOFFIE_BY_COUNTRY, {
    variables: { countryName },
    skip: !countryName,
  });

  useEffect(() => {
    if (getFarmerData && getFarmerData.farmprof && getFarmerData.farmprof.countryName) {
      setCountryName(getFarmerData.farmprof.countryName);
    }
  }, [getFarmerData]);

  
const toggleDrawer1 = () => {
  setDrawerOpen1(!drawerOpen1);
  };

  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
    setDialogOpen(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

    const [, setError] = useState(null);
    
    
const { data: ProdData } = useQuery(GET_USER_PROD_QUERY, {
  variables: { userID: _id },
  fetchPolicy: 'cache-and-network',  // Add this line
  skip: !_id
});

const nextImage = () => {
  setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
};

const prevImage = () => {
  setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
};

const toggleDrawer = () => {
  setDrawerOpen(!drawerOpen);
};

const handleTouchStart = (e) => {
  touchStartX.current = e.touches[0].clientX;
};

const handleTouchEnd = (e) => {
  const touchEndX = e.changedTouches[0].clientX;
  const diff = touchStartX.current - touchEndX;

  if (diff > 50) {
    nextImage();
  } else if (diff < -50) {
    prevImage();
  }
};
const product = ProdData ? ProdData.products : [];
const farmerProfile = getFarmerData?.farmprof;


const images = farmerProfile ? farmerProfile.imagelist ? farmerProfile.imagelist : []  : [] ;
  
    const { loading, data } = useFarmer(_id);
    

if (loading) return <div><Progress/></div>
if (koffieError) return <div>Unexpected Error: {koffieError.message}</div> 
if (loadingFarmer || loadingKoffie) return <p>Loading...</p>;
  if (errorFarmer) return <p>Error loading farmer profile: {errorFarmer.message}</p>;
  if (koffieError) return <p>Error loading coffee profile: {koffieError.message}</p>;

    return (
      <>
      <Dialog fullScreen  TransitionComponent={Transition} sx={{height: '100vh', backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff', zIndex: 1200  }} open={openDialog} onClose={() => handleExpandClick(_id)}>
        
            <div style={{  paddingTop: 0, marginBottom: 40,  backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff', }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <IconButton aria-label="go back" sx={{ color: 'green' }} onClick={() => window.history.back()}>
        <ArrowBackIos />
    </IconButton>
</Box>
            <div style={{backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 0, paddingBottom: 0, margin: 10, borderRadius: 5, }}>
            <Card
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',  
        backgroundImage: `url(${images[currentImageIndex]})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '300px',
        width: '100%',
        position: 'relative',
        borderRadius: 5,
        boxShadow: 5,
        objectFit: 'cover',
        
      }}
      onClick={toggleDrawer}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
  <Box
    sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      padding: 1,
    }}
  >
    <Pagination count={images.length} page={currentImageIndex + 1} color="primary" />
  </Box>
</Card>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box sx={{ }}>
            <IconButton onClick={toggleDrawer}>
            <ArrowBackIcon />
            </IconButton>
          <Grid container spacing={1}>
            {images.map((img, index) => (
              <Grid item xs={4} key={index}>
                <Card sx={{ borderRadius: 2, height: "auto", width: '100%', 
                backgroundImage: `url(${img})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                
                
                
              }} >
                <img src={img} alt={`Farm ${index}`} style={{ width: '100%', height: 'auto' }} />
                </Card>
                
              </Grid>
            ))}
          </Grid>
          
        </Box>
      </Drawer>
    </div>
             <Box>
              <HilltopDialog/>
      {/* Hero Banner */}
      

      {/* User Profile */}
      <Box position="relative" >
     
      

        {/* User Info */}
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '16px', bgcolor: 'background.paper' }}>
        <CardMedia
          component="img"
          sx={{ width: 64, height: 64, marginRight: '16px' , borderRadius: 5,  boxShadow: 10}} // Adjust size as needed
          image={farmerProfile ? farmerProfile.ImageUrl : "Loading..."} // Replace with your image URL
          alt="Company Logo"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column',  }}>
          <Typography variant="h5" fontWeight={600}>
            {data.userprof.Nickname} {/* Replace with your data field */}
          </Typography>
         
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto' }}>
      {koffieData && koffieData.koffy && (
        <div key={_id}>
          
          <Avatar src={koffieData.koffy.flag} width={40} />
          
          {/* Display coffee country profile details */}
        <Chip onClick={toggleDrawer1} label={koffieData.koffy.countryName} />
          <Drawer anchor="right" open={drawerOpen1} onClose={toggleDrawer1}>
        <Box sx={{ width: 300 }}>
            <IconButton onClick={toggleDrawer1}>
            <ArrowBackIcon />
            </IconButton>
            
            <Typography variant="h6" fontWeight={600} gutterBottom component="div">
              {koffieData.koffy.countryName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.keyFacts}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.history}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.climate}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.social}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.political}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.varieties}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.wet}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.dry}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.economics}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {koffieData.koffy.hybrid}
            </Typography>
            </Box>
      </Drawer>
      
          {/* Add more fields as needed */}
        </div>
      )}
      </Box>
      
      </Box>
     
      <CardContent sx={{ flexGrow: 1 }}>
      
       
        {/* ... Repeat for other info like company size, industry, description */}
        <Typography variant="subtitle1" fontWeight={500} gutterBottom component="div">
          Bio
        </Typography>
        <Typography variant="body2" color="text.secondary">
        
    {data.userprof.Koffie_story}
  </Typography>
      </CardContent>
      <CardActionArea>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: '8px' }}>
        {/* Farm Size */}
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            {farmerProfile ? farmerProfile.Hectare : "Loading"}
          </Typography>
          <Typography variant="body2" component="div" sx={{  }}>
            Acres
          </Typography>
        </Box>

        {/* Trees */}
        <Box sx={{ textAlign: 'center' }}>
         
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            {farmerProfile ? farmerProfile.Trees : "Loading"}
          </Typography>
          <Typography variant="body2" component="div" sx={{  }}>
            Trees
          </Typography>
        </Box>

        {/* Employees */}
        <Box sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            { farmerProfile ? farmerProfile.Employees : " Loading"}
          </Typography>
          <Typography variant="body2" component="div" sx={{  }}>
            Employees
          </Typography>
          
        </Box>

        {/* Pounds */}
        <Box sx={{ textAlign: 'center' }}>
          
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            {farmerProfile ? farmerProfile.Production : "Loading"}
          </Typography>
          <Typography variant="body2" component="div" sx={{  }}>
            Pounds
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            { farmerProfile ? farmerProfile.Employees : " Loading"}
          </Typography>
          <Typography variant="body2" component="div" sx={{  }}>
            Employees
          </Typography>
          
        </Box>
        <Box sx={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            { farmerProfile ? farmerProfile.Employees : " Loading"}
          </Typography>
          <Typography variant="body2" component="div" sx={{  }}>
            Employees
          </Typography>
          
        </Box>
      </Box>
    </CardActionArea>
    </Card>
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{data.userprof.Nickname}'s Smart Koffie Contract</Typography>
        </AccordionSummary>
        <AccordionDetails>
      <ContractDetails/>
              
        </AccordionDetails>
      </Accordion>
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '16px', bgcolor: 'background.paper' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column',  }}>
          <Typography variant="body2" fontWeight={600}>
            {data.userprof.Nickname}'s Products
          </Typography>
          </Box>
      </Box>  
      </Card>
        
      {product.map((product, userID) => (
        <Card key={userID} variant="outlined" raised sx={{ display: 'flex', width: '100%', borderRadius: 5,  }}>
          <Link to={`/product/${product._id}`} style={{textDecoration: 'none'}} >
            <Card variant="outlined" raised sx={{ display: 'flex', width: '100%', borderRadius: 5,  }}>
              <CardMedia
                component="img"
                sx={{ width: 120 }}
                image={product.imageurl}
                alt={product.name}
              />
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="h6">{product.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.description1}
                </Typography>
                <Typography variant="h4" color="text.secondary" sx={{textDecoration: 'none'}}>
                  ${product.price}  <Chip label="Buy Now" />
                </Typography>
               
              
              </CardContent>
              
            </Card>
          </Link>
        </Card>
      ))}
    
    <Card sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        borderRadius: 5, 
        boxShadow: 0,
        mb: 2 // Add margin-bottom for spacing
      }}>
      <Box sx={{ 
          display: 'flex', 
          flexDirection: 'row',
          alignItems: 'center', 
          padding: '16px',
          bgcolor: 'background.paper'
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column',  mr: 2 }}> {/* Add margin-right */}
          <Typography variant="heading" fontWeight={600}>
            Tell us about your experience with {data.userprof.Nickname}
          </Typography>
        </Box>
        <ButtonBase sx={{ borderRadius: 5, bgcolor: 'primary.main', color: 'white', p: 2, ml: 'auto' }}>
          <ArrowForwardIcon />
        </ButtonBase>
        
      </Box>  
    </Card>

        {/* User Content */}
          <Box mt={3}>
            <Typography variant="h6" align="left" fontWeight={600}>
             {data.userprof.Nickname}'s' Smart Koffie Contract
            </Typography>
            <Typography variant="body2" align="left" color="text.secondary">
              {data.userprof.Nickname} has a Smart Koffie Contract at Hilltop Coffee
            </Typography>
           
          </Box>
      
      </Box>
      
        
    </Box>
    <Process/>
    <Header/>
      </div>
      </Dialog>
      </>
    )
}

export default ProdManMobile

