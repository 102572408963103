
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form, FormGroup, Label, Input, FormText , Box, Typography,  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Container } from '@mui/material';
import { Table } from '@mui/material';


import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Alert } from '@mui/material';
import { Snackbar } from '@mui/material';   
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import { ArrowBack, ConstructionOutlined } from '@mui/icons-material';
import MiniShow from '../mods/mini-mornignshow';



const bigCoffee = [
    {
      "name": "Coffee ",
      "tickerSymbol": "KC",
      "price": "204.00",
      "imageUrl": "https://example.com/coffee_commodities.png"
    },
    {
      "name": "Crude Oil ",
      "tickerSymbol": "CL",
      "price": "84.81",
      "imageUrl": "https://example.com/starbucks.png"
    },
    {
      "name": "Platinum",
      "tickerSymbol": "KDP",
      "price": "7.78",
      "imageUrl": "https://example.com/keurig_dr_pepper.png"
    },
    {
      "name": "Gold",
      "tickerSymbol": "GC",
      "price": "2,311",
      "imageUrl": "https://example.com/nestle.png"
    },
    {
      "name": "Copper",
      "tickerSymbol": "HG",
      "price": "4.23",
      "imageUrl": ""
    },
    {
      "name": "Cocoa",
      "tickerSymbol": "CC",
      "price": "9,132",
      "imageUrl": "https://example.com/nestle.png"
    },
    {
      "name": "Sugar",
      "tickerSymbol": "SB",
      "price": "21.97",
      "imageUrl": ""
    },
    {
      "name": "Corn",
      "tickerSymbol": "ZC",
      "price": "447.00",
      "imageUrl": ""
    },
    {
      "name": "Oats",
      "tickerSymbol": "QSR",
      "price": "335.50",
      "imageUrl": ""
    },
    {
      "name": "Orange Juice",
      "tickerSymbol": "OJ",
      "price": "363.50",
      "imageUrl": ""
    },
    {
      "name": "Live Cattle",
      "tickerSymbol": "LE",
      "price": "175.95",
      "imageUrl": ""
    }
  ];


function MarketWatch (props) {
    const [commodities, setCommodities] = React.useState([]);


    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', padding: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Typography variant="h4" gutterBottom>
        Market Watch
      </Typography>
      <Typography variant="h6" gutterBottom>
        In Development <ConstructionOutlined />
      </Typography>
      <TableContainer component={Paper} sx={{ width: '100%', marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Commodity</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Symbol</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bigCoffee.map((commodity, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">{commodity.name}</TableCell>
                <TableCell align="right">{commodity.price}</TableCell>
                <TableCell align="right">{commodity.tickerSymbol}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    );
}
export default MarketWatch;

