import React, { useState, useEffect, useContext } from 'react';
import {  Paper, Typography,  Box, Chip, Pagination, Drawer, TextField ,List, Card, CardContent, Grid, CardMedia,  Snackbar, Slide, Button} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import useFileUpload from '../cloud';
import farmer from '../../../images/rita.jpg';
import drink from '../../../images/redpill.png';
import roaster from '../../../images/cololady.webp';
import shop from '../../../images/baggy.png';
import barista from '../../../images/cololady.webp';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormControlLabel, Radio } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_PROF, GET_USER, INSERT_USER_PROF } from '../../../context/gqllogics';
import { UserContext } from '../../../context/usercontext';
import kcoin from '../../../images/kcoin.png';
import { useSpring, animated, config } from 'react-spring';
import PaginationItem from '@mui/material/PaginationItem';
import confetti from 'canvas-confetti';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import '../../../constants/espresso.css';
import { Link } from 'react-router-dom';
import kbean from '../../../images/kbean.png';
import rbean from '../../../images/brkoin.png';
import { Stepper,
StepContent,
StepLabel,
Step,
MenuItem,
Select,
InputLabel,FormControl,

} from '@mui/material';
import BeanList from '../../finance/beanlist';




//button style


const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 0,
    paddingRight: 2,
    borderRadius: 10,
    boxShadow: 10,
    position: 'relative', // Add position relative for absolute positioning of ribbon
   
    
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '50%',
    height: '40px',
    borderRadius: 20,
    backgroundColor: '#077336', // Green color, you can change it as per your theme
    color: 'white',
    border: 'none',
    marginTop: 30,
    fontSize: 20,
    fontWeight: 700,
  },
  logo: {
    width: 40,
    borderRadius: 0,
   
  },
  ribbon: {
    position: 'absolute',
    bottom: 0, // Adjust the position of the ribbon vertically
    left: 20, // Adjust the position of the ribbon horizontally
    backgroundColor: '#d1f0d2', // Adjust the background color of the ribbon
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 1, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
    width: 100, // Adjust the width of the ribbon
    
   
  },
  ribbonText: {
    color: 'green', // Adjust the text color of the ribbon
    fontWeight: 300, // Adjust the font weight of the ribbon text
    fontSize: 12, // Adjust the font size of the ribbon text
  },
}));


function createCoffeeBeanConfetti(options = {}) {
  const defaults = {
    scalar: 1.5,
    spread: 80,
    particleCount: 150,
    origin: { y: -0.1 },
    startVelocity: -40,
    zIndex: 1500,
  };

  // Merge provided options with defaults
  const mergedOptions = { ...defaults, ...options };

  // Define the coffee bean shape as an array of coordinate pairs
  const coffeeBeanShape = [
    [0.1, 0.1],
  [0.2, 0.2],
    
  ];

  confetti({
    ...mergedOptions,
    shape: coffeeBeanShape, // Use the custom coffee bean shape
    colors: ['#704214', '#704214', '704214']
  });
}


//Function to display current time and date
function currentTime() {
  return new Date().toLocaleString();
}



function ShopOn({ userID, updateUserProfile,  }) { 
    const [createConfetti, setCreateConfetti] = useState(false);  // New state variable to control the visibility of the bean rain animation // Assume updateUserProfile is a prop function to handle form submissions
  const { user } = useContext(UserContext);
  const { file, previewURL,handleFileChange,  uploadFile, handleRemoveImage } = useFileUpload(); // Use your custom hook
  const [activeStep, setActiveStep] = useState(0);
    const [selectedUserType, setSelectedUserType] = useState('');
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
  const classes = useStyles();
    const [ setPreviewURL] = useState('');
    const fileInputRef = React.createRef();
    const [formErrors, setFormErrors] = useState({});
    const [showNextButton, setShowNextButton] = useState(false);


  const fileChangeHandler = (event) => {
     const file = event.target.files[0];
     const reader = new FileReader();
     reader.onloadend = () => {
       setPreviewURL(reader.result);
     };
     reader.readAsDataURL(file);
   };
   const growStyles = useSpring({
    from: { transform: 'scale(0)', opacity: 0 },
    to: { transform: 'scale(1)', opacity: 1 },
    config: { tension: 120, friction: 14 },
    delay: 600, // Delays the start of the animation
});
const slideInStyles = useSpring({
  from: { transform: 'translateY(100%)', opacity: 0 },
  to: { transform: 'translateY(0)', opacity: 1 },
  config: { ...config.wobbly, duration: 500 },
  delay: 200, // Delays the start of the animation
});

const [checked, setChecked] = useState(false);

useEffect(() => {
  setChecked(true); // This will trigger the slide in effect when the component mounts
}, []);

const animationStyles = useSpring({
    from: { transform: 'scale(0)', opacity: 0 },
    to: async (next) => {
      // Grows from 0 to slightly larger than normal size
      await next({ transform: 'scale(1.1)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
      // Settles back to normal size
      await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
      // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after settling
      await next({ opacity: 1, config: { duration: 500 } });
    },
    delay: 200,
    
});

const [insertUserprof] = useMutation(INSERT_USER_PROF);
    const [updateUserprof] = useMutation(UPDATE_USER_PROF);
    const [currentStep, setCurrentStep] = useState(0);
    const { data, loading, error, refetch } = useQuery(GET_USER, {
      variables: { _id: user.id },
      notifyOnNetworkStatusChange: true
    });

    const refetchUser = () => {
      refetch();
    };

    const specificStepIndex = 2; // Define this at the component level if it's static

    useEffect(() => {
      if (activeStep === 2) {
        refetch();
      }
    }, [activeStep, refetch]);
  
    const formIsValid = () => {
      const { nickname } = formData;
      let errors = {};
  
      if (!nickname.trim()) {
        errors.nickname = "Nickname is required";
      } else if (nickname.length < 3) {
        errors.nickname = "Nickname must be at least 3 characters long";
      }
  
      if (!selectedUserType) {
        errors.selectedUserType = "Please select your user type";
      }
  
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };
  
    const handleChangeIndex = (index) => {
      if (formIsValid()) {
        setActiveStep(index);
        if (index === 2) {
          refetch();
        }
      } else {
        console.log("Form is not valid. Cannot swipe to the next step.");
      }
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setFormData({
        nickname: '',
        selectedUserType: '',
      });
    };
    
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const navigate = useNavigate();
    const handleSecondStepSubmit = async (event) => {
      event.preventDefault();
      // Implement your form submission logic here
      handleNext();
    };

    const handleFirstStepSubmit = async (event) => {
    event.preventDefault();
    if (formIsValid()) {
      let imageURL = "https://storage.googleapis.com/app_darkendimg/assets/thebean.webp";

      if (process.env.NODE_ENV !== "development") {
        const file = fileInputRef.current.files[0];
        if (file) {
          try {
            const uploadedImageURL = await uploadFile(file, user.id);
            if (!uploadedImageURL) {
              console.error("File upload failed: no image URL returned");
              return;
            }
            imageURL = uploadedImageURL;
          } catch (error) {
            console.error("Error during file upload:", error);
            return;
          }
        }
      }

      try {
        await insertUserprof({
          variables: {
            data: {
              Nickname: formData.nickname,
              usertype: selectedUserType,
              imageurl: imageURL,
              profileBuilt: true,
              _id: user.id,
            },
          },
        });
        console.log("Profile updated successfully.");
        handleNext();
        setShowNextButton(true);
      } catch (error) {
        if (error.message.includes('E11000 duplicate key error collection')) {
          setFormErrors({ ...formErrors, duplicateUser: "A user with this ID already exists. Please use a different ID." });
        } else {
          console.error("Error updating profile:", error);
        }
        
      }
    }
  };

  const [formData, setFormData] = useState({
    nickname: '',
    koffieStory: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePhotoClick = () => {
    fileInputRef.current.click();
  };

  
 
  
    
    
   // Function to handle "Get Beans" action
  const handleGetBeans = async () => {
    // Logic to claim kupa koffie (100 beans)
    const variables = {
        query: { _id: user.id },
        set: {
         
          beanCount: beanCount + "100",
          

        }
      };
    
      try {
        await updateUserprof({ variables });
        setShowNextButton(true); // Enable the Next button on successful submission
        console.log("Successfully updated the user profile.");
      } catch (error) {
        console.log("An error occurred while updating the user profile:", error);
      }

    // Decrement the beanCount by 100
   // Show the bean rain dialog
   setCreateConfetti(true);

   // Trigger the confetti animation with the custom coffee bean shape
   createCoffeeBeanConfetti();

   // Hide the bean rain dialog after 1 second
   setTimeout(() => {
    setCreateConfetti(false);
    
   }, 3000);
   
   
  };
    
    const handleNextStepSubmit = async (additionalFormData) => {
      // Submit additional data for the next step
      // ...
    };
    
  
    // Render the appropriate step based on the currentStep state
    // Each step's submission should call the respective handler
    // ...
  
   
  


    
    const handleProfileUpdate = async (event) => {
      event.preventDefault();
      const file = fileInputRef.current.files[0];
      if (file) {
        try {
          const uploadedImageURL = await uploadFile(file, user.id);
          if (uploadedImageURL) {
            await updateUserProfile({
              _id: userID,
              data: {
                imageurl: uploadedImageURL,
                profileBuilt: true,
                set: formData,
              },
            });
          } else {
            console.error('File upload failed: no image URL returned');
          }
        } catch (error) {
          console.error("Error updating user profile data:", error);
        }
      }
    };
  
    
    
  
  
    const userTypes = [
      { type: 'kupster', description: 'Dinker', imageSrc: drink },
      { type: 'Farmer', description: 'Grower', imageSrc: farmer },
      { type: 'roaster', description: 'Roaster', imageSrc: roaster },
       { type: 'coffeeShop', description: 'Shop Owner', imageSrc: farmer },
       { type: 'barista', description: 'Barista', imageSrc: barista },
      // ... other user types
    ];
    const UserTypeOption = ({ userType, description, imageSrc, selectedValue, onChange, helperText }) => {
      return (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', m: 1,  }}>
          <List sx={{ width: '100%', maxWidth: 360, }}>
            <IconButton sx={{ p: 0, mr: 1 }}>
              <Avatar src={imageSrc} alt={userType} width={20} />
            </IconButton>
        <FormControlLabel
          value={userType}
          control={<Radio          
            checked={selectedValue === userType}
            onChange={() => onChange(userType)}
            sx={{ color: 'green', padding: 0 }}
          />}
          label={userType}
          sx={{ marginRight: 0 , fontWeight: 800, variant: 'body1'}}
        />
        <Typography variant="body2" color="green">
          {description}
        </Typography>
        <Typography variant="caption" color="red">
          {helperText}
        </Typography>
      </List>
      </Box>
      );
    };

    
    const steps = [
      {
          label: "Welcome to Koffieland™",
          contentType: "content",
          imgPath: "https://storage.googleapis.com/app_darkendimg/assets/airport.webp",
          title: "The better bean business ",
          content: "Swipe to access your Passport."
      },
     
      {
          label: "Your Preferences",
          contentType: "form",
          formType: "preferences"
      },
      {
          label: "Connect and Explore",
          contentType: "economy",
          imgPath: "https://storage.googleapis.com/app_darkendimg/assets/thebec.webp",
          title: "Connect with Coffee Lovers",
          content: "Join a global community of coffee enthusiasts.",
          
      },
      {
          label: "Submit Your Story",
          contentType: "form",
          formType: "story"
      }
  ];
  
    const maxSteps = steps.length;
    const [open, setOpen] = useState(true);
   

    useEffect(() => {
        const hasBeenWelcomed = sessionStorage.getItem('hasBeenWelcomed');
        if (!hasBeenWelcomed) {
            setOpen(true);
            sessionStorage.setItem('hasBeenWelcomed', 'true');
        }
    }, []);
   
  
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = () => {
        // Function to call mutation or pass data up to parent component
        updateUserProfile(userID, formData);
    };
    const handleClose = () => {
      setOpen(false);
      navigate('/post');
  }

  const handleNavigate = () => {
    navigate(`/kupster/${user.id}`);
  };


    const renderContent = (step) => {
        if (step.contentType === "content") {
            return (
              <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                
                
<Paper
  square
  elevation={0}
  sx={{
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 2,
    paddingLeft: 2,
    paddingTop: 0,  
   backgroundImage: `url(${step.imgPath})`,
   backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    
  }}
>
  
<div 
        className={classes.boxContainer}
        style={{
          position: 'absolute',
          top: '35px', // Slightly down from the top of the image
          left: '8px', // Slightly in from the right edge of the image
          zIndex: 2,
          textShadow: '2px 2px 8px rgba(0, 0, 0, 0.6)',

        }}
      >
        <div className={classes.logo}>
          <img src={kcoin} alt='koin' width={40} />
        </div>
        <Box className={classes.ribbon}>
          <div className={classes.ribbonText}>Internet Airport</div>
        </Box>
        <Typography variant="h5"   sx={{ fontWeight: 300, mb: 2 }}>
        KHALDI™
        </Typography>
      </div>


      <Typography variant="h4" sx={{
  mb: 15,
  mt: 0,
  fontWeight: 900,
  color: '#fff', // Your chosen text color
  padding: 2, 
  textShadow: '2px 2px 8px rgba(0, 0, 0, 2)'
}}>
  {step.label}
</Typography>

  <animated.div style={animationStyles}>
  <Typography variant="h6" sx={{ mb: 20, fontWeight: 900, textAlign: 'left',
 textShadow: '2px 2px 8px rgba(0, 0, 1, 2)', fontSize: 20, color: '#ffc107'
}}>{step.title}</Typography>

  </animated.div>
  <Typography variant="body" sx={{ mb: 10, color: '#077336', fontWeight: 300 ,
}}>{step.content}</Typography>
</Paper>


            </div>
            
            );
        
        } else if (step.contentType === "form" && step.formType === "preferences") {
            return (
              <Box sx={{
                p: 2, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center',
                alignItems: 'center', // This will center content horizontally
                
              
                backgroundImage: `url(${''})`, // Add your background image here
                backgroundSize: 'cover', // Adjust background size to cover the container
                backgroundPosition: 'center', // Adjust background position
                backgroundRepeat: 'no-repeat', // Prevent background from repeating
                height: '100vh', // Adjust height to fill the viewport
            }}>
                <animated.div style={animationStyles}>
                <Typography variant="h4" sx={{ mb: 4, mt: 1,  fontWeight: 800, textAlign: 'center', color: '#077336', }}>
                    Claim Your Digi Beans
                </Typography>
                </animated.div>
                
               
            
                {renderCard(data?.userprof)}
                
                <Typography variant="h6" sx={{fontweight: 700, mt: 2, color: 'text.secondary', textAlign: 'center' }}>  {/* Corrected typo 'bddy2' to 'body2' */}
                    Your KoffieLand Bean Card is approved.
                </Typography>
                <Box variant='body2' sx={{ width: '100%', mt: 6, mb: 0  }}>
                <Typography variant='h6' sx={{ mb: 2, fontWeight: 700,  textAlign: 'center' }}>
                  Claim your first 100 beans
                </Typography>
                <Box sx={{
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Centers children horizontally in the flex container
  justifyContent: 'center', // Centers children vertically in the flex container
}}>
  <button className={classes.buttonContainer} onClick={handleGetBeans}>Click and Claim!</button>
</Box>

               
                <Typography variant='body1' sx={{ mt: 6, textAlign: 'center' , color: 'green' }}>
                  In Koffieland, you always get free refills.
                </Typography>
                </Box>
            </Box>
            
            );
        }
        else if (step.contentType === "form" && step.formType === "story") {
          return (
            <Box sx={{
              p: 2, 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
             
              
             
              backgroundImage: `url(${''})`, // Add your background image here
              backgroundSize: 'cover', // Adjust background size to cover the container
              backgroundPosition: 'center', // Adjust background position
              backgroundRepeat: 'no-repeat', // Prevent background from repeating
              height: '100%', // Adjust height to fill the viewport
          }}>
                 <Typography variant="h3" sx={{ mt: 0, fontWeight: 200, textAlign: 'center', color: '#077336' }}>
                 Dose
                  </Typography>
                 <div style={{ position: 'relative', width: '100%', height: '350px' , }}>
                 {renderEspresso()}
                 </div>
                  <Typography variant="h6" sx={{ mt: 2, mb: 2, fontWeight: 200, textAlign: 'center', padding: 2 }}>
                  Discover, share and brew your story in every Kup
                  </Typography>
          
                 <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0, textAlign: 'center', alignContent: 'center' }}> 
        <Link to={`/kupster/${user.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>                
<Button sx={{ alignItems: 'center' ,width: '80%', height: '40px', borderRadius: 20, backgroundColor: '#077336', color: 'white', border: 'none', fontWeight: 700 }}>

  Let's Go!
 
  </Button>
  
  </Link>
  </div>            
             </Box>
             
          );
      }
      else if (step.contentType === "economy") {
        return (
          <Box sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            height: '100%',
          }}>
            <img
              src="https://storage.googleapis.com/app_darkendimg/assets/thebec.webp"
              alt="Background"
              style={{
                position: 'absolute',
                
                width: '100%',
                
                alignContent: 'center',
                opacity: 0.1, // Control opacity here
                zIndex: -1
              }}
            />
            {/* Your content here */}
          
          
              <Typography variant="h3" sx={{ mb: 2, fontWeight: 200, textAlign: 'center', color:'green' }}>
                Smart Koffie
              </Typography>
              
    
<Box sx={{ p: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', }}>
  <Typography variant="h4" sx={{ mb: 6, fontWeight: 400 }}>
    Beans and Exchange Commission (BEC)
  </Typography>
  <Typography variant="body2" sx={{ mb: 2, color: 'black', fontWeight: 300,textShadow: '0 0 0 12px', padding: 1 }}>
    Koffieland's BEC is a regulatory body that manages the circulation of beans via the XDC apothem-testnet blockchain for ultimate transparency.
  </Typography>
</Box>

<Box sx={{ maxWidth: 360, mx: 'auto', textAlign: 'center',  borderRadius: 5 , padding: 2 }}>
<animated.div style={animationStyles}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Avatar alt="Koffie Land" src={kcoin} sx={{ width: 50, height: 50 }} />
        </Grid>
        <Grid item>
          <Avatar src='https://storage.googleapis.com/app_darkendimg/products/xdc3.png' alt="Koffie Land" sx={{ width: 55, height: 55 }} />
        </Grid>
      </Grid>
      <Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 900, color: 'green' }}>
        Interact with 92 million smart bean tokens (23k lbs).
      </Typography>
      
      <Typography variant="body2" sx={{ mb: 2, mt: 6, fontWeight: 500 }}>
        From the farmer's seed <img src='https://storage.googleapis.com/app_darkendimg/assets/kbean.png' alt="Bean" width={30} />   to the bean <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="Bean" width={30} /> in your  kup, experience the most disruptive bevarage of all time.
      </Typography>
      </animated.div>
      
    </Box>

    <Typography variant="caption" sx={{ mb: 2, mt: 2, fontWeight: 500, color: 'green', textAlign: 'center' }}>
        This is not Crypto, this is BLOCKCHAIN.
      </Typography>  
                  
            </Box>
        );
    }
        

    };

   const renderCard = (userProfile) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
         <Card
      sx={{
        flex: 1,
        borderRadius: 4,
        overflow: 'hidden',
        width: '100%',
        
      }}
    >
      <CardContent
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto auto auto',
          gap: '8px',
          backgroundColor: '#addeb8',
          padding: 2,
        }}
      >
        <Box sx={{ gridColumn: '1 / 2', gridRow: '1' }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Bean Card
          </Typography>
        </Box>

        <Box sx={{ gridColumn: '1 / 2', gridRow: '2', display: 'flex', alignItems: 'center' }}>
          <img src={userProfile?.imageurl || 'https://storage.googleapis.com/app_darkendimg/assets/thebean.webp'} alt="Profile" width={80} style={{ borderRadius: '50%' }} />
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {userProfile?.Nickname || 'Kupster'}
          </Typography>
        </Box>

        <Box sx={{ gridColumn: '1 / 2', gridRow: '3' }}>
          <Typography variant="body2" sx={{ fontWeight: 400 }}>
            ID# {userProfile?._id.substring(0, 11)}
          </Typography>
        </Box>

        <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 4', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Typography variant="body2" sx={{ fontWeight: 700, marginRight: '8px' }}>
              {userProfile?.beanCount || 0}
            </Typography>
            <img src={kcoin} alt="Bean" width={30} />
          </Box>
          
        </Box>
      </CardContent>
    </Card>
      </Box>
          
      );
  }

  const renderEspresso = () => {
    return (
        <Box  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          
      <div className="container">
        <div className="coffee-header">
          <div className="coffee-header__buttons coffee-header__button-one"></div>
          <div className="coffee-header__buttons coffee-header__button-two"></div>
          <div className="coffee-header__display"></div>
          <div className="coffee-header__details"></div>
        </div>
        <div className="coffee-medium">
          <div className="coffee-medium__exit"></div>
          <div className="coffee-medium__arm"></div>
          <div className="coffee-medium__liquid"></div>
          <div className="coffee-medium__smoke coffee-medium__smoke-one"></div>
          <div className="coffee-medium__smoke coffee-medium__smoke-two"></div>
          <div className="coffee-medium__smoke coffee-medium__smoke-three"></div>
          <div className="coffee-medium__smoke coffee-medium__smoke-for"></div>
          <div className="coffee-medium__cup"></div>
        </div>
        <div className="coffee-footer"></div>
      </div>
     
      </Box>
    );
  }
  

const snack = <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Profile updated successfully." />;
    

    return (
        <Box sx={{ flexGrow: 1,  }}>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '100%', // Full width of the viewport
                        height: '100%', // Full height of the viewport
                        
                    },
                }}
            >
                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleChangeIndex}
                    enableMouseEvents
                    containerStyle={{ height: 'calc(100% - 0px)' }} // Adjust height to account for pagination and skip chip
                >
                    {steps.map((step, index) => renderContent(step))}
                </SwipeableViews>
                <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, py: 2, bgcolor: 'transparent', mb: 0 }}>
    <Pagination
        count={maxSteps}
        page={activeStep + 1}
        onChange={(_, value) => setActiveStep(value - 1)}
        sx={{ justifyContent: 'center', display: 'flex', bgcolor: 'transparent' }}
        renderItem={(item) => (
            <PaginationItem
                {...item}
                sx={{
                    '&.Mui-selected': {
                        bgcolor: '#077336', // Active item color
                        color: '#ffffff',
                        fontSize: 0, // Hide text
                        '& .MuiPaginationItem-icon': {
                            opacity: 1, // Full opacity for active
                        }
                    },
                    width: 10, // Width of the dot
                    height: 8, // Height of the dot
                    margin: '0 4px', // Spacing between dots
                    bgcolor: 'rgba(7, 115, 54, 0.3)', // Inactive item color (green with opacity)
                    
                    fontSize: 0, // Hide numbers
                    shape: 'bar', // Shape of the item
                }}
                components={{ previous: 'div', next: 'div' }} // Override default components to avoid arrow display
            />
        )}
    />
   <Box sx={{display: 'flex', position: 'absolute', bottom: 30, right:25, justifyContent: 'center', alignItems: 'center', mt: 0 }}>
   
    
        <CloseIcon onClick={handleNavigate} />
        <Typography variant="h6" sx={{ color: 'green', fontWeight: 700, fontSize: 16, textAlign: 'center' }}>
    Skip
    </Typography>
   
    </Box>
    
</Box>


            </Drawer>
        </Box>
    );
}

export default ShopOn;
 