import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function Progress() {
  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',       // full viewport width
        height: '100vh',      // full viewport height
        position: 'fixed',    // fix position to overlay over other content
        top: 0,
        left: 0,
        zIndex: 1000,         // high zIndex to ensure it's above other elements
        backgroundColor: 'transparent'  // Optional: white background with slight opacity
      }}
    >
      <img src='https://storage.googleapis.com/app_darkendimg/assets/kland.png' alt='Koffie Beans' style={{ width: '80px', height: '80px' }} />
    </Box>
  );
}

export default Progress;
